import { ApiBase, BaseParams } from "./api-base";

export interface ChBaseParams extends BaseParams {}

export interface GetChannelinfoParams extends BaseParams {
  st_add: string;
}

export interface CreateChParams {
  broad_title: string;
  broad_desc: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_prod_list: any;
  broad_status: string;
  broad_notice: string;
  vod_url: string;
  vod_started_at: string;
  vod_start_sec: number;
  vod_end_sec: number;
  high_start_sec: number;
  password: string;
  extra_type: string;
  progress_store: string;
  channel_img: string;
  channel_template: boolean;
  setting: any;
}

export interface GetChannelListParams {
  broad_date: string[];
  broad_status: string[];
  sort: string;
}

export interface GetChannelListProgressStoreParams {
  progress_store: string;
  broad_date: string[];
  broad_status: string[];
  sort: string;
}

export interface UpdateVodParams {
  pk: string;
  broad_seq: string;
  vod_url: string;
}

export interface UpdateSettingParams {
  pk: string;
  broad_seq: string;
  setting: any;
}

export interface UpdateChParams extends BaseParams, CreateChParams {
  host_id: string;
}

export interface SetStreamParams extends BaseParams {
  cmd: string;
}

export interface CreateTemplateParams {
  template_name: string;
  setting: any;
}

export interface GetTemplateListParams {
  template_name: string;
  template_seq: string;
  template_status: string[];
  sort: string;
}

export interface GetTemplateInfoParams {
  template_seq: string;
}

export interface UpdateTemplateParams {
  pk: string;
  template_seq: string;
  template_name: string;
  template_status: string;
  setting: any;
}

export interface SaveDefaultTemplateParams {
  template_seq: string;
  template_name: string;
  setting: any;
}

export interface SetProdClickParams {
  broad_seq: string;
  user_id: string;
  prod_id: string;
  prod_name: string;
  prod_price: string;
}

export interface GetProdClickParams {
  broad_seq: string;
}

export interface StartGameParams {
  broad_seq: string;
  user_id: string;
  flag: string;
  draw_number: number;
  timeout: number;
}

export interface GetGameListParams {
  broad_seq: string;
}

export interface GetGameApplyListParams {
  gameSeq: string;
}

export interface SetDrawGameParam {
  pk: string;
  sk: string;
  user_id: string;
  drawResult: any;
}

export interface SendChimeMessageParam {
  chime_channel_arn: string;
  content: string;
  sender_user_arn: string;
  type: string;
  persistence: string;
}

export interface PutBroadNotice {
  pk: string;
  broad_seq: string;
  broad_notice: string;
}

export interface PutChannelImg {
  pk: string;
  broad_seq: string;
  channel_img: string;
}

export class HttpChannelApi extends ApiBase {
  onApiName(): string {
    return "AdminApiName";
  }

  onUrlPath(): string {
    return "/channel";
  }

  public async get_prod_click(params: GetProdClickParams) {
    const requestBody = {
      command: "get_prod_click",
      broad_seq: params.broad_seq,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_prod_click(params: SetProdClickParams) {
    const requestBody = {
      command: "set_prod_click",
      prod_data: {
        broad_seq: params.broad_seq,
        user_id: params.user_id,
        prod_id: params.prod_id,
        prod_name: params.prod_name,
        prod_price: params.prod_price,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_info(params: GetChannelinfoParams) {
    const requestBody = {
      command: "get_channel_info",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
      },
      add_ivs_info: params.st_add /* 0: false, 1: true */,
      add_chime_info: params.st_add /* 0: false, 1: true */,
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_list(params: GetChannelListParams) {
    const requestBody = {
      command: "get_channel_list",
      search_condition: {
        broad_date: params.broad_date,
        broad_status: params.broad_status,
        sort: params.sort,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_list_progress_store(params: GetChannelListProgressStoreParams) {
    const requestBody = {
      command: "get_channel_list_progress_store",
      search_condition: {
        progress_store: params.progress_store,
        broad_date: params.broad_date,
        broad_status: params.broad_status,
        sort: params.sort,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_ivs_info(params: BaseParams) {
    const requestBody = {
      command: "get_ivs_info",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async create_channel(params: CreateChParams) {
    const user_id = await this.getUserId();
    if (user_id === undefined) {
      console.error("current user is undefined");
      return;
    }

    const requestBody = {
      command: "create_channel",
      broad_info: {
        broad_title: params.broad_title,
        broad_desc: params.broad_desc,
        broad_start_tm: params.broad_start_tm,
        broad_stop_tm: params.broad_stop_tm,
        broad_prod_list: params.broad_prod_list,
        broad_notice: params.broad_notice,
        vod_url: params.vod_url,
        vod_started_at: params.vod_started_at,
        vod_start_sec: params.vod_start_sec,
        vod_end_sec: params.vod_end_sec,
        high_start_sec: params.high_start_sec,
        password: params.password,
        extra_type: params.extra_type,
        progress_store: params.progress_store,
        setting: params.setting,
        host_id: user_id,
        channel_img: params.channel_img,
        channel_template: params.channel_template,
      },
      request_user_id: user_id,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async delete_channel(params: BaseParams) {
    const requestBody = {
      command: "delete_channel",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_channel(params: UpdateChParams) {
    const requestBody = {
      command: "update_channel",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
        broad_title: params.broad_title,
        broad_desc: params.broad_desc,
        broad_start_tm: params.broad_start_tm,
        broad_stop_tm: params.broad_stop_tm,
        broad_prod_list: params.broad_prod_list,
        broad_status: params.broad_status,
        broad_notice: params.broad_notice,
        vod_url: params.vod_url,
        vod_started_at: params.vod_started_at,
        vod_start_sec: params.vod_start_sec,
        vod_end_sec: params.vod_end_sec,
        high_start_sec: params.high_start_sec,
        password: params.password,
        extra_type: params.extra_type,
        progress_store: params.progress_store,
        setting: params.setting,
        host_id: params.host_id,
        channel_img: params.channel_img,
        channel_template: params.channel_template,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async make_download_media(params: UpdateChParams) {
    const requestBody = {
      command: "make_download_media",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
        broad_title: params.broad_title,
        broad_desc: params.broad_desc,
        broad_start_tm: params.broad_start_tm,
        broad_stop_tm: params.broad_stop_tm,
        broad_prod_list: params.broad_prod_list,
        broad_status: params.broad_status,
        broad_notice: params.broad_notice,
        vod_url: params.vod_url,
        vod_started_at: params.vod_started_at,
        vod_start_sec: params.vod_start_sec,
        vod_end_sec: params.vod_end_sec,
        high_start_sec: params.high_start_sec,
        password: params.password,
        extra_type: params.extra_type,
        progress_store: params.progress_store,
        setting: params.setting,
        host_id: params.host_id,
        channel_img: params.channel_img,
        channel_template: params.channel_template,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async handler_stream(params: SetStreamParams) {
    const requestBody = {
      command: params.cmd,
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_record_list(params: BaseParams) {
    const requestBody = {
      command: "get_record_list",
      broad_info: {
        broad_seq: params.broad_seq,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async delete_channel_message(chArn: string, msgId: string, chimeArn: string) {
    const requestBody = {
      command: "delete_channel_message",
      channel_arn: chArn,
      message_id: msgId,
      chime_bearer: chimeArn,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async block_channel_message(broadSeq: string, blockArn: string, blockTime: string) {
    const requestBody = {
      command: "block_channel_message",
      broad_seq: broadSeq,
      block_arn: blockArn,
      block_time: blockTime,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_channel_setting(param: UpdateSettingParams) {
    const requestBody = {
      command: "update_channel_setting",
      pk: param.pk,
      broad_seq: param.broad_seq,
      setting: param.setting,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async create_template(param: CreateTemplateParams) {
    const requestBody = {
      command: "create_template",
      template_info: {
        template_name: param.template_name,
        setting: param.setting,
        request_user_id: await this.getUserId(),
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_template_list(param: GetTemplateListParams) {
    const requestBody = {
      command: "get_template_list",
      template_seq: param.template_seq,
      template_name: param.template_name,
      template_status: param.template_status,
      sort: param.sort,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_template_info(param: GetTemplateInfoParams) {
    const requestBody = {
      command: "get_template_info",
      template_seq: param.template_seq,
    };

    const response = this.requestPost(requestBody);
    console.info("Request get_template_info : ", response);
    return response;
  }

  public async update_template(param: UpdateTemplateParams) {
    const requestBody = {
      command: "update_template",
      template_info: {
        pk: param.pk,
        template_seq: param.template_seq,
        template_name: param.template_name,
        template_status: param.template_status,
        setting: param.setting,
        request_user_id: await this.getUserId(),
      },
    };

    const response = this.requestPost(requestBody);
    console.info("Request update_template : ", response);
    return response;
  }

  public async set_default_template(param: SaveDefaultTemplateParams) {
    const requestBody = {
      command: "set_default_template",
      template_info: {
        template_seq: param.template_seq,
        template_name: param.template_name,
        setting: param.setting,
        request_user_id: await this.getUserId(),
      },
    };

    const response = this.requestPost(requestBody);
    console.info("Request set_default_template : ", response);
    return response;
  }

  public async get_default_template_info() {
    const requestBody = {
      command: "get_default_template_info",
    };

    const response = this.requestPost(requestBody);
    console.info("Request get_default_template_info : ", response);
    return response;
  }

  public async get_statistics_data(broadInfo: any) {
    const requestBody = {
      command: "get_statistics_data",
      pk: broadInfo.pk,
      broad_seq: broadInfo.broad_seq,
      broad_status: broadInfo.broad_status,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_media_info(record_info: any) {
    const requestBody = {
      command: "get_media_info",
      record_info: record_info,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_chime_message(param: SendChimeMessageParam) {
    let requestBody: any = param;
    requestBody.command = "put_chime_message";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_statistics_list(params: GetChannelListParams) {
    const requestBody = {
      command: "get_channel_statistics_list",
      search_condition: {
        broad_date: params.broad_date,
        broad_status: params.broad_status,
        sort: params.sort,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_cost_report(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_cost_report";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_broad_notice(param: PutBroadNotice) {
    let requestBody: any = param;
    requestBody.command = "put_broad_notice";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_channel_img(param: PutChannelImg) {
    let requestBody: any = param;
    requestBody.command = "put_channel_img";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_error_list(param: any) {
    const response = this.requestPost(param);
    return response;
  }
}
