import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpAdminIotApi } from "../../../service/admin-iot-rest-api";
import { HttpGameApi } from "../../../service/game-rest-api";
import { HttpChatApi } from "../../../service/chat-rest-api";
import { CommonUtils } from "../../../service/common_utils";
import LoadingCircle from "../../../utilities/LoadingCircle";
import { useInterval } from "../../../utilities/UseInterval";
import Toast from "../../../utilities/Toast";

interface propsType {
  userState: userState;
  broadInfo: any;
  chimeInfo: any;
}

const AutoCompleteMsg: string[] = ["잠시후 응모를 시작합니다.", "응모를 곧 마감합니다.", "잠시후 추첨을 시작합니다."];

const adminIotApi = new HttpAdminIotApi();
const gameApi = new HttpGameApi();
const chatApi = new HttpChatApi();

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const gameTypes: any = {
  apply: "응모하기",
};

interface IGameListCols {
  pk: string;
  sk: string;
  flag: string;
  game_status: string;
  manager_id: string;
  timeout: string;
  start_dtm: string;
  draw_number: number;
  winner_flag: string;
  draw_result: [];
}

interface IApplyListCols {
  pk: string;
  sk: string;
  cust_no: string;
  apply_dtm: string;
}

const GameInfo = (props: propsType) => {
  const toastRef: any = useRef();
  const [loading, setLoading] = useState(false);
  const cUtils = new CommonUtils();

  const [drawNumber, setDrawNumber] = useState<any>(0);
  const [timeoutMin, setTimeoutMin] = useState<any>(0);
  const [timeoutSec, setTimeoutSec] = useState<any>(0);

  const [gameList, setGameList] = useState<IGameListCols[]>([]);
  const [ingGameList, setIngGameList] = useState<IGameListCols[]>([]);
  const [endGameList, setEndGameList] = useState<IGameListCols[]>([]);

  const [loop, setLoop] = useState(true);
  const [dueTime, setDuetime] = useState("");
  const [remainTime, setRemainTime] = useState<any>("");

  const [gamePk, setGamePk] = useState("");
  const [gameSk, setGameSk] = useState("");
  const [gameType, setGameType] = useState("");
  const [drawNumberIng, setDrawNumberIng] = useState<any>(0);
  const [applyNumberIng, setApplyNumberIng] = useState<any>(0); // 현재 게임 참여자 숫자.

  const [applyLoop, setApplyLoop] = useState(false);
  const [applyList, setApplyList] = useState<IApplyListCols[]>([]);

  const [open, setOpen] = useState(false);
  const [openGameList, setOpenGameList] = useState(false);

  const gameListCols = [
    {
      field: "winner_flag",
      headerName: "당첨자",
      width: 120,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? (
          <Button
            variant="contained"
            onClick={() => {
              downloadWinnerExcelList(param.value!);
            }}
          >
            당첨자 명단
          </Button>
        ) : (
          <></>
        );
      },
    },
    {
      field: "start_dtm",
      headerName: "시작일시",
      width: 160,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    {
      field: "flag",
      headerName: "구분",
      width: 100,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{getGameTypeText(param.value)}</span>;
      },
    },
    { field: "game_status", headerName: "상태", width: 100 },
    { field: "manager_id", headerName: "관리자", width: 200 },
    {
      field: "timeout",
      headerName: "만료시간",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span> : "제한없음";
      },
    },
    {
      field: "sk",
      headerName: "재전송",
      width: 120,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? (
          <Button
            variant="contained"
            onClick={() => {
              // console.log("param.row : ", param.row);
              resend_winner(param.row);
            }}
          >
            당첨자 재공지
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  const resend_winner = async (info: any) => {
    if (!window.confirm("당첨자를 재공지 하시겠습니까?")) return;
    // console.log("info : ", info);
    const topic = `game/${props.broadInfo.broad_seq}`;
    const payload: any = {
      prot: "draw",
      game_seq: info.sk,
    };
    await sendIoTMessage(topic, payload);
    await sendWinner(info.draw_result, info.draw_number);
  };

  const getGameTypeText = (input: string | undefined) => {
    if (input !== undefined) {
      return gameTypes[input];
    } else {
      return "";
    }
  };

  const setGame = (flag: string) => {
    setOpen(true);
  };

  const sendIoTMessage = async (topic: string, payload: any) => {
    const param: any = {
      topic: topic,
      payload: payload,
    };

    const res = await adminIotApi.send_iot_message(param);
  };

  const sendMessage = async (msg: string, meta: string) => {
    try {
      const msgId = uuidv4();
      const topic = `live/${props.broadInfo.broad_seq}/payload`;
      const chatPayload = {
        topic: "chat/message",
        ChannelArn: props.chimeInfo.chime_channel_arn,
        Content: msg,
        CreatedTimestamp: "",
        LastUpdatedTimestamp: "",
        MessageId: msgId,
        Persistence: "PERSISTENT",
        Redacted: false,
        Sender: {
          Arn: props.chimeInfo.chime_admin_arn,
          Name: props.userState.nickName,
        },
        Status: {
          Value: "SENT",
        },
        Type: "STANDARD",
      };
      const iotParam: any = {
        topic: topic,
        payload: chatPayload,
      };
      // 채팅 금지 확인 필요
      // IoT 채팅 메세지 전송
      await adminIotApi.send_iot_message(iotParam);
      // 채팅 히스토리 저장
      await chatApi.send_chat_history(chatPayload);
    } catch (e) {
      console.error("send msg ERROR : ", e);
    }
  };

  const getGames = async () => {
    initForm();
    const param: any = {
      broad_seq: props.broadInfo.broad_seq,
    };

    const res = await gameApi.get_game_list(param);
    if (res.code === "200") {
      setGameList(res.response.game_list);
      let tempList: any = [];
      let tempListEnd: any = [];
      for (const gameInfo of res.response.game_list) {
        if (gameInfo.game_status === "START") {
          tempList.push(gameInfo);
        } else {
          tempListEnd.push(gameInfo);
        }
      }
      setIngGameList(tempList);
      setEndGameList(tempListEnd);
      setOpen(false);
    } else {
      console.error("error : ", res.response.error_msg);
    }
  };

  const startGame = async (flag: string) => {
    const param: any = {
      broad_seq: props.broadInfo.broad_seq,
      user_id: props.userState.id,
      flag: flag,
      draw_number: drawNumber,
      timeout: timeoutMin * 60 + timeoutSec * 1,
    };

    const res = await gameApi.start_game(param);
    if (res.code === "200") {
      // 차임 메세지 전송
      const topic = `game/${props.broadInfo.broad_seq}`;
      const payload: any = {
        prot: "start",
        game_seq: res.response.game_seq,
      };
      await sendIoTMessage(topic, payload);
      getGames();
    } else if (res.code === "201") {
      window.alert("이미 진행중인 게임이 있습니다. 기존게임을 종료 후 다시 시도하세요.");
    } else {
      console.error(res.response.error_msg);
    }
  };

  const getApplyList = async (seq: string) => {
    const param: any = {
      game_seq: seq,
    };

    const res = await gameApi.get_game_apply_list(param);
    if (res.code === "200") {
      setApplyList(res.response.apply_list);
    } else {
      console.error(res.response.error_msg);
    }
  };

  useEffect(() => {
    setApplyNumberIng(applyList.length);
  }, [applyList]);

  useEffect(() => {
    setApplyNumberIng(applyList.length);
  }, [applyList]);

  // 필드 초기화
  const initForm = () => {
    setIngGameList([]);
    setLoop(false);
  };

  // 게임 남은시간 계산하기
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      if (dueTime !== "") {
        const remainSec = cUtils.calRemainSec(dueTime);
        setRemainTime(cUtils.convertRemainText(remainSec));
        if (remainSec < 0) {
          setRemainTime(cUtils.convertRemainText(0));
          setLoop(false);
        }
      }
    },
    loop ? delay : null
  );

  useEffect(() => {
    if (ingGameList.length > 0) {
      setGamePk(ingGameList[0].pk);
      setGameSk(ingGameList[0].sk);
      setGameType(ingGameList[0].flag);
      setDuetime(ingGameList[0].timeout);
      setDrawNumberIng(ingGameList[0].draw_number);
      getApplyList(ingGameList[0].sk);
      setLoop(true);
      setApplyLoop(true);
    } else {
      setGamePk("");
      setGameSk("");
      setGameType("");
      setDuetime("");
      setDrawNumberIng(0);
      setApplyList([]);
      setLoop(false);
      setApplyLoop(false);
    }
  }, [ingGameList]);

  // 참여자 조회
  const applyDelay: number = 3000;
  useInterval(
    () => {
      getApplyList(gameSk);
    },
    applyLoop ? applyDelay : null
  );

  // 추첨하기
  const drawGame = async () => {
    let target = [];
    const param1: any = {
      game_seq: gameSk,
    };
    const res1 = await gameApi.get_game_apply_list(param1);
    if (res1.code === "200") {
      setApplyList(res1.response.apply_list);
      for (const user of res1.response.apply_list) {
        if (user.last_no === "admin") continue;
        target.push({
          user_id: user.sk,
          cust_no: user.cust_no,
          last_no: user.last_no,
          apply_dtm: user.apply_dtm,
        });
      }

      let drawNum: any = drawNumberIng;

      if (target.length < drawNumberIng) {
        // toastRef.current?.toast("추첨인원보다 참여인원이 적습니다.", "error", 3000);
        // return;
        drawNum = target.length;
      }

      if (!window.confirm("게임 추첨을 하시겠습니까?")) return;

      // 추첨
      const totalTargetCnt = target.length - drawNum;
      let drawResult: any = [];
      while (target.length > totalTargetCnt) {
        let movenum = target.splice(Math.floor(Math.random() * target.length), 1)[0];
        drawResult.push(movenum);
      }

      const param: any = {
        pk: gamePk,
        sk: gameSk,
        user_id: props.userState.id,
        drawResult: drawResult,
      };

      const res = await gameApi.draw_game(param);
      if (res.code === "200") {
        toastRef.current?.toast("추첨을 완료했습니다.", "success", 3000);
        getGames();
        const topic = `game/${props.broadInfo.broad_seq}`;
        const payload: any = {
          prot: "draw",
          game_seq: gameSk,
        };
        await sendIoTMessage(topic, payload);
        await sendWinner(drawResult, drawNumberIng);
      } else {
        toastRef.current?.toast("추첨 중 오류가 발생했습니다.", "error", 3000);
      }
    } else {
      console.error(res1.response.error_msg);
    }
  };

  const sendWinner = async (_drawResult: any, _drawNumberIng: number) => {
    let winner = "";
    for (let i = 0; i < _drawResult.length; i++) {
      winner = winner + cUtils.maskingChar(_drawResult[i].user_id.split("@")[0]);
      if (_drawResult[i].last_no !== undefined && _drawResult[i].last_no !== "" && _drawResult[i].last_no !== null) {
        winner = winner + "(" + _drawResult[i].last_no + ")";
      }
      if (i !== _drawResult.length - 1) {
        winner = winner + ", \n";
      }
    }
    const msg = `${_drawNumberIng}명 추첨 결과\n${winner} 님\n당첨을 축하드립니다!`;
    await sendMessage(msg, "");
  };

  const downloadWinnerExcelList = (sk: string) => {
    for (const item of gameList) {
      if (item.winner_flag === sk) {
        // sheet header
        let winnerContent: any = [["아이디", "고객번호", "응모시간"]];
        // 각 행 순번 만들기
        let winnerIndex = 1;
        // 내용 생성
        for (const winner_info of item.draw_result) {
          const detail = [winner_info["user_id"], winner_info["cust_no"], dayjs(winner_info["apply_dtm"]).format("YYYY-MM-DD HH:mm:ss")];
          winnerContent = [...winnerContent, detail];
          winnerIndex += 1;
        }
        // 컬럼 넓이
        const colWidth = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

        const winnerSheetName = "당첨자 내역";

        // 파일명
        const fileName = dayjs(String(item.start_dtm)).format("YYYY-MM-DD HHmm") + "_당첨자내역_" + props.broadInfo.broad_title + ".xlsx";
        cUtils.downloadExcel(winnerContent, colWidth, winnerSheetName, fileName);

        return;
      }
    }

    alert("당첨자 다운로드는 게임 종료 후 가능합니다.");
  };

  useEffect(() => {
    getGames();
    return () => {
      setDrawNumber(0);
      setTimeoutMin(0);
      setTimeoutSec(0);
      setGameList([]);
      setIngGameList([]);
      setEndGameList([]);
      setLoop(true);
      setDuetime("");
      setRemainTime("");
      setGamePk("");
      setGameSk("");
      setDrawNumberIng(0);
      setApplyNumberIng(0); // 현재 게임 참여자 숫자.
      setApplyLoop(false);
      setApplyList([]);
      setOpen(false);
      setOpenGameList(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box>
          <Stack spacing={2} direction="column">
            {ingGameList.length === 0 ? (
              <Button
                variant="contained"
                onClick={() => {
                  setGame("apply");
                }}
                fullWidth
              >
                응모하기 게임시작
              </Button>
            ) : (
              <ButtonGroup variant="outlined" fullWidth>
                <Button>
                  참여 : {applyNumberIng}명 {remainTime}
                </Button>
                <Button variant="contained" onClick={drawGame}>
                  {drawNumberIng} 명 추첨하기
                </Button>
              </ButtonGroup>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                setOpenGameList(true);
              }}
              endIcon={<SearchIcon />}
            >
              종료된 게임 : {endGameList.length}건
            </Button>
            <Divider />
            {AutoCompleteMsg.map((msg, index) => (
              <Button
                key={index + msg}
                variant="contained"
                sx={{ justifyContent: "flex-start" }}
                onClick={() => {
                  sendMessage(msg, "");
                }}
                startIcon={<SendIcon />}
              >
                {msg}
              </Button>
            ))}
          </Stack>
        </Box>
      )}
      {/* 게임시작 */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              추첨게임을 시작 하시겠습니까?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              참여한 사람 중 n명을 추첨하는 게임입니다.
            </Typography>
            <TextField
              label="추첨인원"
              value={drawNumber}
              variant="outlined"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(e) => {
                setDrawNumber(e.target.value);
              }}
            />
            <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
              <TextField
                label="제한시간(분)"
                value={timeoutMin}
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                onChange={(e) => {
                  setTimeoutMin(e.target.value);
                }}
              />
              <TextField
                label="제한시간(초)"
                value={timeoutSec}
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                onChange={(e) => {
                  setTimeoutSec(e.target.value);
                }}
              />
            </Stack>
            <Button
              variant="contained"
              className="bt-md"
              fullWidth
              onClick={() => {
                startGame("apply");
              }}
            >
              시작
            </Button>
          </Stack>
        </Box>
      </Modal>
      {/* 게임시작 */}
      {/* 당첨자 목록 */}
      <Dialog
        open={openGameList}
        onClose={() => {
          setOpenGameList(false);
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": { width: "100%", minWidth: "50%" },
          },
        }}
      >
        <DialogTitle>{"게임 목록"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
            <div style={{ width: "100%", height: "490px" }}>
              <DataGrid rows={endGameList} columns={gameListCols} pageSize={100} getRowId={(row) => row.sk} />
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenGameList(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 당첨자 목록 */}
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default GameInfo;
