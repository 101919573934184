import { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi } from "../../service/thehyundai-rest-api";

interface IUserCols {
  manager_id: string;
  manager_name: string;
  memo: string;
  team_name: string;
  use_yn: string;
}

const GetManagerList = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [managerRows, setManagerRows] = useState<IUserCols[]>([]);
  const managerCols = [
    { field: "team_name", headerName: "TEAM", width: 150 },
    { field: "manager_id", headerName: "ID", width: 200 },
    { field: "manager_name", headerName: "NAME", width: 300 },
    { field: "memo", headerName: "MEMO", flex: 1 },
    { field: "use_yn", headerName: "USEYN", width: 100 },
  ];

  const getManagerList = async () => {
    setIsLoading(true);

    const res = await theHyundaiApi.get_broadcast_manager_list();
    if ("" + res.code === "200") {
      setManagerRows(res.response.manager_list);
    }

    setIsLoading(false);
  };
  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            관리자 리스트 조회 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            더현대닷컴 관리자로 등록된 리스트를 조회한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "get_broadcast_manager_list"
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { manager_list: ["*manager_info"] } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            *manager_info
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - memo (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - team_name (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - use_yn (string) : "Y|N" !기본적으로 "N"은 제공하지 않음. 필요시 추가 요청
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <Button variant="contained" onClick={getManagerList}>
            관리자 리스트 조회 요청
          </Button>
          {/** 사용자 리스트 그리드 */}
          <div style={{ width: "100%", height: "490px" }}>
            <DataGrid
              rows={managerRows}
              columns={managerCols}
              pageSize={7}
              rowsPerPageOptions={[7]}
              getRowId={(row) => row.manager_id}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: GridToolbarFilterButton,
              }}
              loading={isLoading}
            />
          </div>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default GetManagerList;
