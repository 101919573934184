import { useState, useRef, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {
  HttpChannelApi,
  CreateTemplateParams,
  GetTemplateInfoParams,
  UpdateTemplateParams,
  SaveDefaultTemplateParams,
} from "../../service/channel-api";
import { userState, emptySetting } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";

import TabSetting from "./TabSetting";
import IoTClient from "../../service/iot/IoTClient";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  loadTemplateList: any;
  reloadTemplateList: any;
  openTemplatePreview: any;
  defaultTemplateSeq: string;
}
const SettingDetail = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const [loading, setLoading] = useState(false);
  const iotRef: any = useRef();

  const tabSettingRef: any = useRef();
  const [templateSeq, setTemplateSeq] = useState("");
  const [templateInfo, setTemplateInfo] = useState<any>({});

  const [templateName, setTemplateName] = useState(""); // 검색조건 템플릿명
  const [defaultTemplate, setDefaultTemplate] = useState(false);

  const getTemplateInfo = async (seq: string) => {
    setLoading(true);
    const param: GetTemplateInfoParams = {
      template_seq: seq,
    };
    const res = await chApi.get_template_info(param);
    if (res.result_code === "200") {
      setTemplateInfo(res.result_body);
      setTemplateSeq(res.result_body.template_seq);
      setTemplateName(res.result_body.template_name);
      tabSettingRef.current.setSettingData(res.result_body);
      if (props.defaultTemplateSeq === res.result_body.template_seq) setDefaultTemplate(true);
      else setDefaultTemplate(false);
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const addNewTemplate = async () => {
    setLoading(true);
    const param: CreateTemplateParams = {
      template_name: templateName,
      setting: emptySetting,
    };
    const res = await chApi.create_template(param);
    if (res.result_code === "200") {
      props.loadTemplateList(res.result_body.template_seq);
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const initForm = () => {
    setTemplateInfo({});
    setTemplateSeq("");
    setTemplateName("");
    tabSettingRef.current.initForm();
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (templateSeq !== "" && templateSeq !== undefined) {
      if (defaultTemplate) return; // 이미 기본이면 수정 못함.
      if (!window.confirm("기본 템플릿으로 설정하시겠습니까?")) return;
      saveDefaultTemplate();
    }
  };

  const sendIoTMessageMeta = (metaName: string) => {
    const topic = `template/${templateSeq}/metadata`;
    iotRef.current.sendIoTMessageMeta(topic, metaName);
  };

  const openTemplatePreview = (templateSeq: string) => {
    props.openTemplatePreview(templateSeq);
  };

  const updateTemplate = async () => {
    setLoading(true);
    const param: UpdateTemplateParams = {
      pk: templateInfo.pk,
      template_seq: templateInfo.template_seq,
      template_name: templateName,
      template_status: templateInfo.template_status,
      setting: templateInfo.setting,
    };
    const res = await chApi.update_template(param);
    if (res.result_code === "200") {
      props.reloadTemplateList();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const deleteTemplate = async () => {
    if (!window.confirm("선택하신 템플릿을 삭제하시겠습니까?")) return;
    setLoading(true);
    const param: UpdateTemplateParams = {
      pk: templateInfo.pk,
      template_seq: templateInfo.template_seq,
      template_name: templateName,
      template_status: "DELETE",
      setting: templateInfo.setting,
    };
    const res = await chApi.update_template(param);
    if (res.result_code === "200") {
      props.reloadTemplateList();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const saveDefaultTemplate = async () => {
    setLoading(true);
    const param: SaveDefaultTemplateParams = {
      template_seq: templateInfo.template_seq,
      template_name: templateName,
      setting: templateInfo.setting,
    };
    const res = await chApi.set_default_template(param);
    if (res.result_code === "200") {
      props.reloadTemplateList();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {}

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    initForm,
    getTemplateInfo,
  }));

  // IoT 연결 성공
  const iotConnectEnd = () => {
    console.info("iot Connection Success");
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1} sx={{ mt: 1 }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: "3px" }}>
          <TextField
            label="템플릿명"
            value={templateName}
            size="small"
            fullWidth
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
          />
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={defaultTemplate} onChange={handleSwitchChange} name="defaultTemplate" />}
                label="기본으로 설정"
                sx={{ minWidth: 300 }}
              />
            </FormGroup>
          </FormControl>
          {templateSeq !== "" && templateSeq !== undefined ? (
            <>
              <Button variant="outlined" size="small" onClick={initForm}>
                초기화
              </Button>
              <Button variant="outlined" size="small" onClick={updateTemplate} sx={{ minWidth: 70 }}>
                이름변경
              </Button>
              <Button variant="outlined" size="small" onClick={deleteTemplate} sx={{ minWidth: 70 }}>
                삭제
              </Button>
            </>
          ) : (
            <Button variant="outlined" size="small" onClick={addNewTemplate}>
              추가
            </Button>
          )}
        </Stack>
        <Box sx={{ display: "flex", width: "100%" }}>
          <TabSetting
            ref={tabSettingRef}
            userState={props.userState}
            templateInfo={templateInfo}
            getTemplateInfo={getTemplateInfo}
            sendIoTMessageMeta={sendIoTMessageMeta}
            openTemplatePreview={openTemplatePreview}
          />
        </Box>
      </Stack>
      <LoadingCircle loading={loading} />
      {templateSeq !== undefined && (
        <IoTClient
          ref={iotRef}
          broadSeq={templateSeq}
          iotRegion={appConfig.iotRegion}
          iotIdentityPollId={appConfig.iotIdentityPollId}
          iotEndPoint={appConfig.iotEndPoint}
          recieveIoTMessage={recieveIoTMessage}
          iotConnectEnd={iotConnectEnd}
          iotLost={iotLost}
        />
      )}
    </Box>
  );
};

export default forwardRef(SettingDetail);
