import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import LinearProgress from "@mui/material/LinearProgress";
import { appConfig } from "../../config/Config";
import { DataGrid, GridCellParams, GridToolbarFilterButton, GridSelectionModel } from "@mui/x-data-grid";
import { userState, templateInfo } from "../../interface/MainInterface";
import { HttpChannelApi, GetTemplateListParams } from "../../service/channel-api";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  selectRow: any;
  initForm: any;
  setDefaultTemplateSeq: any;
}
const SearchCondition = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const [loading, setLoading] = useState(false); // 조회중
  const [templateName, setTemplateName] = useState(""); // 검색조건 템플릿명
  const gridRef: any = useRef(); // 그리드 ref
  const [templateRows, setTemplateRows] = useState<templateInfo[]>([]); // 템플릿 목록
  const [pageSize, setPageSize] = useState<number>(100);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const columns = [
    { field: "default", headerName: "", width: 60 },
    { field: "template_name", headerName: "템플릿명", flex: 1 },
  ];

  const getTemplateList = async () => {
    setLoading(true);
    props.initForm();
    setSelectionModel([]);
    const resDefault = await chApi.get_default_template_info();
    if (resDefault.result_code === "200") props.setDefaultTemplateSeq(resDefault.result_body.template_seq);
    else props.setDefaultTemplateSeq("");
    const param: GetTemplateListParams = {
      template_name: templateName,
      template_seq: "",
      template_status: ["CREATE"],
      sort: "ASCEND",
    };
    const res = await chApi.get_template_list(param);
    if (res.result_code === "200") {
      setTemplateRows(res.result_body);
    } else {
      console.error("res : ", res.result_body);
    }

    setLoading(false);
  };

  const getTemplateListSeq = async (seq: string) => {
    setLoading(true);
    props.initForm();
    setSelectionModel([]);
    const param: GetTemplateListParams = {
      template_name: templateName,
      template_seq: seq,
      template_status: ["CREATE"],
      sort: "ASCEND",
    };
    const res = await chApi.get_template_list(param);
    if (res.result_code === "200") {
      setTemplateRows(res.result_body);
    } else {
      console.error("res : ", res.result_body);
    }

    setLoading(false);
  };

  // Row 선택시
  const clickTemplateRow = (param: GridCellParams) => {
    props.selectRow(param);
  };

  useImperativeHandle(ref, () => ({
    getTemplateList,
    getTemplateListSeq,
  }));

  useEffect(() => {
    getTemplateList();
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1} sx={{ mt: 1 }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: "3px" }}>
          <TextField
            label="검색조건-템플릿명"
            value={templateName}
            size="small"
            fullWidth
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
          />
          <Button variant="outlined" size="small" onClick={getTemplateList}>
            조회
          </Button>
        </Stack>
        <Box sx={{ height: 674, width: "100%" }}>
          <Paper sx={{ width: "100%", height: 673, p: 2 }} elevation={5}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGrid
                ref={gridRef}
                rows={templateRows}
                rowHeight={40}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[3, 10, 20, 50, 100]}
                pagination
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                getRowId={(row) => row.template_seq}
                onCellClick={clickTemplateRow}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: GridToolbarFilterButton,
                }}
                loading={loading}
              />
            </Box>
          </Paper>
        </Box>
        {/* 로딩바 */}
        <LoadingCircle loading={loading} />
      </Stack>
    </Box>
  );
};

export default forwardRef(SearchCondition);
