import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import LoadingCircleOnVideo from "../../utilities/LoadingCircleOnVideo";
import { CommonUtils } from "../../service/common_utils";
import "./ClientIVSPlayer.css";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

interface propsType {
  broad_seq: string;
  playbackUrl: string;
  video_ref: any;
  vod_start_sec: number;
  vod_end_sec: number;
  videoStatusCallback: any;
  videoPositionCallback: any;
  pip_mode: boolean;
  pip_callback: any;
  hidden_menu: boolean;
  fitMode: boolean;
}

export let player: any = null;

export function VodIVSPlayer(props: propsType) {
  const PLAYBACK_URL = props.playbackUrl;
  const cUtils = new CommonUtils();
  const videoControls = React.createRef<HTMLDivElement>();
  const [videoDuration, setVideoDuration] = useState(100000);
  const [videoCurrentTime, setVideoCurrentTime] = useState(props.vod_start_sec);
  const [videoRemianTime, setVideoRemainTime] = useState("00:00:00");
  const isChange = useRef(false);
  const [customControl, setCustomControl] = useState(false);
  const [play, setPlay] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    mediaPlayerScriptLoaded();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setVideoCurrentTime(props.vod_start_sec);
      setVideoRemainTime("00:00:00");
      setCustomControl(false);
      setPlay(true);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (PLAYBACK_URL !== "") {
      player.load(PLAYBACK_URL);
    }
  }, [PLAYBACK_URL]);

  let PlayerState: any;
  const mediaPlayerScriptLoaded = () => {
    const MediaPlayerPackage = window.IVSPlayer;

    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("The current browser does not support the Amazon IVS player.");
      return;
    }

    PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
    player.addEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);

    player.setAutoQualityMode(true);
    player.setAutoplay(false);
    player.setMuted(true);
    player.setVolume(1.0);

    const videoWorks = !!document.createElement("video").canPlayType;
    if (videoWorks) {
      props.video_ref.current.controls = false;
      videoControls.current?.classList.remove("hidden");
      setCustomControl(true);

      props.video_ref.current?.addEventListener("enterpictureinpicture", onEnterPip);
      props.video_ref.current?.addEventListener("leavepictureinpicture", onLeavePip);
    }
  };

  const onEnterPip = (e: any) => {
    props.pip_callback(true);
    window.resizeTo(200, 10); // PC 크롬만 됨
    window.moveTo(5000, 5000); // PC 크롬만 됨
  };

  const onLeavePip = (e: any) => {
    props.pip_callback(false);
    window.resizeTo(4000, 4000); // PC 크롬만 됨
    window.moveTo(0, 0); // PC 크롬만 됨
  };

  useEffect(() => {
    if (props.pip_mode) {
      videoControls.current?.classList.add("hidden");
    } else {
      videoControls.current?.classList.remove("hidden");
    }
  }, [props.pip_mode]);

  useEffect(() => {
    if (props.hidden_menu) {
      videoControls.current?.classList.add("padding-10");
      videoControls.current?.classList.remove("bottom--11");
    } else {
      videoControls.current?.classList.remove("padding-10");
      videoControls.current?.classList.add("bottom--11");
    }
  }, [props.hidden_menu]);

  const onIdle = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onIdle");
  };

  const onReady = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState} : ${player.getDuration()}`);
    props.videoStatusCallback("onReady");
    player.seekTo(props.vod_start_sec);
    player.setAutoplay(true);
    player.play();
  };

  const onStatePlaying = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState} : ${player.getDuration()}`);
    props.videoStatusCallback("onStatePlaying");
    if (props.vod_end_sec > 0) {
      setVideoDuration(props.vod_end_sec);
    } else {
      setVideoDuration(player.getDuration());
    }

    let realPostion = player.getPosition();
    if (props.vod_end_sec > 0 && realPostion >= props.vod_end_sec) {
      player.seekTo(props.vod_start_sec);
    }
    setIsLoading(false);
    isChange.current = false;
  };

  const onStateEnd = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    setPlay(false);
    props.videoStatusCallback("onStateEnd");
  };

  const onError = (err: any) => {
    console.warn("Player Event - ERROR:", err);
    setPlay(false);
    props.videoStatusCallback("onError");
  };

  const onTimeUpdate = (cue: any) => {
    let realPostion = player.getPosition();
    let realDuration = player.getDuration();

    if (props.vod_end_sec > 0) {
      // VOD 종료시간이 Custom이면 해당 값으로 Duration 변경
      realDuration = props.vod_end_sec;
    }
    props.videoPositionCallback("onTimeUpdate", realPostion);

    if (!isChange.current) {
      setVideoCurrentTime(realPostion);
      const remainTime = realDuration - realPostion;
      const t = cUtils.convertSecToTime(remainTime);
      if (remainTime < 0) {
        // 남은시간이 -로 남는경우(vod_end_sec는 정수(00초)인데 실제 스트림은 xx.235초 같이 소수가 있을때)
        setVideoRemainTime("00:00:00");
      } else {
        setVideoRemainTime(t.hours + ":" + t.minutes + ":" + t.seconds);
      }
    }
    if (props.vod_end_sec > 0 && realPostion >= props.vod_end_sec) {
      // vod_end_sec는 수기 값으로 customcontrol은 max지만 스트림은 계속 재생함. 따라서 해당 시간 이상은 영상을 일시정지 시킴.
      player.pause();
      setPlay(false);
    }
  };

  const onSeekCompleted = (cue: any) => {
    setIsLoading(false);
  };

  const togglePlay = () => {
    if (play) {
      player.pause();
      setPlay(false);
    } else {
      player.play();
      setPlay(true);
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    isChange.current = true;
    setVideoCurrentTime(newValue as number);
  };

  const completeChange = (event: any, newValue: number | number[]) => {
    setIsLoading(true);
    player.seekTo(newValue as number);
    isChange.current = false;
  };

  function valueLabelFormat(value: number) {
    const t = cUtils.convertSecToTime(value - props.vod_start_sec);
    return t.hours + ":" + t.minutes + ":" + t.seconds;
  }

  return (
    <>
      <Box
        id="vod-screen"
        component="div"
        className="vod-screen-overlay"
        sx={customControl ? { display: "none" } : { display: "flex" }}
      ></Box>
      <video
        id="videoPlayer"
        ref={props.video_ref}
        className={props.fitMode ? "client-video video-contain" : "client-video video-cover"}
        playsInline
        controls
      ></video>
      <div className="video-controls padding-10 hidden" id="video-controls" ref={videoControls}>
        <Stack direction="row" spacing={1}>
          <IconButton sx={props.hidden_menu ? { padding: 0, display: "flex" } : { padding: 0, display: "none" }} onClick={togglePlay}>
            {play ? <PauseIcon className="color-snow" /> : <PlayArrowIcon className="color-snow" />}
          </IconButton>
          <Slider
            aria-label="video"
            value={videoCurrentTime}
            min={props.vod_start_sec}
            max={videoDuration}
            step={0.1}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
            onChangeCommitted={completeChange}
            sx={
              props.hidden_menu
                ? { pt: "11px", ["@media (pointer: coarse)"]: { pt: "11px" } }
                : {
                    pt: "11px",
                    ["@media (pointer: coarse)"]: { pt: "11px" },
                    "& .MuiSlider-thumb": { width: "0px" },
                  }
            }
          />

          <Typography
            sx={
              props.hidden_menu
                ? {
                    color: "snow",
                    display: "inline-block",
                    ["@media (pointer: coarse)"]: { pt: "6px" },
                  }
                : {
                    color: "snow",
                    display: "none",
                    ["@media (pointer: coarse)"]: { pt: "6px" },
                  }
            }
          >
            <span className="svc-text">{videoRemianTime}</span>
          </Typography>
        </Stack>
      </div>
      <LoadingCircleOnVideo loading={isLoading} />
    </>
  );
}
