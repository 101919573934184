import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { appConfig } from "../../config/Config";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "../chat/Chat.css";

interface propsType {
  setting: any;
  screenMode: string;
}

const TemplateChat = (props: propsType) => {
  // Chime Messages
  const [messages, setMessages] = useState<any>([]);
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents
  const [visibility, setVisibility] = useState("visible");
  const adminArn = "admin";
  const userArn = "loginUser";

  const chatList = [
    {
      Content: "",
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: "",
      Redacted: false,
      Sender: {},
      Type: "FAKE",
    },
    {
      Content: "채팅 테스트",
      CreatedTimestamp: "20220920181108416000",
      LastUpdatedTimestamp: "20220920181108416000",
      MessageId: "980255d76d67ab023bfb6d8673cba90d5690db2f7414e9ee7695fe1a7ebc44b8",
      Redacted: false,
      Sender: {
        Arn: "admin",
        Name: "관리자",
      },
      Type: "STANDARD",
    },
    {
      Content: "가나다라마바사아자차카타파하",
      CreatedTimestamp: "20220920181109416000",
      LastUpdatedTimestamp: "20220920181109416000",
      MessageId: "980255d76d67ab023bfb6d8673cba90d5691db2f7414e9ee7695fe1a7ebc44b8",
      Redacted: false,
      Sender: {
        Arn: "admin",
        Name: "관리자",
      },
      Type: "STANDARD",
    },
    {
      Content: "안녕하세요 로그인한 본인입니다.",
      CreatedTimestamp: "20220920181110416000",
      LastUpdatedTimestamp: "20220920181110416000",
      MessageId: "980255d76d67ab023bfb6d8673cba90d5291db2f7414e9ee7695fe1a7ebc44b8",
      Redacted: false,
      Sender: {
        Arn: "loginUser",
        Name: "본인",
      },
      Type: "STANDARD",
    },
    {
      Content: "저는 다른 사람입니다.",
      CreatedTimestamp: "20220920181111416000",
      LastUpdatedTimestamp: "20220920181111416000",
      MessageId: "980255d76d67ab023bfb6d8673cba90d5291db3f7414e9ee7695fe1a7ebc44b8",
      Redacted: false,
      Sender: {
        Arn: "anotherUser",
        Name: "일반고객",
      },
      Type: "STANDARD",
    },
  ];

  useEffect(() => {
    setMessages(chatList);
  }, []);

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current.scrollToItem(messages.length, "end");
    }
  };

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);
    let textShadow = "";
    if (props.setting.chat_shadow) textShadow = "1px 1px 1px " + props.setting.chat_shadow_color;

    return (
      <ListItem style={style} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={
                  messages[index].Type === "FAKE"
                    ? {
                        fontSize: "1.0rem",
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        pl: 1,
                        pr: 1,
                      }
                    : messages[index].Sender.Arn === adminArn
                    ? {
                        /* 방송 관리자 메세지 별도 강조 */
                        fontSize: chatTextSize,
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        border: 1,
                        borderRadius: 2,
                        padding: 1,
                        borderColor: "primary.main",
                      }
                    : {
                        fontSize: chatTextSize,
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        pl: 1,
                        pr: 1,
                      }
                }
              >
                <div>
                  <span
                    style={
                      messages[index].Sender.Arn === adminArn
                        ? { color: "Yellow", whiteSpace: "nowrap", marginRight: "5px", textShadow: textShadow }
                        : messages[index].Sender.Arn === userArn
                        ? {
                            color: "Pink",
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            textShadow: textShadow,
                          }
                        : {
                            color: "LightBlue",
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            textShadow: textShadow,
                          }
                    }
                  >
                    {messages[index].Sender.Name}
                  </span>
                </div>
                <div>
                  {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                    const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                    let styleCss = {
                      color: props.setting.chat_text_color,
                      textShadow: textShadow,
                    };
                    if (idx !== repleLength - 1) styleCss.color = "gray";
                    if (idx === 0) {
                      return (
                        <span key={index} style={styleCss}>
                          {line}
                        </span>
                      );
                    } else {
                      return (
                        <span key={index + "-" + idx} style={styleCss}>
                          <br />
                          {appConfig.quote_separator + " " + line}
                        </span>
                      );
                    }
                  })}
                </div>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 화면 사이즈 변경시 이벤트 발생
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  // 채팅 글자 크기에 맞춰 채팅 창 위치 조정 (하단 부터)
  const stdBottom = 20; // 기본 하단 시작점
  const bottomTxt = useRef(0);
  const liveAnnounceBox = useRef<HTMLDivElement>();

  // 브라우저 크긱에 맞춰 채팅창 높이를 맞춤
  const handleChatWindowPos = () => {
    const nowHeight = liveAnnounceBox.current?.getBoundingClientRect().height;
    if (nowHeight !== undefined) {
      bottomTxt.current = stdBottom + nowHeight;
      setLiveAnnounceVisiblity("visible");
    } else {
      setLiveAnnounceVisiblity("hidden");
    }
  };

  const [liveAnnounceVisiblity, setLiveAnnounceVisiblity] = useState("hidden");
  const [liveAnnounceShadow, setLiveAnnounceShadow] = useState("");
  useEffect(() => {
    if (props.setting.chat_shadow) setLiveAnnounceShadow("1px 1px 1px " + props.setting.chat_shadow_color);
    else setLiveAnnounceShadow("");
  }, [props.setting.chat_shadow, props.setting.chat_shadow_color]);

  const [chatTextSize, setChatTextSize] = useState("2.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setChatTextSize(`${props.setting.chat_text_size}vmax`);
    else setChatTextSize(`${props.setting.chat_text_v_size}vmax`);
  }, [props.screenMode, props.setting.chat_text_size, props.setting.chat_text_v_size]);

  return (
    <>
      <Box
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            width: "80%",
            height: "40vh",
            visibility: visibility,
            position: "absolute",
            left: "0px",
            bottom: bottomTxt.current + "px",
          }
        }
        className="chat-root"
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            component="div"
            sx={{
              width: "100%",
              height: "100%",
            }}
            className="gradient-box"
          >
            <AutoSizer style={{ height: "100%", width: "100%" }}>
              {({ height, width }) => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemSize={getRowHeight}
                  itemCount={messages.length}
                  overscanCount={5}
                  className="chat-list"
                  ref={listRef}
                >
                  {RenderRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </Box>
        </>
      </Box>
      {/* 창 제일 아래(height :100%)영역 잡기 위한 가상 디비젼 */}
      <div id="divBottom" className="css_bottom">
        1
      </div>
    </>
  );
};

export default TemplateChat;
