import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { GridCellParams } from "@mui/x-data-grid";
import SearchCondition from "../../service/settingManage/SearchCondition";
import SettingDetail from "../../service/settingManage/SettingDetail";
import { userState } from "../../interface/MainInterface";
import * as AuthMain from "../../service/auth/AuthMain";
import { appConfig } from "../../config/Config";
import Header from "../Header";

interface propsType {
  userState: userState;
}
const SettingManagement = (props: propsType) => {
  let navigate = useNavigate();
  const conditionRef: any = useRef();
  const detailRef: any = useRef();
  const previewRef: any = useRef();

  const [mouseDown, setMouseDown] = useState(false);
  const [previewDisplay, setPreviewDisplay] = useState("none");
  const [frameSrc, setFrameSrc] = useState("");
  const [defaultTemplateSeq, setDefaultTemplateSeq] = useState("");
  let [mouseDivX, setMouseDivX] = useState(0);
  let [mouseDivY, setMouseDivY] = useState(0);

  const loginNeed = () => {
    alert("로그인 후 이용 가능합니다. 다시 로그인해주세요.");
    navigate("/login");
  };

  const notAuth = () => {
    alert("권한이 없습니다. 관리자에게 문의하세요");
    navigate("/");
  };

  const yesAuth = () => {};

  const checkAuthenticated = async () => {
    const result = await AuthMain.checkAuthenticated();
    if (!result) {
      loginNeed();
    } else {
      props.userState.isAdmin ? yesAuth() : notAuth();
    }
  };

  const selectRow = (params: GridCellParams) => {
    detailRef.current.getTemplateInfo(params.row.template_seq);
  };

  const reloadTemplateList = () => {
    conditionRef.current.getTemplateList();
  };

  const loadTemplateList = (seq: string) => {
    conditionRef.current.getTemplateListSeq(seq);
  };

  const initForm = () => {
    detailRef.current.initForm();
  };

  const openTemplatePreview = (templateSeq: string) => {
    setFrameSrc((frameSrc) => "");
    const linkUrl = `${appConfig.domain}/template/${templateSeq}`;
    // const linkUrl = `http://localhost:3000/template/${templateSeq}`; // Loacl test
    setFrameSrc((frameSrc) => linkUrl);
    setPreviewDisplay("flex");
  };

  let previewX = "10";
  let previewY = "10";

  const fncMouseDown = (e: React.MouseEvent) => {
    const divX = previewRef.current.getBoundingClientRect().left;
    const divY = previewRef.current.getBoundingClientRect().top;

    const mouseX = e.clientX;
    const mouseY = e.clientY;

    setMouseDivX((mouseDivX) => mouseX - divX);
    setMouseDivY((mouseDivY) => mouseY - divY);
    setMouseDown((mouseDown) => true);
  };

  const fncMouseUp = (e: React.MouseEvent) => {
    setMouseDown((mouseDown) => false);
  };

  const fncMouseMove = (e: React.MouseEvent) => {
    if (mouseDown) {
      const newX = (e.clientX - mouseDivX).toString();
      const newY = (e.clientY - mouseDivY).toString();
      previewRef.current.style.left = newX + "px";
      previewRef.current.style.top = newY + "px";
    }
  };

  const previewClose = () => {
    setFrameSrc((frameSrc) => "");
    setPreviewDisplay("none");
  };

  useEffect(() => {
    checkAuthenticated();
    return () => {
      setMouseDown(false);
      setPreviewDisplay("none");
      setFrameSrc("");
      setDefaultTemplateSeq("");
      setMouseDivX(0);
      setMouseDivY(0);
    };
  }, []);

  return (
    <>
      <Header userState={props.userState} />
      <Grid
        container
        spacing={2}
        component="div"
        sx={{ px: 5, pb: 5 }}
        onMouseMove={(e: React.MouseEvent) => {
          fncMouseMove(e);
        }}
        onMouseUp={(e: React.MouseEvent) => {
          fncMouseUp(e);
        }}
      >
        {/* 타이틀 */}
        <Grid item xs={12}>
          <h1>템플릿관리</h1>
        </Grid>
        {/* 검색조건 */}
        <Grid item xs={12} sm={4}>
          <SearchCondition
            ref={conditionRef}
            userState={props.userState}
            selectRow={selectRow}
            initForm={initForm}
            setDefaultTemplateSeq={setDefaultTemplateSeq}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <SettingDetail
            ref={detailRef}
            userState={props.userState}
            loadTemplateList={loadTemplateList}
            reloadTemplateList={reloadTemplateList}
            openTemplatePreview={openTemplatePreview}
            defaultTemplateSeq={defaultTemplateSeq}
          />
        </Grid>
      </Grid>
      <div
        ref={previewRef}
        style={{
          position: "absolute",
          zIndex: 900,
          top: `${previewY}px`,
          left: `${previewX}px`,
          display: previewDisplay,
        }}
        onMouseDown={(e) => {
          fncMouseDown(e);
        }}
        onMouseUp={(e) => {
          fncMouseUp(e);
        }}
        onMouseMove={(e) => {
          fncMouseMove(e);
        }}
      >
        <Paper sx={{ width: "100%", height: "100%", p: 1 }} elevation={5}>
          <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", height: "20px" }}>
              <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
                <Grid item xs={12} sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                  <Typography align="center">여기를 잡고 창을 이동하세요.</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton sx={{ padding: 0 }} onClick={previewClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Stack>
            </Box>
            <div>
              <iframe id={"templatePreview"} src={frameSrc} width={"640px"} height={"360px"}></iframe>
            </div>
          </Stack>
        </Paper>
      </div>
    </>
  );
};

export default SettingManagement;
