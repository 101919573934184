import { useEffect, useState } from "react";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// import user component
import { userState } from "../../../interface/MainInterface";
import GameInfo from "./GameInfo";

interface propsType {
  userState: userState;
  broadInfo: any;
  chimeInfo: any;
  hidden_menu: boolean;
}

const DialogGame = (props: propsType) => {
  const [openGameInfo, setOpenGameInfo] = useState(false);

  // 게임 모달 띄우기
  const handleOpenGameInfo = () => {
    setTimeout(() => {
      setOpenGameInfo(true);
    }, 200);
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenGameInfo(false);
    };
  }, []);

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "block" }} onClick={handleOpenGameInfo}>
        <img className="thd-footer-right-icon" src="/images/btn-game.png" />
      </Box>
      {/* 게임 정보 편집 */}
      <Dialog
        open={openGameInfo}
        onClose={() => {
          setOpenGameInfo(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "30%" } } }}
      >
        <DialogTitle>{"게임 정보 편집"}</DialogTitle>
        <DialogContent>
          {/* <Stack spacing={2} direction="column" sx={{ mt: 1 }}> */}
          <Stack spacing={2} direction="column">
            {/* 게임하기 */}
            <GameInfo userState={props.userState} broadInfo={props.broadInfo} chimeInfo={props.chimeInfo} />
            {/* 게임하기 */}
          </Stack>
          {/* </Stack> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenGameInfo(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 게임 정보 편집 */}
    </>
  );
};

export default DialogGame;
