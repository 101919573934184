import { useEffect, useState, useCallback, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";

// import user component
import { HttpChannelApi, GetChannelListParams } from "../../../service/channel-api";
import { useWindowSize } from "../../../utilities/useWindowSize";
import { CommonUtils } from "../../../service/common_utils";
import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import Header from "../../Header";
import { HttpClientApi, ListChannelMessageParams } from "../../../service/client-rest-api";
import { HttpChatApi } from "../../../service/chat-rest-api";

interface propsType {
  userState: userState;
}

const channelApi = new HttpChannelApi();
const clientApi = new HttpClientApi();
const chatApi = new HttpChatApi();

const StatisticChannel = (props: propsType) => {
  const cUtils = new CommonUtils();
  const toastRef: any = useRef();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().endOf("month"));

  const [channelList, setChannelList] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = useState(608);
  const [nextToken, setNextToken] = useState(undefined);

  const columns = [
    {
      field: "broad_start_tm",
      headerName: "시작일시",
      width: 110,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.broad_start_tm !== "" ? dayjs(param.row.broad_start_tm, "YYYYMMDDHHmmss").format("MM-DD HH:mm") : ""}</span>
          </>
        );
      },
    },
    { field: "broad_title", headerName: "방송명", flex: 1, minWidth: 300 },
    { field: "total_likes", headerName: "좋아요", width: 140 },
    { field: "total_views", headerName: "누적시청자", width: 140 },
    { field: "live_total_prod_click", headerName: "상품클릭", width: 140 },
    {
      field: "live_total_delivered_secs",
      headerName: "시청시간",
      width: 140,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{convertTotalViewTime(param.row.live_total_delivered_secs)}</span>
          </>
        );
      },
    },
    {
      field: "chat_list",
      headerName: "채팅 다운로드",
      width: 140,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <>
            {params.row.broad_seq === "total_sum" ? (
              <></>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  getListChannelMessages(params.row.broad_seq, params.row.broad_start_tm, params.row.broad_title);
                }}
              >
                다운로드
              </Button>
            )}
          </>
        );
      },
    },
  ];

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (broad_seq: string, broad_start_tm: String, broad_title: String) => {
    const param: any = {
      broad_seq: broad_seq,
      next_token: nextToken,
      list_mode: "total",
    };
    setLoading(true);
    await chatApi.list_chat_history(param).then((result: any) => {
      if (result.code === "200") {
        // sheet header
        let chatHistory: any = [["순번", "채팅일시", "작성자", "내용"]];
        // 각 행 순번 만들기
        let chatIndex = 1;
        // 내용 생성
        for (const message of result.response.chat_history.Messages) {
          const detail = [
            chatIndex,
            dayjs(String(message.CreatedTimestamp)).format("YYYY-MM-DD HH:mm:ss"),
            message.Sender.Name,
            message.Content,
          ];
          chatHistory = [...chatHistory, detail];
          chatIndex += 1;
        }
        // 컬럼 넓이
        const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 130 }, { wpx: 800 }];
        const sheetName = "채팅내역";
        // 파일명
        const fileName = dayjs(String(broad_start_tm)).format("YYYY-MM-DD HHmm") + "_채팅_" + broad_title + ".xlsx";
        cUtils.downloadExcel(chatHistory, colWidth, sheetName, fileName);
      }
    });
    setLoading(false);
  };

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const getChannelStatisticsList = useCallback(async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;
    setLoading(true);

    const param: GetChannelListParams = {
      broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
      broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
      sort: "DESCEND",
    };
    const res = await channelApi.get_channel_statistics_list(param);
    if (res.result_code === "200") {
      let tempList: any = [];
      let total_sum_like_cnt = 0;
      let total_sum_view_cnt = 0;
      let total_sum_prod_click_cnt = 0;
      let total_sum_delivered_secs = 0;
      for (const body of res.result_body) {
        total_sum_like_cnt = total_sum_like_cnt + body.total_likes;
        total_sum_view_cnt = total_sum_view_cnt + body.total_views;
        total_sum_prod_click_cnt = total_sum_prod_click_cnt + body.live_total_prod_click;
        total_sum_delivered_secs = total_sum_delivered_secs + body.live_total_delivered_secs;

        body.total_likes = cUtils.numericComma(Number(body.total_likes));
        body.total_views = cUtils.numericComma(Number(body.total_views));
        body.live_total_prod_click = cUtils.numericComma(Number(body.live_total_prod_click));
        tempList.push(body);
      }

      tempList.unshift({
        broad_seq: "total_sum",
        broad_start_tm: "",
        broad_title: "총계",
        total_likes: cUtils.numericComma(Number(total_sum_like_cnt)),
        total_views: cUtils.numericComma(Number(total_sum_view_cnt)),
        live_total_prod_click: cUtils.numericComma(Number(total_sum_prod_click_cnt)),
        live_total_delivered_secs: total_sum_delivered_secs,
      });
      setChannelList(tempList);
    } else {
      console.error(res.result_body);
    }

    setLoading(false);
  }, [srchDateFrom, srchDateTo, props.userState.id]);

  const convertTotalViewTime = (totalSec: number) => {
    const t = cUtils.convertSecToTime(totalSec);
    return `${t.hours}:${t.minutes}:${t.seconds}`;
  };

  const downloadExcelList = () => {
    if (channelList.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다.", "error", 3000);
      return;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
    // sheet header
    let statisticsHistory: any = [["순번", "시작일시", "방송명", "좋아요", "누적시청자", "상품클릭", "시청시간(초)"]];
    // 각 행 순번 만들기
    let statisticsIndex = 1;
    // 내용 생성
    for (const item of channelList) {
      let datetime = "";
      if (item.broad_start_tm !== "") {
        datetime = dayjs(item.broad_start_tm, "YYYYMMDDHHmmss").format("MM-DD HH:mm");
      }
      const detail = [
        statisticsIndex,
        datetime,
        item.broad_title,
        item.total_likes,
        item.total_views,
        item.live_total_prod_click,
        cUtils.numericComma(Number(item.live_total_delivered_secs)),
      ];
      statisticsHistory = [...statisticsHistory, detail];
      statisticsIndex += 1;
    }

    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName = `[${dayjs(srchDateFrom).format("YYYY-MM-DD")}~${dayjs(srchDateTo).format("YYYY-MM-DD")}] 방송별통계.xlsx`;
    cUtils.downloadExcel(statisticsHistory, colWidth, statsticsSheetName, fileName);
  };

  useEffect(() => {
    setGridHeight(windowSize.height - 230);
  }, [windowSize]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setSrchDateFrom(dayjs().startOf("month"));
      setSrchDateTo(dayjs().endOf("month"));
      setChannelList([]);
      setGridHeight(608);
    };
  }, []);

  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ p: 3 }}>
        {/* Title */}
        <Box component={"div"}>
          <Typography variant="h5" gutterBottom>
            방송별통계
          </Typography>
        </Box>
        {/* Title */}
        <Box>
          <Stack spacing={2} direction="column">
            {/* 검색조건 */}
            <Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="방송일From"
                    inputFormat="MM/DD/YYYY"
                    value={srchDateFrom}
                    onChange={handleSrchDateFromChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                  <DesktopDatePicker
                    label="방송일To"
                    inputFormat="MM/DD/YYYY"
                    value={srchDateTo}
                    onChange={handleSrchDateToChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                </LocalizationProvider>
                <Button variant="contained" size="small" onClick={getChannelStatisticsList}>
                  조회
                </Button>
                <Button variant="contained" size="small" onClick={downloadExcelList}>
                  EXCEL
                </Button>
              </Stack>
            </Box>
            {/* 검색조건 */}
            {/* 그리드 */}
            <Box>
              <Paper sx={{ width: "100%", height: gridHeight, p: 2 }} elevation={5}>
                <DataGrid
                  rows={channelList}
                  rowHeight={40}
                  columns={columns}
                  rowsPerPageOptions={[3, 10, 20, 50, 100]}
                  pagination
                  getRowId={(row) => row.broad_seq}
                />
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Box>
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default StatisticChannel;
