import { useState, forwardRef, useImperativeHandle } from "react";

import Dialog from "@mui/material/Dialog";
import { SketchPicker } from "react-color";

interface propsType {
  callBack: any;
}

const ColorPicker = (props: propsType, ref: any) => {
  const [pickedColor, setPickedColor] = useState("#fff");
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => setOpenDialog(false);

  const open = () => {
    // setPickedColor(color);
    setOpenDialog(true);
  };

  const close = () => {
    setOpenDialog(false);
  };

  const handleChangeComplete = (color: any) => {
    setPickedColor(color.hex);
    props.callBack(color.hex);
    close();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Dialog onClose={handleClose} open={openDialog}>
      <SketchPicker color={pickedColor} onChangeComplete={handleChangeComplete} />
    </Dialog>
  );
};

export default forwardRef(ColorPicker);
