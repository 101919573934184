import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Storage } from "aws-amplify";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { HttpChannelApi, UpdateTemplateParams } from "../../service/channel-api";
import { userState, emptySetting } from "../../interface/MainInterface";
import ColorPicker from "../../service/color/ColorPicker";
import ImageViewer from "../image/ImageViewer";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  templateInfo: any;
  getTemplateInfo: any;
  sendIoTMessageMeta: any;
  openTemplatePreview: any;
}

const TabSetting = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const [loading, setLoading] = useState(false);

  const [setting, setSetting] = useState(emptySetting);
  const [paperHeight, setPaperHeight] = useState(329);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSetting({
      ...setting,
      [event.target.name]: event.target.checked,
    });
    if (props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined) {
      let tmpSetting: any = { ...setting };
      tmpSetting[event.target.name] = event.target.checked;
      saveBroadSetting(tmpSetting);
    }
  };

  const setSettingData = (_templateInfo: any) => {
    setSetting(_templateInfo.setting);
    if (_templateInfo.setting.chat_text_size !== undefined) setChatTextSize(_templateInfo.setting.chat_text_size);
    if (_templateInfo.setting.chat_text_v_size !== undefined) setChatTextVSize(_templateInfo.setting.chat_text_v_size);
    if (_templateInfo.setting.title_text_size !== undefined) setTitleTextSize(_templateInfo.setting.title_text_size);
    if (_templateInfo.setting.title_text_v_size !== undefined)
      setTitleTextVSize(_templateInfo.setting.title_text_v_size);
    if (_templateInfo.setting.statistics_text_size !== undefined)
      setStatisticsTextSize(_templateInfo.setting.statistics_text_size);
    if (_templateInfo.setting.statistics_text_v_size !== undefined)
      setStatisticsTextVSize(_templateInfo.setting.statistics_text_v_size);
    if (_templateInfo.setting.host_img_size !== undefined) setHostImgSize(_templateInfo.setting.host_img_size);
    if (_templateInfo.setting.host_img_v_size !== undefined) setHostImgVSize(_templateInfo.setting.host_img_v_size);
    if (_templateInfo.setting.icon_size !== undefined) setIconSize(_templateInfo.setting.icon_size);
    if (_templateInfo.setting.icon_v_size !== undefined) setIconVSize(_templateInfo.setting.icon_v_size);
    if (_templateInfo.setting.ready_img !== undefined) setReadyImg(_templateInfo.setting.ready_img);
    if (_templateInfo.setting.logo_img !== undefined) setLogoImg(_templateInfo.setting.logo_img);
  };

  // 필드 초기화
  const initForm = () => {
    setSetting(emptySetting);
    setTitleTextSize("2.0");
    setTitleTextVSize("2.0");
    setStatisticsTextSize("2.0");
    setStatisticsTextVSize("2.0");
    setChatTextSize("2.0");
    setChatTextVSize("2.0");
    setHostImgSize("6.0");
    setHostImgVSize("5.0");
    setIconSize("3.0");
    setIconVSize("3.0");
    setReadyImg("");
    setLogoImg("");
  };

  const saveBroadSetting = async (newSetting: any) => {
    setLoading(true);
    const param: UpdateTemplateParams = {
      pk: props.templateInfo.pk,
      template_seq: props.templateInfo.template_seq,
      template_name: props.templateInfo.template_name,
      template_status: props.templateInfo.template_status,
      setting: newSetting,
    };
    const res = await chApi.update_template(param);
    if (res.result_code === "200") {
      props.getTemplateInfo(props.templateInfo.template_seq);
      props.sendIoTMessageMeta("reloadChannelInfo");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // 색상 선택기 사용
  const colorPickerRef: any = useRef();
  const [selectedObj, setSelectedObj] = useState("");
  const pickColor = (colorName: string) => {
    setSelectedObj((selectedObj) => colorName);
    colorPickerRef.current.open();
  };

  const colorPickerCallback = (color: string) => {
    setSetting({
      ...setting,
      [selectedObj]: color,
    });
    if (props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined) {
      let tmpSetting: any = { ...setting };
      tmpSetting[selectedObj] = color;
      saveBroadSetting(tmpSetting);
    }
  };

  const saveSetting = (key: string, value: string) => {
    setSetting({
      ...setting,
      [key]: value,
    });
    if (props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined) {
      let tmpSetting: any = { ...setting };
      tmpSetting[key] = value;
      saveBroadSetting(tmpSetting);
    }
  };

  const [titleTextSize, setTitleTextSize] = useState("2.0");
  const [titleTextVSize, setTitleTextVSize] = useState("2.0");
  const [statisticsTextSize, setStatisticsTextSize] = useState("2.0");
  const [statisticsTextVSize, setStatisticsTextVSize] = useState("2.0");
  const [chatTextSize, setChatTextSize] = useState("2.0");
  const [chatTextVSize, setChatTextVSize] = useState("2.0");
  const [hostImgSize, setHostImgSize] = useState("6.0");
  const [hostImgVSize, setHostImgVSize] = useState("5.0");
  const [iconSize, setIconSize] = useState("3.0");
  const [iconVSize, setIconVSize] = useState("3.0");

  const minusChatTextSize = () => {
    const newValue = parseFloat(chatTextSize) - 0.5;
    if (newValue < 0) return false;
    setChatTextSize((chatTextSize) => newValue.toString());
    saveSetting("chat_text_size", newValue.toString());
  };

  const plusChatTextSize = () => {
    const newValue = parseFloat(chatTextSize) + 0.5;
    if (newValue > 100) return false;
    setChatTextSize((chatTextSize) => newValue.toString());
    saveSetting("chat_text_size", newValue.toString());
  };

  const minusChatTextVSize = () => {
    const newValue = parseFloat(chatTextVSize) - 0.5;
    if (newValue < 0) return false;
    setChatTextVSize((chatTextSize) => newValue.toString());
    saveSetting("chat_text_v_size", newValue.toString());
  };

  const plusChatTextVSize = () => {
    const newValue = parseFloat(chatTextVSize) + 0.5;
    if (newValue > 100) return false;
    setChatTextVSize((chatTextSize) => newValue.toString());
    saveSetting("chat_text_v_size", newValue.toString());
  };

  const plusStatisticsTextSize = () => {
    const newValue = parseFloat(statisticsTextSize) + 0.5;
    if (newValue < 0) return false;
    setStatisticsTextSize((statisticsTextSize) => newValue.toString());
    saveSetting("statistics_text_size", newValue.toString());
  };

  const minusStatisticsTextVSize = () => {
    const newValue = parseFloat(statisticsTextVSize) - 0.5;
    if (newValue < 0) return false;
    setStatisticsTextVSize((statisticsTextVSize) => newValue.toString());
    saveSetting("statistics_text_v_size", newValue.toString());
  };

  const plusStatisticsTextVSize = () => {
    const newValue = parseFloat(statisticsTextVSize) + 0.5;
    if (newValue < 0) return false;
    setStatisticsTextVSize((statisticsTextVSize) => newValue.toString());
    saveSetting("statistics_text_v_size", newValue.toString());
  };

  const minusTitleTextSize = () => {
    const newValue = parseFloat(titleTextSize) - 0.5;
    if (newValue < 0) return false;
    setTitleTextSize((titleTextSize) => newValue.toString());
    saveSetting("title_text_size", newValue.toString());
  };

  const plusTitleTextSize = () => {
    const newValue = parseFloat(titleTextSize) + 0.5;
    if (newValue > 100) return false;
    setTitleTextSize((titleTextSize) => newValue.toString());
    saveSetting("title_text_size", newValue.toString());
  };

  const minusTitleTextVSize = () => {
    const newValue = parseFloat(titleTextVSize) - 0.5;
    if (newValue < 0) return false;
    setTitleTextVSize((titleTextVSize) => newValue.toString());
    saveSetting("title_text_v_size", newValue.toString());
  };

  const plusTitleTextVSize = () => {
    const newValue = parseFloat(titleTextVSize) + 0.5;
    if (newValue > 100) return false;
    setTitleTextVSize((titleTextVSize) => newValue.toString());
    saveSetting("title_text_v_size", newValue.toString());
  };

  const minusHostImgSize = () => {
    const newValue = parseFloat(hostImgSize) - 0.5;
    if (newValue < 0) return false;
    setHostImgSize((hostImgSize) => newValue.toString());
    saveSetting("host_img_size", newValue.toString());
  };

  const plusHostImgSize = () => {
    const newValue = parseFloat(hostImgSize) + 0.5;
    if (newValue > 100) return false;
    setHostImgSize((hostImgSize) => newValue.toString());
    saveSetting("host_img_size", newValue.toString());
  };

  const minusHostImgVSize = () => {
    const newValue = parseFloat(hostImgVSize) - 0.5;
    if (newValue < 0) return false;
    setHostImgVSize((hostImgVSize) => newValue.toString());
    saveSetting("host_img_v_size", newValue.toString());
  };

  const plusHostImgVSize = () => {
    const newValue = parseFloat(hostImgVSize) + 0.5;
    if (newValue < 0) return false;
    setHostImgVSize((hostImgVSize) => newValue.toString());
    saveSetting("host_img_v_size", newValue.toString());
  };

  const minusStatisticsTextSize = () => {
    const newValue = parseFloat(statisticsTextSize) - 0.5;
    if (newValue < 0) return false;
    setStatisticsTextSize((statisticsTextSize) => newValue.toString());
    saveSetting("statistics_text_size", newValue.toString());
  };

  const minusIconSize = () => {
    const newValue = parseFloat(iconSize) - 0.5;
    if (newValue < 0) return false;
    setIconSize((iconSize) => newValue.toString());
    saveSetting("icon_size", newValue.toString());
  };

  const plusIconSize = () => {
    const newValue = parseFloat(iconSize) + 0.5;
    if (newValue < 0) return false;
    setIconSize((iconSize) => newValue.toString());
    saveSetting("icon_size", newValue.toString());
  };

  const minusIconVSize = () => {
    const newValue = parseFloat(iconVSize) - 0.5;
    if (newValue < 0) return false;
    setIconVSize((iconVSize) => newValue.toString());
    saveSetting("icon_v_size", newValue.toString());
  };

  const plusIconVSize = () => {
    const newValue = parseFloat(iconVSize) + 0.5;
    if (newValue < 0) return false;
    setIconVSize((iconVSize) => newValue.toString());
    saveSetting("icon_v_size", newValue.toString());
  };

  const enterChatTextSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("chat_text_size", chatTextSize);
    }
  };
  const enterChatTextVSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("chat_text_v_size", chatTextVSize);
    }
  };

  const enterStatisticsTextSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("statistics_text_size", statisticsTextSize);
    }
  };
  const enterStatisticsTextVSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("statistics_text_v_size", statisticsTextVSize);
    }
  };

  const enterTitleTextSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("title_text_size", titleTextSize);
    }
  };
  const enterTitleTextVSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("title_text_v_size", titleTextVSize);
    }
  };

  const enterHostImgSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("host_img_size", hostImgSize);
    }
  };
  const enterHostImgVSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("host_img_v_size", hostImgVSize);
    }
  };

  const enterIconSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("icon_size", iconSize);
    }
  };
  const enterIconVSize = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveSetting("icon_v_size", iconVSize);
    }
  };

  const uploadS3 = async (dirName: string, filePath: string, file: any) => {
    let result = "fail";
    try {
      const data = await Storage.put(filePath, file, { customPrefix: { public: dirName } });
      result = data.key;
    } catch (e) {
      console.error("fail uploadding : ", e);
    }
    return result;
  };

  // 준비중 이미지 ------------------------------------------------------
  const imageViewer: any = useRef();
  const readyImgInputRef = useRef<HTMLInputElement>();
  const [readyImg, setReadyImg] = useState("");

  const previewReadyImg = () => {
    if (readyImg !== "") imageViewer.current.open(props.templateInfo.setting.ready_img_url);
  };

  const registReadyImg = () => {
    if (props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined) {
      readyImgInputRef.current?.click(); // S3에 이미지 업로드
    }
  };

  const handleReadyImgInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      const newFileName = "ready_img";
      const dirName = "template-ready-img/";
      const photoKey = props.templateInfo.template_seq + "/" + newFileName;
      const uploadResult = await uploadS3(dirName, photoKey, file);
      if (uploadResult === "fail") return;
      else saveSetting("ready_img", dirName + uploadResult);
    }
  };

  const deleteReadyImg = () => {
    saveSetting("ready_img", "");
  };

  // 로고 이미지 ------------------------------------------------------
  const logoImgInputRef = useRef<HTMLInputElement>();
  const [logoImg, setLogoImg] = useState("");

  const previewLogoImg = () => {
    if (logoImg !== "") imageViewer.current.open(props.templateInfo.setting.logo_img_url);
  };

  const registLogoImg = () => {
    if (props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined) {
      logoImgInputRef.current?.click(); // S3에 이미지 업로드
    }
  };

  const handleLogoImgInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      const newFileName = "logo_img";
      const dirName = "template-logo-img/";
      const photoKey = props.templateInfo.template_seq + "/" + newFileName;
      const uploadResult = await uploadS3(dirName, photoKey, file);
      if (uploadResult === "fail") return;
      else saveSetting("logo_img", dirName + uploadResult);
    }
  };

  const deleteLogoImg = () => {
    saveSetting("logo_img", "");
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setSettingData,
    initForm,
  }));

  return (
    <>
      <Box
        sx={
          props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined
            ? { width: "100%", display: "flex" }
            : { display: "none" }
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">라이브 상단 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <FormControlLabel
                        control={<Switch checked={setting.logo} onChange={handleSwitchChange} name="logo" />}
                        label="로고"
                      />
                      <FormControlLabel
                        control={
                          <Switch checked={setting.host_picture} onChange={handleSwitchChange} name="host_picture" />
                        }
                        label="호스트이미지"
                      />
                      <FormControlLabel
                        control={<Switch checked={setting.title} onChange={handleSwitchChange} name="title" />}
                        label="타이틀"
                      />
                      <FormControlLabel
                        control={
                          <Switch checked={setting.statistics} onChange={handleSwitchChange} name="statistics" />
                        }
                        label="통계"
                      />
                    </FormGroup>
                    <FormHelperText>각 항목을 숨김/보이기 처리할 수 있습니다.</FormHelperText>
                    <Stack spacing={1} direction="column" sx={{ mt: 6 }}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => {
                            if (
                              props.templateInfo.template_seq !== "" &&
                              props.templateInfo.template_seq !== undefined
                            ) {
                              props.openTemplatePreview(props.templateInfo.template_seq);
                            }
                          }}
                        >
                          템플릿 미리보기
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => {
                            if (
                              props.templateInfo.template_seq !== "" &&
                              props.templateInfo.template_seq !== undefined
                            ) {
                              window.open(`/template/${props.templateInfo.template_seq}`);
                            }
                          }}
                        >
                          새창
                        </Button>
                      </Stack>
                    </Stack>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">타이틀 글자 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={2} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>글자색</Typography>
                          </Box>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{ bgcolor: setting.title_text_color }}
                            color="inherit"
                            onClick={() => {
                              pickColor("title_text_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <FormControlLabel
                            control={
                              <>
                                <Switch
                                  checked={setting.title_shadow}
                                  onChange={handleSwitchChange}
                                  name="title_shadow"
                                />
                              </>
                            }
                            sx={{ minWidth: 103 }}
                            label="그림자"
                          />
                          <Button
                            variant="contained"
                            sx={{ bgcolor: setting.title_shadow_color }}
                            fullWidth
                            color="inherit"
                            onClick={() => {
                              pickColor("title_shadow_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>크기(가로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusTitleTextSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={titleTextSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setTitleTextSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterTitleTextSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusTitleTextSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>크기(세로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusTitleTextVSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={titleTextVSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setTitleTextVSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterTitleTextVSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusTitleTextVSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText>수동으로 입력한 경우 엔터키를 입력해서 저장하세요.</FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">통계 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={2} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>글자색</Typography>
                          </Box>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{ bgcolor: setting.statistics_text_color }}
                            color="inherit"
                            onClick={() => {
                              pickColor("statistics_text_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <FormControlLabel
                            control={
                              <>
                                <Switch
                                  checked={setting.statistics_shadow}
                                  onChange={handleSwitchChange}
                                  name="statistics_shadow"
                                />
                              </>
                            }
                            sx={{ minWidth: 103 }}
                            label="그림자"
                          />
                          <Button
                            variant="contained"
                            sx={{ bgcolor: setting.statistics_shadow_color }}
                            fullWidth
                            color="inherit"
                            onClick={() => {
                              pickColor("statistics_shadow_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(가로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusStatisticsTextSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={statisticsTextSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setStatisticsTextSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterStatisticsTextSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusStatisticsTextSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(세로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusStatisticsTextVSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={statisticsTextVSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setStatisticsTextVSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterStatisticsTextVSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusStatisticsTextVSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText>수동으로 입력한 경우 엔터키를 입력해서 저장하세요.</FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">호스트 이미지 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(가로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusHostImgSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={hostImgSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setHostImgSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterHostImgSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusHostImgSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(세로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusHostImgVSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={hostImgVSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setHostImgVSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterHostImgVSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusHostImgVSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText>&nbsp;</FormHelperText>
                    <FormLabel component="legend">아이콘 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(가로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusIconSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={iconSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setIconSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterIconSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusIconSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>크기(세로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusIconVSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={iconVSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setIconVSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterIconVSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusIconVSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText>수동으로 입력한 경우 엔터키를 입력해서 저장하세요.</FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">채팅창 글자 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>글자색</Typography>
                          </Box>
                          <Button
                            variant="contained"
                            sx={{ bgcolor: setting.chat_text_color }}
                            fullWidth
                            color="inherit"
                            onClick={() => {
                              pickColor("chat_text_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <FormControlLabel
                            control={
                              <>
                                <Switch
                                  checked={setting.chat_shadow}
                                  onChange={handleSwitchChange}
                                  name="chat_shadow"
                                />
                              </>
                            }
                            sx={{ minWidth: 103 }}
                            label="그림자"
                          />
                          <Button
                            variant="contained"
                            sx={{ bgcolor: setting.chat_shadow_color }}
                            color="inherit"
                            fullWidth
                            onClick={() => {
                              pickColor("chat_shadow_color");
                            }}
                          ></Button>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>크기(가로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusChatTextSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={chatTextSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setChatTextSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterChatTextSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusChatTextSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 108, height: 38, alignItems: "center" }}>
                            <Typography>크기(세로화면)</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              minusChatTextVSize();
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          <TextField
                            value={chatTextVSize}
                            size="small"
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                            fullWidth
                            onChange={(e) => {
                              setChatTextVSize(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              enterChatTextVSize(e);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              plusChatTextVSize();
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText>수동으로 입력한 경우 엔터키를 입력해서 저장하세요.</FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Paper sx={{ width: "100%", minWidth: 330, minHeight: paperHeight, p: 2 }} elevation={5}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2} direction="column">
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">기타 설정</FormLabel>
                    <FormGroup sx={{ pt: 1 }}>
                      <Stack spacing={1} direction="column">
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>준비중 이미지</Typography>
                          </Box>
                          <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
                            {readyImg !== "" ? (
                              <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => {
                                  previewReadyImg();
                                }}
                              >
                                보기
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={() => {
                                registReadyImg();
                              }}
                            >
                              {readyImg !== "" ? "변경" : "등록"}
                            </Button>
                            {readyImg !== "" ? (
                              <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={() => {
                                  deleteReadyImg();
                                }}
                              >
                                삭제
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </Stack>
                        <Stack spacing={0} direction="row">
                          <Box sx={{ display: "flex", minWidth: 128, height: 38, alignItems: "center" }}>
                            <Typography>로고 이미지</Typography>
                          </Box>
                          <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
                            {logoImg !== "" ? (
                              <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => {
                                  previewLogoImg();
                                }}
                              >
                                보기
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={() => {
                                registLogoImg();
                              }}
                            >
                              {logoImg !== "" ? "변경" : "등록"}
                            </Button>
                            {logoImg !== "" ? (
                              <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={() => {
                                  deleteLogoImg();
                                }}
                              >
                                삭제
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </FormGroup>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Input
          key="inputReady"
          color="primary"
          type="file"
          inputRef={readyImgInputRef}
          onChange={handleReadyImgInput}
          sx={{ display: "none" }}
        />
        <Input
          key="inputLogo"
          color="primary"
          type="file"
          inputRef={logoImgInputRef}
          onChange={handleLogoImgInput}
          sx={{ display: "none" }}
        />
        <ColorPicker ref={colorPickerRef} callBack={colorPickerCallback} />
        <ImageViewer ref={imageViewer} />
        <LoadingCircle loading={loading} />
      </Box>
      <Box
        sx={
          props.templateInfo.template_seq !== "" && props.templateInfo.template_seq !== undefined
            ? { width: "100%", display: "none" }
            : { width: "100%", display: "flex" }
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", minWidth: 330, height: 674, p: 2 }} elevation={5}></Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default forwardRef(TabSetting);
