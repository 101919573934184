import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoadingCircle from "../../utilities/LoadingCircle";
import * as AuthMain from "../../service/auth/AuthMain";
import { Storage } from "aws-amplify";
import { HttpClientApi, UpdateAdminAttrParams } from "../../service/client-rest-api";

const styleBtnClose = {
  position: "absolute",
  top: "0px",
  right: "0px",
};

const forbiddenWords = ["관리자", "사회자", "어드민", "슈퍼 어드민", "Admin"];

const MyAccount = () => {
  let navigate = useNavigate();
  const clientApi = new HttpClientApi();
  const isAdmin = useRef(false);
  const myPhotoFileRef = useRef<HTMLInputElement>();

  const [isLoading, setIsLoading] = useState(false);
  const [chatName, setChatName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [userPicture, setUserPicture] = useState<string>("");
  const [userPictureUrl, setUserPictureUrl] = useState<string>("");

  useEffect(() => {
    pageInit();
    return () => {
      setIsLoading(false);
      setChatName("");
      setUserId("");
      setUserPicture("");
      setUserPictureUrl("");
    };
  }, []);

  const pageInit = async () => {
    await AuthMain.checkAuthenticated().then(async (isAuth: boolean) => {
      if (isAuth) {
        await AuthMain.getUserAttr().then(async (userAttr: any) => {
          setUserId(userAttr.email);
          setChatName(userAttr.chat_name);
          isAdmin.current = await AuthMain.checkAdmin();
          if (userAttr.picture !== "") {
            setUserPicture(userAttr.picture);
            setUserAvatar(userAttr.picture);
          }
        });
      } else {
        alert("로그인 후 이용하세요.");
        window.location.href = "/";
      }
    });
  };

  const saveMyAccount = async () => {
    if (chatName === "" || chatName.trim() === "") {
      alert("사용할 닉네임을 입력하세요.");
      return;
    }

    if (!isAdmin.current && forbiddenWords.includes(chatName)) {
      alert("사용할 수 없는 닉네임입니다. 다른 닉네임을 입력하세요.");
      return;
    }

    setIsLoading(true);
    let result = await AuthMain.setChatName(chatName);
    result = await AuthMain.setPicture(userPicture);
    const params: UpdateAdminAttrParams = {
      user_id: userId,
    };
    const ddbResult = await clientApi.update_admin_attr(params);
    if (result === "SUCCESS") {
      alert("수정하였습니다.");
      pageReload();
    } else {
      console.error("result : ", result);
      alert("오류가 발생했습니다.");
      setIsLoading(false);
    }
  };

  const handleChatNameEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") saveMyAccount();
  };

  const goHistoryBack = () => {
    navigate(-1);
  };

  const pageReload = () => {
    window.location.reload();
  };

  const changeMyPhoto = () => {
    myPhotoFileRef.current?.click();
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      // 특수문자등 파일 오류를 방지하기 위해 파일명을 다시 생성
      // const newFileName = `${uuidv4()}.${fileName.split(".").pop()}`;
      const newFileName = `${"user_picture"}`; //.${fileName.split(".").pop()}`;

      try {
        const photoKey = userId + "/" + newFileName;
        const data = await Storage.put(photoKey, file, { customPrefix: { public: "user-photos/" } });
        setUserPicture(data.key);
        setUserAvatar(data.key);
      } catch (e) {
        console.error("fail uploadding : ", e);
        setUserPicture("");
        setUserAvatar("");
      }
    }
  };

  const setUserAvatar = async (userPicture: string) => {
    const image_url = await clientApi.get_presigned_url("user-photos/" + userPicture);
    setUserPictureUrl(image_url.result_body.url);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <div style={{ position: "relative" }}>
              <span>프로필 수정</span>
              <Box sx={styleBtnClose}>
                <IconButton sx={{ padding: 0 }} onClick={goHistoryBack}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }} justifyContent="center" alignItems="center">
            <div style={{ position: "relative", display: "inline-block", justifyContent: "center" }}>
              <Avatar alt="" src={userPictureUrl} sx={{ width: 100, height: 100 }} />
              <Button variant="outlined" sx={{ mt: 2 }} onClick={changeMyPhoto}>
                사진 바꾸기
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <TextField
              fullWidth
              id="standard-required"
              label="닉네임"
              variant="standard"
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter", maxLength: 10 }}
              value={chatName}
              onChange={(e) => {
                setChatName(e.target.value);
              }}
              onKeyDown={(e) => {
                handleChatNameEnter(e);
              }}
            />
            <Input
              color="primary"
              type="file"
              inputRef={myPhotoFileRef}
              onChange={handleFileInput}
              sx={{ display: "none" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-save-myaccount-box">
        <Button fullWidth={true} variant="text" onClick={saveMyAccount}>
          <Typography sx={{ color: "snow" }}>수정 완료</Typography>
        </Button>
      </Box>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MyAccount;
