import { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
// mui component
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CardMedia from "@mui/material/CardMedia";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpChannelApi, SetStreamParams, ChBaseParams, UpdateChParams, PutBroadNotice } from "../../../service/channel-api";
import { HttpClientApi, CBroadParams, GetChannelinfoParams } from "../../../service/client-rest-api";
import { CommonUtils } from "../../../service/common_utils";
import { useInterval } from "../../../utilities/UseInterval";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import LiveInfoChatPC from "./LiveInfoChatPC";
import LiveInfoChatInputPC from "./LiveInfoChatInputPC";
import ChangeVod from "./ChangeVod";

interface propsType {
  broadDate: string;
  broadSeq: string;
  windowSize: any;
  userState: userState;
  sendIoTMessageMeta: any;
  sendIoTMessage: any;
}

const clientApi = new HttpClientApi();
const channelApi = new HttpChannelApi();

const ChannelInfoStudio = (props: propsType, ref: any) => {
  const [loading, setLoading] = useState(false);
  const cUtils = new CommonUtils();
  const toastRef: any = useRef();

  const [broadInfo, setBroadInfo] = useState<any>({});
  const [chimeInfo, setChimeInfo] = useState<any>({});
  const [ivsInfo, setIvsInfo] = useState<any>({});

  const [chatMsg, setChatMsg] = useState<any>([]);

  const [chatUrl, setChatUrl] = useState("");
  const [chatSize, setChatSize] = useState({ width: "100%", height: 600 });

  const [openBroadNotice, setOpenBroadNotice] = useState(false);
  const [broadNotice, setBroadNotice] = useState("");
  const [openStreamInfo, setOpenStreamInfo] = useState(false);

  // 스트림정보 더보기
  const moreViewStreamInfo = () => {
    setOpenStreamInfo(true);
  };

  const liveChatRef: any = useRef();
  const liveChatPcRef: any = useRef();
  const liveChatTFRef: any = useRef();

  const loadChannelInfoOnly = async () => {
    if (props.broadSeq !== undefined) {
      const param: CBroadParams = {
        broad_seq: props.broadSeq,
      };

      const res = await clientApi.get_channel_info(param);
      res.result_body.pk = `broad_date#${props.broadDate}`;
      setBroadInfo(res.result_body);
    } else {
      console.error("The channel is undefined");
    }
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadInfo.broad_seq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatPcRef.current.processChannelMessage(payLoad);
      }
    } else if (topic === `live/${broadInfo.broad_seq}/metadata`) {
      if (payLoad.message === "start_stream") loadChannelInfoOnly();
      if (payLoad.message === "ready_stream") loadChannelInfoOnly();
      if (payLoad.message === "stop_stream") loadChannelInfoOnly();
      if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
    }
  }

  // 채팅 메세지 전송
  const sendMessage = (msg: string, meta: string) => {
    liveChatPcRef.current.sendMessage(msg, meta);
  };

  const fncResetQuote = () => {
    liveChatRef.current.fncResetQuote();
  };

  // 채널정보 조회
  const getChannelInfo = useCallback(async () => {
    const param: GetChannelinfoParams = {
      pk: `broad_date#${props.broadDate}`,
      broad_seq: props.broadSeq,
      st_add: "1",
    };

    const res = await clientApi.get_channel_info(param);
    if (res.result_code === "200") {
      res.result_body.pk = `broad_date#${props.broadDate}`;
      setBroadInfo(res.result_body);
      const ivsInfo = {
        channel_arn: res.result_body.ivs_channel_arn,
        ingest_endpoint: res.result_body.ivs_ingest_endpoint,
        playback_url: res.result_body.ivs_playback_url,
        stream_key: res.result_body.ivs_stream_key,
      };
      setIvsInfo(ivsInfo);

      const chimeinfo = {
        chime_admin_arn: res.result_body.chime_admin_arn,
        chime_channel_arn: res.result_body.chime_channel_arn,
      };
      setChimeInfo(chimeinfo);
      setBroadNotice(res.result_body.broad_notice);
    } else {
      console.error("에러 발생 : " + res.result_body);
    }
  }, [props.broadDate, props.broadSeq]);

  // 채팅 입력창 포커스
  const chatInpuFocus = () => {
    liveChatTFRef.current.chatInputFocus();
  };

  const setVodTimeStamp = (vodType: string, url: string, vodStartSec: number, vodEndSec: number) => {};

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (cUtils.isEmptyObj(broadInfo)) return false;
    switch (newAlignment) {
      case "READY":
        if (window.confirm("방송을 준비중 상태로 변경하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "START":
        if (window.confirm("방송을 시작 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "STOP":
        if (window.confirm("방송을 중지 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      default:
        break;
    }
  };

  // 방송상태 변경
  const handleStream = async (status: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }

    let cmd = "";
    if (status === "START") cmd = "start_stream";
    else if (status === "READY") cmd = "ready_stream";
    else if (status === "STOP") cmd = "stop_stream";

    setLoading(true);
    const param: SetStreamParams = {
      pk: broadInfo.pk,
      broad_seq: broadInfo.broad_seq,
      cmd: cmd,
    };
    // 방송 스트림 상태 조회
    let check_stream = true;
    if (param.cmd === "start_stream") {
      await clientApi.get_stream_with_status(broadInfo.ivs_channel_arn, param.cmd).then((res) => {
        if (res.result_code !== "200") check_stream = false;
        if (cUtils.isEmptyObj(res.result_body)) check_stream = false;
      });
    }
    if (param.cmd === "start_stream" && !check_stream && !props.userState.isSuperAdmin) {
      alert("방송 시작전 영상을 먼저 송출하세요.");
      setLoading(false);
      return;
    }

    if (param.cmd === "stop_stream") {
      await clientApi.get_stream_with_status(broadInfo.ivs_channel_arn, param.cmd).then((res) => {
        if (res.result_code !== "200") check_stream = false;
        if (cUtils.isEmptyObj(res.result_body)) check_stream = false;
      });
    }
    if (param.cmd === "stop_stream" && check_stream) {
      alert("방송 종료전 영상을 먼저 중단하세요.");
      setLoading(false);
      return;
    }
    await fncUpdateStream(param, cmd);
    // 방송이 종료되면 VOD를 자동으로 생성한다.
    if (status === "STOP") {
      // await fncMakeVod();
    }
    getChannelInfo();
    setLoading(false);
  };

  const fncUpdateStream = async (param: any, cmd: string) => {
    const res = await channelApi.handler_stream(param);
    console.log("UPDATE STREAM : ", res);
    if (res.result_code === "200") {
      props.sendIoTMessageMeta(cmd);
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
  };

  const fncMakeVod = async () => {
    const param: ChBaseParams = {
      pk: broadInfo.pk,
      broad_seq: broadInfo.broad_seq,
    };
    const res_record = await channelApi.get_record_list(param);
    if (res_record.result_code === "200") {
      if (res_record.result_body.length > 0) {
        const lastRecord = res_record.result_body[res_record.result_body.length - 1];
        const lastRecordStartAtKR = dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString();
        const date1 = dayjs(lastRecordStartAtKR, "YYYYMMDDHHmmss");
        const date2 = dayjs(broadInfo.broad_start_tm, "YYYYMMDDHHmmss");
        let diff = date2.diff(date1, "s");
        if (diff < 0) {
          diff = 0; //방송전 리허설이 없었으면 방송시작시간으로
        }
        const param: UpdateChParams = {
          pk: broadInfo.pk,
          broad_seq: broadInfo.broad_seq,
          broad_title: broadInfo.broad_title,
          broad_desc: broadInfo.broad_desc,
          broad_start_tm: broadInfo.broad_start_tm,
          broad_stop_tm: broadInfo.broad_stop_tm,
          broad_prod_list: broadInfo.broad_prod_list,
          broad_notice: broadInfo.broad_notice,
          broad_status: "VOD",
          vod_url: "/" + lastRecord.stream_info.bucket_root + "/media/hls/master.m3u8",
          vod_started_at: dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString(),
          vod_start_sec: diff,
          vod_end_sec: 0,
          high_start_sec: broadInfo.high_start_sec,
          password: broadInfo.password,
          extra_type: broadInfo.extra_type,
          progress_store: broadInfo.progress_store,
          setting: broadInfo.setting,
          host_id: broadInfo.host_id,
          channel_img: broadInfo.channel_img,
          channel_template: broadInfo.channel_template,
        };
        const makeVodRes = await channelApi.update_channel(param);
        console.log("MAKE VOD : ", makeVodRes);
      }
    } else {
      console.error("[ERROR] getRecordList : ", res_record.result_body);
    }
  };

  // 공지사항 보기
  const moreBroadNotice = () => {
    setOpenBroadNotice(true);
  };

  // 공지사항 수정하기
  const saveBroadNotice = async () => {
    if (!window.confirm("공지사항을 수정하시겠습니까?")) return;
    setLoading(true);
    const param: PutBroadNotice = {
      pk: broadInfo.pk,
      broad_seq: broadInfo.broad_seq,
      broad_notice: broadNotice,
    };
    const res = await channelApi.put_broad_notice(param);
    if (res.result_code === "200") {
      setOpenBroadNotice(false);
      toastRef.current?.toast("공지사항을 수정했습니다.", "success", 3000);
      props.sendIoTMessageMeta("reloadChannelInfo");
      getChannelInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getChannelInfo();
  }, [getChannelInfo]);

  useEffect(() => {
    if (props.windowSize.width < 600) {
      setChatSize({
        width: "100%",
        height: props.windowSize.height - 260,
      });
    } else {
      setChatSize({
        width: "100%",
        height: props.windowSize.height - 210,
      });
    }
  }, [props.windowSize]);

  useImperativeHandle(ref, () => ({
    recieveIoTMessage,
  }));

  // 방송 시작시간 아후 방송 상태가 생성 또는 준비중인지 체크
  const [checkOnAir, setCheckOnAir] = useState(false);
  const [onAirError, setOnAirError] = useState(false);
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(broadInfo.broad_start_tm);
      if (remainSec < 0) {
        if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
          setOnAirError(true);
        } else {
          setOnAirError(false);
        }
      }
    },
    checkOnAir ? delay : null
  );

  useEffect(() => {
    if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
      setCheckOnAir(true);
    } else {
      setCheckOnAir(false);
      setOnAirError(false);
    }
  }, [broadInfo.broad_status]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setBroadInfo({});
      setChimeInfo({});
      setIvsInfo({});
      setChatUrl("");
      setChatSize({ width: "100%", height: 600 });
      setOpenBroadNotice(false);
      setBroadNotice("");
      setOpenStreamInfo(false);
      setCheckOnAir(false);
      setOnAirError(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(broadInfo) && (
        <Box>
          <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
            <TextField label="방송명" value={broadInfo.broad_title} size="small" fullWidth />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ width: "100%" }}>
              <ToggleButtonGroup
                color="standard"
                value={broadInfo.broad_status}
                size="small"
                exclusive
                fullWidth
                onChange={handleStatusChange}
                aria-label="방송상태"
              >
                <ToggleButton value="CREATE" color="success">
                  생성
                </ToggleButton>
                <ToggleButton value="READY" color="warning">
                  준비중
                </ToggleButton>
                <ToggleButton value="START" color="error">
                  방송중
                </ToggleButton>
                <ToggleButton value="STOP" color="standard">
                  종료
                </ToggleButton>
                <ToggleButton value="VOD" color="info">
                  VOD
                </ToggleButton>
              </ToggleButtonGroup>
              <Box
                sx={{
                  position: "absolute",
                  top: "180px",
                  left: "320px",
                  ...(onAirError ? { display: "" } : { display: "none" }),
                }}
              >
                <Typography sx={{ color: "red", fontWeight: "bold", fontSize: "3rem" }}>↖︎방송 상태를 확인해주세요.</Typography>
              </Box>
              <Stack direction={"row"} spacing={1} width={"100%"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    moreBroadNotice();
                  }}
                  sx={{ minWidth: 12, maxWidth: 120 }}
                  fullWidth
                  endIcon={<SavedSearchIcon />}
                >
                  공지사항
                </Button>
                <ButtonGroup variant="outlined" fullWidth>
                  <Button onClick={() => moreViewStreamInfo()} endIcon={<SearchIcon />}>
                    스트림
                  </Button>
                  <Button
                    onClick={() => {
                      if (broadInfo.extra_type === "THD") {
                        window.open(`/frame/${broadInfo.broad_seq}`);
                      } else {
                        window.open(`/live/${broadInfo.broad_seq}`);
                      }
                    }}
                    endIcon={<SearchIcon />}
                  >
                    라이브
                  </Button>
                  <Button onClick={() => window.open(`/frame/${broadInfo.broad_seq}?mode=rehearsal`)} endIcon={<SearchIcon />}>
                    리허설
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>

            {/* 채팅 화면 */}
            {props.userState.id !== "" && broadInfo.broad_status !== "VOD" && (
              <Box
                className="live-channel-info-chat-box"
                sx={{
                  position: "relative",
                  height: chatSize.height,
                  width: chatSize.width,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={"live-content-chat live-channel-info-pc-chat"}>
                  <LiveInfoChatPC
                    ref={liveChatPcRef}
                    broad_seq={broadInfo.broad_seq}
                    channelArn={chimeInfo.chime_channel_arn}
                    adminArn={chimeInfo.chime_admin_arn}
                    userState={props.userState}
                    extra_type={broadInfo.extra_type}
                    sendIoTMessage={props.sendIoTMessage}
                    chatInpuFocus={chatInpuFocus}
                    setChatMsg={setChatMsg}
                  />
                </div>
                <LiveInfoChatInputPC
                  ref={liveChatTFRef}
                  broad_status={broadInfo.broad_status}
                  user_id={props.userState.id}
                  sendMessage={sendMessage}
                  fncResetQuote={fncResetQuote}
                />
              </Box>
            )}
            {/* 채팅 화면 */}
            {broadInfo.broad_status === "VOD" && (
              <Box sx={{ position: "relative", height: chatSize.height, width: chatSize.width, display: "flex" }}>
                <ChangeVod
                  userState={props.userState}
                  broadInfo={broadInfo}
                  getChannelInfo={getChannelInfo}
                  setVodTimeStamp={setVodTimeStamp}
                />
              </Box>
            )}
          </Stack>
        </Box>
      )}
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
      {/* 공지사항 */}
      <Dialog
        open={openBroadNotice}
        onClose={() => {
          setOpenBroadNotice(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "80%" } } }}
      >
        <DialogTitle>{"공지사항"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
            <TextField
              id="txtNotice"
              multiline
              rows={15}
              variant="filled"
              size="small"
              value={broadNotice}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              onChange={(e) => {
                setBroadNotice(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              saveBroadNotice();
            }}
          >
            수정
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenBroadNotice(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 공지사항 */}
      {/* 스트림 정보 */}
      <Dialog
        open={openStreamInfo}
        onClose={() => {
          setOpenStreamInfo(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "58%" } } }}
      >
        <DialogTitle>{"스트림 정보"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
            <TextField label="스트림 서버" value={`rtmps://${ivsInfo.ingest_endpoint}:443/app/`} size="small" fullWidth />
            <TextField label="스트림 키" value={ivsInfo.stream_key} size="small" fullWidth />
            <CardMedia component="img" image={"/images/ivs_settings.jpg"} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenStreamInfo(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 스트림 정보 */}
    </>
  );
};

export default forwardRef(ChannelInfoStudio);
