import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import ClientChannelCard from "../../service/live-display/ChannelCard";
import ModalChatName from "../../service/chat/ModalChatName";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpClientApi, GetChannelListParams } from "../../service/client-rest-api";
import { itemChannel, userState } from "../../interface/MainInterface";
import Header from "../Header";

const toDate = new Date();
let fromDate = new Date(toDate);
fromDate.setDate(toDate.getDate() - 7);
let endDate = new Date(toDate);
endDate.setDate(toDate.getDate() + 3);

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const ClientChannels = (props: propsType) => {
  const chApi = new HttpClientApi();

  const [loading, setLoading] = useState(false);
  const [modalCommand, setModalCommand] = useState("INIT");
  const [srchDateFrom, setSrchDateFrom] = useState<Date | null>(fromDate);
  const [srchDateTo, setSrchDateTo] = useState<Date | null>(endDate);
  const [rows, setRows] = useState<itemChannel[]>([]);
  const [selectedBroadSeq, setSelectedBroadSeq] = useState("");

  useEffect(() => {
    getChannels();
    return () => {
      setLoading(false);
      setModalCommand("INIT");
      setSrchDateFrom(fromDate);
      setSrchDateTo(endDate);
      setRows([]);
      setSelectedBroadSeq("");
    };
  }, []);

  const getChannels = async () => {
    setLoading(true);
    // 어드민, 일반 사용자 필터
    const adminStatus = ["CREATE", "START", "READY", "STOP", "VOD"];
    const userStatus = ["START", "READY", "VOD"];
    const sort = "ASCEND";

    const param: GetChannelListParams = {
      broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).format("YYYYMMDD")],
      broad_status: userStatus,
      sort: sort,
    };

    if (props.userState.isAdmin) param.broad_status = adminStatus;

    const res = await chApi.get_channel_list(param);
    setRows(res.result_body);
    setLoading(false);
  };

  const channelCardCallBack = (command: any, freeForm: any) => {
    setSelectedBroadSeq("");
    switch (command) {
      case "OPEN_CHAT_NAME_MODAL":
        setModalCommand("OPEN_CHAT_NAME_MODAL");
        setSelectedBroadSeq(freeForm);
        setTimeout(() => {
          setModalCommand("CLEAR_COMMAND");
        }, 100);

        break;
      default:
        console.error("not support command");
    }
  };

  const modalProcDone = (res: any) => {
    const seq = selectedBroadSeq;
    setSelectedBroadSeq("");
    if (res === "SUCCESS") window.location.href = "/live/" + seq;
  };

  return (
    <>
      <Header userState={props.userState} />
      <Box className="client-channel-container" sx={{ flexGrow: 1, px: 5, pb: 5 }}>
        <Grid container spacing={2} component="div">
          <Grid item xs={12} sm={2}>
            <h2>Videos</h2>
          </Grid>
          <Grid item xs={12} sm={10} className="client-channel-datePicker-container">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                sx={{
                  "& input": { padding: "10px" },
                  alignContent: "center",
                }}
              >
                <MobileDatePicker
                  label="From"
                  value={srchDateFrom}
                  onChange={(newValue) => {
                    setSrchDateFrom(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="To"
                  value={srchDateTo}
                  onChange={(newValue) => {
                    setSrchDateTo(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button variant="outlined" onClick={getChannels}>
                  Search
                </Button>
              </Stack>
            </LocalizationProvider>
          </Grid>
          {rows.map((item: itemChannel, index: number) => {
            return (
              <ClientChannelCard
                key={item.broad_seq}
                item={item}
                webViewStatus={props.webViewStatus}
                userState={props.userState}
                callBack={channelCardCallBack}
              />
            );
          })}
        </Grid>
        <ModalChatName modalCommand={modalCommand} callBack={modalProcDone} />
        <LoadingCircle loading={loading} />
      </Box>
    </>
  );
};

export default ClientChannels;
