import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { CommonUtils } from "../../service/common_utils";

const IFrameDemo = () => {
  const cUtils = new CommonUtils();

  const [domainText, setDomainText] = useState("https://live.hd-smartstore.com");
  const [userId, setUserId] = useState("");
  const [nickName, setNickName] = useState("");
  const [loginUrl, setLoginUrl] = useState("https://live.hd-smartstore.com/loginFrame");
  const [linkUrl, setLinkUrl] = useState("");
  const [frameSrc, setFrameSrc] = useState("");
  const [sampleCode, setSampleCode] = useState("");

  useEffect(() => {
    const linkUrl = `${domainText}/stream/c5fca07c-4878-4705-afed-f9046b245060?userId=${userId}&nickName=${nickName}&loginUrl=${loginUrl}`;
    setLinkUrl(linkUrl);
  }, [userId, nickName, loginUrl, domainText]);

  const submitIFrameTest = () => {
    setFrameSrc(linkUrl);
    const SampleText = `
    <div>
      <iframe src={${linkUrl}} width={"100%"} height={"720"}></iframe>
    </div>
    `;
    setSampleCode(SampleText);
  };

  const copyLinkURL = () => {
    const res = cUtils.copyToClipboard(linkUrl);
    if (res) {
      alert("링크 URL이 클립보드에 복사되었습니다.");
    }
  };

  const copySampleCode = () => {
    const res = cUtils.copyToClipboard(sampleCode);
    if (res) {
      alert("샘플코드가 클립보드에 복사되었습니다.");
    }
  };

  // 브라우저 이벤트
  useEffect(() => {
    window.addEventListener("message", (e) => {
      procMessage(e);
    });
    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      window.addEventListener("message", (e) => {
        procMessage(e);
      });
    };
  }, []);

  const procMessage = (e: any) => {
    if (e.origin === "https://live.hd-smartstore.com") {
    }
  };

  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            iframe 예제
          </Typography>
          <Divider />
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - userId : 연동하는 사이트의 고객 ID(string) 값, 비회원의 경우 빈값("")으로 전송. 채팅을 볼수는 있으나 참여할 수 없음
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - nickName : 채팅 참여시 사용할 별명
          </Typography>
          <TextField
            id="txtUserId"
            label="User ID"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
          <TextField
            id="txtNickName"
            label="Nickname"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value);
            }}
          />
          <Typography variant="body1" component="div" gutterBottom>
            - LoginUrl : 연동하는 사이트의 로그인 경로로 사전 협의 후 iframe내에 추가해야함. 이 페이지에서는 테스트를 위하여 임시 로그인
            경로를 파라미터로 전송함.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            로그인 페이지를 호출하면 QueryString으로 redirectUrl 파라미터를 전송하며 로그인 성공 후 해당 경로로 리다이렉트 하면 된다.
          </Typography>
          <TextField
            error
            id="txtLoginUrl"
            label="Login URL"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={loginUrl}
            onChange={(e) => {
              setLoginUrl(e.target.value);
            }}
          />
          <Typography variant="body1" component="div" gutterBottom>
            - Domain : 각 연동하는 기업마다 별도의 도메인을 제공예정. 현재 페이지에서는 데모용 도메인으로 임시 제공함.
          </Typography>
          <TextField
            error
            id="txtDomain"
            label="Domain"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={domainText}
            onChange={(e) => {
              setDomainText(e.target.value);
            }}
          />
          <Stack spacing={1} direction="row">
            <TextField
              error
              id="resultLinkUrl"
              label="iframe SRC"
              variant="standard"
              multiline
              fullWidth
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              InputProps={{
                readOnly: true,
              }}
              value={linkUrl}
            />
            <IconButton size="large" aria-label="copy" onClick={copyLinkURL}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
          <Button variant="contained" onClick={submitIFrameTest}>
            SUBMIT
          </Button>
          <div>
            <iframe id={"first"} src={frameSrc} width={"100%"} height={"720"}></iframe>
          </div>
          <Stack spacing={1} direction="row">
            <TextField
              error
              id="sampleCode"
              label="Sample Code"
              variant="standard"
              multiline
              fullWidth
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              InputProps={{
                readOnly: true,
              }}
              value={sampleCode}
            />
            <IconButton size="large" aria-label="copy" onClick={copySampleCode}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default IFrameDemo;
