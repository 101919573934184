import { useEffect, useRef, useState } from "react";
import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";
import { deviceInfo } from "../../../interface/MainInterface";
import { HttpClientApi } from "../../../service/client-rest-api";

import { CardMedia } from "@mui/material";
import "./ClientIVSPlayer.css";

interface propsType {
  broad_seq: string;
  broad_status: string;
  playbackUrl: string;
  streamState: string;
  videoStatusCallback: any;
}
export let player: any = null;

const clientApi = new HttpClientApi();

const _deviceInfo: deviceInfo = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

export const LiveIVSPlayer = (props: propsType) => {
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;

  const { broad_seq, broad_status, playbackUrl, streamState, videoStatusCallback } = props;

  const playerRef: any = useRef(null);
  const videoEl: any = useRef(null);
  const [onStateReady, setStateOnReady] = useState(false);

  useEffect(() => {
    const putErrorLog = async (errorMsg: string) => {
      try {
        const param = {
          command: "put_broaderror_data",
          broad_seq: broad_seq,
          device_info: _deviceInfo,
          error_msg: errorMsg,
        };

        await clientApi.put_broaderror_data(param);
      } catch (error) {
        console.error(error);
      }
    };

    if (!isPlayerSupported) {
      console.error("지원하지 않는 브라우저입니다. 다른 브라우저로 시도하시거나. 더현대닷컴 APP을 설치하시기 바랍니다.");
      putErrorLog("UnSupported Browser");
      props.videoStatusCallback("unSupported");
      return;
    }

    const onStateIdle = () => {
      setStateOnReady(false);
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      videoStatusCallback("onIdle");
    };

    const onStateReady = () => {
      setStateOnReady(true);
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      videoStatusCallback("onReady");
    };

    const onStatePlay = () => {
      setStateOnReady(false);
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      videoStatusCallback("onStatePlaying");
    };

    const onStateEnd = () => {
      setStateOnReady(false);
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      videoStatusCallback("onEnd");
    };

    const onError = (err: any) => {
      setStateOnReady(false);
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      console.warn("Player Event - ERROR:", err);
      const error: any = JSON.stringify(err);
      putErrorLog(error);
      videoStatusCallback("onError");
    };

    playerRef.current = IVSPlayer.create({
      serviceWorker: {
        url: "amazon-ivs-service-worker-loader.js",
      },
    });

    const PlayerState = IVSPlayer.PlayerState;

    playerRef.current.attachHTMLVideoElement(videoEl.current);
    playerRef.current.setAutoQualityMode(true);
    playerRef.current.setLiveLowLatencyEnabled(true);
    playerRef.current.setMuted(true);
    playerRef.current.load(playbackUrl);
    playerRef.current.play();

    playerRef.current.addEventListener(PlayerState.IDLE, onStateIdle);
    playerRef.current.addEventListener(PlayerState.READY, onStateReady);
    playerRef.current.addEventListener(PlayerState.PLAYING, onStatePlay);
    playerRef.current.addEventListener(PlayerState.ENDED, onStateEnd);
    playerRef.current.addEventListener(PlayerState.ERROR, onError);

    player = playerRef.current;

    const onVisibilityChange = () => {
      if (_deviceInfo.osName === "iOS") {
        if (document.visibilityState === "hidden" && playerRef.curretn.isMuted()) {
          playerRef.current.pause();
        }
        if (document.visibilityState === "visible" && playerRef.current.getState() !== PlayerState.PLAYING) {
          playerRef.current.play();
        }
      }
    };

    window.addEventListener("visibilitychange", onVisibilityChange);

    videoStatusCallback("ivsInitStart");

    return () => {
      playerRef.current.removeEventListener(PlayerState.IDLE, onStateIdle);
      playerRef.current.removeEventListener(PlayerState.READY, onStateReady);
      playerRef.current.removeEventListener(PlayerState.PLAYING, onStatePlay);
      playerRef.current.removeEventListener(PlayerState.ENDED, onStateEnd);
      playerRef.current.removeEventListener(PlayerState.ERROR, onError);
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [IVSPlayer, isPlayerSupported, playbackUrl]);

  useEffect(() => {
    if (playbackUrl !== "" && playerRef.current !== null) {
      if (broad_status === "START") {
        playerRef.current.load(playbackUrl);
        playerRef.current.play();
      }
      if (broad_status === "READY") {
        playerRef.current.load("");
      }
      if (broad_status === "CREATE") {
        playerRef.current.load("");
      }
      if (streamState === "Stream Start" && broad_status === "START") {
        playerRef.current.load(playbackUrl);
        playerRef.current.play();
      }
    }
  }, [streamState, broad_status, playbackUrl]);

  return (
    <>
      {onStateReady && (
        <div className="video-poster">
          <CardMedia component="img" image={"/images/ready_img.png"} sx={{ width: "100%", height: "100%", zIndex: "903" }} />
        </div>
      )}
      <video
        ref={videoEl}
        id="videoPlayer"
        className="th-client-video"
        poster={"/images/ready_img.png"}
        playsInline
        muted
        autoPlay
        preload="none"
      ></video>
    </>
  );
};
