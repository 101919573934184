import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { appConfig } from "../../../config/Config";
import { v4 as uuidv4 } from "uuid";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import IconButton from "@mui/material/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";
import ModeIcon from "@mui/icons-material/Mode";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { Stack } from "@mui/material";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { HttpAdminIotApi } from "../../../service/admin-iot-rest-api";
import { HttpChatApi } from "../../../service/chat-rest-api";
import { userState, broadSetting } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../service/common_utils";
import * as ChatConstant from "../TheHyundai/ChatConstant";

import ChatBlock from "../../../service/chat/ChatBlock";
import ChatUpdate from "../../../service/chat/ChatUpdate";
import LoadingCircle from "../../../utilities/LoadingCircle";

interface propsType {
  broad_seq: string;
  channelArn: string;
  adminArn: string;
  chat_open: boolean;
  hidden_menu: boolean;
  userState: userState;
  extra_type: string;
  sendIoTMessage: any;
  chatInpuFocus: any;
}

let quoteBoxCss = {
  width: "100%",
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
};

const chatApi = new HttpChatApi();
const adminIotApi = new HttpAdminIotApi();

const LiveInfoChatMobile = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();

  const chatInnerRef = useRef<HTMLDivElement>();
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const quoteBoxClassName: any = quoteBoxCss;

  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<any>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({
    display: "none",
  });
  const [managerCampaignText, setManagerCampaignText] = useState("");
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibility, setVisibility] = useState("visible");

  // Ref for subscribe callback
  const messagesRef = useRef(messages);
  const chatBlockRef: any = useRef();
  const chatUpdateRef: any = useRef();

  const [quoteText, setQuoteText] = useState("");
  const [qMessageId, setQMessageId] = useState("");

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setMessages([]);
      setNextToken(undefined);
      setNeedScrollEnd(true);
      setBtnNewMsgBoxStyle({ display: "none" });
      setManagerCampaignText("");
      setVisibleStartIndex(0);
      setVisibility("visible");
      setQuoteText("");
      setQMessageId("");
      setLoading(false);
    };
  }, []);

  // Form for send message to chime channel
  useEffect(() => {
    if (props.hidden_menu && !props.chat_open) {
      setVisibility((visibility) => "hidden");
      setBtnNewMsgBoxStyle({ display: "none" });
    } else {
      setVisibility((visibility) => "visible");
      setBtnNewMsgBoxStyle(needScrollEnd ? { display: "none" } : { display: "inline" });
    }
  }, [props.hidden_menu, props.chat_open]);

  // subscript callback 연동용 Ref 최신화
  useEffect(() => {
    messagesRef.current = messages;
  });

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기
  useEffect(() => {
    if (managerCampaignText !== "") {
      setTimeout(() => {
        setManagerCampaignText("");
      }, 2000);
    }
  }, [managerCampaignText]);

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        if (!props.hidden_menu) setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (channelArn: string) => {
    const param: any = {
      channel_arn: channelArn,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
    };

    await chatApi.list_chat_history(param).then((result: any) => {
      if (result.code === "200") {
        setMessages(result.response.chat_history.Messages);
      }
    });
  };

  // 차임 메시지 도착(실시간)시 처리용 프로세스
  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    if (newMessage.ChannelArn !== props.channelArn) return;
    let isDuplicate = false;
    messagesRef.current.forEach((m: any, i: number, self: any) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages: any = [...messagesRef.current];
    if (!isDuplicate && newMessage.Persistence === ChatConstant.Persistence.PERSISTENT) {
      newMessages = [...newMessages, newMessage];
      setMessages(newMessages);
    }
  };

  const fncQuote = (msg: any) => {
    if (props.extra_type === "THD") setQuoteText(`(${cUtils.maskingChar(msg.Sender.Name)}) ${msg.Content}`);
    else setQuoteText(`(${msg.Sender.Name}) ${msg.Content}`);
    setQMessageId(msg.MessageId);
    props.chatInpuFocus();
  };

  const fncBlock = async (message: any) => {
    // Speed Dial open
    chatBlockRef.current.open(message);
  };

  const fncChatUpdate = async (msg: any) => {
    chatUpdateRef.current.open(msg);
  };

  const fncUpdate = async (msg: any) => {
    setLoading(true);
    await chatApi.update_chat_history(msg, props.broad_seq);
    const topic = `live/${props.broad_seq}/payload`;
    const chatPayload = {
      topic: "chat/message",
      ChannelArn: props.channelArn,
      Content: msg.Content,
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: msg.MessageId,
      Persistence: "PERSISTENT",
      Redacted: false,
      Sender: {
        Arn: props.adminArn,
        Name: props.userState.nickName,
      },
      Status: {
        Value: "SENT",
      },
      Type: "STANDARD",
    };
    // 채팅 금지 확인 필요
    const param: any = {
      topic: topic,
      payload: chatPayload,
    };

    await adminIotApi.send_iot_message(param);
    setLoading(false);

    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  const fncResetQuote = () => {
    setQuoteText((quoteText) => "");
    setQMessageId((qMessageId) => "");
  };

  // 채팅 메시지 전송
  const sendMessage = async (msg: string, meta: string) => {
    const origin_msg = msg;
    const qMsgId = qMessageId;
    // 답글이 있으면 답글을 신규 컨텐츠에 추가
    if (quoteText !== "") {
      msg = quoteText + appConfig.quote_separator + msg;
    }

    fncResetQuote();

    const msgId = uuidv4();
    const topic = `live/${props.broad_seq}/payload`;
    const chatPayload = {
      topic: "chat/message",
      ChannelArn: props.channelArn,
      Content: msg,
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: msgId,
      Persistence: "PERSISTENT",
      Redacted: false,
      Sender: {
        Arn: props.adminArn,
        Name: props.userState.nickName,
      },
      Status: {
        Value: "SENT",
      },
      Type: "STANDARD",
    };
    // 채팅 금지 확인 필요
    const param: any = {
      topic: topic,
      payload: chatPayload,
    };

    await adminIotApi.send_iot_message(param);
    await chatApi.send_chat_history(chatPayload);
    if (quoteText !== "") {
      const resultQna = await chatApi.send_qna_message(props.broad_seq, props.channelArn, qMsgId, msgId, origin_msg, props.adminArn);
      props.sendIoTMessage({
        message: "qna",
        q: resultQna.response.q_message_info.content,
        qSender: resultQna.response.q_message_info.nick_name,
        a: origin_msg,
        aSender: resultQna.response.a_message_info.nick_name,
      });
    }

    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    return (
      <ListItem sx={{ userSelect: "text" }} style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={{
                  width: "100%",
                  color: "black",
                  display: "inline-flex",
                  fontSize: "1rem",
                  ...(messages[index].Sender.Arn === props.adminArn ? { justifyContent: "flex-end" } : { justifyContent: "flex-start" }),
                }}
              >
                <Stack direction="column" spacing={1}>
                  {messages[index].Sender.Arn !== props.adminArn && messages[index].Sender.Arn !== messages[index - 1]?.Sender.Arn && (
                    <span style={{ maxWidth: "10rem", display: "inline-block", textOverflow: "ellipsis", overflow: "hidden" }}>
                      {cUtils.maskingChar(messages[index].Sender.Name)}
                    </span>
                  )}
                  <Stack direction="row" spacing={1}>
                    {messages[index].Sender.Arn === props.adminArn && (
                      <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                        <IconButton sx={{ padding: 0 }} onClick={() => fncChatUpdate(messages[index])}>
                          {/* <ModeIcon sx={{ color: "black" }} /> */}
                          <ModeIcon sx={{ color: "white" }} />
                        </IconButton>
                      </div>
                    )}
                    <div className={messages[index].Sender.Arn === props.adminArn ? "speech-bubble-me" : "speech-bubble"}>
                      {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                        const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                        let styleCss: any = {
                          wordBreak: "break-all",
                          whiteSpace: "pre-line",
                        };
                        if (idx !== repleLength - 1) styleCss.color = "#999999";
                        if (idx === 0) {
                          if (messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked") {
                            styleCss.color = "cadetblue";
                            // 차단된 메세지
                            return (
                              <del key={index} style={styleCss}>
                                {"[차단]"}
                                {line}
                              </del>
                            );
                          } else {
                            return (
                              <span key={index} style={styleCss}>
                                {line}
                              </span>
                            );
                          }
                        } else {
                          // styleCss.color = "#59f4f4";
                          return (
                            <span key={index + "-" + idx} style={styleCss}>
                              <Divider sx={{ borderColor: "#000" }} />
                              {appConfig.quote_separator + " " + line}
                            </span>
                          );
                        }
                      })}
                    </div>
                    {messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked" ? (
                      <></>
                    ) : (
                      messages[index].Sender.Arn !== props.adminArn && (
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end" }}>
                          <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                            <IconButton sx={{ padding: 0 }} onClick={() => fncQuote(messages[index])}>
                              {/* <MapsUgcIcon sx={{ color: "black" }} /> */}
                              <MapsUgcIcon sx={{ color: "white" }} />
                            </IconButton>
                          </div>
                          <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                            <IconButton sx={{ padding: 0 }} onClick={() => fncBlock(messages[index])}>
                              <BlockIcon sx={{ color: "red" }} />
                            </IconButton>
                          </div>
                        </Stack>
                      )
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);
    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current.scrollToItem(messages.length, "end");
    }
  };

  // 채팅 삭제
  const resetMessages = async () => {
    if (window.confirm("채팅 메세지를 모두 삭제하시겠습니까?")) {
      setLoading(true);
      const param: any = {
        channel_arn: props.channelArn,
        broad_seq: props.broad_seq,
      };
      await chatApi.delete_chat_history(param);
      setLoading(false);
      window.location.reload();
    }
  };

  const callbackChatBlock = () => {};

  useEffect(() => {
    if (props.channelArn !== "" && props.channelArn !== undefined) {
      getListChannelMessages(props.channelArn);
    }
  }, [props.channelArn]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    sendMessage,
    scrollToBottom,
    fncResetQuote,
    processChannelMessage,
  }));

  return (
    <>
      <Box
        id="thd-chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            // width: "90%",
            height: "40vh",
            visibility: visibility,
          }
        }
        className="thd-chat-root"
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            id="chat-box"
            component="div"
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
            className={visibleStartIndex > 0 ? "thd-gradient-box" : ""}
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              <AutoSizer style={{ height: "100%", width: "100%" }}>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="thd-chat-list"
                    innerRef={chatInnerRef}
                    ref={listRef}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>

            {/* 채팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
            <Box className="btn-new-chat-hd" sx={btnNewMsgBoxStyle}>
              <Button
                sx={{
                  backgroundColor: "#ff376d",
                  "&:hover": { backgroundColor: "#ff376d" },
                }}
                variant="contained"
                size="small"
                onClick={scrollToBottom}
                endIcon={<ArrowCircleDownIcon />}
              >
                <span>최신 채팅으로 이동</span>
              </Button>
            </Box>
            {/* <Box
              sx={{
                position: "absolute",
                top: 60,
                right: 10,
                width: 150,
                pl: 3,
                pr: 3,
                borderRadius: "0.5rem",
                backgroundColor: "#ffffff",
              }}
            >
              <Button variant="text" color="secondary" onClick={() => resetMessages()} startIcon={<DeleteForeverIcon />} fullWidth>
                채팅삭제
              </Button>
            </Box> */}
          </Box>
        </>
        {quoteText !== "" && (
          <Box className="quote-Container-2" sx={quoteBoxClassName}>
            <div style={{ flexGrow: 1 }}>{quoteText}</div>
            <IconButton sx={{ padding: 0 }} onClick={fncResetQuote}>
              <CloseIcon className="color-snow" />
            </IconButton>
          </Box>
        )}
        <ChatBlock ref={chatBlockRef} broadSeq={props.broad_seq} callBack={callbackChatBlock} />
      </Box>
      <ChatUpdate ref={chatUpdateRef} updateChat={fncUpdate} />
      <LoadingCircle loading={loading} />
    </>
  );
};

export default forwardRef(LiveInfoChatMobile);
