import { useEffect, useState } from "react";
import { CommonUtils } from "../../../service/common_utils";
import "./BroadWait.css";

interface propsType {
  remainTime: string;
  broad_info: any;
}

const BroadWaitIOS = (props: propsType) => {
  const cUtils = new CommonUtils();

  const [broadStartText, setBroadStartText] = useState("");

  useEffect(() => {
    setBroadStartText(cUtils.convertBroadStartText(props.broad_info.broad_start_tm));
  }, [props.broad_info.broad_start_tm]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadStartText("");
    };
  }, []);

  return (
    <div>
      {/* Android에서 http 이미지 안보이는 이슈 대응 */}
      {/* <video
        id="videoPlayer"
        className="th-client-video"
        poster={
          props.broad_info.broad_cover_url !== ""
            ? props.broad_info.broad_cover_url.startsWith("http://")
              ? "https://" + props.broad_info.broad_cover_url.substring(7)
              : props.broad_info.broad_cover_url
            : props.broad_info.setting.ready_img_url
        }
      ></video> */}
      <div className="remain-item snow">
        <div className="remain-live-mark">
          <img src="/images/comingsoon.png" alt="" />
        </div>
        <div className="remain-comming-soon">COMING SOON</div>
        <div className="remain-broad-time">{broadStartText}</div>
        {props.remainTime !== "" && props.remainTime !== undefined && (
          <div className="remain-time">{props.remainTime}</div>
        )}
      </div>
    </div>
  );
};

export default BroadWaitIOS;
