import { useEffect, useState } from "react";
import dayjs from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpChannelApi, GetProdClickParams } from "../../../service/channel-api";
import { CommonUtils } from "../../../service/common_utils";
import { useInterval } from "../../../utilities/UseInterval";
import LineChart from "./LineChart";
import { Typography } from "@mui/material";

interface propsType {
  userState: userState;
  broadInfo: any;
}

const channelApi = new HttpChannelApi();

const StatisticInfo = (props: propsType) => {
  const cUtils = new CommonUtils();

  const [viewChart, setViewChart] = useState(0);
  const [statisticsList, setStatisticsList] = useState<any>([]);

  const [prodClickList, setProdClickList] = useState<any>([]);
  const [totalLike, setTotalLike] = useState(0);
  const [totalLiveLike, setTotalLiveLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [totalLiveView, setTotalLiveView] = useState(0);
  const [totalViewTime, setTotalViewTime] = useState(0);
  const [concurentView, setConcurentView] = useState(0);

  const [statisticsExcelDataList, setStatisticsExcelDataList] = useState<any>([]);

  const [concurentViewDataRows, setConcurentViewDataRows] = useState<any>([]);
  const [enterDataRows, setEnterDataRows] = useState<any>([]);
  const [likeDataRows, setLikeDataRows] = useState<any>([]);
  const [prodDataRows, setProdDataRows] = useState<any>([]);
  const [deliveredSecDataRows, setDeliveredSecDataRows] = useState<any>([]);

  // 통계 데이터 조회
  const getStatisticsData = async () => {
    setStatisticsList([]);
    const statisticsData = await channelApi.get_statistics_data(props.broadInfo);
    if (statisticsData.result_code === "200") {
      setStatisticsList(statisticsData.result_body.statistic);
      setTotalLike(statisticsData.result_body.total_likes);
      setTotalView(statisticsData.result_body.total_views);
    }
    handleProdClickDownload();
  };

  const handleProdClickDownload = async () => {
    const param: GetProdClickParams = {
      broad_seq: props.broadInfo.broad_seq,
    };

    await channelApi.get_prod_click(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  const convertTimeFormat = (time: string) => {
    return (
      time.substring(0, 4) +
      "-" +
      time.substring(4, 6) +
      "-" +
      time.substring(6, 8) +
      " " +
      time.substring(8, 10) +
      ":" +
      time.substring(10, 12)
    );
  };

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const totalList = [];
      const concurentViewList = [];
      const enterCntList = [];
      const likeCntList = [];
      const prodCntList = [];
      const deliveredSecList = [];
      let lastConcurentView: any = undefined;
      let totalLiveViewTimeSec = 0;
      let totalLiveEnterCnt = 0;
      let totalLiveLikeCnt = 0;

      for (const data of newDataRows) {
        const time = convertTimeFormat(data.sk);
        totalList.push({
          time: time,
          concurrent: data.concurrent_cnt,
          enter: data.enter_cnt,
          like: data.like_cnt,
          diliveredTime: data.deliverd_sec,
        });
        concurentViewList.push({ label: data.sk, value: data.concurrent_cnt });
        enterCntList.push({ label: data.sk, value: data.enter_cnt });
        likeCntList.push({ label: data.sk, value: data.like_cnt });
        prodCntList.push({ label: data.sk, value: data.prod_click_cnt });
        deliveredSecList.push({ label: data.sk, value: data.deliverd_sec });
        lastConcurentView = data.concurrent_cnt;
        totalLiveViewTimeSec = totalLiveViewTimeSec + data.deliverd_sec;
        totalLiveEnterCnt = totalLiveEnterCnt + data.enter_cnt;
        totalLiveLikeCnt = totalLiveLikeCnt + data.like_cnt;
      }
      if (lastConcurentView !== undefined) {
        setConcurentView(lastConcurentView);
      }
      setTotalViewTime(totalLiveViewTimeSec);
      setTotalLiveView(totalLiveEnterCnt);
      setTotalLiveLike(totalLiveLikeCnt);

      setStatisticsExcelDataList(totalList);
      setConcurentViewDataRows(concurentViewList);
      setEnterDataRows(enterCntList);
      setLikeDataRows(likeCntList);
      setProdDataRows(prodCntList);
      setDeliveredSecDataRows(deliveredSecList);
    }
  }, [statisticsList]);

  const downloadProdExcelList = () => {
    // if (prodClickList.length === 0) {
    //   alert("통계데이터 다운로드는 방송 종료 후 가능합니다.");
    //   return;
    // }
    // sheet header
    let prodHistory: any = [["순번", "방송명", "사용자ID", "클릭일자", "상품ID", "상품명", "상품가격"]];
    // 각 행 순번 만들기
    let prodIndex = 1;
    // 내용 생성
    for (const item of prodClickList) {
      const detail = [
        prodIndex,
        props.broadInfo.broad_title,
        item.user_id,
        dayjs(String(item.sk)).format("YYYY-MM-DD HH:mm:ss"),
        item.prod_id,
        item.prod_name,
        item.prod_price,
      ];
      prodHistory = [...prodHistory, detail];
      prodIndex += 1;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];

    // sheet header
    let statisticsHistory: any = [["순번", "시간", "동시시청자", "입장고객", "좋아요", "시청시간(초)"]];
    // 각 행 순번 만들기
    let statisticsIndex = 1;
    // 내용 생성
    for (const item of statisticsExcelDataList) {
      const detail = [
        statisticsIndex,
        dayjs(String(item.time)).format("YYYY-MM-DD HH:mm"),
        item.concurrent,
        item.enter,
        item.like,
        item.diliveredTime,
      ];
      statisticsHistory = [...statisticsHistory, detail];
      statisticsIndex += 1;
    }

    const clickSheetName = "상품클릭내역";
    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName =
      dayjs(String(props.broadInfo.broad_start_tm)).format("YYYY-MM-DD HHmm") +
      "_통계데이터_" +
      props.broadInfo.broad_title +
      ".xlsx";
    cUtils.downloadStatisticsExcel(
      prodHistory,
      statisticsHistory,
      colWidth,
      clickSheetName,
      statsticsSheetName,
      fileName
    );
  };

  const convertTotalViewTime = (totalSec: number) => {
    const t = cUtils.convertSecToTime(totalSec);
    return `${cUtils.numericComma(Number(t.hours))}:${cUtils.numericComma(Number(t.minutes))}:${cUtils.numericComma(
      Number(t.seconds)
    )}`;
  };

  const delay: number = 60000; // 1분마다 변경
  useInterval(
    () => {
      getStatisticsData();
    },
    props.broadInfo.broad_status === "START" ? delay : null
  );

  useEffect(() => {
    getStatisticsData();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setViewChart(0);
      setStatisticsList([]);
      setProdClickList([]);
      setTotalLike(0);
      setTotalView(0);
      setConcurentView(0);
      setTotalViewTime(0);
      setTotalLiveView(0);
      setTotalLiveLike(0);
      setStatisticsExcelDataList([]);
      setConcurentViewDataRows([]);
      setEnterDataRows([]);
      setLikeDataRows([]);
      setProdDataRows([]);
      setDeliveredSecDataRows([]);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box>
          <Stack spacing={2} direction="column">
            <Box>
              <Typography variant="caption" component="div" align="right">
                ()안의 숫자는 라이브가 아닐때 발생한 숫자입니다.
              </Typography>
              <ButtonGroup variant="outlined" fullWidth>
                <Button
                  onClick={() => {
                    setViewChart(0);
                  }}
                >
                  동시 : {cUtils.numericComma(concurentView)}명
                </Button>
                <Button
                  onClick={() => {
                    setViewChart(1);
                  }}
                >
                  누적 : {cUtils.numericComma(totalLiveView)}({cUtils.numericComma(totalView - totalLiveView)})명
                </Button>
                <Button
                  onClick={() => {
                    setViewChart(2);
                  }}
                >
                  좋아요 : {cUtils.numericComma(totalLiveLike)}({cUtils.numericComma(totalLike - totalLiveLike)})건
                </Button>
                <Button
                  onClick={() => {
                    setViewChart(3);
                  }}
                >
                  상품 : {cUtils.numericComma(prodClickList.length)}건
                </Button>
                <Button
                  onClick={() => {
                    setViewChart(4);
                  }}
                >
                  누적시청 : {convertTotalViewTime(totalViewTime)}
                </Button>
              </ButtonGroup>
            </Box>

            <Button
              variant="contained"
              onClick={() => {
                downloadProdExcelList();
              }}
              fullWidth
            >
              통계 다운로드
            </Button>
          </Stack>
          <div className="statistic-chart-root">
            {viewChart === 0 && (
              <LineChart
                dataRows={concurentViewDataRows}
                chartTitle={"동시시청자"}
                labelTitle={"동시시청자"}
                xName={"시간"}
                yName={"명"}
                lineColor={"rgb(255,128,0)"}
              />
            )}
            {viewChart === 1 && (
              <LineChart
                dataRows={enterDataRows}
                chartTitle={"입장고객"}
                labelTitle={"입장고객"}
                xName={"시간"}
                yName={"명"}
                lineColor={"#f07818"}
              />
            )}
            {viewChart === 2 && (
              <LineChart
                dataRows={likeDataRows}
                chartTitle={"좋아요"}
                labelTitle={"좋아요"}
                xName={"시간"}
                yName={"건수"}
                lineColor={"#f07818"}
              />
            )}
            {viewChart === 3 && (
              <LineChart
                dataRows={prodDataRows}
                chartTitle={"상품클릭"}
                labelTitle={"상품클릭"}
                xName={"시간"}
                yName={"건수"}
                lineColor={"#f07818"}
              />
            )}
            {viewChart === 4 && (
              <LineChart
                dataRows={deliveredSecDataRows}
                chartTitle={"시청시간"}
                labelTitle={"시청시간"}
                xName={"시간"}
                yName={"초"}
                lineColor={"#f07818"}
              />
            )}
          </div>
        </Box>
      )}
    </>
  );
};

export default StatisticInfo;
