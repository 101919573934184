import { useEffect, useState } from "react";
// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpChannelApi, GetProdClickParams } from "../../../service/channel-api";
import { CommonUtils } from "../../../service/common_utils";
import { useInterval } from "../../../utilities/UseInterval";

interface propsType {
  userState: userState;
  broadInfo: any;
  concurrentCnt: number;
  enterCnt: number;
  likeCnt: number;
}

const channelApi = new HttpChannelApi();

const StatisticInfoMobile = (props: propsType) => {
  const cUtils = new CommonUtils();
  const [statisticsList, setStatisticsList] = useState<any>([]);

  const [prodClickList, setProdClickList] = useState<any>([]);
  const [totalLike, setTotalLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [concurentView, setConcurentView] = useState(0);

  // 통계 데이터 조회
  const getStatisticsData = async () => {
    setStatisticsList([]);
    const statisticsData = await channelApi.get_statistics_data(props.broadInfo);
    if (statisticsData.result_code === "200") {
      setStatisticsList(statisticsData.result_body.statistic);
      setTotalLike(statisticsData.result_body.total_likes);
      setTotalView(statisticsData.result_body.total_views);
    }
    handleProdClickDownload();
  };

  const handleProdClickDownload = async () => {
    const param: GetProdClickParams = {
      broad_seq: props.broadInfo.broad_seq,
    };

    await channelApi.get_prod_click(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      let lastConcurentView: any = undefined;

      for (const data of newDataRows) {
        lastConcurentView = data.concurrent_cnt;
      }
      if (lastConcurentView !== undefined) {
        setConcurentView(lastConcurentView);
      }
    }
  }, [statisticsList]);

  const delay: number = 60000; // 30초마다 변경
  useInterval(
    () => {
      getStatisticsData();
      // handleProdClickDownload();
    },
    props.broadInfo.broad_status === "START" ? delay : null
  );

  useEffect(() => {
    // handleProdClickDownload();
    getStatisticsData();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setProdClickList([]);
      setStatisticsList([]);
      setTotalLike(0);
      setTotalView(0);
      setConcurentView(0);
      // setConcurentViewDataRows([]);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box className="live-statistic-info-mobile-box">
          <Stack spacing={2} direction="row">
            <Typography>동시시청자 : {cUtils.numericComma(concurentView)}</Typography>
            <Typography>누적시청자 : {cUtils.numericComma(totalView)}</Typography>
            <Typography>좋아요 : {cUtils.numericComma(totalLike)}</Typography>
            <Typography>상품클릭 : {cUtils.numericComma(prodClickList.length)}</Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default StatisticInfoMobile;
