import Typography from "@mui/material/Typography";
const StreamErrorNotice = () => {
  return (
    <>
      <Typography className="error-notice"> {"방송이 안보이시나요?\n새로고침 하시거나\n더현대닷컴 앱으로\n이용해주세요."}</Typography>
    </>
  );
};

export default StreamErrorNotice;
