import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface propsType {
  loading: boolean;
}

const LoadingCircleOnVideo = (props: propsType) => {
  return (
    <>
      <Box
        id="loading-circle-on-video"
        className="loading-circle-video"
        sx={props.loading ? { display: "flex", color: "#fff", zIndex: 801 } : { display: "none" }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </>
  );
};

export default LoadingCircleOnVideo;
