import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "./LiveFooter.css";

interface propsType {
  broad_status: string;
  user_id: string;
  sendMessage: any;
}

interface chatSendForm {
  msg: string;
}

const LiveChatTF = (props: propsType, ref: any) => {
  const inputChatRef: any = useRef();

  const [inputChatTFDisplay, setInputChatTFDisplay] = useState("none");
  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setInputChatTFDisplay("none");
    };
  }, []);

  // Form for send message to chime channel
  const {
    register,
    setFocus,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    document.getElementById("input-chat-Message")?.focus();
    reset({ msg: "" });
    await props.sendMessage(data.msg, "");
  };

  const handleHiddenForm = (hidden: boolean) => {
    if (hidden) setInputChatTFDisplay("none");
    else {
      setInputChatTFDisplay("flex");
      // 가상의 인풋을 만든다
      const fakeInput: any = document.createElement("input");
      fakeInput.setAttribute("type", "text");
      fakeInput.style.position = "absolute";
      fakeInput.style.opacity = 0;
      fakeInput.style.height = 0;
      fakeInput.style.fontSize = "16px"; // disable auto zoom
      window.document.body.appendChild(fakeInput);
      fakeInput.focus(); // 가상의 인풋에 먼저 포커스를 주고

      setTimeout(() => {
        window.document.getElementById("input-chat-Message")?.focus(); // 실제 우리 채팅창에 포커스(근데 이건 다른 컴포넌트에 있다..)
        window.document.body.removeChild(fakeInput); // 가상의 인풋은 삭제
      }, 300);
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    handleHiddenForm,
  }));

  return (
    <>
      <Box
        id="thd-input-chat"
        sx={
          props.broad_status === "VOD"
            ? { flexGrow: 1, display: "none" }
            : {
                flexGrow: 1,
                display: inputChatTFDisplay,
                alignItems: "center",
              }
        }
        ref={inputChatRef}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <TextField
          id="input-chat-Message"
          className="thd-without-padding "
          inputProps={
            props.user_id === ""
              ? { readOnly: true, enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
              : { enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
          }
          type="search"
          autoComplete="off"
          sx={{ width: "100%", color: "black", "&::placeholder": { color: "gray" } }}
          placeholder={props.user_id === "" ? "로그인 후 채팅에 참여하세요." : "채팅에 참여해 주세요."}
          {...register("msg", { required: true, maxLength: 3000 })}
        />
        <Button id="thd-input-chat-button" type="submit" variant="text">
          보내기
        </Button>
      </Box>
    </>
  );
};

export default forwardRef(LiveChatTF);
