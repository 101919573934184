import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface propsType {
  loading: boolean;
}

const LoadingCircle = (props: propsType) => {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoadingCircle;
