import { useState, createRef, forwardRef, useImperativeHandle, useEffect } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import { recordInfo, userState } from "../../../interface/MainInterface";
import { HttpChannelApi, ChBaseParams, UpdateChParams } from "../../../service/channel-api";
import { CommonUtils } from "../../../service/common_utils";
import { appConfig } from "../../../config/Config";
import LoadingCircle from "../../../utilities/LoadingCircle";

import { VodIVSPlayerAdmin, player as vodPlayer } from "../../../service/ivs-player/VodIVSPlayerAdmin";
import VodMediaList from "./VodMediaList";

interface propsType {
  userState: userState;
  broadInfo: any;
  getChannelInfo: any;
  setVodTimeStamp: any;
}

const chApi = new HttpChannelApi();
const ChangeVod = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const [loading, setLoading] = useState(false);

  // 등록된 VOD 시작, 종료 시간
  const [vodStartHour, setVodStartHour] = useState("0");
  const [vodStartMinute, setVodStartMinute] = useState("0");
  const [vodStartSecond, setVodStartSecond] = useState("0");
  const [vodEndHour, setVodEndHour] = useState("0");
  const [vodEndMinute, setVodEndMinute] = useState("0");
  const [vodEndSecond, setVodEndSecond] = useState("0");
  const [vodUrl, setVodUrl] = useState("");

  const [nowPlayList, setNowPlayList] = useState("full");

  // 하이라이트 시작시간
  const [highStartHour, setHighStartHour] = useState("0");
  const [highStartMinute, setHighStartMinute] = useState("0");
  const [highStartSecond, setHighStartSecond] = useState("0");

  // 녹화 리스트
  const [recordList, setRecordList] = useState<recordInfo[]>([]);

  // VOD 미리보기
  const videoRef = createRef();
  const [pipMode, setPipMode] = useState(false);
  const [vodMuted, setVodMuted] = useState(false);
  const [hiddenMenu, setHiddenMenu] = useState(true);
  const [openVodPreview, setOpenVodPreview] = useState(false);
  const handleVodPreviewDrawerClose = () => setOpenVodPreview(false);
  const handleVodPreviewDrawerOpen = () => {
    setVodMuted(false);
    setOpenVodPreview(true);
  };

  // 페이지 최초 로딩시 선택된 방송정보 세팅
  const setVodInfo = async () => {
    if (props.broadInfo.vod_start_sec > 0) {
      const t = cUtils.convertSecToTime(props.broadInfo.vod_start_sec);
      const t2 = cUtils.convertSecToTime(props.broadInfo.vod_end_sec);
      setVodStartHour(t.hours);
      setVodStartMinute(t.minutes);
      setVodStartSecond(t.seconds);
      setVodEndHour(t2.hours);
      setVodEndMinute(t2.minutes);
      setVodEndSecond(t2.seconds);
    } else {
      setVodStartHour("0");
      setVodStartMinute("0");
      setVodStartSecond("0");
      setVodEndHour("0");
      setVodEndMinute("0");
      setVodEndSecond("0");
    }

    if (props.broadInfo.high_start_sec > 0) {
      const t3 = cUtils.convertSecToTime(props.broadInfo.high_start_sec);
      setHighStartHour(t3.hours);
      setHighStartMinute(t3.minutes);
      setHighStartSecond(t3.seconds);
    } else {
      setHighStartHour("0");
      setHighStartMinute("0");
      setHighStartSecond("0");
    }

    getRecordList(props.broadInfo.pk, props.broadInfo.broad_seq); // 녹화 목록 조회
  };

  // 필드 초기화
  const initForm = () => {
    setRecordList([]);
    setVodStartHour("0");
    setVodStartMinute("0");
    setVodStartSecond("0");
    setVodEndHour("0");
    setVodEndMinute("0");
    setVodEndSecond("0");
    setHighStartHour("0");
    setHighStartMinute("0");
    setHighStartSecond("0");
  };

  // 레코딩 리스트 조회
  const getRecordList = async (_pk: string, _broadSeq: string) => {
    const param: ChBaseParams = {
      pk: _pk,
      broad_seq: _broadSeq,
    };
    setRecordList([]);
    setLoading(true);
    const res = await chApi.get_record_list(param);
    if (res.result_code === "200") {
      setRecordList(res.result_body);
    } else {
      console.error("[ERROR] getRecordList : ", res.result_body);
    }
    setLoading(false);
  };

  // 채널에 VOD 지정하기
  const setVodforChannel = async (record_root: string, started_at: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (!window.confirm("선택하신 영상을 VOD로 등록하시겠습니까?")) return;
    setLoading(true);
    const param: UpdateChParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_title: props.broadInfo.broad_title,
      broad_desc: props.broadInfo.broad_desc,
      broad_start_tm: props.broadInfo.broad_start_tm,
      broad_stop_tm: props.broadInfo.broad_stop_tm,
      broad_prod_list: props.broadInfo.broad_prod_list,
      broad_notice: props.broadInfo.broad_notice,
      broad_status: "VOD",
      vod_url: "/" + record_root + "/media/hls/master.m3u8",
      vod_start_sec: props.broadInfo.vod_start_sec,
      vod_end_sec: props.broadInfo.vod_end_sec,
      vod_started_at: started_at,
      high_start_sec: props.broadInfo.high_start_sec,
      password: props.broadInfo.password,
      extra_type: props.broadInfo.extra_type,
      progress_store: props.broadInfo.progress_store,
      setting: props.broadInfo.setting,
      host_id: props.broadInfo.host_id,
      channel_img: props.broadInfo.channel_img,
      channel_template: props.broadInfo.channel_template,
    };
    const res = await chApi.update_channel(param);
    setLoading(false);
    if (res.result_code === "200") {
      alert("VOD를 등록 완료하였습니다.");
      props.getChannelInfo(props.broadInfo.pk, props.broadInfo.broad_seq);
      setNowPlayList("full");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
  };

  // VOD 해제하기
  const unSetVodforChannel = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (!window.confirm("VOD를 하제하시겠습니까?")) return;
    setLoading(true);
    const param: UpdateChParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_title: props.broadInfo.broad_title,
      broad_desc: props.broadInfo.broad_desc,
      broad_start_tm: props.broadInfo.broad_start_tm,
      broad_stop_tm: props.broadInfo.broad_stop_tm,
      broad_prod_list: props.broadInfo.broad_prod_list,
      broad_notice: props.broadInfo.broad_notice,
      broad_status: "STOP",
      vod_url: "",
      vod_started_at: "",
      vod_start_sec: 0,
      vod_end_sec: 0,
      high_start_sec: 0,
      password: props.broadInfo.password,
      extra_type: props.broadInfo.extra_type,
      progress_store: props.broadInfo.progress_store,
      setting: props.broadInfo.setting,
      host_id: props.broadInfo.host_id,
      channel_img: props.broadInfo.channel_img,
      channel_template: props.broadInfo.channel_template,
    };
    const res = await chApi.update_channel(param);
    if (res.result_code === "200") {
      alert("등록된 VOD를 해제 완료하였습니다.");
      props.getChannelInfo(props.broadInfo.pk, props.broadInfo.broad_seq);
      setNowPlayList("");
      viewVod("");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // VOD Play time 지정하기
  const saveVodStartTime = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    const hsec = parseInt(vodStartHour) * 60 * 60;
    const msec = parseInt(vodStartMinute) * 60;
    const sec = parseInt(vodStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    const hEsec = parseInt(vodEndHour) * 60 * 60;
    const mEsec = parseInt(vodEndMinute) * 60;
    const eSec = parseInt(vodEndSecond) * 1;
    const totalESec = hEsec + mEsec + eSec;

    setLoading(true);
    const param: UpdateChParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_title: props.broadInfo.broad_title,
      broad_desc: props.broadInfo.broad_desc,
      broad_start_tm: props.broadInfo.broad_start_tm,
      broad_stop_tm: props.broadInfo.broad_stop_tm,
      broad_prod_list: props.broadInfo.broad_prod_list,
      broad_notice: props.broadInfo.broad_notice,
      broad_status: props.broadInfo.broad_status,
      vod_url: props.broadInfo.vod_url.replaceAll(appConfig.vod_cloudfront_endpoint, ""),
      vod_started_at: props.broadInfo.vod_started_at,
      vod_start_sec: totalSec,
      vod_end_sec: totalESec,
      high_start_sec: props.broadInfo.high_start_sec,
      password: props.broadInfo.password,
      extra_type: props.broadInfo.extra_type,
      progress_store: props.broadInfo.progress_store,
      setting: props.broadInfo.setting,
      host_id: props.broadInfo.host_id,
      channel_img: props.broadInfo.channel_img,
      channel_template: props.broadInfo.channel_template,
    };
    const res = await chApi.update_channel(param);
    if (res.result_code === "200") {
      await props.getChannelInfo(props.broadInfo.pk, props.broadInfo.broad_seq);
      viewVod(props.broadInfo.vod_url);
      setNowPlayList("full");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const saveHighStartTime = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    const hsec = parseInt(highStartHour) * 60 * 60;
    const msec = parseInt(highStartMinute) * 60;
    const sec = parseInt(highStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    setLoading(true);
    const param: UpdateChParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_title: props.broadInfo.broad_title,
      broad_desc: props.broadInfo.broad_desc,
      broad_start_tm: props.broadInfo.broad_start_tm,
      broad_stop_tm: props.broadInfo.broad_stop_tm,
      broad_prod_list: props.broadInfo.broad_prod_list,
      broad_notice: props.broadInfo.broad_notice,
      broad_status: props.broadInfo.broad_status,
      vod_url: props.broadInfo.vod_url.replaceAll(appConfig.vod_cloudfront_endpoint, ""),
      vod_started_at: props.broadInfo.vod_started_at,
      vod_start_sec: props.broadInfo.vod_start_sec,
      vod_end_sec: props.broadInfo.vod_end_sec,
      high_start_sec: totalSec,
      password: props.broadInfo.password,
      extra_type: props.broadInfo.extra_type,
      progress_store: props.broadInfo.progress_store,
      setting: props.broadInfo.setting,
      host_id: props.broadInfo.host_id,
      channel_img: props.broadInfo.channel_img,
      channel_template: props.broadInfo.channel_template,
    };
    const res = await chApi.update_channel(param);
    if (res.result_code === "200") {
      props.getChannelInfo(props.broadInfo.pk, props.broadInfo.broad_seq);
      viewHigh(props.broadInfo.vod_url);
      setNowPlayList("highlight");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // 녹화 목록 영상 보기
  const viewRecord = (record_root: string, playListKey: string) => {
    // setVodUrl("/" + record_root + "/media/hls/master.m3u8");
    // handleVodPreviewDrawerOpen();
    props.setVodTimeStamp("full", appConfig.vod_cloudfront_endpoint + "/" + record_root + "/media/hls/master.m3u8", 0, 0);
    setNowPlayList(playListKey);
  };

  const viewFullVod = (vud_url: string) => {
    props.setVodTimeStamp("full", vud_url, 0, 0);
    setNowPlayList("full");
  };

  // 등록된 VOD 보기
  const viewVod = (vod_url: string) => {
    const hsec = parseInt(vodStartHour) * 60 * 60;
    const msec = parseInt(vodStartMinute) * 60;
    const sec = parseInt(vodStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    const hEsec = parseInt(vodEndHour) * 60 * 60;
    const mEsec = parseInt(vodEndMinute) * 60;
    const eSec = parseInt(vodEndSecond) * 1;
    const totalESec = hEsec + mEsec + eSec;

    props.setVodTimeStamp("full", vod_url, totalSec, totalESec);
    setNowPlayList("full");
  };

  const viewHigh = (vod_url: string) => {
    const hsec = parseInt(highStartHour) * 60 * 60;
    const msec = parseInt(highStartMinute) * 60;
    const sec = parseInt(highStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    props.setVodTimeStamp("highlight", vod_url, totalSec, 0);
    setNowPlayList("highlight");
  };

  // VOD PlayerCallBack
  const videoStatusCallback = (status: string) => {};
  const videoPositionCallback = (event: string, pos: number) => {};
  const pipCallback = (pip: boolean) => {};

  // VOD 음량 컨트롤
  const toggleVodMute = () => {
    const muteNext = !vodPlayer.isMuted();
    vodPlayer.setMuted(muteNext);
    setVodMuted(muteNext);
  };

  useEffect(() => {
    setVodInfo();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setVodStartHour("0");
      setVodStartMinute("0");
      setVodStartSecond("0");
      setVodEndHour("0");
      setVodEndMinute("0");
      setVodEndSecond("0");
      setVodUrl("");
      setNowPlayList("full");
      setHighStartHour("0");
      setHighStartMinute("0");
      setHighStartSecond("0");
      setRecordList([]);
      setPipMode(false);
      setVodMuted(false);
      setHiddenMenu(true);
      setOpenVodPreview(false);
    };
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setVodInfo,
    initForm,
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
        {/* 등록된 VOD 영역 */}
        {props.broadInfo.vod_started_at !== "" ? (
          <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
            <Box sx={{ width: "100%" }}>
              <Stack sx={{ width: "100%" }} direction={{ xs: "column" }} spacing={2}>
                <h3>등록된 VOD</h3>
                <List sx={{ width: "100%" }} component="nav" aria-label="mailbox folders">
                  <Divider />
                  <ListItem sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                      <ListItemText primary={"풀영상"} />
                      {"※ 시작구간 설정시 리허설이 포함된 전체영상을 보고 시간을 지정해주세요 >>>"}
                      <Button
                        variant="contained"
                        onClick={() => {
                          viewFullVod(props.broadInfo.vod_url);
                        }}
                        color={nowPlayList === "full" ? "error" : "primary"}
                      >
                        전체영상(리허설포함)
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          viewVod(props.broadInfo.vod_url);
                        }}
                        color={nowPlayList === "full" ? "error" : "primary"}
                      >
                        보기
                      </Button>
                    </Stack>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                      <TextField
                        id="start_hour"
                        label="hh"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodStartHour}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodStartHour("0");
                          } else {
                            setVodStartHour(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="start_minute"
                        label="mm"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodStartMinute}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodStartMinute("0");
                          } else {
                            setVodStartMinute(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="start_second"
                        label="ss"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodStartSecond}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodStartSecond("0");
                          } else {
                            setVodStartSecond(e.target.value);
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        className="bt-md"
                        sx={{ minWidth: 200 }}
                        onClick={() => {
                          saveVodStartTime();
                        }}
                      >
                        시작시간저장
                      </Button>
                    </Stack>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                      <TextField
                        id="end_hour"
                        label="hh"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodEndHour}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodEndHour("0");
                          } else {
                            setVodEndHour(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="end_minute"
                        label="mm"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodEndMinute}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodEndMinute("0");
                          } else {
                            setVodEndMinute(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="end_second"
                        label="ss"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={vodEndSecond}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setVodEndSecond("0");
                          } else {
                            setVodEndSecond(e.target.value);
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        className="bt-md"
                        sx={{ minWidth: 200 }}
                        onClick={() => {
                          saveVodStartTime();
                        }}
                      >
                        종료시간저장
                      </Button>
                    </Stack>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                      <ListItemText primary={"하이라이트 영상"} />
                      <Button
                        variant="outlined"
                        onClick={() => {
                          viewHigh(props.broadInfo.vod_url);
                        }}
                        color={nowPlayList === "highlight" ? "error" : "primary"}
                      >
                        보기
                      </Button>
                    </Stack>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                      <TextField
                        id="start_hour"
                        label="hh"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={highStartHour}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setHighStartHour("0");
                          } else {
                            setHighStartHour(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="start_minute"
                        label="mm"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={highStartMinute}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setHighStartMinute("0");
                          } else {
                            setHighStartMinute(e.target.value);
                          }
                        }}
                      />
                      <TextField
                        id="start_second"
                        label="ss"
                        type="number"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={highStartSecond}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setHighStartSecond("0");
                          } else {
                            setHighStartSecond(e.target.value);
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        className="bt-md"
                        sx={{ minWidth: 200 }}
                        onClick={() => {
                          saveHighStartTime();
                        }}
                      >
                        시작시간저장
                      </Button>
                    </Stack>
                  </ListItem>
                  <Divider />
                </List>
              </Stack>
            </Box>
          </Paper>
        ) : (
          <></>
        )}
        {/* 녹화 영상 목록 */}
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={props.broadInfo.vod_started_at !== "" ? { width: "100%", minHeight: 328 } : { width: "100%", minHeight: 642 }}>
            <List sx={{ width: "100%" }} component="nav" aria-label="mailbox folders">
              {recordList.length > 0 ? <Divider /> : <></>}
              {recordList.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <ListItem sx={{ width: "100%" }}>
                      <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                        <ListItemText
                          primary={
                            dayjs(new Date(item.stream_info.recording_started_at)).format("YYYY-MM-DD HH:mm").toString() + " 녹화영상"
                          }
                        />

                        <Button
                          variant="outlined"
                          onClick={() => {
                            viewRecord(item.stream_info.bucket_root, "record-" + index);
                          }}
                          color={nowPlayList === "record-" + index ? "error" : "primary"}
                        >
                          보기
                        </Button>
                        <VodMediaList userState={props.userState} recordInfo={item} broadInfo={props.broadInfo} />
                        {props.broadInfo.vod_url ===
                        appConfig.vod_cloudfront_endpoint + "/" + item.stream_info.bucket_root + "/media/hls/master.m3u8" ? (
                          <Button
                            variant="contained"
                            sx={{ minWidth: 125 }}
                            color="error"
                            onClick={() => {
                              unSetVodforChannel();
                            }}
                          >
                            등록된 VOD
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            sx={{ minWidth: 125 }}
                            onClick={() => {
                              setVodforChannel(
                                item.stream_info.bucket_root,
                                dayjs(new Date(item.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString()
                              );
                            }}
                          >
                            VOD로 등록
                          </Button>
                        )}
                      </Stack>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          </Box>
        </Paper>
        {/* VOD Player */}
        <Drawer
          PaperProps={{ elevation: 0, style: { height: "80%", borderRadius: "16px" } }}
          anchor="bottom"
          open={openVodPreview}
          onClose={handleVodPreviewDrawerClose}
          className="record-preview-drawer"
        >
          <Box sx={{ width: "100%", height: "100%", bgcolor: "while", borderRadius: "16px" }} className="record-preview-drawer">
            <div className="record_preview pos-relative">
              <VodIVSPlayerAdmin
                broad_seq={props.broadInfo.broad_seq}
                playbackUrl={appConfig.vod_cloudfront_endpoint + vodUrl}
                video_ref={videoRef}
                vod_start_sec={0}
                vod_end_sec={0}
                videoStatusCallback={videoStatusCallback}
                videoPositionCallback={videoPositionCallback}
                pip_mode={pipMode}
                pip_callback={pipCallback}
                hidden_menu={hiddenMenu}
              />
              <Box component="div" className="live_preview_btn">
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Stack direction="row" spacing={2}>
                    <IconButton sx={{ display: "flex", padding: 0 }} onClick={toggleVodMute}>
                      {vodMuted ? <VolumeOffIcon className="color-snow" /> : <VolumeUpIcon className="color-snow" />}
                    </IconButton>
                  </Stack>
                </Grid>
              </Box>
            </div>
          </Box>
        </Drawer>
        <LoadingCircle loading={loading} />
      </Stack>
    </Box>
  );
};

export default forwardRef(ChangeVod);
