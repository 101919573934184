import { ApiBase, BaseParams } from "./api-base";

export interface ComBaseParams extends BaseParams {}

export interface GetAdminListParams {
  grant_type_cd: string;
  user_id: string;
}

export interface SaveAdminParams {
  user_id: string;
  user_name: string;
  user_gubun_cd: string;
  team_name: string;
  use_yn: string;
}

export class HttpCommonApi extends ApiBase {
  onApiName(): string {
    return "AdminApiName";
  }

  onUrlPath(): string {
    return "/common";
  }

  public async get_admin_list(params: GetAdminListParams) {
    const requestBody = {
      command: "get_admin_list",
      search_condition: {
        grant_type_cd: params.grant_type_cd,
        user_id: params.user_id,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_admin_info(user_id: string) {
    const requestBody = {
      command: "get_admin_info",
      search_condition: {
        user_id: user_id,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async set_admin_info(params: SaveAdminParams) {
    const requestBody = {
      command: "set_admin_info",
      admin_info: {
        user_id: params.user_id,
        user_name: params.user_name,
        user_gubun_cd: params.user_gubun_cd,
        team_name: params.team_name,
        use_yn: params.use_yn,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_access_token() {
    const response = this.getAccessToken();
    return response;
  }
}
