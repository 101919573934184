import { useEffect, useState, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid, GridRenderCellParams, GridCellParams } from "@mui/x-data-grid";

// import user component
import { HttpChannelApi } from "../../../service/channel-api";
import { useWindowSize } from "../../../utilities/useWindowSize";
import { CommonUtils } from "../../../service/common_utils";
import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import Header from "../../Header";

interface propsType {
  userState: userState;
}

const channelApi = new HttpChannelApi();

const UsageExplore = (props: propsType) => {
  const cUtils = new CommonUtils();
  const toastRef: any = useRef();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [usageList, setUseageList] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = useState(608);

  const columns = [
    {
      field: "service",
      sortable: false,
      headerName: "서비스",
      flex: 1,
      minWidht: 300,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.sort2 === "1" ? "" : param.row.service}</span>
          </>
        );
      },
    },
    { field: "usage_type", sortable: false, headerName: "타입", flex: 1, minWidht: 300 },
    {
      field: "UnblendedCost_amount",
      sortable: false,
      headerName: "청구금액",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{cUtils.numericComma(param.row.UnblendedCost_amount)}</span>
          </>
        );
      },
    },
    {
      field: "UnblendedCost_unit",
      sortable: false,
      headerName: "단위",
      width: 200,
    },
    {
      field: "UsageQuantity_amount",
      sortable: false,
      headerName: "사용량",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{cUtils.numericComma(param.row.UsageQuantity_amount)}</span>
          </>
        );
      },
    },
    {
      field: "UsageQuantity_unit",
      sortable: false,
      headerName: "단위",
      width: 200,
    },
  ];

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };

  const downloadExcelList = () => {
    if (usageList.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다.", "error", 3000);
      return;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 300 }, { wpx: 300 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
    // sheet header
    let statisticsHistory: any = [["서비스", "타입", "청구금액", "단위", "사용량", "단위"]];

    // 내용 생성
    for (const item of usageList) {
      const detail = [
        item.service,
        item.usage_type,
        item.UnblendedCost_amount,
        item.UnblendedCost_unit,
        item.UsageQuantity_amount,
        item.UsageQuantity_amount,
      ];
      statisticsHistory = [...statisticsHistory, detail];
    }

    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName = `[${dayjs(srchDateFrom).format("YYYY-MM")}] 월별사용량통계.xlsx`;
    cUtils.downloadExcel(statisticsHistory, colWidth, statsticsSheetName, fileName);
  };

  useEffect(() => {
    setGridHeight(windowSize.height - 230);
  }, [windowSize]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setSrchDateFrom(dayjs().startOf("month"));
      setUseageList([]);
      setGridHeight(608);
    };
  }, []);

  const getCostReport = async () => {
    const param: any = {
      search_date_from: dayjs(srchDateFrom).format("YYYY-MM-DD"),
      search_date_to: dayjs(srchDateFrom).endOf("month").format("YYYY-MM-DD"),
    };
    setLoading(true);
    const res = await channelApi.get_cost_report(param);
    if (res.code === "200") {
      setUseageList(res.response.cost_list);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000);
    }
    setLoading(false);
  };
  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ p: 3 }}>
        {/* Title */}
        <Box component={"div"}>
          <Typography variant="h5" gutterBottom>
            월별사용량통계
          </Typography>
        </Box>
        {/* Title */}
        <Box>
          <Stack spacing={2} direction="column">
            {/* 검색조건 */}
            <Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="방송일From"
                    views={["year", "month"]}
                    openTo="month"
                    inputFormat="YYYY/MM"
                    value={srchDateFrom}
                    onChange={handleSrchDateFromChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" />}
                  />
                </LocalizationProvider>
                <Button variant="contained" size="small" onClick={getCostReport}>
                  조회
                </Button>
                <Button variant="contained" size="small" onClick={downloadExcelList}>
                  EXCEL
                </Button>
              </Stack>
            </Box>
            {/* 검색조건 */}
            {/* 그리드 */}
            <Box>
              <Paper
                sx={{
                  width: "100%",
                  height: gridHeight,
                  p: 2,
                  "& .data": {
                    backgroundColor: "#b9d5ff91",
                    color: "#1a3e72",
                  },
                  "& .sub-total": {
                    backgroundColor: "#ff943975",
                    color: "#1a3e72",
                  },
                  "& .total": {
                    backgroundColor: "#ff393975",
                    color: "#1a3e72",
                  },
                }}
                elevation={5}
              >
                <DataGrid
                  rows={usageList}
                  rowHeight={40}
                  columns={columns}
                  rowsPerPageOptions={[3, 10, 20, 50, 100]}
                  pagination
                  getRowId={(row) => `${row.service}-${row.usage_type}`}
                  getCellClassName={(params: GridCellParams<any, any, any>) => {
                    if (params.row.sort === "0") {
                      return "total";
                    } else {
                      if (params.row.sort2 === "0") return "sub-total";
                      else return "data";
                    }
                  }}
                />
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Box>
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default UsageExplore;
