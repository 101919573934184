import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";

import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, GetChannelInfoParam, UpdateChannelParam } from "../../service/thehyundai-rest-api";
import { prodInfo } from "../../interface/MainInterface";

const emptyRow = {
  prod_id: "",
  prod_title: "",
  prod_callback: "",
};

const UpdateChannel = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [broadSeq, setBroadSeq] = useState("");
  const [broadTitle, setBroadTitle] = useState("");
  const [broadDesc, setBroadDesc] = useState("");
  const [broadStartDtm, setBroadStartDtm] = useState("");
  const [broadEndDtm, setBroadEndDtm] = useState("");
  const [broadCoverUrl, setBroadCoverUrl] = useState("");
  const [broadPassword, setBroadPassword] = useState("");
  const [broadManagerId, setBroadManagerId] = useState("");
  const [resultBroadSeq, setResultBroadSeq] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const prodColumns = [
    { field: "prod_title", headerName: "상품명", width: 300, editable: true },
    { field: "prod_callback", headerName: "랜딩URL", flex: 1, editable: true },
    { field: "prod_id", headerName: "id", width: 100, hide: true },
  ];
  const [prodRows, setProdRows] = useState<prodInfo[]>([]);
  const [expandProdList, setExpandProdList] = useState(false);
  const toggleExpandProdGrid = () => {
    setExpandProdList((expandProdList) => !expandProdList);
  };

  const getChannelInfo = async () => {
    if (broadSeq === "" || broadSeq === undefined) {
      window.alert("불러올 채널의 고유번호를 입려하세요.");
      return;
    }

    const param: GetChannelInfoParam = {
      broad_seq: broadSeq,
    };
    setIsLoading(true);
    const res = await theHyundaiApi.get_broadcast_channel_info(param);
    if (res.code === "200") getBroadcastChannelSuccess(res.response);
    else getBroadcastChannelInfoFail(res);
  };

  const getBroadcastChannelSuccess = (res: any) => {
    setBroadTitle(res.channel_info.broad_title);
    setBroadDesc(res.channel_info.broad_desc);
    setBroadStartDtm(res.channel_info.broad_start_tm);
    setBroadEndDtm(res.channel_info.broad_stop_tm);
    setBroadCoverUrl(res.channel_info.broad_cover_url);
    setBroadPassword(res.channel_info.password);
    setBroadManagerId(res.channel_info.manager_id);
    setProdRows(res.channel_info.broad_prod_list);
    setIsLoading(false);
  };
  const getBroadcastChannelInfoFail = (error: any) => {
    if (error.code === "201" || error.code === "202") alert(`${error.response.error_msg}`);
    else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };

  const updateChannel = async () => {
    if (broadTitle === "" || broadTitle === undefined) {
      window.alert("방송명을 입력하세요.");
      return;
    }
    if (broadStartDtm === "" || broadStartDtm === undefined) {
      window.alert("방송 시작일시를 입력하세요.");
      return;
    }
    if (broadManagerId === "" || broadManagerId === undefined) {
      window.alert("방송 관리자의 ID를 입력하세요.");
      return;
    }

    const param: UpdateChannelParam = {
      broad_seq: broadSeq,
      broad_title: broadTitle,
      broad_desc: broadDesc,
      broad_start_tm: broadStartDtm,
      broad_stop_tm: broadEndDtm,
      broad_cover_url: broadCoverUrl,
      broad_prod_list: [],
      password: broadPassword,
      manager_id: broadManagerId,
    };

    setIsLoading(true);
    const res = await theHyundaiApi.update_broadcast_channel(param);
    if (res.code === "200") updateChannelSuccess(res);
    else updateChannelFail(res);
  };

  const updateChannelSuccess = (res: any) => {
    setResultBroadSeq(res.response.broad_seq);
    alert("채널 수정이 성공하였습니다.");
    setIsLoading(false);
  };
  const updateChannelFail = (error: any) => {
    alert(`에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };

  // 연동상품 셀편집 완료시
  const onCellEditCommit = (cellData: any) => {
    const { id, field, value } = cellData;
    let newRows: any = [...prodRows];
    newRows[id * 1 - 1][field] = value;
  };

  // 연동상품 상품 추가
  const addProdList = () => {
    let newRows: any = [...prodRows];
    const newRowId = newRows.length + 1;
    let newRow = JSON.parse(JSON.stringify(emptyRow));
    newRow.prod_id = "" + newRowId;
    newRows = [...newRows, newRow];
    setProdRows(newRows);
  };

  // 연동상품 상품 삭제
  const delProdList = () => {
    let newRows: any = [...prodRows];
    for (const val of selectionModel) {
      const idx = newRows.findIndex(function (item: any) {
        return item.prod_id === val;
      });
      if (idx > -1) newRows.splice(idx, 1);
    }
    let newData: any = [];
    let rowId = 1;
    for (const rowInfo of newRows) {
      newData.push({
        prod_id: "" + rowId,
        prod_title: rowInfo.prod_title,
        prod_callback: rowInfo.prod_callback,
      });
      rowId += 1;
    }
    setSelectionModel([]);
    setProdRows(newData);
  };

  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            채널 정보 수정 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            생성된 라이브 채널의 정보를 수정한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "update_broadcast_channel"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_seq (string) : 방송고유번호 (변경할 채널의 번호)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_title (string) : 방송명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_desc (string) : 방송설명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_start_tm (string) : 방송시작일시 (YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_stop_tm (string) : 방송종료일시 (YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - cover_url (string) : 방송 커머 이미지 주소
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 비공개 방송시 입장 비밀번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : 채널 관리자의 ID (email address) - 수정할 채널의 소유자
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { broad_seq: "string" } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <Stack spacing={1} direction="row">
            <TextField
              id="txtBroadSeq"
              label="Seq"
              variant="standard"
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              fullWidth
              value={broadSeq}
              onChange={(e) => {
                setBroadSeq(e.target.value);
              }}
            />
            <Button variant="contained" sx={{ minWidth: "150px" }} onClick={getChannelInfo}>
              채널정보 불러오기
            </Button>
          </Stack>
          <TextField
            id="txtBroadTitle"
            label="Title"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadTitle}
            onChange={(e) => {
              setBroadTitle(e.target.value);
            }}
          />
          <TextField
            id="txtBroadDesc"
            label="Description"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadDesc}
            onChange={(e) => {
              setBroadDesc(e.target.value);
            }}
          />
          <TextField
            id="txtBroadStartDtm"
            label="Start TM"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadStartDtm}
            onChange={(e) => {
              setBroadStartDtm(e.target.value);
            }}
          />
          <TextField
            id="txtBroadEndDtm"
            label="End TM"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadEndDtm}
            onChange={(e) => {
              setBroadEndDtm(e.target.value);
            }}
          />
          <TextField
            id="txtBroadCoverUrl"
            label="Cover URL"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadCoverUrl}
            onChange={(e) => {
              setBroadCoverUrl(e.target.value);
            }}
          />
          <TextField
            id="txtBroadPasswrod"
            label="Password"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadPassword}
            onChange={(e) => {
              setBroadPassword(e.target.value);
            }}
          />
          <TextField
            id="txtBroadManagerId"
            label="Manager ID (email)"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadManagerId}
            onChange={(e) => {
              setBroadManagerId(e.target.value);
            }}
          />
          <Typography variant="body1" component="div" gutterBottom>
            - broad_prod_list :
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={1} direction="column">
              <Stack spacing={2} direction="row" sx={{ width: "100%", justifyContent: "flex-end" }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    toggleExpandProdGrid();
                  }}
                >
                  {expandProdList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    addProdList();
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    delProdList();
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Stack>
              <Box sx={expandProdList ? { width: "100%", height: 800 } : { width: "100%", height: 200 }}>
                <DataGrid
                  rows={prodRows}
                  columns={prodColumns}
                  rowHeight={40}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  getRowId={(row) => row.prod_id}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  loading={isLoading}
                />
              </Box>
            </Stack>
          </Box>
          <Button variant="contained" onClick={updateChannel}>
            채널 수정 요청
          </Button>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Result
          </Typography>
          <TextField
            id="txtResultbroasSeq"
            label="Update Channel Result - Broad Seq"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={resultBroadSeq}
            onChange={(e) => {
              setBroadSeq(e.target.value);
            }}
          />
          <Typography variant="body1" component="div" gutterBottom sx={{ fontWeight: "bold", color: "red" }}>
            방송날짜가 변경된 경우 방송 고유번호가 변경됩니다. 이점 주의하시기 바랍니다.
          </Typography>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default UpdateChannel;
