import { ApiBase, BaseParams } from "./api-base";

export class HttpChatInputApi extends ApiBase {
  onApiName(): string {
    return "ChatInputApiName";
  }

  onUrlPath(): string {
    return "/chat";
  }

  public async send_chat_history(param: any) {
    let requestBody: any = param;
    requestBody.command = "send_chat_history";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async send_qna_message(
    broad_seq: string,
    channel_arn: string,
    qMessageId: string,
    aMessageId: string,
    origin_msg: string,
    sender_id: string
  ) {
    const requestBody = {
      command: "send_qna_message",
      message_info: {
        broad_seq: broad_seq,
        channel_arn: channel_arn,
        q_message_id: qMessageId,
        a_message_id: aMessageId,
        origin_msg: origin_msg,
        user_id: sender_id,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_chat_history(param: any, broadSeq: string) {
    let requestBody: any = param;
    requestBody.command = "update_chat_history";
    requestBody.broad_seq = broadSeq;
    const response = this.requestPost(requestBody);
    return response;
  }

  public async delete_chat_history(param: any) {
    let requestBody: any = param;
    requestBody.command = "delete_chat_history";
    const response = this.requestPost(requestBody);
    return response;
  }
}
