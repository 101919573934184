import React, { useEffect } from "react";
import { Auth } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  modalCommand: any;
  callBack: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalChatName = (props: propsType) => {
  const [openChatNameInputModal, setOpenChatNameInputModal] = React.useState(false);
  const [userChatName, setUserChatName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChatNameInputModalOpen = () => setOpenChatNameInputModal(true);
  const handleChatNameInputModalClose = () => setOpenChatNameInputModal(false);

  useEffect(() => {
    switch (props.modalCommand) {
      case "OPEN_CHAT_NAME_MODAL":
        handleChatNameInputModalOpen();
        break;
      case "CLEAR_COMMAND":
        break;
      case "INIT":
        break;
      default:
        console.warn("not support command =>", props.modalCommand);
    }
  }, [props.modalCommand]);

  const saveUserChatName = async () => {
    if (userChatName === "" || userChatName.trim() === "") {
      alert("사용할 별명을 입력하세요.");
      return;
    }
    setIsLoading(true);
    let loginUser = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(loginUser, {
      "custom:chat_name": userChatName,
    });
    if (result === "SUCCESS") {
      // alert("별명 등록이 완료되었습니다. 방송에 다시 입장해주세요.");
      handleChatNameInputModalClose();
      setIsLoading(false);
      props.callBack(result);
    } else {
      alert("오류가 발생하여 별명을 등록하지 못했습니다.");
      setIsLoading(false);
      props.callBack(result);
    }
  };

  const handleChatNameEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") saveUserChatName();
  };

  return (
    <Modal open={openChatNameInputModal} onClose={handleChatNameInputModalClose}>
      <Box sx={style}>
        <Stack spacing={1} direction="column">
          <Typography variant="h6" component="h2">
            채팅에 사용할 별명을 입력하세요.
          </Typography>
          <Stack spacing={1} direction="row">
            <TextField
              label="별명"
              className="bt-md"
              value={userChatName}
              variant="outlined"
              size="small"
              fullWidth
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              onKeyDown={(e) => {
                handleChatNameEnter(e);
              }}
              onChange={(e) => {
                setUserChatName(e.target.value);
              }}
            />
            <Button variant="outlined" className="bt-md" onClick={saveUserChatName}>
              SAVE
            </Button>
          </Stack>
        </Stack>
        <LoadingCircle loading={isLoading} />
      </Box>
    </Modal>
  );
};

export default ModalChatName;
