/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_PtHcN8pj6", // Please change this value.
    userPoolWebClientId: "14fmhbqs9u8vivu12slfj43rfh", // Please change this value.
    identityPoolId: "ap-northeast-2:58141503-e6c8-45ae-95d0-bebe1c893d5f",
  },
  Storage: {
    AWSS3: {
      bucket: "liveitnedev-channelbackendstack-wa-ap-northeast-2-14627",
      region: "ap-northeast-2",
    },
  },
  aws_cloud_logic_custom: [
    {
      name: "AdminApiName",
      endpoint: "https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ThehyundaiApiName",
      endpoint: "https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "MobileApiName",
      endpoint: "https://uwdnyllmbc.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "LiveChannelApiName",
      endpoint: "https://uwdnyllmbc.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "GameApiName",
      endpoint: "https://uwdnyllmbc.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "StatisticApiName",
      endpoint: "https://uwdnyllmbc.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ChatApiName",
      endpoint: "https://1csurp2y19.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ChatInputApiName",
      endpoint: "https://ph0lye6o2g.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "AdminIotApiName",
      endpoint: "https://j4t72ulbni.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ExternalApiName",
      endpoint: "https://76kvtuoss0.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "AccessTokenUrl",
      endpoint: "https://api-liveitnedev-livestream-hd.auth.ap-northeast-2.amazoncognito.com/",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
