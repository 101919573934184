import { forwardRef } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Local CSS
import "./LiveGameDraw.css";
import { Grid, Stack } from "@mui/material";

import { CommonUtils } from "../../../service/common_utils";

interface propsType {
  gameWinner: any;
  gameWinnerDetail: any;
  drawEventCallback: any;
  userId: string;
}

const LiveGameDraw = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const completeDraw = () => {
    props.drawEventCallback();
  };

  return (
    <>
      <div className="thd-live-game-draw-wrapper">
        {props.gameWinner.indexOf(props.userId) > -1 && (
          <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
          </div>
        )}
        <Stack className="thd-live-game-draw-stack">
          {props.gameWinner.indexOf(props.userId) > -1 && (
            <Box component="img" className="lthd-ive-game-draw-img" src="/images/game-draw.png" />
          )}
          <Typography className="thd-live-game-draw-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="21px" fontStyle="normal">
            {props.gameWinner.indexOf(props.userId) > -1 ? "축하합니다! 당첨되셨습니다!" : "아쉽지만 당첨되지 않았습니다."}
          </Typography>
          <Box className="thd-live-game-draw-apply-box">
            <Grid container spacing={3}>
              {props.gameWinnerDetail.length > 0 &&
                props.gameWinnerDetail.map((winner: any, index: number) => {
                  return (
                    <Grid key={`${winner}#${index}`} item xs={6}>
                      <Typography
                        className="thd-live-game-draw-list"
                        fontFamily="Noto Sans KR"
                        fontWeight="700"
                        fontSize="14px"
                        fontStyle="normal"
                        color="#F2FF5A"
                      >
                        {winner.last_no !== undefined && winner.last_no !== "" && winner.last_no !== null
                          ? cUtils.maskingChar(winner.user_id.split("@")[0]) + "(" + winner.last_no + ")"
                          : cUtils.maskingChar(winner.user_id.split("@")[0])}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Button
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              borderRadius: "6px",
              height: "40px",
              overflow: "hidden",
              marginTop: "10px",
              "&:hover": { border: "1px solid #fff" },
            }}
            variant="outlined"
            onClick={() => {
              completeDraw();
            }}
          >
            <Typography className="thd-live-game-draw-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="14px" fontStyle="normal">
              확인
            </Typography>
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default forwardRef(LiveGameDraw);
