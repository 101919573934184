import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface propsType {
  modalCommand: any;
  password: string;
  callBack: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalAskPassword = (props: propsType) => {
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    switch (props.modalCommand) {
      case "OPEN_MODAL":
        handleOpenModal();
        break;
      case "CLEAR_COMMAND":
        break;
      case "INIT":
        break;
      default:
        console.warn("not support command =>", props.modalCommand);
    }
  }, [props.modalCommand]);

  const checkPassword = async () => {
    if (realPassword === "" || realPassword.trim() === "") {
      alert("비밀번호를 입력하세요.");
      return;
    }

    if (realPassword === props.password) {
      handleCloseModal();
      props.callBack("SUCCESS");
    } else {
      alert("비밀번호가 다릅니다.");
    }
  };

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") checkPassword();
  };

  const handleCancel = () => {
    handleCloseModal();
    props.callBack("CANCEL");
  };

  const [realPassword, setRealPassword] = useState("");
  // autoComplete 도저히 안되서 (React Render 이슈인 듯...) 완전 잔머리 굴림....(Password type만 안됨)
  const [maskedPassword, setMaskedPassword] = useState("");
  useEffect(() => {
    let maskedText = "";
    if (password.length > realPassword.length) {
      setRealPassword((realPassword) => realPassword + "" + password.charAt(password.length - 1));
    } else {
      if (password.length === 0) {
        setRealPassword((realPassword) => password);
      } else {
        const oldReal = realPassword;
        let newReal = "";
        for (let i = 0; i < password.length; i++) {
          newReal += oldReal[i];
        }
        setRealPassword(newReal);
      }
    }
    // setRealPassword((realPassword) => password);
    for (const val of password) {
      maskedText += "•";
    }
    setMaskedPassword(maskedText);
  }, [password]);

  return (
    <Modal open={openModal}>
      <Box sx={style}>
        <Stack spacing={1} direction="column">
          <Typography variant="h6" component="h2">
            채널 입장을 위한 비밀번호를 입력하세요.
          </Typography>
          <TextField
            label="비밀번호"
            className="bt-md"
            value={maskedPassword}
            variant="outlined"
            size="small"
            autoComplete="off"
            fullWidth
            onKeyDown={(e) => {
              handlePasswordEnter(e);
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Stack spacing={1} direction="row">
            <Button variant="outlined" className="bt-md" onClick={checkPassword}>
              ENTER
            </Button>
            <Button variant="outlined" className="bt-md" onClick={handleCancel}>
              CANCEL
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalAskPassword;
