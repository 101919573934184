import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface propsType {
  modalCommand: any;
  password: string;
  callBack: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const ModalAskPassword = (props: propsType) => {
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [realPassword, setRealPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenModal(false);
      setPassword("");
      setRealPassword("");
      setMaskedPassword("");
    };
  }, []);

  useEffect(() => {
    switch (props.modalCommand) {
      case "OPEN_MODAL":
        handleOpenModal();
        break;
      case "CLEAR_COMMAND":
        break;
      case "INIT":
        break;
      default:
        console.warn("not support command =>", props.modalCommand);
    }
  }, [props.modalCommand]);

  const checkPassword = async () => {
    if (realPassword === "" || realPassword.trim() === "") {
      alert("비밀번호를 입력하세요.");
      return;
    }

    if (realPassword === props.password) {
      handleCloseModal();
      props.callBack("SUCCESS");
    } else {
      alert("비밀번호가 다릅니다.");
    }
  };

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") checkPassword();
  };

  const handleCancel = () => {
    handleCloseModal();
    props.callBack("CANCEL");
  };

  // autoComplete 도저히 안되서 (React Render 이슈인 듯...) 완전 잔머리 굴림....(Password type만 안됨)
  useEffect(() => {
    let maskedText = "";
    if (password.length > realPassword.length) {
      setRealPassword((realPassword) => realPassword + "" + password.charAt(password.length - 1));
    } else {
      if (password.length === 0) {
        setRealPassword((realPassword) => password);
      } else {
        const oldReal = realPassword;
        let newReal = "";
        for (let i = 0; i < password.length; i++) {
          newReal += oldReal[i];
        }
        setRealPassword(newReal);
      }
    }
    for (const val of password) {
      maskedText += "•";
    }
    setMaskedPassword(maskedText);
  }, [password]);

  return (
    <Modal open={openModal}>
      <Box sx={style}>
        <Box sx={{ color: "white", background: "#384e45", padding: "10px 20px" }}>PRIVATE 채널 입장</Box>
        <Stack sx={{ padding: "25px 30px", alignItems: "center" }} spacing={1} direction="column">
          <Typography variant="h6" component="h2" sx={{ fontSize: "1rem" }}>
            채널 비밀번호를 입력하세요.
          </Typography>
          <TextField
            label="비밀번호"
            className="bt-md"
            value={maskedPassword}
            variant="outlined"
            size="small"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            fullWidth
            onKeyDown={(e) => {
              handlePasswordEnter(e);
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            sx={{ "& > label": { fontSize: "0.9rem" } }}
          />
        </Stack>
        {/* <Divider /> */}
        <Stack spacing={1} direction="row" sx={{ borderTop: "1px solid #777777" }}>
          <Button className="bt-md" onClick={handleCancel} sx={{ width: "50%", borderRadius: 0, color: "black" }}>
            취소
          </Button>
          <Button
            className="bt-md"
            onClick={checkPassword}
            sx={{
              width: "50%",
              background: "#333333",
              borderRadius: 0,
              margin: 0,
              color: "white",
            }}
          >
            입장
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalAskPassword;
