import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { appConfig } from "../../config/Config";
import IoTClient from "../../service/iot/IoTClient";
import LoadingCircle from "../../utilities/LoadingCircle";
import TemplateHeader from "../../service/settingManage/TemplateHeader";
import TemplateRightFooter from "../../service/settingManage/TemplateRightFooter";
import TemplateChat from "../../service/settingManage/TemplateChat";
import "../../service/ivs-player/ClientIVSPlayer.css";

import { HttpChannelApi, GetTemplateInfoParams } from "../../service/channel-api";

interface propsType {}

const TemplatePreview = (props: propsType) => {
  const { templateseq } = useParams();
  const chApi = new HttpChannelApi();

  const iotRef: any = useRef();

  const screenMode: string = "W";

  const [loading, setLoading] = useState(false);
  const [endLoadInfo, setEndLoadInfo] = useState(false);
  const [templateInfo, setTemplateInfo] = useState<any>({});

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `template/${templateseq}/metadata`) {
      if (payLoad.message === "reloadChannelInfo") getTemplateInfo();
    }
  }

  useEffect(() => {
    getTemplateInfo();
    return () => {
      setLoading(false);
      setEndLoadInfo(false);
      setTemplateInfo({});
    };
  }, []);

  const getTemplateInfo = async () => {
    if (templateseq !== undefined) {
      setLoading(true);
      const param: GetTemplateInfoParams = {
        template_seq: templateseq,
      };
      const res = await chApi.get_template_info(param);
      if (res.result_code === "200") {
        setTemplateInfo(res.result_body);
        setEndLoadInfo(true);
      } else {
        alert(`[ERROR] ${res.result_body}`);
        setEndLoadInfo(false);
      }
      setLoading(false);
    }
  };

  const iotConnectEnd = () => {
    console.info("iot Connection Success");
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  return (
    <>
      <div className="live-main-root no-scroll">
        {templateInfo.template_seq !== undefined ? (
          <>
            <video
              id="videoPlayer"
              className="client-video"
              poster={
                templateInfo.setting.ready_img_url !== ""
                  ? templateInfo.setting.ready_img_url
                  : endLoadInfo
                  ? "/images/2020_com_topimg4.jpg"
                  : ""
              }
            ></video>
            {/* 헤더 */}
            <TemplateHeader setting={templateInfo.setting} screenMode={screenMode} />
            <TemplateRightFooter setting={templateInfo.setting} screenMode={screenMode} />
            <TemplateChat setting={templateInfo.setting} screenMode={screenMode} />
          </>
        ) : (
          <></>
        )}
      </div>
      <LoadingCircle loading={loading} />
      {templateseq === undefined ? (
        <></>
      ) : (
        <IoTClient
          ref={iotRef}
          broadSeq={templateseq}
          iotRegion={appConfig.iotRegion}
          iotIdentityPollId={appConfig.iotIdentityPollId}
          iotEndPoint={appConfig.iotEndPoint}
          recieveIoTMessage={recieveIoTMessage}
          iotConnectEnd={iotConnectEnd}
          iotLost={iotLost}
        />
      )}
    </>
  );
};

export default TemplatePreview;
