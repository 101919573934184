import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { appConfig } from "../../config/Config";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { HttpClientApi } from "../client-rest-api";
import { HttpChatApi } from "../chat-rest-api";
import { userState, broadSetting } from "../../interface/MainInterface";
import { getRandomNum } from "../../utilities/utils";
import * as ChatConstant from "./ChatConstant";
import "./Chat.css";

interface propsType {
  broad_seq: string;
  channelArn: string;
  adminArn: string;
  chatUrl: string;
  chat_open: boolean;
  screenMode: string;
  hidden_menu: boolean;
  chatInitCallback: any;
  player_id: string;
  like_count_callback: any;
  newLikeUpCallback: any;
  viewCountCallback: any;
  streamEventCallback: any;
  videoPosition: string;
  vod_started_at: string;
  userState: userState;
  setting: broadSetting;
}

const chatApi = new HttpChatApi();

const ChatVerVod = (props: propsType) => {
  const ClientApi = new HttpClientApi();

  // Chime Messages
  const [messages, setMessages] = useState<any>([]);
  const [manageMessages, setManageMessages] = useState<any>([]);
  const [totalMessages, setTotalMessages] = useState<any>([]);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({ display: "none" });
  const [managerCampaignText, setManagerCampaignText] = useState("");
  const chatInnerRef = useRef<HTMLDivElement>();
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const [width, height] = useWindowSize();
  const [isResize, setIsResize] = useState(false);
  const [visibility, setVisibility] = useState("visible");

  useEffect(() => {
    if (props.hidden_menu && !props.chat_open) {
      setVisibility((visibility) => "hidden");
      setBtnNewMsgBoxStyle({ display: "none" });
    } else {
      setVisibility((visibility) => "visible");
    }
  }, [props.hidden_menu, props.chat_open]);

  useEffect(() => {
    if (props.channelArn !== "") {
      getListChannelMessages(props.channelArn);
    }
  }, [props.channelArn]);

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (channel_arn: string) => {
    const param: any = {
      channel_arn: channel_arn,
    };
    await chatApi.list_chat_history(param).then((result: any) => {
      if (result.code === "200") {
        setTotalMessages(result.response.chat_history.Messages);
        props.chatInitCallback(true);
      }
    });
  };

  useEffect(() => {
    // useEffect의 특성상 1초에 한번씩만 업데이트 됨. 나이스~
    let newMessages: any = [];
    let newManageMessages: any = [];
    for (let msg of totalMessages) {
      if (msg.CreatedTimestamp < props.vod_started_at) continue;
      if (msg.CreatedTimestamp > props.videoPosition) break;
      // if (msg.Persistence === ChatConstant.Persistence.NON_PERSISTENT) continue; // 관리용 메세지는 일단 무시
      if (msg.Persistence === ChatConstant.Persistence.NON_PERSISTENT && JSON.parse(msg.Content).prot === "view") continue;
      if (msg.Persistence === ChatConstant.Persistence.NON_PERSISTENT) {
        const tmp_ts = dayjs(props.videoPosition).add(-1, "s").format("YYYYMMDDHHmmss");
        if (msg.CreatedTimestamp < tmp_ts) continue;
        newManageMessages = [...newManageMessages, msg];
        continue;
      }
      newMessages = [...newMessages, msg];
    }
    setManageMessages(newManageMessages);

    // const tempMsg = {
    //   Content: "",
    //   CreatedTimestamp: "",
    //   Metadata: "",
    //   Persistence: "",
    //   Sender: {},
    //   Type: "FAKE",
    // };
    // newMessages = [tempMsg, ...newMessages];
    setMessages(newMessages);
    // call_new_like_up(getRandomNum(0, 3));
  }, [props.videoPosition]);

  useEffect(() => {
    if (manageMessages.length > 0) {
      for (let msg of manageMessages) {
        const metadataText = JSON.parse(msg.Content);
        if (metadataText.prot === "like") new_like_up();
        // if (metadataText.prot === "manager_campaign") handleManagerCampaign(metadataText.contents);
      }
    }
  }, [manageMessages]);

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);
    let textShadow = "";
    if (props.setting.chat_shadow) textShadow = "1px 1px 1px " + props.setting.chat_shadow_color;

    return (
      <ListItem style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={{
                  fontSize: chatTextSize,
                  color: "Snow",
                  display: "inline-flex",
                  flexDirection: "row",
                  borderRadius: 2,
                  pl: 1,
                  pr: 1,
                }}
                className={chatClick ? "bg-dark" : ""}
              >
                <div>
                  <span
                    style={
                      messages[index].Sender.Arn === props.adminArn
                        ? { color: "Yellow", whiteSpace: "nowrap", marginRight: "5px", textShadow: textShadow }
                        : messages[index].Sender.Arn === props.userState.userArn
                        ? {
                            color: "#08e1e1",
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            textShadow: textShadow,
                          }
                        : {
                            color: "Snow",
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            textShadow: textShadow,
                          }
                    }
                  >
                    {messages[index].Sender.Arn === props.adminArn ? messages[index].Sender.Name : messages[index].Sender.Name}
                  </span>
                  {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                    const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                    let styleCss: any = {
                      color: props.setting.chat_text_color,
                      textShadow: textShadow,
                      wordBreak: "break-all",
                      whiteSpace: "pre-line",
                    };
                    if (idx !== repleLength - 1) styleCss.color = "gray";
                    if (idx === 0) {
                      return (
                        <span key={index} style={styleCss}>
                          {line}
                        </span>
                      );
                    } else {
                      return (
                        <span key={index + "-" + idx} style={styleCss}>
                          <br />
                          {appConfig.quote_separator + " " + line}
                        </span>
                      );
                    }
                  })}
                </div>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);

    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 화면 사이즈 변경시 이벤트 발생
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setIsResize(true);
  }, [width, height]);

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        if (!props.hidden_menu) setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current.scrollToItem(messages.length, "end");
    }
  };

  // 좋아요 이벤트 수신시 다른 Component에 이벤트 전달용
  const like_count_up = () => {
    props.like_count_callback();
  };

  // 다른사람이 발생시킨 좋아요 이벤트를 다른 Component에 전달용
  const new_like_up = () => {
    props.newLikeUpCallback();
  };

  const call_new_like_up = (max: number) => {
    for (let i = 0; i < max; i++) {
      new_like_up();
    }
  };

  // 새로운 시청자 인입시 이벤트를 다른 Component에 전달용
  const view_count_up = () => {
    props.viewCountCallback();
  };

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기 위한 핸들러
  const handleManagerCampaign = (contents: string) => {
    setManagerCampaignText(contents);
  };

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기
  useEffect(() => {
    if (managerCampaignText !== "") {
      setTimeout(() => {
        setManagerCampaignText("");
      }, 2000);
    }
  }, [managerCampaignText]);

  // 채팅 메세지 윈도우 클릭시 이벤트 발생(글씨 음영 강조/해제)
  const [chatClick, setChatClick] = useState(false);
  const togleChatWindow = () => {
    setChatClick(!chatClick);
  };

  // 채팅 글자 크기에 맞춰 채팅 창 위치 조정 (하단 부터)
  const bottomTxt = useRef(60);

  const [chatTextSize, setChatTextSize] = useState("2.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setChatTextSize(`${props.setting.chat_text_size}vmax`);
    else setChatTextSize(`${props.setting.chat_text_v_size}vmax`);
  }, [props.screenMode, props.setting.chat_text_size, props.setting.chat_text_v_size]);

  useEffect(() => {
    handleChatWindowPos();
  }, [chatTextSize]);

  // 브라우저 크긱에 맞춰 채팅창 높이를 맞춤
  const handleChatWindowPos = () => {};

  return (
    <>
      <Box
        id="chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            width: "90%",
            height: "40vh",
            visibility: visibility,
          }
        }
        className="chat-root"
        onClick={togleChatWindow}
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            id="chat-box"
            component="div"
            sx={{
              width: "100%",
              height: "100%",
            }}
            className={visibleStartIndex > 0 ? "gradient-box" : ""}
          >
            <AutoSizer style={{ height: "100%", width: "100%" }}>
              {({ height, width }) => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemSize={getRowHeight}
                  itemCount={messages.length}
                  overscanCount={5}
                  className="chat-list"
                  innerRef={chatInnerRef}
                  ref={listRef}
                  onItemsRendered={evtItemRendered}
                >
                  {RenderRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </Box>
        </>
      </Box>
      {/* 체팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
      <Box className="btn-new-chat" sx={btnNewMsgBoxStyle}>
        <Button variant="contained" size="small" onClick={scrollToBottom} endIcon={<ArrowCircleDownIcon />}>
          최신 채팅으로 이동
        </Button>
      </Box>
    </>
  );
};

export default ChatVerVod;
