import { ApiBase, BaseParams } from "./api-base";

export interface ChBaseParams extends BaseParams {}

export interface GetCodeListParams {
  large_code: string;
}

export interface GetCodeDetailParams {
  large_code: string;
}

export interface GetCodeInfoParams {
  large_code: string;
}

export interface CreateCodeParams {
  type: string;
  large_code: string;
  large_code_name: string;
  small_code: string;
  small_code_name: string;
}

export interface DeleteCodeParams {
  pk: string;
  sk: string;
  code: string;
  code_type: string;
}

export class HttpCodeApi extends ApiBase {
  onApiName(): string {
    return "AdminApiName";
  }

  onUrlPath(): string {
    return "/code";
  }

  public async get_code_list(params: GetCodeListParams) {
    const requestBody = {
      command: "get_code_list",
      search_condition: {
        large_code: params.large_code,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_code_detail(params: GetCodeDetailParams) {
    const requestBody = {
      command: "get_code_detail",
      search_condition: {
        large_code: params.large_code,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_code_info(params: GetCodeInfoParams) {
    const requestBody = {
      command: "get_code_info",
      search_condition: {
        large_code: params.large_code,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async create_code(params: CreateCodeParams) {
    const requestBody = {
      command: "create_code",
      code_info: {
        type: params.type,
        large_code: params.large_code,
        large_code_name: params.large_code_name,
        small_code: params.small_code,
        small_code_name: params.small_code_name,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async delete_code(params: DeleteCodeParams) {
    const requestBody = {
      command: "delete_code",
      code_info: {
        pk: params.pk,
        sk: params.sk,
        code: params.code,
        code_type: params.code_type,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }
}
