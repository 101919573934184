import { ApiBase, BaseParams } from "./api-base";

export class HttpLiveChannelApi extends ApiBase {
  onApiName(): string {
    return "LiveChannelApiName";
  }

  onUrlPath(): string {
    return "/channel";
  }

  public async get_channel_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_channel_info";
    const response = this.requestPost(requestBody);
    return response;
  }
}
