import { ApiBase, BaseParams } from "./api-base";

export class HttpStatisticApi extends ApiBase {
  onApiName(): string {
    return "StatisticApiName";
  }

  onUrlPath(): string {
    return "/statistic";
  }

  public async update_count(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_count";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_statistic_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_statistic_info";
    const response = this.requestPost(requestBody);
    return response;
  }
}
