import { useState, forwardRef, useImperativeHandle } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

interface propsType {}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = (props: propsType, ref: any) => {
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastDuration, setToastDuration] = useState(3000);
  const [serverrity, setServerrity] = useState<AlertColor>("info");

  const toast = (inMessage: string, inServerity: string, inDuration: number) => {
    let tmpserverity: any = "info";
    if (inServerity !== "" && inServerity !== undefined) {
      tmpserverity = inServerity;
    }
    setToastMessage((toastMessage) => inMessage);
    setServerrity((serverrity) => tmpserverity);
    setToastDuration((toastDuration) => inDuration);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    toast,
  }));

  return (
    <>
      <Snackbar open={open} autoHideDuration={toastDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={`${serverrity}`} sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default forwardRef(Toast);
