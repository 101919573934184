import { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, GetManagerInfoParam } from "../../service/thehyundai-rest-api";

const GetManagerInfo = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [memo, setMemo] = useState("");
  const [teamName, setTeamName] = useState("");
  const [useYn, setUseYn] = useState("");

  const getManagerInfo = async () => {
    if (managerId === "" || managerId === undefined) {
      window.alert("관리자 ID를 입력하세요.");
      return;
    }

    const param: GetManagerInfoParam = {
      managerId: managerId,
    };
    setIsLoading(true);
    const res = await theHyundaiApi.get_broadcast_manager_info(param);
    if (res.code === "200") getBroadcastManagerSuccess(res.response);
    else getBroadcastManagerInfoFail(res);
  };

  const getBroadcastManagerSuccess = (res: any) => {
    setManagerName(res.manager_info.manager_name);
    setTeamName(res.manager_info.team_name);
    setMemo(res.manager_info.memo);
    setUseYn(res.manager_info.use_yn);
    setIsLoading(false);
  };
  const getBroadcastManagerInfoFail = (error: any) => {
    if (error.code === "201" || error.code === "202") alert(`${error.response.error_msg}`);
    else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };
  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            관리자 정보 조회 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            더현대닷컴 관리자 1명에 대한 정보를 조회한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "get_broadcast_manager_info"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : "string"
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { manager_info: "{*manager_info}" } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            *manager_info
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - memo (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - team_name (string) : "string"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - use_yn (string) : "Y|N" !기본적으로 "N"은 제공하지 않음. 필요시 추가 요청
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <TextField
            id="txtManagerId"
            label="Manager ID"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerId}
            onChange={(e) => {
              setManagerId(e.target.value);
            }}
          />
          <Button variant="contained" onClick={getManagerInfo}>
            관리자 정보 조회 요청
          </Button>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Result
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - user_name : {managerName}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - team_name : {teamName}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - memo : {memo}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - use_yn : {useYn}
          </Typography>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default GetManagerInfo;
