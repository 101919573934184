import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Interface
import { HttpClientApi } from "../../service/client-rest-api";

// Component
import { useInterval } from "../../utilities/UseInterval";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  broad_info: any;
}

const LiveNotice = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  const [qnaText, setQnaText] = useState(""); // 롤링영역 QNA 최신 텍스트
  const [noticeText, setNoticeText] = useState(""); // 롤링영역 공지사항 최신 텍스트

  const [broadQnaList, setBroadQnaList] = useState<any>([]); // 방송 QNA LIST
  const [openQna, setOpenQna] = useState(false); // 공지사항, Q&A 상세보기

  const [tabValue, setTabValue] = useState("notice"); // 탭 위치

  useEffect(() => {
    getBroadQnaList(props.broad_info.broad_seq);
  }, []);

  // 방송 Q&A List 조회
  const getBroadQnaList = async (broad_seq: string) => {
    const broadQnaResult = await clientApi.list_broad_qna(broad_seq, "DESCEND");
    setBroadQnaList(broadQnaResult.result_body);
  };

  // 부모 컴포넌트에서 공지사항 변경시 최신화
  useEffect(() => {
    setNoticeText(props.broad_info.broad_notice);
  }, [props.broad_info.broad_notice]);

  // QNA List 변경시 최신 QNA TEXT 최신화
  useEffect(() => {
    if (broadQnaList.length > 0) {
      setQnaText(broadQnaList[0].qContent);
    } else {
      setQnaText("");
    }
  }, [broadQnaList]);

  // QNA TEXT, 공지사항 TEXT 변경시 Rolling 영역 텍스트 최신화
  useEffect(() => {
    let newRollingText: any = [];
    if (noticeText !== "") {
      newRollingText = [
        ...newRollingText,
        noticeText.replaceAll("<br />", " ").replaceAll("\n", " "),
      ];
    }
    if (qnaText !== "") {
      newRollingText = [...newRollingText, qnaText];
    }
    setRollingTexts(newRollingText);
  }, [qnaText, noticeText]);

  // 공지사항 슬라이더 관련 ------------------------------------
  // for useInterval
  const delay: number = 4000; // 4초마다 변경
  const [isRunning, setIsRunning] = useState(true); // SetInterval 실행
  const noticeSliderRef: any = useRef(); // slider ref
  // 슬라이딩할 텍스트
  const [rollingTexts, setRollingTexts] = useState<any>([]);

  // 슬라이딩 구현 (한 row의 height가 제일 중요)
  useInterval(
    () => {
      if (rollingTexts.length > 0) {
        if (noticeSliderRef.current.children.length > 0) {
          if (noticeSliderRef.current.children.length > 1) {
            // 리스트를 위로 올림. 에니메이션 추가
            noticeSliderRef.current.className = "notice-silder-list-animation";
            setTimeout(() => {
              noticeSliderRef.current.className = "notice-silder-list"; // 에니메이션 초기화
              // 올라간 리스트를 삭제한다.
              noticeSliderRef.current.removeChild(
                noticeSliderRef.current.children[0]
              );
              // 마지막 하나 남았을때 아래에 기존 리스트를 다시 붙인다.
              if (noticeSliderRef.current.children.length === 1) {
                for (const text of rollingTexts) {
                  const newElement = document.createElement("div");
                  newElement.className = "notice-silder-item";
                  newElement.innerText = text;
                  noticeSliderRef.current.appendChild(newElement);
                }
              }
            }, 2000); // 에니메이션 시간과 동일하게 세팅
          }
        }
      }
    },
    isRunning ? delay : null
  );

  // 상세보기 Drawer Control
  const handleQnaDrawerClose = () => setOpenQna(false);
  const handleQnaDrawerOpen = () => {
    getBroadQnaList(props.broad_info.broad_seq);
    setOpenQna(true);
  };

  // 탭 변경시 현재 탭 위치 지정
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getBroadQnaList,
  }));

  return (
    <>
      {rollingTexts.length > 0 ? (
        <div className="live-notice-wrapper">
          <Box className="live-notice-root" onClick={handleQnaDrawerOpen}>
            {/* 슬라이더 */}
            <div className="notice-slider">
              <div ref={noticeSliderRef} className="notice-silder-list">
                {rollingTexts.map((text: string, index: number) => {
                  return (
                    <div key={index} className="notice-silder-item">
                      {text}
                    </div>
                  );
                })}
              </div>
            </div>
          </Box>
          {/* 상세보기 */}
          <Drawer
            PaperProps={{ elevation: 0, style: { borderRadius: "16px" } }}
            anchor="bottom"
            open={openQna}
            onClose={handleQnaDrawerClose}
            className="live-more-body"
          >
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                bgcolor: "while",
                borderRadius: "16px",
              }}
              className="live-notice-more"
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab value="notice" label="공지사항" sx={{ p: 0 }} />
                  <Tab value="qna" label="Q&A" sx={{ p: 0 }} />
                </Tabs>
              </Box>
              {/* 공지사항 상세보기 */}
              <Box
                sx={
                  tabValue === "notice"
                    ? { display: "flex", width: "100%", whiteSpace: "pre-line" }
                    : { display: "none" }
                }
              >
                {props.broad_info.broad_notice}
              </Box>
              {/* QNA 상세보기 */}
              <Box
                sx={
                  tabValue === "qna"
                    ? { display: "flex", width: "100%" }
                    : { display: "none" }
                }
              >
                <List sx={{ width: "100%" }}>
                  {broadQnaList.map((obj: any, index: number) => {
                    return (
                      <div key={`qna${obj.qCreatedTimestamp}`}>
                        <ListItem disablePadding>
                          <ListItemText
                            primary={
                              <Typography variant="h6" component="div">
                                <ContactSupportIcon className="color-orangered" />
                                {obj.qContent}
                              </Typography>
                            }
                            secondary={
                              obj.qUserName +
                              " • " +
                              dayjs(String(obj.qCreatedTimestamp)).format(
                                "YYYY.MM.DD, HH:mm"
                              )
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemText
                            primary={
                              <Typography variant="body1" component="div">
                                <CheckCircleIcon className="color-dark" />
                                {obj.aContent}
                              </Typography>
                            }
                            secondary={
                              obj.aUserName +
                              " • " +
                              dayjs(String(obj.aCreatedTimestamp)).format(
                                "YYYY.MM.DD, HH:mm"
                              )
                            }
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNotice);
