import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import IosShare from "@mui/icons-material/IosShare";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import "./LiveShare.css";

interface propsType {
  broad_seq: string;
  iconSize: string;
}

const LiveShare = (props: propsType) => {
  const tmpImageLink = "https://liveappdownload.s3.ap-northeast-2.amazonaws.com/images/image64.png";
  const [openShare, setOpenShare] = useState(false);

  const openKaKao = () => {
    console.log("카카오톡 공유하기 클릭");
  };
  const copyURL = () => {
    console.log("URL 복사 클릭");
  };
  const openSMS = () => {
    console.log("sms 공유 클릭");
  };

  const handleShareModalClose = () => setOpenShare(false);
  const handleShareModalOpen = () => {
    // getBroadQnaList(props.broad_seq);
    setOpenShare(true);
  };

  return (
    <>
      <IconButton sx={{ padding: 0 }} onClick={handleShareModalOpen}>
        <IosShare sx={{ fontSize: props.iconSize }} className="color-snow" />
      </IconButton>
      <Modal
        open={openShare}
        onClose={handleShareModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-share">
          <Box className="modal-top-border">
            <span className="green-border"></span>
            <span className="pink-border"></span>
          </Box>
          <Box className="modal-content">
            <IconButton className="modal-close" onClick={handleShareModalClose}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ width: "80%" }}>
              <Stack
                className="stack-row"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} onClick={openKaKao}>
                  <Avatar className="item-small" alt="Rem Sharp" src={tmpImageLink} />
                  <Typography id="modal-modal-title" variant="caption">
                    카카오톡
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} onClick={copyURL}>
                  <Avatar className="item-small" alt="Rem Sharp" src={tmpImageLink} />
                  <Typography id="modal-modal-title" variant="caption">
                    URL 복사
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                className="stack-row"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} onClick={openSMS}>
                  <Avatar className="item-small" alt="Rem Sharp" src={tmpImageLink} />
                  <Typography id="modal-modal-title" variant="caption">
                    SMS
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LiveShare;
