import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

interface propsType {
  updateChat: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  p: 3,
};

const ChatUpdate = (props: propsType, ref: any) => {
  const [isMobile, setIsMobile] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [messageInfo, setMessageInfo] = useState<any>({});
  const [newContent, setNewContent] = useState("");

  useEffect(() => {
    return () => {
      setIsMobile(false);
      setOpenDialog(false);
      setMessageInfo({});
      setNewContent("");
    };
  }, []);

  const open = (message: any) => {
    setMessageInfo(message);
    setNewContent(message.Content);
    setOpenDialog(true);
    isMobileCheck();
  };

  const isMobileCheck = () => {
    const pathName = window.location.pathname.split("/").pop();
    if (pathName == "pc") {
      setIsMobile(false);
    } else if (pathName == "mobile") {
      setIsMobile(true);
    }
  };

  const fncNo = () => {
    setOpenDialog(false);
  };
  const fncYes = async () => {
    let newMessage: any = messageInfo;
    newMessage.Content = newContent;
    setOpenDialog(false);
    await props.updateChat(newMessage);
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      {isMobile ? (
        // 모바일에서
        <Modal
          open={openDialog}
          onClose={() => {
            fncNo();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Typography>채팅 수정</Typography>
              <IconButton
                onClick={() => {
                  fncNo();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack direction="column" spacing={2}>
              <TextField
                id="txtUpdateChatContent"
                variant="outlined"
                size="small"
                fullWidth
                type="search"
                autoComplete="off"
                autoFocus
                multiline
                maxRows={3}
                inputProps={{ enterKeyHint: "Enter" }}
                value={newContent}
                onChange={(e) => {
                  setNewContent(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") fncYes();
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  fncYes();
                }}
              >
                수정
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : (
        // pc에서
        <Modal
          open={openDialog}
          onClose={() => {
            fncNo();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" spacing={2}>
              <TextField
                id="txtUpdateChatContent"
                variant="outlined"
                size="small"
                fullWidth
                type="search"
                autoComplete="off"
                autoFocus
                inputProps={{ enterKeyHint: "Enter" }}
                value={newContent}
                onChange={(e) => {
                  setNewContent(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") fncYes();
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  fncYes();
                }}
              >
                수정
              </Button>
              <IconButton
                onClick={() => {
                  fncNo();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default forwardRef(ChatUpdate);
