import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import LiveLike from "../TheHyundai/LiveLike";

import { userState } from "../../../interface/MainInterface";
import DialogChangeData from "./DialogChangeData";
import DialogGame from "./DialogGame";
import ChatColorChange from "./ChatColorChange";
import LiveProduct from "../TheHyundai/LiveProduct";

interface propsType {
  broad_seq: string;
  broad_status: string;
  user_id: string;
  userState: userState;
  like_click: any;
  hidden_menu: boolean;
  sendMessage: any;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  newQnAMsg: any;
  openChatInput: any;
  videoMode: number;
  broadInfo: any;
  chimeInfo: any;
  broadDate: string;
  sendIoTMessageMeta: any;
  sendIoTMessage: any;
  getChannelInfo: any;
}

const LiveFooterMobile = (props: propsType, ref: any) => {
  const iconSize: string = "44px";
  const [newLikeCount, setNewLikeCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNewLikeCount(0);
      setIsMobile(false);
    };
  }, []);

  useEffect(() => {
    isMobileCheck();
  }, []);

  const isMobileCheck = () => {
    const pathName = window.location.pathname.split("/").pop();
    if (pathName == "pc") {
      setIsMobile(false);
    } else if (pathName == "mobile") {
      setIsMobile(true);
    }
  };

  const addNewLikeCount = () => {
    setNewLikeCount((newLikeCount: number) => newLikeCount + 1);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    addNewLikeCount,
  }));

  return (
    <>
      {/* <Box component="div" sx={{ width: "100%", padding: "8px 14px" }} className="color-snow"> */}
      <Box component="div" sx={{ width: "100%", padding: "2px" }} className="color-snow">
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            // marginBottom: `${props.hidden_menu && props.chat_open ? "41px" : "0"}`
          }}
        >
          {/* <LiveProduct
            isNotFloat={props.isNotFloat}
            isFloat={props.isFloat}
            hidden_menu={props.hidden_menu}
            prodList={props.prodList}
            postMsg={props.postMsg}
            broadSeq={props.broad_seq}
            userId={props.user_id}
            videoMode={props.videoMode}
          /> */}
          <Stack
            className="thd-footer-right-stack"
            direction="column"
            sx={{ padding: `${props.broad_status === "VOD" ? "0 8px" : "8px"}`, position: "absolute", right: "3%" }}
          >
            <LiveLike like_click={props.like_click} new_like_count={newLikeCount} hidden_menu={props.hidden_menu} iconSize={iconSize} />
            {isMobile ? <></> : <ChatColorChange hidden_menu={props.hidden_menu} sendIoTMessage={props.sendIoTMessage} />}
            <DialogGame
              userState={props.userState}
              broadInfo={props.broadInfo}
              chimeInfo={props.chimeInfo}
              hidden_menu={props.hidden_menu}
            />
            <DialogChangeData
              broadDate={props.broadDate}
              broadSeq={props.broad_seq}
              userState={props.userState}
              sendIoTMessage={props.sendIoTMessage}
              sendIoTMessageMeta={props.sendIoTMessageMeta}
              hidden_menu={props.hidden_menu}
              getChannelInfo={props.getChannelInfo}
            />
            {/* <LiveQnA
              isNotFloat={props.isNotFloat}
              isFloat={props.isFloat}
              hidden_menu={props.hidden_menu}
              newQnAMsg={props.newQnAMsg}
              broad_seq={props.broad_seq}
              iconSize={iconSize}
              videoMode={props.videoMode}
            /> */}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(LiveFooterMobile);
