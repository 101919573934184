import { useState, forwardRef, useEffect, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { HttpChannelApi, GetProdClickParams } from "../../service/channel-api";
import dayjs from "dayjs";
import { CommonUtils } from "../../service/common_utils";
import { useInterval } from "../../utilities/UseInterval";
import LoadingCircle from "../../utilities/LoadingCircle";

import LineChart from "./LineChart";

interface propsType {
  broad_info: any;
  tabValue: string;
}

interface IChartCols {
  sk: string;
  concurrent_cnt: number;
  enter_cnt: number;
  like_cnt: number;
  prod_click_cnt: number;
}

const TabStatistics = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const cUtils = new CommonUtils();

  const [loading, setLoading] = useState(false);

  // 상품 클릭 이벤트 저장
  const [prodClickList, setProdClickList] = useState<any>([]);
  const [totalLike, setTotalLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [concurentView, setConcurentView] = useState(0);
  const [statisticsList, setStatisticsList] = useState<any>([]);
  const [statisticsExcelDataList, setStatisticsExcelDataList] = useState<any>([]);

  // 통계 데이터 조회
  const getStatisticsData = async (broad_seq: string) => {
    setStatisticsList([]);
    resetChartData();
    // setLoading(true);
    const statisticsData = await chApi.get_statistics_data(props.broad_info);
    if (statisticsData.result_code === "200") {
      setStatisticsList(statisticsData.result_body.statistic);
      setTotalLike(statisticsData.result_body.total_likes);
      setTotalView(statisticsData.result_body.total_views);
    }
    handleProdClickDownload();
    // setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    getStatisticsData,
  }));

  // useEffect(() => {
  //   handleProdClickDownload();
  // }, [props.broad_info]);

  const handleProdClickDownload = async () => {
    const param: GetProdClickParams = {
      broad_seq: props.broad_info.broad_seq,
    };

    await chApi.get_prod_click(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  const downloadProdExcelList = () => {
    if (prodClickList.length === 0) {
      alert("통계데이터 다운로드는 방송 종료 후 가능합니다.");
      return;
    }
    // sheet header
    let prodHistory: any = [["순번", "방송명", "사용자ID", "클릭일자", "상품ID", "상품명", "상품가격"]];
    // 각 행 순번 만들기
    let prodIndex = 1;
    // 내용 생성
    for (const item of prodClickList) {
      const detail = [
        prodIndex,
        props.broad_info.broad_title,
        item.user_id,
        dayjs(String(item.sk)).format("YYYY-MM-DD HH:mm:ss"),
        item.prod_id,
        item.prod_name,
        item.prod_price,
      ];
      prodHistory = [...prodHistory, detail];
      prodIndex += 1;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];

    // sheet header
    let statisticsHistory: any = [["순번", "시간", "동시시청자", "입장고객", "좋아요"]];
    // 각 행 순번 만들기
    let statisticsIndex = 1;
    // 내용 생성
    for (const item of statisticsExcelDataList) {
      const detail = [statisticsIndex, dayjs(String(item.time)).format("YYYY-MM-DD HH:mm"), item.concurrent, item.enter, item.like];
      statisticsHistory = [...statisticsHistory, detail];
      statisticsIndex += 1;
    }

    const clickSheetName = "상품클릭내역";
    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName =
      dayjs(String(props.broad_info.broad_start_tm)).format("YYYY-MM-DD HHmm") + "_통계데이터_" + props.broad_info.broad_title + ".xlsx";
    cUtils.downloadStatisticsExcel(prodHistory, statisticsHistory, colWidth, clickSheetName, statsticsSheetName, fileName);
  };

  const convertTime = (input: string) => {
    return input.substring(8, 10) + ":" + input.substring(10, 12);
  };

  const [chartData, setChartData] = useState({
    labels: statisticsList.map((data: IChartCols) => convertTime(data.sk)),
    datasets: [
      { label: "동시시청자", data: statisticsList.map((data: IChartCols) => data.concurrent_cnt) },
      { label: "입장고객", data: statisticsList.map((data: IChartCols) => data.enter_cnt) },
      { label: "좋아요", data: statisticsList.map((data: IChartCols) => data.like_cnt) },
      { label: "상품클릭", data: statisticsList.map((data: IChartCols) => data.prod_click_cnt) },
    ],
  });

  const resetChartData = () => {
    setChartData({
      labels: [],
      datasets: [
        { label: "동시시청자", data: [] },
        { label: "입장고객", data: [] },
        { label: "좋아요", data: [] },
        { label: "상품클릭", data: [] },
      ],
    });
    setStatisticsExcelDataList([]);
    setConcurentViewDataRows([]);
    setEnterDataRows([]);
    setLikeDataRows([]);
    setProdDataRows([]);
    setTotalLike(0);
    setTotalView(0);
    setConcurentView(0);
  };

  const convertTimeFormat = (time: string) => {
    return (
      time.substring(0, 4) +
      "-" +
      time.substring(4, 6) +
      "-" +
      time.substring(6, 8) +
      " " +
      time.substring(8, 10) +
      ":" +
      time.substring(10, 12)
    );
  };

  const [concurentViewDataRows, setConcurentViewDataRows] = useState<any>([]);
  const [enterDataRows, setEnterDataRows] = useState<any>([]);
  const [likeDataRows, setLikeDataRows] = useState<any>([]);
  const [prodDataRows, setProdDataRows] = useState<any>([]);

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];
      setChartData({
        labels: newDataRows.map((data: IChartCols) => convertTime(data.sk)),
        datasets: [
          { label: "동시시청자", data: newDataRows.map((data: IChartCols) => data.concurrent_cnt) },
          { label: "입장고객", data: newDataRows.map((data: IChartCols) => data.enter_cnt) },
          { label: "좋아요", data: newDataRows.map((data: IChartCols) => data.like_cnt) },
          { label: "상품클릭", data: statisticsList.map((data: IChartCols) => data.prod_click_cnt) },
        ],
      }); // 하나의 차트에 모두 표시할땐 여기 쓰자.

      const totalList = [];
      const concurentViewList = [];
      const enterCntList = [];
      const likeCntList = [];
      const prodCntList = [];
      let lastConcurentView: any = undefined;

      for (const data of newDataRows) {
        const time = convertTimeFormat(data.sk);
        totalList.push({ time: time, concurrent: data.concurrent_cnt, enter: data.enter_cnt, like: data.like_cnt });
        concurentViewList.push({ label: data.sk, value: data.concurrent_cnt });
        enterCntList.push({ label: data.sk, value: data.enter_cnt });
        likeCntList.push({ label: data.sk, value: data.like_cnt });
        prodCntList.push({ label: data.sk, value: data.prod_click_cnt });
        lastConcurentView = data.concurrent_cnt;
      }
      if (lastConcurentView !== undefined) {
        setConcurentView(lastConcurentView);
      }

      setStatisticsExcelDataList(totalList);
      setConcurentViewDataRows(concurentViewList);
      setEnterDataRows(enterCntList);
      setLikeDataRows(likeCntList);
      setProdDataRows(prodCntList);
    }
  }, [statisticsList]);

  const delay: number = 60000; // 1분마다 변경
  useInterval(
    () => {
      getStatisticsData(props.broad_info.broad_seq);
    },
    props.broad_info.broad_status === "START" && props.tabValue === "statistics" ? delay : null
  );

  const chartHeight: string = "296px";

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", p: 1 }} elevation={5}>
              <Stack spacing={2} direction="row">
                <Grid item xs={8}>
                  <Stack spacing={2} direction="row" sx={{ pt: 1, userSelect: "text" }}>
                    <Typography>동시시청자 : {cUtils.numericComma(concurentView)}</Typography>
                    <Typography>누적시청자 : {cUtils.numericComma(totalView)}</Typography>
                    <Typography>좋아요 : {cUtils.numericComma(totalLike)}</Typography>
                    <Typography>상품클릭 : {cUtils.numericComma(prodClickList.length)}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button variant="contained" onClick={downloadProdExcelList}>
                      통계데이터다운로드
                    </Button>
                  </Stack>
                </Grid>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ width: "100%", height: chartHeight, p: 1 }} elevation={5}>
              <div className="chart-root">
                <LineChart
                  dataRows={concurentViewDataRows}
                  chartTitle={"동시시청자"}
                  labelTitle={"동시시청자"}
                  xName={"시간"}
                  yName={"명"}
                  lineColor={"rgb(255,128,0)"}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ width: "100%", height: chartHeight, p: 2 }} elevation={5}>
              <div className="chart-root">
                <LineChart
                  dataRows={enterDataRows}
                  chartTitle={"입장고객"}
                  labelTitle={"입장고객"}
                  xName={"시간"}
                  yName={"명"}
                  lineColor={"#f07818"}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ width: "100%", height: chartHeight, p: 2 }} elevation={5}>
              <div className="chart-root">
                <LineChart
                  dataRows={likeDataRows}
                  chartTitle={"좋아요"}
                  labelTitle={"좋아요"}
                  xName={"시간"}
                  yName={"건수"}
                  lineColor={"#f07818"}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ width: "100%", height: chartHeight, p: 2 }} elevation={5}>
              <div className="chart-root">
                <LineChart
                  dataRows={prodDataRows}
                  chartTitle={"상품클릭"}
                  labelTitle={"상품클릭"}
                  xName={"시간"}
                  yName={"건수"}
                  lineColor={"#f07818"}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <LoadingCircle loading={loading} />
    </>
  );
};

export default forwardRef(TabStatistics);
