import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import SearchIcon from "@mui/icons-material/Search";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";

import {
  DataGrid,
  GridCellParams,
  GridToolbarFilterButton,
  GridSelectionModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { templateInfo } from "../../interface/MainInterface";
import { HttpChannelApi, GetTemplateListParams } from "../../service/channel-api";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  callBack: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TemplateViewer = (props: propsType, ref: any) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const open = () => {
    handleOpenModal();
  };

  const chApi = new HttpChannelApi();
  const [loading, setLoading] = useState(false); // 조회중
  const [templateName, setTemplateName] = useState(""); // 검색조건 템플릿명
  const gridRef: any = useRef(); // 그리드 ref
  const [templateRows, setTemplateRows] = useState<templateInfo[]>([]); // 템플릿 목록
  const [pageSize, setPageSize] = useState<number>(100);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const columns = [
    { field: "template_name", headerName: "템플릿명", flex: 1 },
    {
      field: "preview",
      headerName: "",
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </>
        );
      },
      width: 60,
    },
    {
      field: "import",
      headerName: "",
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <IconButton>
              <PanToolAltIcon />
            </IconButton>
          </>
        );
      },
      width: 60,
    },
  ];

  const getTemplateList = async () => {
    setLoading(true);
    setSelectionModel([]);
    const param: GetTemplateListParams = {
      template_name: templateName,
      template_seq: "",
      template_status: ["CREATE"],
      sort: "ASCEND",
    };
    const res = await chApi.get_template_list(param);
    if (res.result_code === "200") {
      setTemplateRows(res.result_body);
    } else {
      console.error("res : ", res.result_body);
    }
    setLoading(false);
  };

  // Row 선택시
  const clickTemplateRow = (param: GridCellParams) => {
    if (param.field === "preview") {
      previewTemplate(param);
    } else if (param.field === "import") {
      importTemplate(param);
    }
  };

  const previewTemplate = (param: GridCellParams) => {
    window.open(`/template/${param.row.template_seq}`);
  };

  const importTemplate = (param: GridCellParams) => {
    if (!window.confirm("기존에 지정하신 템플릿을 선택하신 템플릿으로 변경합니까?")) return;
    handleCloseModal();
    props.callBack(param.row.setting);
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={style}>
        <Stack spacing={1} direction="column">
          <Typography variant="h6" component="h2">
            템플릿 불러오기
          </Typography>
          <Box sx={{ width: "640px" }}>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: "3px" }}>
                <TextField
                  label="검색조건-템플릿명"
                  value={templateName}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                  }}
                />
                <Button variant="outlined" size="small" onClick={getTemplateList}>
                  조회
                </Button>
              </Stack>
              <Box sx={{ height: 674, width: "100%" }}>
                <Paper sx={{ width: "100%", height: 673, p: 2 }} elevation={5}>
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <DataGrid
                      ref={gridRef}
                      rows={templateRows}
                      rowHeight={40}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[3, 10, 20, 50, 100]}
                      pagination
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={selectionModel}
                      getRowId={(row) => row.template_seq}
                      onCellClick={clickTemplateRow}
                      components={{
                        LoadingOverlay: LinearProgress,
                        Toolbar: GridToolbarFilterButton,
                      }}
                      loading={loading}
                    />
                  </Box>
                </Paper>
              </Box>
              {/* 로딩바 */}
              <LoadingCircle loading={loading} />
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default forwardRef(TemplateViewer);
