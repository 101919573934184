import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LinearProgress from "@mui/material/LinearProgress";
import { appConfig } from "../../config/Config";
import { DataGrid, GridCellParams, GridToolbarFilterButton, GridSelectionModel } from "@mui/x-data-grid";
import { itemChannel, userState } from "../../interface/MainInterface";
import { HttpChannelApi, GetChannelListParams, GetChannelListProgressStoreParams } from "../../service/channel-api";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  selectRow: any;
  initForm: any;
  progressStores: any;
  openSearchArea: boolean;
  toggleOpenSearchArea: any;
}
const SearchCondition = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();

  const gridRef: any = useRef();

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().subtract(30, "day"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().add(7, "day"));
  const [rows, setRows] = useState<itemChannel[]>([]);
  const [pageSize, setPageSize] = useState<number>(100);
  const [loading, setLoading] = useState(false);
  const [progressStore, setProgressStore] = useState("A"); // "" = A
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const handleProgressStoreChange = (event: SelectChangeEvent) => {
    setProgressStore(event.target.value as string);
  };

  const columns = [
    {
      field: "broad_start_tm",
      headerName: "시작일시",
      width: 160,
      valueGetter: (value: any) => dayjs(value.row.broad_start_tm, "YYYYMMDDHHmmss").format("YY-MM-DD HH:mm"),
    },
    { field: "broad_title", headerName: "방송명", width: 350 },
    { field: "broad_status", headerName: "상태", width: 80 },
    { field: "host_id", headerName: "채널관리자", width: 200 },
    {
      field: "broad_stop_tm",
      headerName: "종료일시",
      width: 160,
      valueGetter: (value: any) => dayjs(value.row.broad_stop_tm, "YYYYMMDDHHmmss").format("YY-MM-DD HH:mm"),
    },
    { field: "broad_desc", headerName: "비고", width: 400 },
  ];

  const getChannelList = async () => {
    setLoading(true);
    props.initForm();
    setSelectionModel([]);
    if (progressStore === "A") {
      const param: GetChannelListParams = {
        broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
        broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
        sort: "DESCEND",
      };
      const res = await chApi.get_channel_list(param);
      setRows(res.result_body);
    } else if (progressStore !== "A") {
      // GSI로 조회
      const param: GetChannelListProgressStoreParams = {
        progress_store: progressStore,
        broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).format("YYYYMMDD")],
        broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
        sort: "DESCEND",
      };
      const res = await chApi.get_channel_list_progress_store(param);
      setRows(res.result_body);
    }
    setLoading(false);
  };

  // Row 선택시
  const clickBroadRow = (param: GridCellParams) => {
    props.selectRow(param);
  };

  useImperativeHandle(ref, () => ({
    getChannelList,
  }));

  const toggleSearchArea = () => {
    props.toggleOpenSearchArea();
  };

  useEffect(() => {
    getChannelList();
    return () => {
      setSrchDateFrom(dayjs().subtract(30, "day"));
      setSrchDateTo(dayjs().add(7, "day"));
      setRows([]);
      setPageSize(100);
      setLoading(false);
      setProgressStore("A");
      setSelectionModel([]);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1} sx={{ mt: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: "3px" }}>
            <Box sx={props.openSearchArea ? { display: "flex" } : { display: "none" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: "3px" }}>
                <DesktopDatePicker
                  label="방송일From"
                  inputFormat="MM/DD/YYYY"
                  value={srchDateFrom}
                  onChange={handleSrchDateFromChange}
                  renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                />
                <DesktopDatePicker
                  label="방송일To"
                  inputFormat="MM/DD/YYYY"
                  value={srchDateTo}
                  onChange={handleSrchDateToChange}
                  renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                />
                {appConfig.progressStoreSelect ? (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="extra-type-select-label">진행점</InputLabel>
                      <Select
                        labelId="extra-type-select-label"
                        id="extra-type-select-label"
                        value={progressStore}
                        label="진행점"
                        onChange={handleProgressStoreChange}
                      >
                        <MenuItem key="1" value={"A"}>
                          전체
                        </MenuItem>
                        {props.progressStores.map((codes: any) => (
                          <MenuItem key={codes.code} value={codes.code}>
                            {codes.code_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}
                <Button variant="contained" size="small" onClick={getChannelList}>
                  조회
                </Button>
              </Stack>
            </Box>
            <Button
              variant="outlined"
              size="small"
              sx={props.openSearchArea ? { height: "40px" } : { height: "40px", width: "100%" }}
              onClick={toggleSearchArea}
            >
              {props.openSearchArea ? "<" : ">"}
            </Button>
          </Stack>
        </LocalizationProvider>
        <Box sx={{ height: 674, width: "100%" }}>
          <Paper sx={{ width: "100%", height: 673, p: 2 }} elevation={5}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGrid
                ref={gridRef}
                rows={rows}
                rowHeight={40}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[3, 10, 20, 50, 100]}
                pagination
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                getRowId={(row) => row.broad_seq}
                onCellClick={clickBroadRow}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: GridToolbarFilterButton,
                }}
                loading={loading}
              />
            </Box>
          </Paper>
        </Box>
        {/* 로딩바 */}
        <LoadingCircle loading={loading} />
      </Stack>
    </Box>
  );
};

export default forwardRef(SearchCondition);
