import { useEffect, useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import ClientChannelCard from "./ChannelCard";
import { HttpClientApi, CBroadParams } from "../client-rest-api";
import { itemChannel, userState } from "../../interface/MainInterface";

interface propsType {
  webViewStatus: string;
  userState: userState;
}

const clientApi = new HttpClientApi();

const vodList: any = [
  "9cd960be-3a84-4b63-aff5-4a1868fc951e",
  "eb39531b-de9d-4c3c-b742-df3fcf3ff2f7",
  "4c5b9412-babc-4363-961f-e691648a6dbe",
  "6582f5c0-9c62-45ba-8239-01b75896700b",
];

const VodMainList = (props: propsType) => {
  const [vodChannelList, setVodChannelList] = useState<itemChannel[]>([]);

  const channelCardCallBack = (command: any, freeForm: any) => {};

  const getChannelist = useCallback(async () => {
    let list = [];
    for (const vod_seq of vodList) {
      const param: CBroadParams = {
        broad_seq: vod_seq,
      };
      const res = await clientApi.get_channel_info(param);
      list.push(res.result_body);
    }
    setVodChannelList(list);
  }, []);

  useEffect(() => {
    getChannelist();
  }, [getChannelist]);

  useEffect(() => {
    return () => {
      setVodChannelList([]);
    };
  }, []);

  return (
    <>
      <Stack spacing={0} direction="column">
        <h2>지난방송보기</h2>
      </Stack>
      <Box sx={{ width: "100%", flexGrow: 1, pt: 2 }}>
        <Grid container spacing={2}>
          {vodChannelList.map((item: itemChannel, index: number) => {
            return (
              <ClientChannelCard
                key={item.broad_seq}
                item={item}
                webViewStatus={props.webViewStatus}
                userState={props.userState}
                callBack={channelCardCallBack}
              />
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default VodMainList;
