export const appConfig = {
  appInstanceUserArn: "",
  channelArn: "",
  chime_region: "us-east-1",
  listChannelMode: "recent" /*recent or total*/,
  app_client_id: "220bf4ik6qrj0cdssr32gsutb0",
  app_client_secret: "iaqp8hpj1o560lrfog7n4vtfrc51qhdl4na6mhf7pk3ln6poubi",
  app_client_url: "https://api-liveitnedev-livestream-hd.auth.ap-northeast-2.amazoncognito.com/oauth2/token",
  access_token: undefined,
  access_token_expire_time: undefined,
  external_credential: undefined,
  external_cred_expire_time: undefined,
  quote_separator: "⤷",
  vod_cloudfront_endpoint: "https://vod.hd-smartstore.com",
  iotRegion: "ap-northeast-2",
  iotIdentityPollId: "ap-northeast-2:3268b353-ca0e-40ea-8cc5-c1b854ce86ef",
  iotEndPoint: "a1594iyi5z41mp-ats.iot.ap-northeast-2.amazonaws.com",
  domain: "https://live.hd-smartstore.com",
  broadTypeCode: "00002",
  progressStoreSelect: true,
  progressStoreCode: "00003",
  extra_type: "P001",
  chat_id_mask: true,
  kakao_app_key: "92bc87488cf1b89205df7513f9b729c6",
  chatFontColorLight: "rgb(255, 255, 255)",
  chatFontcolorDark: "rgb(47, 47, 47)",
  recent_enter_private_channel: "recent_enter_private_channel"
};

export const setUserArn = (arn: string) => {
  appConfig.appInstanceUserArn = arn;
};

export const setChArn = (arn: string) => {
  appConfig.channelArn = arn;
};

export const setAccessToken = (token: any, time: any) => {
  appConfig.access_token = token;
  appConfig.access_token_expire_time = time;
};

export const setExtCredential = (credential: any, time: any) => {
  appConfig.external_credential = credential;
  appConfig.external_cred_expire_time = time;
};
