export interface itemChannel {
  broad_desc: string;
  broad_title: string;
  broad_seq: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_status: string;
  pk: string;
  vod_url: string;
  host_name: string;
  host_picture: string;
}

export interface channelInfo {
  broad_desc: string;
  broad_seq: string;
  broad_start_tm: string;
  broad_status: string;
  broad_stop_tm: string;
  broad_title: string;
  broad_notice: string;
  broad_prod_list: any;
  broad_cover_url: string;
  vod_url: string;
  vod_started_at: string;
  vod_start_sec: number;
  vod_end_sec: number;
  high_start_sec: number;
  most_concurrent_view_timestamp: string;
  chime_admin_arn: string;
  chime_channel_arn: string;
  ivs_channel_arn: string;
  ivs_ingest_endpoint: string;
  ivs_playback_url: string;
  ivs_stream_key: string;
  total_likes: number;
  total_views: number;
  password: string;
  extra_type: string;
  progress_store: string;
  host_id: string;
  host_name: string;
  host_picture: string;
  channel_img: string;
  channel_img_url: string;
  channel_template: boolean;
  setting: any;
}

export interface broadSetting {
  logo: boolean;
  host_picture: boolean;
  title: boolean;
  statistics: boolean;
  title_text_color: string;
  title_text_size: string;
  title_text_v_size: string;
  title_shadow: boolean;
  title_shadow_color: string;
  statistics_text_size: string;
  statistics_text_v_size: string;
  statistics_text_color: string;
  statistics_shadow: boolean;
  statistics_shadow_color: string;
  chat_text_color: string;
  chat_text_size: string;
  chat_text_v_size: string;
  chat_shadow: boolean;
  chat_shadow_color: string;
  host_img_size: string;
  host_img_v_size: string;
  icon_size: string;
  icon_v_size: string;
  ready_img: string;
  logo_img: string;
  ready_img_url: string;
  logo_img_url: string;
}

export const emptySetting = {
  logo: true,
  host_picture: true,
  title: true,
  statistics: true,
  title_text_size: "2.0",
  title_text_v_size: "2.0",
  title_text_color: "#ffffff",
  title_shadow: true,
  title_shadow_color: "#9b9b9b",
  statistics_text_size: "2.0",
  statistics_text_v_size: "2.0",
  statistics_text_color: "#ffffff",
  statistics_shadow: true,
  statistics_shadow_color: "#9b9b9b",
  chat_text_size: "2.0",
  chat_text_v_size: "2.0",
  chat_text_color: "#ffffff",
  chat_shadow: true,
  chat_shadow_color: "#9b9b9b",
  host_img_size: "6.0",
  host_img_v_size: "5.0",
  icon_size: "3.0",
  icon_v_size: "3.0",
  ready_img: "",
  logo_img: "",
};

export interface broadInfo {
  pk: string;
  broad_seq: string;
  broad_title: string;
  broad_desc: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_status: string;
  broad_notice: string;
  broad_prod_list: any;
  host_id: string;
  vod_url: string;
  vod_started_at: string;
  vod_start_sec: number;
  vod_end_sec: number;
  password: string;
  extra_type: string;
  progress_store: string;
  channel_template: boolean;
}

export interface streamInfo {
  channelArn: string;
  health: string;
  playbackUrl: string;
  startTime: string;
  state: string;
  streamId: string;
  viewerCount: number;
}

export interface userState {
  id: string;
  nickName: string;
  picture: string;
  isAuth: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  userArn: string;
}

export interface prodInfo {
  prod_id: string;
  prod_title: string;
  prod_callback: string;
}

export interface recordInfo {
  stream_info: any;
}

export interface androidWebAppInterface {
  enterPipMode(w: number, h: number): any;
  close(): any;
  openWindow(url: String): any;
  setMuteState(state: Boolean): any;
  moveLoginPage(): any;
  setPipBlockState(state: Boolean): any;
  moveProductPage(url: String): any;
  getProductList(): any;
  getCouponList(): any;
  downloadCoupon(): any;
  shareKakao(): any;
  shareLink(): any;
  shareSMS(): any;
  shareMore(): any;
}

export interface templateInfo {
  pk: string;
  sk: string;
  template_seq: string;
  template_name: string;
  template_status: string;
  setting: any;
}

export interface deviceInfo {
  isBrowser: boolean;
  isMobile: boolean;
  browserName: string;
  fullBrowserVersion: string;
  mobileModel: string;
  osName: string;
  osVersion: string;
}

export let emptyDeviceInfo = {
  isBrowser: false,
  isMobile: false,
  browserName: "unKnown",
  fullBrowserVersion: "unKnown",
  mobileModel: "unKnown",
  osName: "unKnown",
  osVersion: "unKnown",
};
