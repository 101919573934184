import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingCircleOnVideo from "../../utilities/LoadingCircleOnVideo";
import { broadSetting } from "../../interface/MainInterface";
import "./ClientIVSPlayer.css";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

interface propsType {
  type: string;
  endLoadInfo: boolean;
  broad_seq: string;
  broad_status: string;
  playbackUrl: string;
  player_id: string;
  video_ref: any;
  streamState: string;
  like_count_callback: any;
  newLikeUpCallback: any;
  viewCountCallback: any;
  videoStatusCallback: any;
  videoPositionCallback: any;
  pip_callback: any;
  setting: broadSetting;
  fitMode: boolean;
}

export let player: any = null;

export function ClientIVSPlayer(props: propsType) {
  const PLAYBACK_URL = props.playbackUrl;
  const playRef = useRef(false);
  const [alertStyle, setAlertStyle] = useState({ display: "flex" });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (PLAYBACK_URL !== "") {
      mediaPlayerScriptLoaded();
    }
  }, [PLAYBACK_URL]);

  let PlayerState: any;
  const mediaPlayerScriptLoaded = () => {
    // This shows how to include the Amazon IVS Player with a script tag from our CDN
    // If self hosting, you may not be able to use the create() method since it requires
    // that file names do not change and are all hosted from the same directory.

    const MediaPlayerPackage = window.IVSPlayer;
    //const { IVSPlayer } = window;

    // First, check if the browser supports the Amazon IVS player.
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("The current browser does not support the Amazon IVS player.");
      return;
    }

    PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);

    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue: any) => {
      const metadataText = JSON.parse(cue.text);
      if (metadataText.prot === "like") like_count_up();
      if (metadataText.prot === "like" && metadataText.playerID !== props.player_id) new_like_up();
      if (metadataText.prot === "view" && metadataText.playerID !== props.player_id) view_count_up();
    });

    // Setup stream and play
    player.setAutoQualityMode(true);
    player.setLiveLowLatencyEnabled(true);
    player.setAutoplay(true);
    player.setMuted(true);
    player.setVolume(1.0);

    const videoWorks = !!document.createElement("video").canPlayType;
    if (videoWorks) {
      props.video_ref.current?.addEventListener("enterpictureinpicture", onEnterPip);
      props.video_ref.current?.addEventListener("leavepictureinpicture", onLeavePip);
    }

    if (props.type === "live") {
      if (props.broad_status === "START") player.load(PLAYBACK_URL);
    } else {
      player.load(PLAYBACK_URL);
    }
  };

  const onEnterPip = (e: any) => {
    props.pip_callback(true);
    window.resizeTo(200, 10); // PC 크롬만 됨
    window.moveTo(5000, 5000); // PC 크롬만 됨
  };

  const onLeavePip = (e: any) => {
    props.pip_callback(false);
    window.resizeTo(4000, 4000); // PC 크롬만 됨
    window.moveTo(0, 0); // PC 크롬만 됨
  };

  useEffect(() => {
    if (props.streamState === "Stream Start" && props.broad_status === "START") player.load(PLAYBACK_URL);
  }, [props.streamState]);

  useEffect(() => {
    if (props.type === "live") {
      if (props.broad_status === "START") player.load(PLAYBACK_URL);
      if (props.broad_status === "READY") player.load("");
    }
  }, [props.broad_status]);

  const clearLoading = () => {
    playRef.current = true;
    setAlertStyle({ display: "none" });
  };

  const startLoading = () => {
    playRef.current = false;
    setAlertStyle({ display: "flex" });
  };

  const isAutoMode = () => {
    // if (props.video_status !== "onStatePlaying") return false;
    if (player.isAutoQualityMode()) return true;
    return false;
  };
  const setBestQlty = () => {
    let timer = setTimeout(() => {
      const nowQlty = parseInt(player.getQuality().height);
      const nowLatncy = player.getLiveLatency();
      if (isAutoMode()) {
        if (nowQlty <= 720 && nowLatncy > 3) {
          setMaxQlty(1080);
        } else if (720 < nowQlty && nowLatncy > 5) {
          setMaxQlty(1080);
        }
      }
      // player.setAutoQualityMode(true);
    }, 1000);
  };

  const setMaxQlty = (maxQlty: number) => {
    const liveQlty = player.getQualities();
    if (liveQlty.length) {
      let bestQltyIdx = 0;
      let bestQlty = 100;
      liveQlty.map((obj: any, index: number) => {
        const currQlty = parseInt(obj.group.split("p")[0]);
        if (currQlty <= maxQlty) {
          if (currQlty >= bestQlty) {
            bestQlty = currQlty;
            bestQltyIdx = index;
          }
        }
      });
      player.setQuality(liveQlty[bestQltyIdx]);
    }
  };

  const onIdle = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onIdle");
    setAlertStyle({ display: "flex" });
  };

  const onReady = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    // clearLoading();
    props.videoStatusCallback("onReady");
  };

  const onStatePlaying = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    clearLoading();
    props.videoStatusCallback("onStatePlaying");
    setBestQlty(); // VOD는 이걸 활성화 하면 안됨
    setIsLoading(false);
    sendCommand.current = false;
  };

  const onStateEnd = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onStateEnd");
    startLoading();
    player.load("");
    setIsLoading(true);
  };

  const onError = (err: any) => {
    console.warn("Player Event - ERROR:", err);
    props.videoStatusCallback("onError");
  };

  const sendCommand = useRef(false);
  const onTimeUpdate = (cue: any) => {
    const nowLatncy = player.getLiveLatency();
    const liveLow = player.isLiveLowLatency();
    if (liveLow && nowLatncy * 1 > 5) {
      if (!sendCommand.current) {
        sendCommand.current = true;
        setRefresh();
      }
    }
    let realPostion = player.getPosition();
    props.videoPositionCallback("onTimeUpdate", realPostion);
  };

  const setRefresh = () => {
    let timer = setTimeout(() => {
      player.setAutoQualityMode(true);
      setMaxQlty(1080);
    }, 1000);
  };

  const like_count_up = () => {
    props.like_count_callback();
  };

  const new_like_up = () => {
    props.newLikeUpCallback();
  };

  const view_count_up = () => {
    props.viewCountCallback();
  };

  return (
    <>
      <video
        id="videoPlayer"
        ref={props.video_ref}
        className={props.fitMode ? "client-video video-contain" : "client-video video-cover"}
        poster={
          props.setting.ready_img_url !== ""
            ? props.setting.ready_img_url
            : props.endLoadInfo
            ? "/images/2020_com_topimg4.jpg"
            : ""
        }
        playsInline
      ></video>
      <Box className="box-screen-alert" sx={alertStyle}>
        {props.setting.ready_img_url === "" && props.endLoadInfo ? (
          <Typography component="div" className="chat-list-body">
            <Box sx={{ fontWeight: 700, fontSize: "6vw" }}>
              <span style={{ color: "Snow" }}>방송 준비중 입니다.</span>
            </Box>
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <LoadingCircleOnVideo loading={isLoading} />
    </>
  );
}
