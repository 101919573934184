import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { CommonUtils } from "../../../service/common_utils";
import "./LiveHeader.css";

interface propsType {
  broad_seq: string;
  broad_title: string;
  broad_status: string;
  video_status: string;
  host_picture: string;
  host_name: string;
  postMsg: any;
  tot_view_count: number;
  tot_like_count: number;
  hidden_menu: boolean;
  vodType: string;
  haveHighlight: boolean;
  toggleVodType: any;
  muted: boolean;
  toggleMute: any;
  braodWait: boolean;
  handleResolution: any;
}

const LiveHeader = (props: propsType, ref: any) => {
  const titleShadow: string = "0px 0px 1px #494949";

  const cUtils = new CommonUtils();
  const [openAlert, setOpenAlert] = useState(false);
  const [startIvs, setStartIvs] = useState(false);
  const [totLikeCount, setTotLikeCount] = useState(0);
  const [totViewCount, setTotViewCount] = useState(0);
  const [broadStop, setBroadStop] = useState(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenAlert(false);
      setStartIvs(false);
      setTotLikeCount(0);
      setTotViewCount(0);
      setBroadStop(false);
    };
  }, []);

  // 새로고침
  const handleRefresh = () => window.location.reload();

  // 볼륨 컨트롤
  const toggleMute = () => {
    setOpenAlert(false);
    props.toggleMute();
  };

  useEffect(() => {
    if (props.broad_status === "STOP") setBroadStop(true);
    else setBroadStop(false);
  }, [props.broad_status]);

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
      }, 30000);
    }
  }, [openAlert]);

  useEffect(() => {
    setTotLikeCount(props.tot_like_count);
  }, [props.tot_like_count]);

  useEffect(() => {
    setTotViewCount(props.tot_view_count);
  }, [props.tot_view_count]);

  // 창닫기
  const closeWindow = () => {
    // 더현대 부모창 방송목록으로 이동
    console.log("SEND goBroadList");
    props.postMsg({ msg: "goBroadList" }, "*");
  };

  // 음소거 알림
  const openAlertMuted = () => {
    setOpenAlert(true);
  };

  const initStartIvs = () => {
    setStartIvs(true);
  };

  const addLiveCount = () => {
    setTotLikeCount((totLikeCount: number) => totLikeCount + 1);
  };

  const addViewCount = () => {
    setTotViewCount((totViewCount: number) => totViewCount + 1);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    openAlertMuted,
    initStartIvs,
    addLiveCount,
    addViewCount,
  }));

  return (
    <>
      <Box className="live-header-wrapper color-snow" sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}>
        <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
          <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
            {/* 로고영역 */}
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              {/* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */}
              <Box component="img" className="thd-live-logo white-shadow thd-live-no-shadow" src="/images/logo.png" />
            </Grid>
            {/* 버튼영역 */}
            <Grid
              item
              xs={6}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                {/* 2023.09.22 앱 배포 후, 아래 주석 해제 */}
                {/* {props.broad_status === "VOD" && props.haveHighlight ? (
                  <Box sx={{ width: "100%" }} onClick={props.toggleVodType}>
                    <span className="thd-vod-select font-small white-shadow">
                      {props.vodType === "highlight" ? "풀영상 보기" : "하이라이트 보기"}
                    </span>
                  </Box>
                ) : (
                  <></>
                )} */}
                {/* 새로고침 */}
                <IconButton
                  sx={props.video_status !== "onStatePlaying" ? { display: "flex", padding: 0 } : { display: "none", padding: 0 }}
                  onClick={handleRefresh}
                >
                  <AutorenewIcon sx={{ fontSize: "20px" }} className="color-snow white-shadow thd-live-no-shadow" />
                </IconButton>
                {/* VOD 하이라이트/풀영상 보기 */}
                {!(openAlert && !broadStop) && props.broad_status === "VOD" && props.haveHighlight ? (
                  <Box className="thd-vod-select-box" sx={{ width: "100%", mt: 1 }} onClick={props.toggleVodType}>
                    <span className="thd-vod-select font-small white-shadow thd-live-no-shadow">
                      {props.vodType === "highlight" ? "풀영상 보기" : "하이라이트 보기"}
                    </span>
                  </Box>
                ) : (
                  <></>
                )}
                {/* 볼륨 컨트롤 */}
                {openAlert && !broadStop ? (
                  <Box
                    className="thd-live-mute-text"
                    sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}
                    onClick={toggleMute}
                  >
                    <Box className="thd-live-mute-text-container">
                      <Box component="img" src="/images/sound-mute.png" className="thd-live-mute-text-img" />
                      <Typography
                        sx={{
                          position: "relative",
                          left: "3px",
                          fontSize: "13px",
                          color: "white",
                          overflow: "hidden",
                          textOverflow: "clip",
                        }}
                      >
                        {/* 소리 켜기 */}
                        음소거 해제
                      </Typography>
                    </Box>
                  </Box>
                ) : startIvs && !broadStop ? (
                  <IconButton className="thd-live-mute-button" sx={{ padding: 0 }} onClick={toggleMute}>
                    {props.muted ? (
                      /* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */
                      <Box
                        component="img"
                        className="thd-live-mute thd-shake_bell white-shadow thd-live-no-shadow"
                        src="/images/sound-mute.png"
                      />
                    ) : (
                      /* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */
                      <Box component="img" className="thd-live-mute white-shadow thd-live-no-shadow" src="/images/sound.png" />
                    )}
                  </IconButton>
                ) : (
                  <></>
                )}
                {/* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */}
                <Box
                  component="img"
                  className="thd-live-close white-shadow thd-live-no-shadow"
                  src="/images/close.png"
                  onClick={closeWindow}
                />
              </Stack>
            </Grid>
          </Stack>
          <Box sx={{ width: "100%", mt: 2 }}>
            <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
              <Box>
                <Avatar
                  sx={{
                    width: 44,
                    height: 44,
                    backgroundColor: "#07582e",
                    top: "1px",
                    borderRadius: "21.87px",
                  }}
                  src={props.host_picture}
                ></Avatar>
              </Box>
              <Stack
                direction="column"
                spacing={0}
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: "3px" }}>
                  {props.broad_status === "START" ? <div className="thd-broad-title-live-mark">LIVE</div> : <></>}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textShadow: titleShadow, // {/* 2023.09.22 앱 배포 후 shadow 뺄 예정 */}
                      fontFamily: "Noto Sans KR",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#FFFFFF",
                    }}
                    className="thd-live-no-shadow"
                  >
                    {props.broad_title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {!props.braodWait ? (
                    <>
                      <Box className="thd-live-header-icon">
                        {/* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */}
                        <Box
                          component="img"
                          className="thd-live-viewer-icon white-shadow thd-live-no-shadow"
                          src="/images/header-viewers.png"
                        />
                        {/* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */}
                        <span className="thd-live-count-text white-shadow thd-live-no-shadow">
                          {totViewCount > 0 ? cUtils.numericComma(totViewCount) : "0"}
                        </span>
                        {/* 2023.09.22 앱 배포 후 white-shadow 뺄 예정 */}
                        <Box
                          component="img"
                          className="thd-live-heart-icon white-shadow thd-live-no-shadow"
                          src="/images/header-like.png"
                        />
                        <span className="thd-live-count-text white-shadow thd-live-no-shadow">
                          {totLikeCount > 0 ? cUtils.numericComma(totLikeCount) : "0"}
                        </span>{" "}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
          {/* 2023.09.22 앱 배포 후, 아래 주석 처리 */}
          {/* {props.broad_status === "VOD" && props.haveHighlight ? (
            <Box sx={{ width: "100%", mt: 1 }} onClick={props.toggleVodType}>
              <span className="thd-vod-select font-small white-shadow">
                {props.vodType === "highlight" ? "풀영상 보기" : "하이라이트 보기"}
              </span>
            </Box>
          ) : (
            <></>
          )} */}
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(LiveHeader);
