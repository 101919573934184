import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

interface propsType {
  broad_status: string;
  user_id: string;
  sendMessage: any;
  fncResetQuote: any;
}

interface chatSendForm {
  msg: string;
}

const LiveInfoChatInput = (props: propsType, ref: any) => {
  const inputChatRef: any = useRef();
  const inputMsgRef = useRef<any>();

  // Form for send message to chime channel
  const {
    register,
    setFocus,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    document.getElementById("input-chat-Message")?.focus();
    reset({ msg: "" });
    await props.sendMessage(data.msg, "");
  };

  const handleHiddenForm = (hidden: boolean) => {};

  const inputMsgKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") props.fncResetQuote();
  };

  const chatInputFocus = () => {
    inputMsgRef.current.focus();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    handleHiddenForm,
    chatInputFocus,
  }));

  return (
    <>
      <Box
        id="thd-input-chat"
        sx={
          props.broad_status === "VOD"
            ? { flexGrow: 1, display: "none" }
            : {
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
              }
        }
        ref={inputChatRef}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <TextField
          id="input-chat-Message"
          className="thd-without-padding "
          inputRef={inputMsgRef}
          inputProps={
            props.user_id === ""
              ? { readOnly: true, enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
              : { enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
          }
          type="search"
          autoComplete="off"
          sx={{ width: "100%", color: "black", "&::placeholder": { color: "gray" } }}
          placeholder={props.user_id === "" ? "로그인 후 채팅에 참여하세요." : "채팅 내용을 입력해주세요."}
          onKeyDown={inputMsgKeyPress}
          {...register("msg", { required: true, maxLength: 3000 })}
        />
        <Button id="thd-input-chat-button" type="submit" variant="text">
          보내기
        </Button>
      </Box>
    </>
  );
};

export default forwardRef(LiveInfoChatInput);
