import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridCellParams, GridToolbarFilterButton } from "@mui/x-data-grid";
import { HttpCommonApi, GetAdminListParams, SaveAdminParams } from "../../service/common-rest-api";
import { userState } from "../../interface/MainInterface";
import Header from "../Header";
interface propsType {
  userState: userState;
}
interface IUserCols {
  user_id: string;
  user_name: string;
  team_name: string;
  use_yn: string;
}

const AdminManagement = (props: propsType) => {
  const apiHandler = new HttpCommonApi();

  // 그리드 로딩 컨트롤
  const [loading, setIsLoading] = useState(false);
  const [userRows, setUserRows] = useState<IUserCols[]>([]);
  // 검색조건 사용자ID
  const [userID_S, setUserID_S] = useState<string>("");

  const [userID, setUserID] = useState<string>(""); // 사용자 ID
  const [userName, setUserName] = useState<string>(""); // 사용자 이름
  const [teamName, setTeamName] = useState<string>(""); // 부서명
  const [userGubunCd, setUserGubunCd] = useState<string>("A"); // 사용자 구분
  const [useYn, setUseYn] = useState("Y"); // 사용여부

  const userCols = [
    { field: "user_id", headerName: "ID", width: 200 },
    { field: "user_name", headerName: "NAME", flex: 1 },
    { field: "team_name", headerName: "TEAM", width: 150 },
    { field: "use_yn", headerName: "USEYN", width: 100 },
  ];
  // 관리자 리스트 조회
  const fncSearchAdminList = async () => {
    setIsLoading(true);

    const sParam: GetAdminListParams = {
      grant_type_cd: "",
      user_id: "",
    };

    const res = await apiHandler.get_admin_list(sParam);
    if ("" + res.result_code === "200") {
      setUserRows(res.result_body);
    }

    setIsLoading(false);
  };

  // 상세보기 필드
  const handleUseYnChange = (event: SelectChangeEvent) => {
    setUseYn(event.target.value as string);
  };

  const fncCellClick = (param: GridCellParams) => {
    setUserID(param.row.user_id);
    setUserName(param.row.user_name);
    setTeamName(param.row.team_name);
    setUseYn(param.row.use_yn);
    setUserGubunCd(param.row.user_gubun_cd);
  };

  // 상세정보 저장버튼 클릭
  const fncSaveAdmin = async () => {
    // 필수값 검사
    if (userID === "" || userID.trim() === "") {
      alert("사용자 ID를 입력하세요.");
      return;
    }
    if (userName === "" || userName.trim() === "") {
      alert("사용자 이름을 입력하세요.");
      return;
    }

    const param: SaveAdminParams = {
      user_id: userID,
      user_name: userName,
      user_gubun_cd: userGubunCd,
      team_name: teamName,
      use_yn: useYn,
    };

    const res = await apiHandler.set_admin_info(param);
    if ("" + res.result_code === "200") {
      alert("등록이 완료되었습니다.");
      window.location.reload();
    } else {
      alert("[ERROR] " + res.result_body);
      return;
    }
  };

  const fncTest = async () => {
    await apiHandler.get_access_token();
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserRows([]);
      setUserID_S("");
      setUserID("");
      setUserName("");
      setTeamName("");
      setUserGubunCd("A");
      setUseYn("Y");
    };
  }, []);

  return (
    <>
      <Header userState={props.userState} />
      <Grid container spacing={2} component="div" sx={{ px: 5, pb: 5 }}>
        {/* 타이틀 */}
        <Grid item xs={12}>
          <h1>관리자 정보관리</h1>
        </Grid>
        {/* 사용자 추가정보 등록현황 조회 */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} direction="column">
            <Stack spacing={1} direction="row">
              {/** 검색조건 - 권한그룹 (관리자, 고객사) */}
              {/** 검색조건 - 사용자ID == ID(우리는 email), 검색조건이 없으면 사용자 추가정보 등록된 리스트를 불러온다.(리스트가 엄청 많음..) */}
              <Grid item xs={8}>
                <div>
                  <TextField
                    label="사용자 ID"
                    className="bt-md"
                    value={userID_S}
                    variant="outlined"
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      setUserID_S(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button variant="outlined" className="bt-md" onClick={fncSearchAdminList}>
                    FIND
                  </Button>
                </Stack>
              </Grid>
            </Stack>
            {/** 사용자 리스트 그리드 */}
            <div style={{ width: "100%", height: "490px" }}>
              <DataGrid
                rows={userRows}
                columns={userCols}
                pageSize={100}
                rowsPerPageOptions={[7]}
                getRowId={(row) => row.user_id}
                onCellClick={fncCellClick}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: GridToolbarFilterButton,
                }}
                loading={loading}
              />
            </div>
          </Stack>
        </Grid>
        {/* 사용자 정보 자세히 보기 */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} direction="column">
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button variant="outlined" className="bt-md" onClick={fncTest}>
                ACCESS TOKEN TEST
              </Button>
              <Button variant="outlined" className="bt-md" onClick={fncSaveAdmin}>
                SAVE
              </Button>
            </Stack>
            <Paper elevation={5}>
              <Box m={2} sx={{ height: "458px" }}>
                <Stack spacing={3} direction="column">
                  <TextField
                    id="input-title"
                    label="사용자 ID"
                    value={userID}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setUserID(e.target.value);
                    }}
                    // InputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="input-title"
                    label="사용자 이름"
                    value={userName}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                  <TextField
                    id="input-title"
                    label="부서명"
                    value={teamName}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setTeamName(e.target.value);
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="selUseYn-label">사용여부</InputLabel>
                    <Select
                      labelId="selUseYn-label"
                      id="selUseYn"
                      value={useYn}
                      defaultValue={"Y"}
                      label="사용여부"
                      onChange={handleUseYnChange}
                    >
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminManagement;
