import { ApiBase, BaseParams, BroadParams } from "./api-base";

export interface CreateManagerParam {
  managerId: string;
  managerName: string;
  password: string;
  memo: string;
}

export interface UpdateManagerParam {
  managerId: string;
  managerName: string;
  memo: string;
}

export interface DeleteManagerParam {
  managerId: string;
}

export interface ChangeManagerPasswordParam {
  managerId: string;
  password: string;
}

export interface GetManagerInfoParam {
  managerId: string;
}

export interface CreateChannelParam {
  broad_title: string;
  broad_desc: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_cover_url: string;
  broad_prod_list: any;
  password: string;
  manager_id: string;
}

export interface UpdateChannelParam {
  broad_seq: string;
  broad_title: string;
  broad_desc: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_cover_url: string;
  broad_prod_list: any;
  password: string;
  manager_id: string;
}

export interface GetChannelInfoParam {
  broad_seq: string;
}

export interface GetChannelListParam {
  broad_date: string[];
  broad_status: string[];
  sort: string;
  manager_id: string;
}

export class HttpTheHyundaiApi extends ApiBase {
  onApiName(): string {
    return "ThehyundaiApiName";
  }

  onUrlPath(): string {
    return "/thehyundai";
  }

  public async create_broadcast_manager(params: CreateManagerParam) {
    const requestBody = {
      command: "create_broadcast_manager",
      manager_id: params.managerId,
      manager_name: params.managerName,
      password: params.password,
      memo: params.memo,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_broadcast_manager(params: UpdateManagerParam) {
    const requestBody = {
      command: "update_broadcast_manager",
      manager_id: params.managerId,
      manager_name: params.managerName,
      memo: params.memo,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async delete_broadcast_manager(params: DeleteManagerParam) {
    const requestBody = {
      command: "delete_broadcast_manager",
      manager_id: params.managerId,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async change_broadcast_manager_password(params: ChangeManagerPasswordParam) {
    const requestBody = {
      command: "change_broadcast_manager_password",
      manager_id: params.managerId,
      password: params.password,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_broadcast_manager_info(params: GetManagerInfoParam) {
    const requestBody = {
      command: "get_broadcast_manager_info",
      manager_id: params.managerId,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_broadcast_manager_list() {
    const requestBody = {
      command: "get_broadcast_manager_list",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async create_broadcast_channel(params: CreateChannelParam) {
    const requestBody = {
      command: "create_broadcast_channel",
      broad_title: params.broad_title,
      broad_desc: params.broad_desc,
      broad_start_tm: params.broad_start_tm,
      broad_stop_tm: params.broad_stop_tm,
      broad_prod_list: params.broad_prod_list,
      broad_cover_url: params.broad_cover_url,
      password: params.password,
      manager_id: params.manager_id,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_broadcast_channel(params: UpdateChannelParam) {
    const requestBody = {
      command: "update_broadcast_channel",
      broad_seq: params.broad_seq,
      broad_title: params.broad_title,
      broad_desc: params.broad_desc,
      broad_start_tm: params.broad_start_tm,
      broad_stop_tm: params.broad_stop_tm,
      broad_prod_list: params.broad_prod_list,
      broad_cover_url: params.broad_cover_url,
      password: params.password,
      manager_id: params.manager_id,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_broadcast_channel_info(params: GetChannelInfoParam) {
    const requestBody = {
      command: "get_broadcast_channel_info",
      broad_seq: params.broad_seq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_broadcast_channel_list(params: GetChannelListParam) {
    const requestBody = {
      command: "get_broadcast_channel_list",
      broad_date: params.broad_date,
      broad_status: params.broad_status,
      sort: params.sort,
      manager_id: params.manager_id,
    };
    const response = this.requestPost(requestBody);
    console.info("Request response", response);
    return response;
  }
}
