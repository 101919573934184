import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

interface propsType {
  setting: any;
  screenMode: string;
}

const TemplateRightFooter = (props: propsType) => {
  const [iconSize, setIconSize] = useState("3.0vmax");
  const [btnChatClass, setBtnChatclass] = useState({ padding: 0, display: "flex" });

  useEffect(() => {
    if (props.screenMode === "W") setIconSize(`${props.setting.icon_size}vmax`);
    else setIconSize(`${props.setting.icon_v_size}vmax`);
  }, [props.screenMode, props.setting.icon_size, props.setting.icon_v_size]);

  return (
    <>
      <Box component="div" sx={{ visibility: "visible" }} className={"template-right-footer color-snow"}>
        <Stack direction="column" spacing={3}>
          <IconButton sx={btnChatClass}>
            <FavoriteBorderIcon sx={{ fontSize: iconSize }} className="color-snow" />
          </IconButton>
          <IconButton sx={{ padding: 0, display: "flex" }}>
            <LiveHelpIcon sx={{ fontSize: iconSize }} className="color-snow" />
          </IconButton>
          <IconButton sx={btnChatClass}>
            <CommentOutlinedIcon sx={{ fontSize: iconSize }} className="color-snow" />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
};

export default TemplateRightFooter;
