import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";

import { CommonUtils } from "../common_utils";
import { HttpClientApi, CBroadParams } from "../client-rest-api";
import { itemChannel, channelInfo, streamInfo, userState, androidWebAppInterface } from "../../interface/MainInterface";
import "./ChannelCard.css";

declare var android: androidWebAppInterface;

interface propsType {
  item: any;
  webViewStatus: string;
  userState: userState;
  callBack: any;
}

const ClientChannelCard = (props: propsType) => {
  const cUtils = new CommonUtils();
  const [ClientApi] = useState(() => new HttpClientApi());
  const [channelInfo, setChannelInfo] = useState<any>();
  const [streamInfo, setStreamInfo] = useState<streamInfo>();
  const [channelImgUrl, setChannelImgUrl] = useState("");

  useEffect(() => {
    get_channel_info();
  }, []);

  const get_channel_info = async () => {
    const param: CBroadParams = {
      broad_seq: props.item.broad_seq,
    };
    await ClientApi.get_channel_info(param)
      .then((res: any) => {
        if (res.result_code !== "200") throw Error(res.result_body);
        setChannelInfo(res.result_body);
        if (res.result_body.channel_img_url !== undefined && res.result_body.channel_img_url !== "")
          setChannelImgUrl(res.result_body.channel_img_url);
        else setChannelImgUrl(`https://picsum.photos/300/200?random=${props.item.broad_seq}`);
      })
      .catch((e) => {
        console.info("[ERROR] get_channel_info : ", e);
      });
  };

  useEffect(() => {
    if (channelInfo !== undefined) {
      get_stream();
    }
  }, [channelInfo]);

  const get_stream = async () => {
    if (channelInfo !== undefined) {
      await ClientApi.get_stream_by_arn(channelInfo?.ivs_channel_arn)
        .then((res) => {
          if (res.result_code !== "200") throw Error(res.result_body);
          if (channelInfo.broad_status === "START" && cUtils.isEmptyObj(res.result_body))
            throw Error("Broad status is START. but The Stream is Empty");
          setStreamInfo(res.result_body.stream);
        })
        .catch((e) => {
          console.warn("[ERROR] get_stream : ", e);
        });
    }
  };

  const enterChannel = async (item: any) => {
    if (props.userState.isAuth && props.userState.nickName === "") {
      props.callBack("OPEN_CHAT_NAME_MODAL", item.broad_seq);
      return;
    }
    props.webViewStatus === "android" ? webViewStatusOpen(item.broad_seq) : windowOpen(item.broad_seq);
  };

  const windowOpen = (broadSeq: string) => {
    if (props.item.extra_type === "THD") {
      window.location.href = "/stream/" + broadSeq + "?userId=test@test.com&custNo=test";
    } else {
      window.location.href = "/live/" + broadSeq;
    }
  };

  const [loginUrl, setLoginUrl] = useState("https://d2h23qc69wyef0.cloudfront.net/loginFrame");
  const webViewStatusOpen = (broadSeq: string) => {
    const domainText = `${window.location.protocol}//${window.location.host}`;
    const userId = "";
    const nickName = "";
    android.openWindow(`${domainText}/stream/${broadSeq}?userId=${userId}&nickName=${nickName}&loginUrl=${loginUrl}`);
  };

  return (
    <>
      <Grid item sx={{ positon: "relative", width: 196 }}>
        <Card className="card-container" onClick={() => enterChannel(props.item)}>
          <CardActionArea>
            <div>
              <video
                src={props.item.clip_url}
                poster={channelImgUrl}
                height="320"
                playsInline
                muted
                autoPlay
                loop
              ></video>

              {streamInfo?.state === "LIVE" ? (
                props.item.broad_status === "START" ? (
                  <>
                    <div className="card-live-viewcount">
                      {cUtils.numericComma(streamInfo?.viewerCount) + "명 시청"}
                    </div>
                    <div className="card-live">LIVE</div>
                  </>
                ) : (
                  <>
                    <div className="card-live-viewcount">
                      {cUtils.numericComma(streamInfo?.viewerCount) + "명 시청"}
                    </div>
                    <div className="card-live-fault">LIVE</div>
                  </>
                )
              ) : props.item.broad_status === "START" && streamInfo === undefined ? (
                <>
                  <div className="card-live-fault">LIVE</div>
                </>
              ) : props.item.broad_status === "VOD" ? (
                <div className="card-vod">VOD</div>
              ) : props.item.broad_status === "STOP" ? (
                <div className="card-stop">STOP</div>
              ) : (
                <></>
              )}
              {channelInfo?.total_views !== undefined && (
                <div className="card-live-totalview">
                  {"누적 " + cUtils.numericComma(channelInfo?.total_views) + "명"}
                </div>
              )}
              {props.item.broad_status === "VOD" ? (
                <div className="card-tm-vod">
                  {cUtils.dateFormat(props.item.broad_start_tm, "YYYYMMDDHHmmss", "MM/DD")}
                </div>
              ) : (
                <div className="card-tm">{cUtils.dateFormat(props.item.broad_start_tm, "YYYYMMDDHHmmss", "HH:mm")}</div>
              )}
            </div>
            <CardContent sx={{ p: 0 }}>
              <div className="card-title">{props.item.broad_title}</div>
              {/* <div className="card-desc">{props.item.broad_desc}</div> */}
              <div className="card-host">
                <Stack sx={{ width: "100%" }} direction="row" spacing={1}>
                  <Avatar
                    alt="Host Icon"
                    sx={
                      props.item.host_picture !== ""
                        ? { width: "20px", height: "20px" }
                        : { backgroundColor: "#07582e" }
                    }
                    src={props.item.host_picture}
                  ></Avatar>
                  <Typography sx={{ fontSize: "12px" }}>{props.item.host_name}</Typography>
                </Stack>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default ClientChannelCard;
