import { useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { userState } from "../../interface/MainInterface";
import { HttpChannelApi, PutBroadNotice } from "../../service/channel-api";
import { appConfig } from "../../config/Config";
import { CommonUtils } from "../../service/common_utils";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  broadInfo: any;
  getChannelInfo: any;
  sendIoTMessageMeta: any;
}
const TabNotice = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const cUtils = new CommonUtils();
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState("");

  const setNoticeInfo = async (_broadInfo: any) => {
    setNotice(_broadInfo.broad_notice);
  };

  // 필드 초기화
  const initForm = () => {
    setNotice("");
  };

  const saveBroadNotice = async () => {
    setLoading(true);
    const param: PutBroadNotice = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_notice: notice,
    };
    const res = await chApi.put_broad_notice(param);
    if (res.result_code === "200") {
      props.getChannelInfo(props.broadInfo.pk, props.broadInfo.broad_seq);
      props.sendIoTMessageMeta("reloadChannelInfo");
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setNoticeInfo,
    initForm,
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
        {/* 본문 영역 */}
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={{ width: "100%" }}>
            <Stack sx={{ width: "100%" }} direction={{ xs: "column" }} spacing={2}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="txtNotice"
                  multiline
                  rows={23}
                  variant="filled"
                  size="small"
                  value={notice}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setNotice(e.target.value);
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className="bt-md"
                fullWidth
                onClick={() => {
                  saveBroadNotice();
                }}
              >
                공지사항 저장하기
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Stack>
      <LoadingCircle loading={loading} />
    </Box>
  );
};

export default forwardRef(TabNotice);
