import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import queryString from "query-string";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

// login
import AskLoginDialog from "../../../service/auth/AskLoginDialog";

// chat
import ChatVerNoSdk from "./ChatVerNoSdk";
import ChatVerVod from "./ChatVerVod";
import IoTClient from "../../../service/iot/IoTClient";

// ivs
import { LiveIVSPlayer, player as livePlayer } from "./LiveIVSPlayer";
import { ClientIVSPlayer, player as rehearsalPlayer } from "./ClientIVSPlayer";
import { VodIVSPlayer, player as vodPlayer } from "./VodIVSPlayer";
import { VodIVSPlayerHigh, player as vodPlayerHigh } from "./VodIVSPlayerHigh";
import BroadWait from "./BroadWait";
import BroadStop from "./BroadStop";

// live menu
import LiveHeader from "./LiveHeader";
import LiveFooter from "./LiveFooter";
import LiveCoupon from "./LiveCoupon";
import LiveNoticeQnA from "./LiveNoticeQnA";
import LiveChatTF from "./LiveChatTF";
import LiveGame from "./LiveGame";
import LiveGameDraw from "./LiveGameDraw";
import ModalAskPassword from "./ModalAskPassword";
import StreamErrorNotice from "./StreamErrorNotice";

// api
import { HttpStatisticApi } from "../../../service/statistic-rest-api";
import { HttpGameApi } from "../../../service/game-rest-api";
import { HttpLiveChannelApi } from "../../../service/live-channel-rest-api";
import { userState, channelInfo, emptySetting, deviceInfo } from "../../../interface/MainInterface";

// common
import { appConfig } from "../../../config/Config";
import { CommonUtils } from "../../../service/common_utils";

import { useInterval } from "../../../utilities/UseInterval";

import Toast2 from "../../../utilities/Toast2";

import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

let channelParams: channelInfo = {
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_status: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_notice: "",
  broad_prod_list: [],
  broad_cover_url: "",
  vod_url: "",
  vod_started_at: "",
  vod_start_sec: 0,
  vod_end_sec: 0,
  high_start_sec: 0,
  most_concurrent_view_timestamp: "",
  ivs_channel_arn: "",
  ivs_stream_key: "",
  ivs_ingest_endpoint: "",
  ivs_playback_url: "",
  chime_channel_arn: "",
  chime_admin_arn: "",
  total_likes: 0,
  total_views: 0,
  password: "",
  extra_type: "",
  progress_store: "",
  host_id: "",
  host_name: "",
  host_picture: "",
  channel_img: "",
  channel_img_url: "",
  channel_template: false,
  setting: emptySetting,
};

let lastConcurrentView: number = 0;
let isFirst = true;

const tmpList: any = [
  {
    productId: "40A0044509",
    name: "라이브커머스테스트",
    salePrice: 5000,
    basePrice: 5000,
    stock: 10,
    soldout: false,
    thumbImageUrl: "https://hdimagedev.thehyundai.com/static/0/5/4/04/A0/40A0044509_0_300.jpg",
    detailUrl: "/front/pda/itemPtc.thd?slitmCd=40A0044509",
    cartUrl: "",
  },
  {
    productId: "40A0063602",
    name: "LIVE NFT 테스트 옥션",
    salePrice: 45000,
    basePrice: 45000,
    stock: 10,
    soldout: false,
    thumbImageUrl: "https://hdimagedev.thehyundai.com/static/0/6/3/06/A0/40A0063602_0_300.jpg",
    detailUrl: "/front/pda/itemPtc.thd?slitmCd=40A0063602",
    cartUrl: "",
  },
  {
    productId: "40A0044544",
    name: "라이브커머스1111",
    salePrice: 2000,
    basePrice: 2000,
    stock: 10,
    soldout: true,
    thumbImageUrl: "https://hdimagedev.thehyundai.com/static/4/5/4/04/A0/40A0044544_0_300.jpg",
    detailUrl: "/front/pda/itemPtc.thd?slitmCd=40A0044544",
    cartUrl: "",
  },
];

const _deviceInfo: deviceInfo = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

const userStateFormat: userState = {
  id: "",
  nickName: "",
  picture: "",
  isAuth: false,
  isAdmin: false,
  isSuperAdmin: false,
  userArn: "",
};

const liveChannalApi = new HttpLiveChannelApi();
const gameApi = new HttpGameApi();
const statisticApi = new HttpStatisticApi();

const LiveTheHyundai = (props: propsType) => {
  const screenMode: string = "H";
  const cUtils = new CommonUtils();
  const { broadseq } = useParams();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const toastRef: any = useRef();
  const iotRef: any = useRef();
  const liveChatRef: any = useRef();
  const liveChatTFRef: any = useRef();
  const liveNoticeRef: any = useRef();
  const liveHeaderRef: any = useRef();
  const liveFooterRef: any = useRef();
  const liveGameRef: any = useRef();

  const [endLoadInfo, setEndLoadInfo] = useState(false);
  const [videoStatus, setVideoStatus] = useState("");
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const [chatUrl, setChatUrl] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [controlOpen, setControlOpen] = useState(true);
  const [chatFontColor, setChatFontColor] = useState("rgb(255, 255, 255)");

  const [likeCount, setLikeCount] = useState(0);
  const [totalViewCount, setTotalViewCount] = useState(0);

  const [playerID, setPlayerID] = useState("");
  const [info, setInfo] = useState(channelParams); // 채널정보
  const [broadStatus, setBroadStatus] = useState(""); // 방송의 상태
  const [streamState, setStreamState] = useState(""); // 스트림 상태
  const [videoPosition, setVideoPosition] = useState(""); // 비디오 재생위치
  const [braodWait, setBroadWait] = useState(false); // 예약된 방송인지 확인
  const [remainTime, setRemainTime] = useState(""); // 방송 시작까지 남은시간계산

  const [vodType, setVodType] = useState("full");
  const [haveHighlight, setHaveHighlight] = useState(false);
  const [vodStartSecFull, setVodStartSecFull] = useState(0);
  const [vodEndSecFull, setVodEndSecFull] = useState(0);
  const [vodStartSecHigh, setVodStartSecHigh] = useState(-1);
  const [vodEndSecHigh, setVodEndSecHigh] = useState(-1);
  const [muted, setMuted] = useState(true);

  // 방송 비밀번호 확인
  const [askLogin, setAskLogin] = useState(false);
  const [askPwdModalCommand, setAskPwdModalCommand] = useState("INIT");
  const [passwordConfirm, setPasswordConfirm] = useState(false);

  // 더현대닷컴 쿠폰, 상품목록 처리용
  const [coupons, setCoupons] = useState<any>([]);
  const [prodList, setProdList] = useState<any>([]);

  // 방송 입장알림용
  const [userList, setUserList] = useState<any>([]);
  const [alertUser, setAlertUser] = useState(false);
  const [alertUserText, setAlertUserText] = useState("");

  // 동시시청자 접속돌파 알림
  const [concurrentView, setConcurrentView] = useState(0);
  const [concurrentViewText, setConcurrentViewText] = useState("");
  const [concurrentViewFlag, setConcurrentViewFlag] = useState(false);

  // Q&A
  const [newQnAMsg, setNewQnAMsg] = useState<any>(null);

  // Game Draw
  const [gameInfo, setGameInfo] = useState<any>({});
  const [gameDraw, setGameDraw] = useState(false);
  const [gameWinner, setGameWinner] = useState<any>([]);
  const [gameWinnerDetail, setGameWinnerDetail] = useState<any>([]);
  const [gameSk, setGameSk] = useState("");

  const [videoMode, setVideoMode] = useState(1); // 0: 가로모드 영상, 1: 세로모드 영상

  const [openResolutionInfo, setOpenResolutionInfo] = useState(false);
  const [resolutionList, setResolutionList] = useState<any>([]);
  const [latency, setLatency] = useState(0.0);
  const [isLiveLowLatency, setIsLiveLowLatency] = useState("false");

  const [userState, setUserState] = useState<userState>(userStateFormat);

  // 리허설 처리용 (방송 start가 아니라도 비디오 play)
  const [rehearsal, setRehearsal] = useState(false);

  // 플레이환경
  const [screenEnv, setScreenEnv] = useState("mo");

  const [ivsInitStart, setIvsInitStart] = useState(false);

  const [noChat, setNoChat] = useState(false);

  // 최초 페이지 로딩
  useEffect(() => {
    if (!checkQueryStrings()) windowClose();
    setPageInit(); // 채널정보 및 채팅방 입장
    return () => {
      setEndLoadInfo(false);
      setVideoStatus("");
      setHiddenMenu(false);
      setChatUrl("");
      setChatOpen(false);
      setControlOpen(true);
      setChatFontColor("rgb(255, 255, 255)");
      setLikeCount(0);
      setTotalViewCount(0);
      setPlayerID("");
      setInfo(channelParams); // 채널정보
      setBroadStatus(""); // 방송의 상태
      setStreamState(""); // 스트림 상태
      setVideoPosition(""); // 비디오 재생위치
      setBroadWait(false); // 예약된 방송인지 확인
      setRemainTime(""); // 방송 시작까지 남은시간계산
      setVodType("full");
      setHaveHighlight(false);
      setVodStartSecFull(0);
      setVodEndSecFull(0);
      setVodStartSecHigh(-1);
      setVodEndSecHigh(-1);
      setMuted(true);
      setAskLogin(false);
      setAskPwdModalCommand("INIT");
      setPasswordConfirm(false);
      setCoupons([]);
      setProdList([]);
      setUserList([]);
      setAlertUser(false);
      setAlertUserText("");
      setConcurrentView(0);
      setConcurrentViewText("");
      setConcurrentViewFlag(false);
      setNewQnAMsg(null);
      setGameInfo({});
      setGameDraw(false);
      setGameWinner([]);
      setGameWinnerDetail([]);
      setGameSk("");
      setVideoMode(1);
      setOpenResolutionInfo(false);
      setResolutionList([]);
      setLatency(0.0);
      setIsLiveLowLatency("false");
      setUserState(userStateFormat);
      setRehearsal(false);
      setScreenEnv("mo");
    };
  }, []);

  // 쿼리 파라미터 확인
  const checkQueryStrings = () => {
    if (queryParams.mode !== undefined) {
      if (queryParams.mode === "rehearsal") setRehearsal((rehearsal) => true);
    }
    if (queryParams.screenMode !== undefined) {
      if (queryParams.screenMode === "pc") setScreenEnv((screenEnv) => "pc");
    }
    if (queryParams.userId === undefined) {
      window.alert("호출 URL의 QueryString에 필요한 Parameter가 없습니다.[userId]");
      return false;
    } else {
      return true;
    }
  };

  const setPageInit = async () => {
    loadChannelInfoFast(); // 채널정보 조회
    procUserJoin(); // 사용자 세션 설정
    loadStatisticInfo(); // 좋아요, 누적시청자 조회
    loadChannelGameList(); // 게임목록 조회
  };

  // 채널정보 조회
  const loadChannelInfoFast = async () => {
    if (broadseq !== undefined) {
      const channelParam: any = {
        broad_seq: broadseq,
      };
      const res = await liveChannalApi.get_channel_info(channelParam);
      const channelInfo = res.response.channel_info;
      console.log("channelInfo : ", channelInfo);

      checkChannelPwd(channelInfo); // 폐쇄 채널 코드 입력 쿠키 조회
      checkVodStatus(channelInfo); // VOD 시작시간 세팅
      setInfo(() => channelInfo); // 채털정보
      setBroadStatus(channelInfo.broad_status); // 방송상태
      checkBroadStartTime(channelInfo); // 방송시간 체크하기
      setEndLoadInfo(true);
    } else {
      console.error("The channel is undefined");
    }
  };

  const loadChannelInfoOnly = async () => {
    if (broadseq !== undefined) {
      const channelParam: any = {
        broad_seq: broadseq,
      };
      const res = await liveChannalApi.get_channel_info(channelParam);
      const channelInfo = res.response.channel_info;
      console.log("loadChannelInfoOnly finish");

      checkBroadStartTime(channelInfo); // 방송시간 체크하기
      setInfo((info) => channelInfo);
    } else {
      console.error("The channel is undefined");
    }
  };

  // 채팅방 입장 전 사용자 정보 체크
  const procUserJoin = () => {
    let userId = uuidv4(); // 임시 아이디
    const cookieTempId = window.localStorage.getItem("thehyundaiLiveTempId");
    if (cookieTempId !== undefined && cookieTempId !== "" && cookieTempId !== null) {
      userId = cookieTempId;
    } else {
      window.localStorage.setItem("thehyundaiLiveTempId", userId); //임시 발급 아이디 쿠키에 저장 후 재사용
    }
    let userNickName = "";
    if (queryParams.userId && queryParams.userId !== "tester@test.com") {
      userId = queryParams.userId;
      userNickName = queryParams.nickName;
      if (userNickName === "" || userNickName === undefined) userNickName = queryParams.userId.split("@")[0];
    }
    setPlayerID(userId);
    setUserState({
      id: userId,
      nickName: userNickName,
      picture: "",
      isAuth: false,
      isAdmin: false,
      isSuperAdmin: false,
      userArn: userId,
    });
  };

  const loadStatisticInfo = async () => {
    if (broadseq !== undefined) {
      const param: any = {
        broad_seq: broadseq,
      };
      const res = await statisticApi.get_statistic_info(param);
      if (res.code === "200") {
        if (!cUtils.isEmptyObj(res.response.statistic_info)) {
          setLikeCount(res.response.statistic_info.total_likes);
          setTotalViewCount(res.response.statistic_info.total_views);
        }
      }
    } else {
      console.error("loadStatisticInfo - The channel is undefined");
    }
  };

  const checkChannelPwd = (channelInfo: any) => {
    var cookie_private_channel = window.localStorage.getItem(appConfig.recent_enter_private_channel);
    if (cookie_private_channel === channelInfo.broad_seq) {
      // 있으면: 입력창 띄우지 말기
      setPasswordConfirm(true);
    } else {
      if (channelInfo.password !== "" && !passwordConfirm) {
        setAskPwdModalCommand("OPEN_MODAL"); // Private 채널이 경우 패스워드 입력
      }
    }
  };

  const checkVodStatus = (channelInfo: any) => {
    if (
      // VOD 상태이며 하이라이트가 있는 경우
      channelInfo.most_concurrent_view_timestamp !== "" &&
      channelInfo.vod_started_at !== "" &&
      channelInfo.most_concurrent_view_timestamp > channelInfo.vod_started_at &&
      channelInfo.broad_status === "VOD"
    ) {
      if (channelInfo.high_start_sec > 0) {
        setVodStartSecHigh(channelInfo.high_start_sec);
        setVodEndSecHigh(channelInfo.high_start_sec + 150);
      } else {
        const date1 = dayjs(channelInfo.most_concurrent_view_timestamp, "YYYYMMDDHHmmss");
        const date2 = dayjs(channelInfo.vod_started_at, "YYYYMMDDHHmmss");
        const diff = date1.diff(date2, "s");
        const enddate = date1.add(150, "s").format("YYYYMMDDHHmmss");
        const endSec = dayjs(enddate, "YYYYMMDDHHmmss").diff(date2, "s");
        if (diff > 0) {
          setVodStartSecHigh(diff);
          setVodEndSecHigh(endSec);
        }
      }

      setVodType("highlight");
      setHaveHighlight(true);
    }
    setVodStartSecFull(channelInfo.vod_start_sec);
    setVodEndSecFull(channelInfo.vod_end_sec);
  };

  const checkBroadStartTime = (channelInfo: any) => {
    const now = dayjs();
    const startTime = dayjs(channelInfo.broad_start_tm, "YYYYMMDDHHmmss");
    const dateDiff = startTime.diff(now, "s");
    if (dateDiff > 0 && channelInfo.broad_status !== "VOD" && channelInfo.broad_status !== "START" && queryParams.mode !== "rehearsal")
      setBroadWait(true);
    else setBroadWait(false);
  };

  // 방송이 송출전이면 방송 남은시간 계산
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(info.broad_start_tm);
      if (remainSec > 0) setRemainTime(cUtils.convertRemainText(remainSec));
      else {
        loadChannelInfoOnly();
      }
    },
    braodWait ? delay : null
  );

  // 화면 클릭시 메뉴 숨기기
  const fncBodyClick = (e: any) => {
    const width = window.innerWidth;
    if (width < 200) return;
    if (
      e.target === document.getElementById("tab-screen-content") ||
      e.target === document.getElementsByClassName("thd-chat-list")[0] ||
      e.target === document.getElementsByClassName("live-content-chat")[0] ||
      e.target === document.getElementsByClassName("live-coupon-root")[0] ||
      e.target === document.getElementById("divheartActions")
    ) {
      setHiddenMenu((hiddenMenu) => !hiddenMenu);
      setChatOpen(false);
      setControlOpen(true);
      liveChatTFRef.current?.handleHiddenForm(true);
    }
  };

  // 사용자 도착 알림
  const chatInitCallback = (result: boolean) => {
    if (queryParams.userId !== "" && queryParams.userId !== undefined) {
      // 입장한 사용자의 id와 함께 데이터를 전송한다.
      const topic = `live/${broadseq}/metadata`;
      const payload = { message: "userComming", id: queryParams.userId };
      iotRef.current?.sendIoTMessage(topic, payload);
    }
  };

  // 채팅버튼 클릭시
  const fncBtnChatClick = async (status: boolean) => {
    if (queryParams.userId !== "") {
      liveChatTFRef.current?.handleHiddenForm(false);
      setChatOpen((chatOpen) => status);
      setHiddenMenu((hiddenMenu) => true);
      setTimeout(() => {
        liveChatRef.current?.scrollToBottom();
      }, 200);
    } else {
      goLoginPage();
    }
  };

  // 채팅 메세지 전송
  const sendMessage = async (msg: string, meta: string) => {
    liveChatRef.current?.sendMessage(msg);
  };

  const sendIoTMessage = async (topic: string, chatPayload: any) => {
    iotRef.current?.sendIoTMessage(topic, chatPayload);
  };

  useEffect(() => {
    if (userState.id !== "" && userState.id !== undefined) {
      putMetaFunc("view"); //// 24.01.04 최초 로딩 시 바로 조회수 업데이트.허정은
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id]);

  // 좋아요, 누적시청자 등 시스템 메시지 처리용
  async function putMetaFunc(metaName: string) {
    try {
      // IoT 전송
      if (info.broad_status !== "VOD") {
        if (metaName !== "like" && metaName !== "view") {
          const topic = `live/${broadseq}/metadata`;
          iotRef.current?.sendIoTMessageMeta(topic, metaName);
        }
      } else {
        const topic = `live/${broadseq}/metadata`;
        iotRef.current?.sendIoTMessageMeta(topic, metaName);
      }
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
    }
    if (metaName === "like" || metaName === "view") {
      let _os = "";
      if (_deviceInfo.osName === "iOS" || _deviceInfo.osName === "Android") {
        if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
          _os = `${_deviceInfo.osName}_app`;
        } else {
          _os = _deviceInfo.osName;
        }
      } else {
        _os = "PC";
      }

      // 통계 테이블 저장
      const param: any = {
        broad_seq: broadseq,
        prot: metaName,
        user_id: userState.id,
        member: queryParams.userId && queryParams.userId !== "tester@test.com" ? queryParams.userId : "",
        os: _os,
      };

      await statisticApi.update_count(param);
    }
  }

  // IVS 초기화 완료
  const ivsInitFinished = () => {
    if (isFirst) {
      isFirst = false;

      // 음소거상태 알림
      if (muted) liveHeaderRef.current?.openAlertMuted();
      liveHeaderRef.current?.initStartIvs();
    }
  };

  // IVS Player의 상태 콜백
  const videoStatusCallback = (status: string) => {
    if (status === "ivsInitStart") {
      setTimeout(() => {
        setIvsInitStart(true);
      }, 3000);
    } else {
      setVideoStatus(status);
      if (status === "onStatePlaying") ivsInitFinished();
      if (status === "unSupported")
        toastRef.current?.toast(
          "지원하지 않는 브라우저입니다. 다른 브라우저로 시도하시거나, 더현대닷컴 APP으로 이용 바랍니다.",
          "error",
          5000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
    }
  };

  useEffect(() => {
    if (broadStatus === "START" && !(videoStatus === "onStatePlaying" || videoStatus === "onReady" || videoStatus === "")) {
      console.log("방송을 가져오지 못했습니다.");
      setNoChat(true);
    }
    if (broadStatus === "START" && videoStatus === "onStatePlaying") {
      setNoChat(false);
    }
  }, [videoStatus, broadStatus]);

  // VOD의 경우 비디오의 재생위치 콜백
  const videoPositionCallback = (event: string, pos: number) => {
    const videoPos = dayjs(info.vod_started_at).add(pos, "s").format("YYYYMMDDHHmmss"); // Sec to datetime
    setVideoPosition(videoPos);
  };

  // 볼륨 컨트롤
  const toggleMute = () => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

    const muteNext = !player.isMuted();
    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadseq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatRef.current?.processChannelMessage(payLoad);
      } else if (payLoad.topic === "game") {
        // 게임 메세지
        if (payLoad.prot === "start") {
          loadChannelGameInfo(payLoad.game_seq);
        } else if (payLoad.prot === "draw") {
          loadChannelGameDrawInfo(payLoad.game_seq);
        }
      }
    } else if (topic === `live/${broadseq}/metadata`) {
      if (payLoad.message === "view" && payLoad.senderId !== clientId) viewCountCallback();
      if (payLoad.message === "like") likeCountCallback();
      if (payLoad.message === "like" && payLoad.senderId !== clientId) newLikeUpCallback();
      if (payLoad.message === "start_stream") setStreamStart();
      if (payLoad.message === "ready_stream") setStreamReady();
      if (payLoad.message === "stop_stream") setStreamStop();
      if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
      if (payLoad.message === "userComming") setUserComming(payLoad.id);
      if (payLoad.message === "qna") setQnAArrive(payLoad);
      if (payLoad.message === "apply") applyCallback();
      if (payLoad.message === "chatFontColor") setChatFontColor(payLoad.color);
    }
    if (topic === `stream/${broadseq}/viewerCount`) {
      // 라이브 스트림 동시 시청자 건수
      if (payLoad.viewerCount > concurrentView) {
        setConcurrentView(payLoad.viewerCount);
      }
    }
    if (topic === `stream/${broadseq}/stream_state`) {
      // 라이브 스트림 상태변경
      streamEventCallback(payLoad.event_name);
    }
    if (topic === `game/${broadseq}`) {
      // 게임 메세지
      if (payLoad.prot === "start") {
        loadChannelGameInfo(payLoad.game_seq);
      } else if (payLoad.prot === "draw") {
        loadChannelGameDrawInfo(payLoad.game_seq);
      }
    }
    if (topic === `chat/${broadseq}/block`) {
      liveChatRef.current?.addBlockList(payLoad);
    }
  }

  // 이미 화면 로딩이 끝났는데 영상이 아직 출력중이지 않으면 영상이 시잘될때 스트림의 상태에 따라서 비디오를 오토플레이한다.
  const streamEventCallback = (event_name: string) => {
    setStreamState(event_name);
  };

  // 게임 응모 알림
  const btnApplyClickCallback = () => {
    putMetaFunc("apply");
  };

  // 우하단 레이어에서 좋아요 버튼 클릭시 부모창 put meta 함수 호출
  const btnLikeClickCallback = () => {
    putMetaFunc("like");
  };

  // 좋아요 버튼 클릭시 상단 레이어 전달 -> 좋아요 count 증가
  const likeCountCallback = () => {
    liveHeaderRef.current?.addLiveCount();
  };

  // 다른 사람이 좋아요 누른것을 우하단 레이어에 전달 -> 하트 이벤트 발생
  const newLikeUpCallback = () => {
    liveFooterRef.current?.addNewLiveCount();
  };

  // 게임 응모 클릭 시 -> 응모 count 증가
  const applyCallback = () => {
    liveGameRef.current?.applyNumCallback();
  };

  // 새로운 시청자 인입시 IVS or Chime에서 이벤트를 수신 받기위한 콜백
  const viewCountCallback = () => {
    liveHeaderRef.current?.addViewCount();
  };

  // Private 방송 패스워드 입력 프로세스
  const askPwdModalProcDone = (res: any) => {
    if (res === "SUCCESS") {
      // 1회 패스워드 성공시 쿠키 저장
      if (broadseq !== undefined) window.localStorage.setItem(appConfig.recent_enter_private_channel, broadseq);
      setPasswordConfirm(true);
    } else if (res === "CANCEL") {
      setPasswordConfirm(false);
      windowClose();
    }
  };

  // 로그인 선택시 로그인 페이지 호출
  const askLoginDone = (res: any) => {
    if (res === "YES") {
      goLoginPage();
    } else {
      setAskLogin(false);
    }
  };

  // 로그인 페이지 이동
  const goLoginPage = () => {
    window.parent.postMessage({ msg: "needLogin" }, "*");
  };

  // 창닫기
  const windowClose = () => {
    // 더현대 부모창 방송목록으로 이동
    console.log("SEND goBroadList");
    postMsg({ msg: "goBroadList" }, "*");
  };

  // 방송상태 처리(방송중, 준비중 등)
  const setStreamStart = () => {
    setBroadStatus((broadStatus) => "START");
    loadChannelInfoOnly();
  };
  const setStreamReady = () => {
    setBroadStatus((broadStatus) => "READY");
    loadChannelInfoOnly();
  };
  const setStreamStop = () => {
    setBroadStatus((broadStatus) => "STOP");
  };

  // [더현대닷컴 전용] 쿠폰, 상품리스트 처리
  window.addEventListener(
    "message",
    function (e) {
      if (e.data.response !== undefined) {
        if (e.data.response.coupons) {
          setCoupons(e.data.response.coupons);
        } else if (e.data.response.prodList) {
          setProdList(e.data.response.prodList);
        }
      }
    },
    false
  );

  // [더현대닷컴 전용] 부모창에 메세지 보내기
  const postMsg = (payload: any, target: any) => {
    window.parent.postMessage(payload, target);
  };

  // 유저입장 알림
  const setUserComming = (inputId: string) => {
    if (info.broad_status !== "VOD") {
      setUserList((userList: any) => [...userList, inputId]);
      if (!alertUser) setAlertUser(true);
    }
  };

  // 유저입장 알림
  useEffect(() => {
    if (alertUser) {
      const oldUserList: any = [...userList];
      if (oldUserList.length > 1) {
        setAlertUserText(cUtils.maskingChar(oldUserList[0]) + "님외 " + (oldUserList.length - 1) + "명이 들어왔습니다.");
      } else {
        setAlertUserText(cUtils.maskingChar(oldUserList[0]) + "님이 들어왔습니다.");
      }

      let tempUserList: any = [...userList];
      for (let id of oldUserList) {
        for (let i = 0; i < tempUserList.length; i++) {
          if (tempUserList[i] === id) {
            tempUserList.splice(i, 1);
            i--;
          }
        }
      }
      setUserList((userList: any) => [...tempUserList]);
      setTimeout(() => {
        setAlertUser((alertUser) => false);
        setAlertUserText("");
      }, 3000);
    }
  }, [alertUser]);

  // QnA 알림
  const setQnAArrive = (payload: any) => {
    const tmpQnA = {
      qContent: payload.q,
      qUserName: payload.qSender,
      aContent: payload.a,
    };
    setNewQnAMsg(tmpQnA);
  };

  // 동시접속자 알림
  useEffect(() => {
    if (concurrentView > 0) {
      const nowConcurrentView = Math.floor(concurrentView / 10);
      if (nowConcurrentView > lastConcurrentView) {
        setConcurrentViewText("동시 접속자가 " + nowConcurrentView * 10 + "명을 돌파했습니다.");
        lastConcurrentView = nowConcurrentView;
        setConcurrentViewFlag(true);
      }
    }
  }, [concurrentView]);

  useEffect(() => {
    if (concurrentViewFlag === true) {
      setTimeout(() => {
        setConcurrentViewFlag(false);
      }, 5000);
    }
  }, [concurrentViewFlag]);

  // APP Floating controls
  const handleNoFloating = useCallback(() => {
    const width = window.innerWidth;
    if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
      if (width > 200) {
        window.location.href = "thehyundai://showpopup";
      }
    }
  }, []);

  const handleYesFloating = useCallback(() => {
    const width = window.innerWidth;
    if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
      if (width > 200) {
        window.location.href = "thehyundai://closepopup";
      }
    }
  }, []);

  // 브라우저 이벤트
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 화면 사이즈로 플로팅 상태 파악 후 메뉴 히든처리
  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width < 200) {
      // floating 메뉴일때 메뉴 감추기
      setHiddenMenu((hiddenMenu) => true);
      setChatOpen(false);
      setControlOpen(false);
      // setNoChat(true);
      liveChatTFRef.current?.handleHiddenForm(true);
    } else {
      // Floating 해제시 처리
      if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
        if (height > 500) {
          handleYesFloating();
          setHiddenMenu((hiddenMenu) => false);
          // setNoChat(false);
          setTimeout(() => {
            liveChatRef.current?.scrollToBottom();
          }, 200);
        } else {
          handleNoFloating();
          setHiddenMenu((hiddenMenu) => true);
          setControlOpen(true);
          // setNoChat(true);
        }
      }
    }
  };

  // VOD 하이라이트, 전체보기
  const toggleVodType = () => {
    if (vodType === "highlight") setVodType((vodType) => "full");
    else setVodType((vodType) => "highlight");
  };

  // IoT 연결 성공
  const iotConnectEnd = () => {
    console.info("iot Connection Success");
    // putMetaFunc("view"); // 시청자 View + 1
  };

  // IoT 연결 끊김
  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  // 해상도선택
  const handleResolution = () => {
    resolutionDrawerOpen();
  };

  // 해상도 선택
  const resolutionDrawerClose = () => setOpenResolutionInfo(false);
  const resolutionDrawerOpen = () => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

    let liveQlty = player.getQualities();
    const liveCurrQlty = player.getQuality();

    // 해상도별로 정렬
    liveQlty.sort((a: any, b: any) => {
      if (a.height > b.height) return -1;
      if (a.height < b.height) return 1;
      return 0;
    });

    setResolutionList(
      liveQlty.map((obj: any, index: number) => {
        return (
          <ListItem key={`quality${obj.name}`} disablePadding>
            <ListItemButton onClick={() => setbtnQuality(obj)}>
              {obj.name === liveCurrQlty.name ? <ListItemText primary={obj.name + " *"} /> : <ListItemText primary={obj.name} />}
            </ListItemButton>
          </ListItem>
        );
      })
    );
    setLatency(player.getLiveLatency());
    setIsLiveLowLatency("" + player.isLiveLowLatency());
    setOpenResolutionInfo(true);
  };

  const setbtnQuality = (obj: any) => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;
    player.setQuality(obj);
    setOpenResolutionInfo(false);
  };

  const toggleAuto = () => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;
    player.setAutoQualityMode(true);
  };

  const isAutoMode = () => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;
    if (videoStatus !== "onStatePlaying") return false;
    if (player !== null) {
      if (player.isAutoQualityMode()) return true;
    }
    return false;
  };

  // 방송 입장 시 진행 중인 게임 리스트 조회
  const loadChannelGameList = async () => {
    if (broadseq !== undefined) {
      const gameParam: any = {
        broad_seq: broadseq,
      };
      const gameRes = await gameApi.get_live_game_info(gameParam);
      if (!cUtils.isEmptyObj(gameRes.response.game_info)) {
        setGameInfo(gameRes.response.game_info);
        setGameSk((gameSk) => gameRes.response.game_info.sk);
      }
    } else {
      // console.error("loadChannelGameList - The channel is undefined");
    }
  };

  // 방송 도중 진행한 게임 목록 조회
  const loadChannelGameInfo = async (sk: string) => {
    if (broadseq !== undefined && sk !== "") {
      const gameParam: any = {
        broad_seq: broadseq,
        sk: sk,
      };
      const gameRes = await gameApi.get_game_info(gameParam);
      if (!cUtils.isEmptyObj(gameRes.response.game_info)) {
        setGameInfo(gameRes.response.game_info);
        setGameSk((gameSk) => gameRes.response.game_info.sk);
      }
    } else {
      console.error("loadChannelGameInfo - The channel is undefined");
    }
  };

  // 당첨자 정보 조회
  const loadChannelGameDrawInfo = async (sk: string) => {
    if (broadseq !== undefined && sk !== "") {
      const gameParam: any = {
        broad_seq: broadseq,
        sk: sk,
      };
      const gameRes = await gameApi.get_draw_result(gameParam);
      if (gameRes.code === "200") {
        const userIdList = [];
        const userList = [];
        for (const draw_result of gameRes.response.result) {
          let user_id = draw_result.user_id;
          userIdList.push(user_id);
          userList.push({ user_id: user_id, last_no: draw_result.last_no });
        }
        setGameWinner(userIdList);
        setGameWinnerDetail(userList);
        setGameDraw(true);
      } else {
        console.error("get_draw_result failed");
      }
    } else {
      console.error("The channel is undefined");
    }
  };

  // 당첨자 공지 후 데이터 초기화
  const drawEventCallback = () => {
    setGameSk((gameSk) => "");
    setGameDraw(false);
    setGameInfo({});
  };

  useEffect(() => {
    if (videoStatus === "onStatePlaying") {
      let player = livePlayer;
      if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
      if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
      if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

      const videoQlty = player.getQuality();
      if (!cUtils.isEmptyObj(videoQlty)) {
        if (videoQlty.height > videoQlty.width) setVideoMode(1); // 세로모드 영상 인입
        else setVideoMode(0); // 가로모드 영상 인입
      }
    }
  }, [videoStatus]);

  return (
    <>
      <div className="viewer-root">
        {!passwordConfirm && info.password !== "" && info.password !== undefined ? ( // Private 패스워드 입력창
          <div className="client-video no-scroll">
            <ModalAskPassword modalCommand={askPwdModalCommand} password={info.password} callBack={askPwdModalProcDone} />
          </div>
        ) : (
          <div className={videoMode === 1 ? "Live-Layout-wrapper small-layout" : "Live-Layout-wrapper"}>
            <div className={videoMode === 1 ? "Live-Layout-inner small-layout-inner" : "Live-Layout-inner"}>
              <div className="Live-Swipe-content">
                <div className="VideoPlayer-wrapper color-snow">
                  {braodWait && info.broad_seq !== "" ? (
                    <BroadWait remainTime={remainTime} broad_info={info} />
                  ) : broadStatus === "STOP" ? (
                    <BroadStop broad_info={info} />
                  ) : info.broad_status !== "VOD" ? (
                    !rehearsal ? (
                      <LiveIVSPlayer
                        broad_seq={info.broad_seq}
                        broad_status={broadStatus}
                        playbackUrl={info.ivs_playback_url}
                        streamState={streamState}
                        videoStatusCallback={videoStatusCallback}
                      />
                    ) : (
                      <ClientIVSPlayer
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        broad_status={broadStatus}
                        playbackUrl={info.ivs_playback_url}
                        streamState={streamState}
                        videoStatusCallback={videoStatusCallback}
                        muted={muted}
                        webViewStatus={props.webViewStatus}
                        rehearsal={rehearsal}
                      />
                    )
                  ) : (
                    <></>
                  )}
                  {info.broad_status === "VOD" && info.broad_seq !== "" ? (
                    vodType === "highlight" ? (
                      <VodIVSPlayerHigh
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        playbackUrl={info.vod_url}
                        vod_start_sec={vodStartSecHigh}
                        vod_end_sec={vodEndSecHigh}
                        videoStatusCallback={videoStatusCallback}
                        videoPositionCallback={videoPositionCallback}
                        hidden_menu={hiddenMenu}
                        muted={muted}
                        controlOpen={controlOpen}
                      />
                    ) : (
                      <VodIVSPlayer
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        playbackUrl={info.vod_url}
                        vod_start_sec={vodStartSecFull}
                        vod_end_sec={vodEndSecFull}
                        videoStatusCallback={videoStatusCallback}
                        videoPositionCallback={videoPositionCallback}
                        hidden_menu={hiddenMenu}
                        muted={muted}
                        controlOpen={controlOpen}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className={
                    hiddenMenu && info.broad_status === "VOD" ? "Tap-screen-wrapper color-snow bottom-50" : "Tap-screen-wrapper color-snow"
                  }
                >
                  <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                    <div className="live-header-root">
                      {info.broad_seq !== "" ? (
                        <LiveHeader
                          ref={liveHeaderRef}
                          broad_seq={info.broad_seq}
                          broad_title={info.broad_title}
                          broad_status={broadStatus}
                          video_status={videoStatus}
                          host_picture={info.host_picture}
                          host_name={info.host_name}
                          postMsg={postMsg}
                          tot_view_count={totalViewCount}
                          tot_like_count={likeCount}
                          hidden_menu={hiddenMenu}
                          vodType={vodType}
                          haveHighlight={haveHighlight}
                          toggleVodType={toggleVodType}
                          muted={muted}
                          toggleMute={toggleMute}
                          braodWait={braodWait}
                          handleResolution={handleResolution}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {info.broad_seq !== "" && broadStatus === "START" && !braodWait ? (
                      <div className="live-coupon-root">
                        <LiveCoupon
                          broad_seq={info.broad_seq}
                          user_id={queryParams.userId}
                          cust_no={queryParams.custNo}
                          userState={props.userState}
                          coupons={coupons}
                          postMsg={postMsg}
                          goLoginPage={goLoginPage}
                          hidden_menu={hiddenMenu}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="live-content-root">
                      <div
                        className={
                          hiddenMenu && !chatOpen ? "live-content-wrapper chat-bg-transparent" : "live-content-wrapper chat-bg-gradient"
                        }
                      >
                        {concurrentViewFlag === true && !hiddenMenu && !braodWait && broadStatus !== "STOP" ? (
                          <div className="live-user-comming-alert">
                            <div className="live-user-comming-alert-wrapper">
                              <div className="live-concurrent-view-body live-alert-box">
                                <Box>{concurrentViewText}</Box>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {alertUserText && !hiddenMenu ? (
                          info.broad_seq !== "" && info.broad_status !== "VOD" && !braodWait && broadStatus !== "STOP" ? (
                            <div className="live-user-comming-alert">
                              <div className="live-user-comming-alert-wrapper">
                                <div className="live-user-comming-alert-body live-alert-box">
                                  <Box>{alertUserText}</Box>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        <div className="live-content-chat live-content-chat-mobile">
                          {playerID !== "" && info.broad_status !== "VOD" && !braodWait && broadStatus !== "STOP" && (
                            <ChatVerNoSdk
                              ref={liveChatRef}
                              broad_seq={info.broad_seq}
                              broad_status={info.broad_status}
                              channelArn={info.chime_channel_arn}
                              adminArn={info.chime_admin_arn}
                              chat_open={chatOpen}
                              hidden_menu={hiddenMenu}
                              chatInitCallback={chatInitCallback}
                              userState={userState}
                              chatFontColor={chatFontColor}
                              sendIoTMessage={sendIoTMessage}
                              noChat={noChat}
                            />
                          )}
                          {playerID !== "" && info.broad_status === "VOD" && !braodWait && broadStatus !== "STOP" && (
                            <ChatVerVod
                              ref={liveChatRef}
                              broad_seq={info.broad_seq}
                              channelArn={info.chime_channel_arn}
                              adminArn={info.chime_admin_arn}
                              chatUrl={chatUrl}
                              chat_open={chatOpen}
                              screenMode={screenMode}
                              hidden_menu={hiddenMenu}
                              chatInitCallback={chatInitCallback}
                              player_id={playerID}
                              streamEventCallback={streamEventCallback}
                              videoPosition={videoPosition}
                              vod_started_at={info.vod_started_at}
                              userState={userState}
                              setting={info.setting}
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                            />
                          )}
                        </div>
                        {gameSk !== "" && !braodWait && broadStatus !== "STOP" ? (
                          <div className={hiddenMenu ? "display-none" : "live-content-game"}>
                            <LiveGame
                              ref={liveGameRef}
                              gameInfo={gameInfo}
                              userId={queryParams.userId}
                              custNo={queryParams.custNo}
                              lastNo={queryParams.lastNo}
                              applyClick={btnApplyClickCallback}
                              goLoginPage={goLoginPage}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {info.broad_seq !== "" && !braodWait && broadStatus !== "STOP" ? (
                          <div
                            className={
                              hiddenMenu
                                ? "display-none"
                                : info.broad_status === "VOD" && prodList.length === 0
                                ? "live-content-notice mb-17"
                                : "live-content-notice"
                            }
                          >
                            <LiveNoticeQnA
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                              newQnAMsg={newQnAMsg}
                              ref={liveNoticeRef}
                              broad_info={info}
                              videoMode={videoMode}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {gameDraw && !braodWait && broadStatus !== "STOP" ? (
                          <div className={hiddenMenu ? "display-none" : "live-game-draw-root"}>
                            <LiveGameDraw
                              gameWinner={gameWinner}
                              gameWinnerDetail={gameWinnerDetail}
                              drawEventCallback={drawEventCallback}
                              userId={queryParams.userId}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className={
                            info.broad_status === "VOD" && prodList.length === 0 ? "live-content-th-absolute" : "live-content-footer"
                          }
                        >
                          {info.broad_seq !== "" && !braodWait && broadStatus !== "STOP" ? (
                            <LiveFooter
                              ref={liveFooterRef}
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                              broad_seq={info.broad_seq}
                              broad_status={broadStatus}
                              user_id={queryParams.userId}
                              userState={props.userState}
                              like_click={btnLikeClickCallback}
                              hidden_menu={hiddenMenu}
                              sendMessage={sendMessage}
                              prodList={prodList}
                              postMsg={postMsg}
                              newQnAMsg={newQnAMsg}
                              openChatInput={fncBtnChatClick}
                              videoMode={videoMode}
                              screenEnv={screenEnv}
                              videoStatus={videoStatus}
                              rehearsal={rehearsal}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <LiveChatTF
                          ref={liveChatTFRef}
                          broad_status={info.broad_status}
                          user_id={queryParams.userId}
                          sendMessage={sendMessage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer
        PaperProps={{ elevation: 0, style: { borderRadius: "16px" } }}
        anchor="bottom"
        open={openResolutionInfo}
        onClose={resolutionDrawerClose}
        className="thd-live-more-body"
      >
        <Box
          sx={{
            width: "94%",
            height: "350px",
            bgcolor: "while",
            borderRadius: "16px",
          }}
          className="thd-live-more-body"
          onClick={resolutionDrawerClose}
        >
          해상도 선택
          <nav aria-label="Set Resolution">
            <List>
              <ListItem key={`auto`} disablePadding>
                <ListItemButton onClick={() => toggleAuto()}>
                  {isAutoMode() ? <ListItemText primary={"Auto *"} /> : <ListItemText primary={"Auto"} />}
                </ListItemButton>
              </ListItem>
              {resolutionList}
            </List>
          </nav>
          {broadStatus !== "VOD" ? (
            <>
              <div> 지연시간 : {latency} sec</div>
              <div> 저지연 모드 : {isLiveLowLatency}</div>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Drawer>
      {/* <div className="live-log">{videoStatus}</div> */}
      <AskLoginDialog command={askLogin} callBack={askLoginDone} />
      {broadseq !== undefined && (
        <IoTClient
          ref={iotRef}
          broadSeq={broadseq}
          iotRegion={appConfig.iotRegion}
          iotIdentityPollId={appConfig.iotIdentityPollId}
          iotEndPoint={appConfig.iotEndPoint}
          recieveIoTMessage={recieveIoTMessage}
          iotConnectEnd={iotConnectEnd}
          iotLost={iotLost}
        />
      )}
      <Toast2 ref={toastRef} />
      {ivsInitStart && broadStatus === "START" && videoStatus !== "onStatePlaying" && _deviceInfo.osName === "iOS" && <StreamErrorNotice />}
    </>
  );
};

export default LiveTheHyundai;
