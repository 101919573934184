import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

import { userState } from "../../interface/MainInterface";

interface propsType {
  broad_seq: string;
  user_id: string;
  userState: userState;
  chat_open: boolean;
  hidden_menu: boolean;
  sendMessage: any;
}

interface chatSendForm {
  msg: string;
}

const LiveFooter = (props: propsType) => {
  const [inputChatDisplay, setInputChatDisplay] = useState("none");

  // Form for send message to chime channel
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    document.getElementById("input-chat-Message")?.focus();
    reset({ msg: "" });
    await props.sendMessage(data.msg, "");
  };

  useEffect(() => {
    if (props.hidden_menu && !props.chat_open) setInputChatDisplay("none");
    else if (props.hidden_menu && props.chat_open) setInputChatDisplay("inline-flex");
    else setInputChatDisplay("none");
  }, [props.hidden_menu, props.chat_open]);

  useEffect(() => {
    if (inputChatDisplay === "inline-flex") {
      document.getElementById("input-chat-Message")?.focus();
    }
  }, [inputChatDisplay]);

  return (
    <>
      <Box component="div" sx={{ width: "100%", p: 1 }} className="color-snow">
        <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
          <Box
            id="input-chat"
            sx={{ flexGrow: 1, display: inputChatDisplay, alignItems: "center" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <TextField
              id="input-chat-Message"
              inputProps={
                props.user_id === ""
                  ? { style: { color: "snow" }, readOnly: true, enterKeyHint: "Enter" }
                  : { style: { color: "snow" }, enterKeyHint: "Enter" }
              }
              type="search"
              autoComplete="off"
              sx={{ width: "100%", color: "snow" }}
              placeholder={props.user_id === "" ? "로그인 후 이용하세요" : "메세지를 입력하세요."}
              {...register("msg", { required: true, maxLength: 3000 })}
            />
            <IconButton id="input-chat-button" type="submit" aria-label="send">
              <SendIcon sx={{ color: "snow" }} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default LiveFooter;
