import { useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
interface propsType {}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#2c2c2c",
  boxShadow: 24,
  p: 4,
};

const ImageViewer = (props: propsType, ref: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const open = (imageUrlParam: string) => {
    setImageUrl((imageUrl) => imageUrlParam);
    setOpenModal(true);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={style}>
        <Stack spacing={1} direction="column">
          <Typography variant="h6" component="h2" className="color-snow">
            이미지 뷰어
          </Typography>
          <CardMedia
            component="img"
            style={{
              width: "640px",
              maxHeight: "360px",
              objectFit: "contain",
            }}
            image={imageUrl}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default forwardRef(ImageViewer);
