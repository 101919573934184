import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Storage } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ToggleButton from "@mui/material/ToggleButton";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";

import { HttpChannelApi, CreateChParams, UpdateChParams, SetStreamParams, ChBaseParams } from "../../service/channel-api";
import { HttpClientApi } from "../../service/client-rest-api";
import { appConfig } from "../../config/Config";
import { CommonUtils } from "../../service/common_utils";
import { prodInfo, userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utilities/LoadingCircle";
import Toast from "../../utilities/Toast";
import ImageViewer from "../image/ImageViewer";

interface propsType {
  userState: userState;
  reloadChannelInfo: any;
  reloadChannelList: any;
  broadInfo: any;
  ivsInfo: any;
  sendIoTMessageMeta: any;
  broadTypes: [];
  progressStores: [];
}

const emptyRow = {
  prod_id: "",
  prod_title: "",
  prod_callback: "",
};

const chApi = new HttpChannelApi();
const clientApi = new HttpClientApi();

const TabChannel = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();

  const toastRef: any = useRef();
  const imageViewer: any = useRef();
  const channelImgInputRef = useRef<HTMLInputElement>();

  const [loading, setLoading] = useState(false);
  const [pk, setPk] = useState("");
  const [sk, setSk] = useState("");
  const [broadSeq, setBroadSeq] = useState("");
  const [broadTitle, setBroadTitle] = useState("");
  const [broadSecret, setBroadSecret] = useState("");
  const [broadStratTm, setBroadStratTm] = useState<Dayjs | null>(null);
  const [broadEndTm, setBroadEndTm] = useState<Dayjs | null>(null);
  const [broadStatus, setBroadStatus] = useState("CREATE");
  const [broadDesc, setBroadDesc] = useState("");
  const [extraType, setExtraType] = useState("N001"); // "" = N001
  const [progressStore, setProgressStore] = useState("A"); // "" = A
  const [prodRows, setProdRows] = useState<prodInfo[]>([]);
  const [expandProdList, setExpandProdList] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [channelImg, setChannelImg] = useState("");
  const [channelImgUrl, setChannelImgUrl] = useState("");

  const prodColumns = [
    { field: "prod_title", headerName: "상품명", width: 300, editable: true },
    { field: "prod_callback", headerName: "랜딩URL", flex: 1, editable: true },
    { field: "prod_id", headerName: "id", width: 100, hide: true },
  ];

  const setChannelInfo = async (_broadInfo: any) => {
    // //채널상세 form mapping
    setPk((pk) => _broadInfo.pk);
    setSk((sk) => _broadInfo.sk);
    setBroadSeq((broadSeq) => _broadInfo.broad_seq);
    setBroadTitle(_broadInfo.broad_title);
    let extra_type = _broadInfo.extra_type;
    if (extra_type === "") extra_type = "N001";
    setExtraType(extra_type);
    let progress_store = _broadInfo.progress_store;
    if (progress_store === "") progress_store = "A";
    setProgressStore(progress_store);
    setBroadSecret(_broadInfo.password);
    setBroadStratTm(_broadInfo.broad_start_tm);
    setBroadEndTm(_broadInfo.broad_stop_tm);
    setBroadStatus(_broadInfo.broad_status);
    setBroadDesc(_broadInfo.broad_desc);
    setProdRows(_broadInfo.broad_prod_list);
    setChannelImg(_broadInfo.channel_img);
  };

  // 필드 초기화
  const initForm = () => {
    setPk("");
    setSk("");
    setBroadSeq("");
    setBroadTitle("");
    setBroadSecret("");
    setBroadStratTm(null);
    setBroadEndTm(null);
    setBroadStatus("CREATE");
    setBroadDesc("");
    setExtraType("N001");
    setProdRows([]);
    setExpandProdList(false);
    setChannelImg("");
  };

  const handleExtraTypeChange = (event: SelectChangeEvent) => {
    setExtraType(event.target.value as string);
  };

  const handleProgressStoreChange = (event: SelectChangeEvent) => {
    setProgressStore(event.target.value as string);
  };

  const handleBroadStartTmChange = (newValue: Dayjs | null) => {
    setBroadStratTm(newValue);
  };

  const handleBroadEndTmChange = (newValue: Dayjs | null) => {
    setBroadEndTm(newValue);
  };

  const toggleExpandProdGrid = () => {
    setExpandProdList((expandProdList) => !expandProdList);
  };

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (pk === "" || sk === "" || broadSeq === "") return false;
    switch (newAlignment) {
      case "READY":
        if (window.confirm("방송을 준비중 상태로 변경하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "START":
        if (window.confirm("방송을 시작 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "STOP":
        if (window.confirm("방송을 중지 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      default:
        break;
    }
  };

  const createChannel = async () => {
    // 필수값
    if (broadTitle === "" || broadTitle === undefined) {
      alert("방송명을 입력하세요.");
      return;
    }

    if (broadStratTm === null || !broadStratTm) {
      alert("방송 시작시간을 입력하세요.");
      return;
    }

    if (broadEndTm === null || !broadEndTm) {
      alert("방송 종료시간을 입력하세요.");
      return;
    }

    for (const prodInfo of prodRows) {
      if (prodInfo.prod_title === "") {
        alert("상품명을 입력하세요.");
        return;
      }
      if (prodInfo.prod_callback === "") {
        alert("렌딩URL을 입력하세요.");
        return;
      }
    }

    let extra_type = extraType;
    if (extraType === "N001") extra_type = "";

    let progress_store = progressStore;
    if (progressStore === "A") progress_store = "";

    const create_payload: CreateChParams = {
      broad_title: broadTitle,
      broad_desc: broadDesc,
      broad_start_tm: dayjs(broadStratTm).format("YYYYMMDDHHmm") + "00",
      broad_stop_tm: dayjs(broadEndTm).format("YYYYMMDDHHmm") + "00",
      broad_prod_list: prodRows,
      broad_status: "CREATE",
      broad_notice: "",
      vod_url: "",
      vod_started_at: "",
      vod_start_sec: 0,
      vod_end_sec: 0,
      high_start_sec: 0,
      password: broadSecret,
      extra_type: extra_type,
      progress_store: progress_store,
      channel_img: channelImg,
      channel_template: false,
      setting: {
        logo: true,
        host_picture: true,
        title: true,
        statistics: true,
        title_text_size: "2.0",
        title_text_v_size: "2.0",
        title_text_color: "#ffffff",
        title_shadow: true,
        title_shadow_color: "#9b9b9b",
        statistics_text_size: "2.0",
        statistics_text_v_size: "2.0",
        statistics_text_color: "#ffffff",
        statistics_shadow: true,
        statistics_shadow_color: "#9b9b9b",
        chat_text_size: "2.0",
        chat_text_v_size: "2.0",
        chat_text_color: "#ffffff",
        chat_shadow: true,
        chat_shadow_color: "#9b9b9b",
        host_img_size: "6.0",
        host_img_v_size: "5.0",
        icon_size: "3.0",
        icon_v_size: "3.0",
        ready_img: "",
        logo_img: "",
      },
    };

    setLoading(true);
    const res = await chApi.create_channel(create_payload);
    if (res.result_code === "200") {
      alert("채널 생성을 완료하였습니다.");
      props.reloadChannelList();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const updateChannel = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    // 필수값
    if (broadTitle === "" || broadTitle === undefined) {
      alert("방송명을 입력하세요.");
      return;
    }

    if (broadStratTm === null || !broadStratTm) {
      alert("방송 시작시간을 입력하세요.");
      return;
    }

    if (broadEndTm === null || !broadEndTm) {
      alert("방송 종료시간을 입력하세요.");
      return;
    }

    for (const prodInfo of prodRows) {
      if (prodInfo.prod_title === "") {
        alert("상품명을 입력하세요.");
        return;
      }
      if (prodInfo.prod_callback === "") {
        alert("렌딩URL을 입력하세요.");
        return;
      }
    }

    let extra_type = extraType;
    if (extraType === "N001") extra_type = "";

    let progress_store = progressStore;
    if (progressStore === "A") progress_store = "";

    const param: UpdateChParams = {
      pk: pk,
      broad_seq: broadSeq,
      broad_title: broadTitle,
      broad_desc: broadDesc,
      broad_start_tm: dayjs(broadStratTm).format("YYYYMMDDHHmm") + "00",
      broad_stop_tm: dayjs(broadEndTm).format("YYYYMMDDHHmm") + "00",
      broad_prod_list: prodRows,
      broad_status: broadStatus,
      broad_notice: props.broadInfo.broad_notice,
      vod_url: props.broadInfo.vod_url,
      vod_started_at: props.broadInfo.vod_started_at,
      vod_start_sec: props.broadInfo.vod_start_sec,
      vod_end_sec: props.broadInfo.vod_end_sec,
      high_start_sec: props.broadInfo.high_start_sec,
      password: broadSecret,
      extra_type: extra_type,
      progress_store: progress_store,
      setting: props.broadInfo.setting,
      host_id: props.broadInfo.host_id,
      channel_img: channelImg,
      channel_template: props.broadInfo.channel_template,
    };

    setLoading(true);
    const res = await chApi.update_channel(param);

    if (res.result_code === "200") {
      alert("채널 수정을 완료하였습니다.");
      props.reloadChannelList();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const deleteChannel = async () => {
    if (pk === "" || broadSeq === "") {
      alert("삭제할 채널을 선택하세요.");
      return;
    }
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (props.broadInfo.broad_status === "START") {
      alert("현재 방송중인 채널입니다. 먼저 방송을 종료 후 다시 시도하세요.");
      return;
    }
    if (!window.confirm("채널을 삭제 하겠습니까?\n한번 삭제한 채널은 복구할 수 없습니다.")) return;

    const param: ChBaseParams = {
      pk: pk,
      broad_seq: broadSeq,
    };
    setLoading(true);
    const res = await chApi.delete_channel(param);
    if (res.result_code === "200") {
      props.reloadChannelList();
    } else {
      alert(`[ERROR] ${res.result_body.error_msg}`);
    }
    setLoading(false);
  };

  const handleStream = async (status: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    let cmd = "";
    if (status === "START") cmd = "start_stream";
    else if (status === "READY") cmd = "ready_stream";
    else if (status === "STOP") cmd = "stop_stream";
    const param: SetStreamParams = {
      pk: pk,
      broad_seq: broadSeq,
      cmd: cmd,
    };
    // 방송 스트림 상태 조회
    let check_stream = true;
    if (param.cmd === "start_stream") {
      await clientApi.get_stream_by_arn(props.ivsInfo?.channel_arn).then((res) => {
        if (res.result_code !== "200") check_stream = false;
        if (cUtils.isEmptyObj(res.result_body)) check_stream = false;
      });
    }
    if (!check_stream && !props.userState.isSuperAdmin) {
      alert("방송 시작전 영상을 먼저 송출하세요.");
      return;
    }

    setLoading(true);
    const res = await chApi.handler_stream(param);
    if (res.result_code === "200") {
      setBroadStatus(status);
      props.sendIoTMessageMeta(cmd);
      // 방송이 종료되면 VOD를 자동으로 생성한다.
      if (status === "STOP") {
        const param: ChBaseParams = {
          pk: pk,
          broad_seq: broadSeq,
        };
        const res_record = await chApi.get_record_list(param);
        if (res.result_code === "200") {
          if (res_record.result_body.length > 0) {
            const lastRecord = res_record.result_body[res_record.result_body.length - 1];
            const lastRecordStartAtKR = dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString();
            const date1 = dayjs(lastRecordStartAtKR, "YYYYMMDDHHmmss");
            const date2 = dayjs(props.broadInfo.broad_start_tm, "YYYYMMDDHHmmss");
            let diff = date2.diff(date1, "s");
            if (diff < 0) {
              diff = 0; //방송전 리허설이 없었으면 방송시작시간으로
            }

            const param: UpdateChParams = {
              pk: props.broadInfo.pk,
              broad_seq: props.broadInfo.broad_seq,
              broad_title: props.broadInfo.broad_title,
              broad_desc: props.broadInfo.broad_desc,
              broad_start_tm: props.broadInfo.broad_start_tm,
              broad_stop_tm: props.broadInfo.broad_stop_tm,
              broad_prod_list: props.broadInfo.broad_prod_list,
              broad_notice: props.broadInfo.broad_notice,
              broad_status: "VOD",
              vod_url: "/" + lastRecord.stream_info.bucket_root + "/media/hls/master.m3u8",
              vod_started_at: dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString(),
              vod_start_sec: diff,
              vod_end_sec: 0,
              high_start_sec: props.broadInfo.high_start_sec,
              password: props.broadInfo.password,
              extra_type: props.broadInfo.extra_type,
              progress_store: props.broadInfo.progress_store,
              setting: props.broadInfo.setting,
              host_id: props.broadInfo.host_id,
              channel_img: props.broadInfo.channel_img,
              channel_template: props.broadInfo.channel_template,
            };
            const res = await chApi.update_channel(param);
          }
        } else {
          console.error("[ERROR] getRecordList : ", res.result_body);
        }
      }

      props.reloadChannelInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // 연동상품 셀편집 완료시
  const onCellEditCommit = (cellData: any) => {
    const { id, field, value } = cellData;
    let newRows: any = [...prodRows];
    newRows[id * 1 - 1][field] = value;
  };

  // 연동상품 상품 추가
  const addProdList = () => {
    let newRows: any = [...prodRows];
    const newRowId = newRows.length + 1;
    let newRow = JSON.parse(JSON.stringify(emptyRow));
    newRow.prod_id = "" + newRowId;
    newRows = [...newRows, newRow];
    setProdRows(newRows);
  };

  // 연동상품 상품 삭제
  const delProdList = () => {
    let newRows: any = [...prodRows];
    for (const val of selectionModel) {
      const idx = newRows.findIndex(function (item: any) {
        return item.prod_id === val;
      });
      if (idx > -1) newRows.splice(idx, 1);
    }
    let newData: any = [];
    let rowId = 1;
    for (const rowInfo of newRows) {
      newData.push({
        prod_id: "" + rowId,
        prod_title: rowInfo.prod_title,
        prod_callback: rowInfo.prod_callback,
      });
      rowId += 1;
    }
    setSelectionModel([]);
    setProdRows(newData);
  };

  // 클립보드 복사
  const copyToClipboard = (text: string) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastRef.current?.toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
  };

  const previewChannelImg = () => {
    if (channelImg !== "") imageViewer.current.open(channelImgUrl);
  };

  const registChannelImg = () => {
    if (props.broadInfo.broad_seq !== "" && props.broadInfo.broad_seq !== undefined) {
      if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
        // 권한
        alert("다른 사람의 채널을 수정하실 수 없습니다.");
        return;
      }
      channelImgInputRef.current?.click(); // S3에 이미지 업로드
    } else {
      window.alert("채널을 먼저 생성 후 대표이미지를 등록해주세요.");
    }
  };

  const handleChannelImgInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      const newFileName = "channel_img";
      const dirName = "channel-img/";
      const photoKey = props.broadInfo.broad_seq + "/" + newFileName;
      const uploadResult = await uploadS3(dirName, photoKey, file);
      if (uploadResult === "fail") return;
      setChannelImg(dirName + photoKey);
    }
  };

  const deleteChannelImg = () => {
    setChannelImg("");
  };

  const uploadS3 = async (dirName: string, filePath: string, file: any) => {
    let result = "fail";
    try {
      const data = await Storage.put(filePath, file, { customPrefix: { public: dirName } });
      result = data.key;
    } catch (e) {
      console.error("fail uploadding : ", e);
    }
    return result;
  };

  useEffect(() => {
    if (channelImg !== "") generateChannelImgUrl(channelImg);
    else setChannelImgUrl("");
  }, [channelImg]);

  const generateChannelImgUrl = async (path: string) => {
    const image_url = await clientApi.get_presigned_url(path);
    setChannelImgUrl(image_url.result_body.url);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setChannelInfo,
    initForm,
    createChannel,
    updateChannel,
    deleteChannel,
  }));

  useEffect(() => {
    return () => {
      initForm();
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2} direction="column">
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                <TextField
                  label="방송명"
                  value={broadTitle}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setBroadTitle(e.target.value);
                  }}
                />

                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="extra-type-select-label">방송타입</InputLabel>
                    <Select
                      labelId="extra-type-select-label"
                      id="extra-type-select-label"
                      value={extraType}
                      label="방송타입"
                      onChange={handleExtraTypeChange}
                    >
                      <MenuItem value={"N001"}>일반</MenuItem>
                      {props.broadTypes.map((codes: any) => (
                        <MenuItem key={codes.code} value={codes.code}>
                          {codes.code_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={appConfig.progressStoreSelect ? { minWidth: 120, diplay: "flex" } : { minWidth: 120, display: "none" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="extra-type-select-label">진행점</InputLabel>
                    <Select
                      labelId="extra-type-select-label"
                      id="extra-type-select-label"
                      value={progressStore}
                      label="진행점"
                      onChange={handleProgressStoreChange}
                    >
                      <MenuItem key="1" value={"A"}>
                        전체
                      </MenuItem>
                      {props.progressStores.map((codes: any) => (
                        <MenuItem key={codes.code} value={codes.code}>
                          {codes.code_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TextField
                  label="비밀번호"
                  value={broadSecret}
                  size="small"
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setBroadSecret(e.target.value);
                  }}
                />
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                  <DateTimePicker
                    label="시작시간"
                    value={broadStratTm}
                    onChange={handleBroadStartTmChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                  <DateTimePicker
                    label="종료시간"
                    value={broadEndTm}
                    onChange={handleBroadEndTmChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                  <ToggleButtonGroup
                    color="standard"
                    value={broadStatus}
                    fullWidth
                    size="small"
                    exclusive
                    onChange={handleStatusChange}
                    aria-label="방송상태"
                  >
                    <ToggleButton value="CREATE" color="success">
                      생성
                    </ToggleButton>
                    <ToggleButton value="READY" color="warning">
                      준비중
                    </ToggleButton>
                    <ToggleButton value="START" color="error">
                      방송중
                    </ToggleButton>
                    <ToggleButton value="STOP" color="standard">
                      종료
                    </ToggleButton>
                    <ToggleButton value="VOD" color="info">
                      VOD
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </LocalizationProvider>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                <TextField
                  label="비고"
                  value={broadDesc}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setBroadDesc(e.target.value);
                  }}
                />
                <Stack spacing={1} direction="row" sx={{ minWidth: 250 }}>
                  {channelImg !== "" ? (
                    <Button
                      variant="outlined"
                      color="success"
                      fullWidth
                      onClick={() => {
                        previewChannelImg();
                      }}
                    >
                      보기
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      registChannelImg();
                    }}
                  >
                    {channelImg !== "" ? "변경" : "대표이미지 등록"}
                  </Button>
                  {channelImg !== "" ? (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="error"
                      onClick={() => {
                        deleteChannelImg();
                      }}
                    >
                      삭제
                    </Button>
                  ) : (
                    <></>
                  )}
                </Stack>
                <TextField
                  label="채널관리자"
                  value={props.broadInfo.host_id}
                  size="small"
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  sx={{ minWidth: 250 }}
                />
              </Stack>
              <Input
                key="inputLogo"
                color="primary"
                type="file"
                inputRef={channelImgInputRef}
                onChange={handleChannelImgInput}
                sx={{ display: "none" }}
              />
            </Stack>
          </Box>
        </Paper>
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2} direction="column">
              <Stack spacing={2} direction="row">
                <TextField
                  label="스트림 서버"
                  value={props.ivsInfo.ingest_endpoint === "" ? "" : `rtmps://${props.ivsInfo.ingest_endpoint}:443/app/`}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                />
                <Button variant="outlined" onClick={() => copyToClipboard(`rtmps://${props.ivsInfo.ingest_endpoint}:443/app/`)}>
                  복사
                </Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  label="스트림 키"
                  value={props.ivsInfo.stream_key}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                />
                <Button variant="outlined" onClick={() => copyToClipboard(props.ivsInfo.stream_key)}>
                  복사
                </Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  label="라이브 경로"
                  value={props.broadInfo.broad_seq === "" ? "" : `${appConfig.domain}/live/${props.broadInfo.broad_seq}`}
                  size="small"
                  fullWidth
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                />
                <Button
                  variant="outlined"
                  sx={{ minWidth: 80 }}
                  onClick={() => window.open(`/frame/${props.broadInfo.broad_seq}?mode=rehearsal`)}
                >
                  리허설
                </Button>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 80 }}
                  onClick={() => {
                    if (props.broadInfo.extra_type === "THD") {
                      window.open(`/frame/${props.broadInfo.broad_seq}`);
                    } else {
                      window.open(`/live/${props.broadInfo.broad_seq}`);
                    }
                  }}
                >
                  라이브
                </Button>
                <Button variant="outlined" onClick={() => copyToClipboard(`${appConfig.domain}/live/${props.broadInfo.broad_seq}`)}>
                  복사
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Paper>
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={1} direction="column">
              <Stack spacing={2} direction="row" sx={{ width: "100%", justifyContent: "flex-end" }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    toggleExpandProdGrid();
                  }}
                >
                  {expandProdList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    addProdList();
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    delProdList();
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Stack>
              <Box sx={expandProdList ? { width: "100%", height: 800 } : { width: "100%", height: 200 }}>
                <DataGrid
                  rows={prodRows}
                  columns={prodColumns}
                  rowHeight={40}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  checkboxSelection
                  disableSelectionOnClick
                  getRowId={(row) => row.prod_id}
                  onCellEditCommit={onCellEditCommit}
                  onSelectionModelChange={(newSelection) => {
                    setSelectionModel(newSelection);
                  }}
                  selectionModel={selectionModel}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                />
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Stack>
      <ImageViewer ref={imageViewer} />
      <Toast ref={toastRef} />
      <LoadingCircle loading={loading} />
    </Box>
  );
};

export default forwardRef(TabChannel);
