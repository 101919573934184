import { useEffect, useState, useCallback, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";

// import user component
import { HttpChannelApi, GetChannelListParams } from "../../../service/channel-api";
import { useWindowSize } from "../../../utilities/useWindowSize";
import { CommonUtils } from "../../../service/common_utils";
import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import Header from "../../Header";
import { HttpClientApi, ListChannelMessageParams } from "../../../service/client-rest-api";
import Toast2 from "../../../utilities/Toast2";

interface propsType {
  userState: userState;
}

const channelApi = new HttpChannelApi();

const ErrorLogView = (props: propsType) => {
  const toastRef: any = useRef();
  const [loading, setLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().endOf("month"));

  const [gridHeight, setGridHeight] = useState(608);
  const [errorList, setErrorList] = useState<any[]>([]);

  const columns: any = [
    {
      field: "sk",
      headerName: "발생일시",
      width: 130,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.broad_start_tm !== "" ? dayjs(param.row.sk, "YYYYMMDDHHmmssFF").format("MM-DD HH:mm:ss") : ""}</span>
          </>
        );
      },
    },
    { field: "broad_seq", headerName: "방송순번", width: 320, editable: true },
    {
      field: "device_info-1",
      headerName: "브라우저",
      width: 70,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.device_info.isBrowser && <CheckIcon />}</span>
          </>
        );
      },
    },
    {
      field: "device_info-2",
      headerName: "모바일",
      width: 70,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.device_info.isMobile && <CheckIcon />}</span>
          </>
        );
      },
    },
    {
      field: "device_info-3",
      headerName: "OS",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{`${param.row.device_info.osName}(${param.row.device_info.osVersion})`}</span>
          </>
        );
      },
    },
    {
      field: "device_info-4",
      headerName: "Browser",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{`${param.row.device_info.browserName}(${param.row.device_info.fullBrowserVersion})`}</span>
          </>
        );
      },
    },
    {
      field: "device_info-5",
      headerName: "Phone",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <span>{param.row.device_info.mobileModel}</span>
          </>
        );
      },
    },
    { field: "error_msg", headerName: "에러", flex: 1, minWidth: 300, editable: true },
  ];

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const fncGetErrorList = async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;
    setLoading(true);

    const param: any = {
      command: "get_error_list",
      std_dates: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
    };

    const res = await channelApi.get_error_list(param);
    if (res.code === "200") {
      setErrorList(res.response.error_list);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 5000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ p: 3 }}>
        {/* Title */}
        <Box component={"div"}>
          <Typography variant="h5" gutterBottom>
            오류목록조회
          </Typography>
        </Box>
        {/* Title */}
        <Box>
          <Stack spacing={2} direction="column">
            {/* 검색조건 */}
            <Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="기준일From"
                    inputFormat="MM/DD/YYYY"
                    value={srchDateFrom}
                    onChange={handleSrchDateFromChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                  <DesktopDatePicker
                    label="기준일To"
                    inputFormat="MM/DD/YYYY"
                    value={srchDateTo}
                    onChange={handleSrchDateToChange}
                    renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    fncGetErrorList();
                  }}
                >
                  조회
                </Button>
              </Stack>
            </Box>
            {/* 검색조건 */}
            {/* 그리드 */}
            <Box>
              <Paper sx={{ width: "100%", height: gridHeight, p: 2 }} elevation={5}>
                <DataGrid
                  rows={errorList}
                  rowHeight={40}
                  columns={columns}
                  rowsPerPageOptions={[3, 10, 20, 50, 100]}
                  pagination
                  getRowId={(row) => row.sk}
                />
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Box>
      <LoadingCircle loading={loading} />
      <Toast2 ref={toastRef} />
    </>
  );
};

export default ErrorLogView;
