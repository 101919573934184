import { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, ChangeManagerPasswordParam } from "../../service/thehyundai-rest-api";

const ChangeManagerPassword = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [managerPassword, setManagerPassword] = useState("");

  const changeManagerPassword = async () => {
    if (managerId === "" || managerId === undefined) {
      window.alert("관리자 ID를 입력하세요.");
      return;
    }
    if (managerPassword === "" || managerPassword === undefined) {
      window.alert("변경할 비밀번호를 입력하세요.");
      return;
    }

    const param: ChangeManagerPasswordParam = {
      managerId: managerId,
      password: managerPassword,
    };
    setIsLoading(true);
    const res = await theHyundaiApi.change_broadcast_manager_password(param);
    if (res.code === "200") changeBroadManagerPasswordSuccess();
    else changeBroadManagerPasswordFail(res);
  };

  const changeBroadManagerPasswordSuccess = () => {
    alert("관리자 비밀번호 변경에 성공하였습니다.");
    setIsLoading(false);
  };
  const changeBroadManagerPasswordFail = (error: any) => {
    if (error.code === "201") alert(`${error.response.error_msg}`);
    else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };
  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            관리자 비밀번호 변경 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            관리자의 비밀번호를 변경할 때 사용한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "change_broadcast_manager_password"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : 방송을 관리하는 관리자가 사용할 아이디(email format). 향후 채널 생성/수정/삭제 및
            방송 송출시 해당 ID를 필수로 전송
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 변경할 관리자의 패스워드
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { msg: "string" } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <TextField
            id="txtManagerId"
            label="Manager ID"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerId}
            onChange={(e) => {
              setManagerId(e.target.value);
            }}
          />
          <TextField
            id="txtManagerPassword"
            label="Pasword"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerPassword}
            onChange={(e) => {
              setManagerPassword(e.target.value);
            }}
          />
          <Button variant="contained" onClick={changeManagerPassword}>
            관리자 비밀번호 변경 요청
          </Button>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ChangeManagerPassword;
