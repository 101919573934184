import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FitScreenIcon from "@mui/icons-material/FitScreen";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import { player as livePlayer } from "../../service/ivs-player/ClientIVSPlayer";
import { player as vodPlayer } from "../../service/ivs-player/VodIVSPlayer";
import { player as vodPlayerHigh } from "../../service/ivs-player/VodIVSPlayerHigh";
import { androidWebAppInterface, broadSetting } from "../../interface/MainInterface";
import { CommonUtils } from "../../service/common_utils";

import LiveShare from "./LiveShare";

interface propsType {
  broad_seq: string;
  broad_title: string;
  setting: broadSetting;
  hidden_menu: boolean;
  pip_mode: boolean;
  webViewStatus: string;
  video_ref: any;
  video_status: string;
  playbackUrl: string;
  extra_type: string;
  host_id: string;
  host_name: string;
  host_picture: string;
  like_count: number;
  tot_view_count: number;
  broad_status: string;
  pip_callback: any;
  screenMode: string;
  vodType: string;
  haveHighlight: boolean;
  toggleVodType: any;
  handleFitscreen: any;
}

declare var android: androidWebAppInterface;

const LiveHeader = (props: propsType) => {
  let player = livePlayer;
  if (props.broad_status === "VOD" && props.vodType === "full") player = vodPlayer;
  if (props.broad_status === "VOD" && props.vodType === "highlight") player = vodPlayerHigh;
  let navigate = useNavigate();
  const cUtils = new CommonUtils();
  const logoSize: string = "2vmax";

  const [iconSize, setIconSize] = useState("3.0vmax");
  const [titleShadow, setTitleShadow] = useState("");
  const [titleTextSize, setTitleTextSize] = useState("2.0vmax");
  const [statisticsShadow, setStatisticsShadow] = useState("");
  const [statisticsTextSize, setStatisticsTextSize] = useState("2.0vmax");
  const [logoImgSize, setLogoImgSize] = useState("3vmax");
  const [muted, setMuted] = useState(true);
  const [enablePip, setEnablePip] = useState(false);
  const [openResolutionInfo, setOpenResolutionInfo] = useState(false);
  const [resolutionList, setResolutionList] = useState([] as any);
  const [latency, setLatency] = useState(0.0);
  const [isLiveLowLatency, setIsLiveLowLatency] = useState("false");
  const [fullscreen, setFullscreen] = useState(false);

  // 새로고침
  const handleRefresh = () => window.location.reload();

  // 볼륨 컨트롤
  const toggleMute = () => {
    const muteNext = !player.isMuted();
    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  // 창닫기
  const windowClose = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      if (window.history.length === 1) {
        window.close();
      } else {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (props.screenMode === "W") setIconSize(`${props.setting.icon_size}vmax`);
    else setIconSize(`${props.setting.icon_v_size}vmax`);
  }, [props.screenMode, props.setting.icon_size, props.setting.icon_v_size]);

  useEffect(() => {
    if (props.setting.title_shadow) setTitleShadow("1px 1px 1px " + props.setting.title_shadow_color);
    else setTitleShadow("");
  }, [props.setting.title_shadow, props.setting.title_shadow_color]);

  useEffect(() => {
    if (props.screenMode === "W") setTitleTextSize(`${props.setting.title_text_size}vmax`);
    else setTitleTextSize(`${props.setting.title_text_v_size}vmax`);
  }, [props.screenMode, props.setting.title_text_size, props.setting.title_text_v_size]);

  useEffect(() => {
    if (props.setting.statistics_shadow) setStatisticsShadow("1px 1px 1px " + props.setting.statistics_shadow_color);
    else setStatisticsShadow("");
  }, [props.setting.statistics_shadow, props.setting.statistics_shadow_color]);

  useEffect(() => {
    if (props.screenMode === "W") setStatisticsTextSize(`${props.setting.statistics_text_size}vmax`);
    else setStatisticsTextSize(`${props.setting.statistics_text_v_size}vmax`);
  }, [props.screenMode, props.setting.statistics_text_size, props.setting.statistics_text_v_size]);

  // pip mode 지원여부 세팅
  useEffect(() => {
    const supportsPiP = (videoEl: any) => {
      if (videoEl && videoEl.webkitSupportsPresentationMode && typeof videoEl.webkitSetPresentationMode === "function")
        return true;
      if ("pictureInPictureEnabled" in document) return true;
      if (props.webViewStatus === "android") return true;
      return false;
    };
    // set pip state
    setEnablePip((enablePip) => supportsPiP(props.video_ref.current));
  }, [props.video_ref, props.webViewStatus]);

  // PIP 모드 실행
  const togglePipMode = async () => {
    if ("pictureInPictureEnabled" in document) {
      // chrome or support pip
      pipProcChromWeb();
    } else if (
      props.video_ref.current &&
      props.video_ref.current.webkitSupportsPresentationMode &&
      typeof props.video_ref.current.webkitSetPresentationMode === "function"
    ) {
      // safari
      pipProcSafariWeb();
    } else if (props.webViewStatus === "android") {
      // android webview
      pipProcAndroidWebView();
    }
  };

  const pipProcChromWeb = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      } else {
        await props.video_ref.current.requestPictureInPicture();
      }
    } catch (err) {
      console.error("pipProcChromWeb : ", err);
    }
  };

  const pipProcSafariWeb = () => {
    try {
      const { current: v } = props.video_ref.current;
      if (!enablePip) return;
      v.webkitSetPresentationMode(v.webkitPresentationMode === "picture-in-picture" ? "inline" : "picture-in-picture");
    } catch (err) {
      console.error("pipProcSafariWeb : ", err);
    }
  };

  const pipProcAndroidWebView = () => {
    try {
      // 화면비율 구해오기
      const liveCurrQlty = player.getQuality();
      let w = 9;
      let h = 16;
      if (liveCurrQlty) {
        const ratio = cUtils.screenRatio(liveCurrQlty.width, liveCurrQlty.height);
        w = ratio[0];
        h = ratio[1];
      }
      props.pip_callback(true);
      android.enterPipMode(w, h);
    } catch (err) {
      console.error("pipProcAndroidWebView : ", err);
    }
  };

  // 해상도 선택

  const resolutionDrawerClose = () => setOpenResolutionInfo(false);
  const resolutionDrawerOpen = () => {
    let liveQlty = player.getQualities();
    const liveCurrQlty = player.getQuality();

    // 해상도별로 정렬
    liveQlty.sort((a: any, b: any) => {
      if (a.height > b.height) return -1;
      if (a.height < b.height) return 1;
      return 0;
    });

    setResolutionList(
      liveQlty.map((obj: any, index: number) => {
        return (
          <ListItem key={`quality${obj.name}`} disablePadding>
            <ListItemButton onClick={() => setbtnQuality(obj)}>
              {obj.name === liveCurrQlty.name ? (
                <ListItemText primary={obj.name + " *"} />
              ) : (
                <ListItemText primary={obj.name} />
              )}
            </ListItemButton>
          </ListItem>
        );
      })
    );
    setLatency(player.getLiveLatency());
    setIsLiveLowLatency("" + player.isLiveLowLatency());
    setOpenResolutionInfo(true);
  };
  const setbtnQuality = (obj: any) => {
    player.setQuality(obj);
    setOpenResolutionInfo(false);
  };

  const toggleAuto = () => {
    player.setAutoQualityMode(true);
  };

  const isAutoMode = () => {
    if (props.video_status !== "onStatePlaying") return false;
    if (player !== null) {
      if (player.isAutoQualityMode()) return true;
    }
    return false;
  };

  // 전체화면
  const handleFullscreen = () => {
    cUtils.toggelFullScreen();
  };

  // 전체화면 상태에 따른 아이콘 변경
  const fullscreenchanged = (event: any) => {
    setFullscreen((fullscreen) => cUtils.isFullscreen());
  };

  // 전체화면 상태 이벤트 리스너
  window.addEventListener("fullscreenchange", fullscreenchanged, false);
  window.addEventListener("webkitfullscreenchange", fullscreenchanged, false);
  window.addEventListener("mozfullscreenchange", fullscreenchanged, false);

  const handleFitscreen = () => {
    props.handleFitscreen();
  };

  useEffect(() => {
    if (props.extra_type === "P001") {
      setLogoImgSize("1.5vmax");
    }
  }, [props.extra_type]);

  useEffect(() => {
    return () => {
      setIconSize("3.0vmax");
      setTitleShadow("");
      setTitleTextSize("2.0vmax");
      setStatisticsShadow("");
      setStatisticsTextSize("2.0vmax");
      setLogoImgSize("3vmax");
      setMuted(true);
      setEnablePip(false);
      setOpenResolutionInfo(false);
      setResolutionList([]);
      setLatency(0.0);
      setIsLiveLowLatency("false");
      setFullscreen(false);
    };
  }, []);

  return (
    <>
      <Box
        className="live-header-wrapper color-snow"
        sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}
      >
        <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
          <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
            {/* 로고영역 */}
            <Grid item xs={6} sx={{ display: "flex" }}>
              {props.setting.logo_img_url !== "" ? (
                <CardMedia
                  component="img"
                  style={{
                    width: "auto",
                    height: logoImgSize,
                    objectFit: "contain",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  image={props.setting.logo_img_url}
                />
              ) : (
                <Typography
                  variant={"h6"}
                  sx={{ textOverflow: "clip", overflow: "hidden", whiteSpace: "nowrap", mb: 0 }}
                  gutterBottom
                >
                  {props.extra_type === "P001" ? (
                    <>
                      <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>현대백화점</span>
                      <span style={{ color: "red", fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>
                        &nbsp;VIP
                      </span>
                      <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>&nbsp;Live</span>
                    </>
                  ) : (
                    <>
                      <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>IT</span>
                      <span style={{ color: "red", fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>&</span>
                      <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>LIVE</span>
                    </>
                  )}
                </Typography>
              )}
            </Grid>
            {/* 버튼영역 */}
            <Grid item xs={6} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={1}>
                {/* 새로고침 */}
                <IconButton
                  sx={
                    props.video_status !== "onStatePlaying"
                      ? { display: "flex", padding: 0 }
                      : { display: "none", padding: 0 }
                  }
                  onClick={handleRefresh}
                >
                  <AutorenewIcon sx={{ fontSize: iconSize }} className="color-snow" />
                </IconButton>
                {/* PIP */}
                <IconButton
                  sx={
                    props.video_status === "onStatePlaying" && enablePip
                      ? { display: "flex", padding: 0 }
                      : { display: "none", padding: 0 }
                  }
                  onClick={() => {
                    togglePipMode();
                  }}
                >
                  <PictureInPictureAltIcon sx={{ fontSize: iconSize }} className="color-snow pd-0" />
                </IconButton>
                {/* 볼륨 컨트롤 */}
                <IconButton sx={{ padding: 0 }} onClick={toggleMute}>
                  {muted ? (
                    <VolumeOffIcon sx={{ fontSize: iconSize }} className="shake_bell color-orangered" />
                  ) : (
                    <VolumeUpIcon sx={{ fontSize: iconSize }} className="color-snow" />
                  )}
                </IconButton>
                {/* 해상도 선택 */}
                <IconButton
                  sx={
                    props.video_status === "onStatePlaying" && props.broad_status !== "VOD"
                      ? { display: "flex", padding: 0 }
                      : { display: "none", padding: 0 }
                  }
                  onClick={resolutionDrawerOpen}
                >
                  <VideoSettingsIcon sx={{ fontSize: iconSize }} className="color-snow" />
                </IconButton>
                {/* share */}
                {props.extra_type !== "P001" ? <LiveShare broad_seq={props.broad_seq} iconSize={iconSize} /> : <></>}
                {/* fullscreen */}
                <IconButton
                  sx={cUtils.isSupportFullScreen() ? { padding: 0, display: "flex" } : { padding: 0, display: "none" }}
                  onClick={handleFullscreen}
                >
                  {fullscreen ? (
                    <CloseFullscreenIcon sx={{ fontSize: iconSize }} className="color-snow" />
                  ) : (
                    <FullscreenIcon sx={{ fontSize: iconSize }} className="color-snow" />
                  )}
                </IconButton>
                {/* fullscreen */}
                <IconButton
                  sx={cUtils.isSupportFullScreen() ? { padding: 0, display: "flex" } : { padding: 0, display: "none" }}
                  onClick={handleFitscreen}
                >
                  <FitScreenIcon sx={{ fontSize: iconSize }} className="color-snow" />
                </IconButton>
                {/* close */}
                <IconButton sx={{ padding: 0 }} onClick={windowClose}>
                  <CloseIcon sx={{ fontSize: iconSize }} className="color-snow" />
                </IconButton>
              </Stack>
            </Grid>
          </Stack>
          <Box sx={{ width: "100%", mt: 1 }}>
            <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: titleTextSize,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textShadow: titleShadow,
                    fontWeight: "bold",
                  }}
                >
                  {props.broad_title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: statisticsTextSize,
                    textShadow: statisticsShadow,
                  }}
                >
                  {props.extra_type !== "P001" ? (
                    <>
                      <span className="svc-text">{props.host_name}</span>{" "}
                      <VisibilityIcon sx={{ fontSize: statisticsTextSize }} className="svc-icon" />{" "}
                      <span className="svc-text">
                        {props.tot_view_count > 0 ? cUtils.numericComma(props.tot_view_count) : "0"}
                      </span>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          {props.broad_status === "VOD" && props.haveHighlight ? (
            <Box sx={{ width: "100%", mt: 1 }} onClick={props.toggleVodType}>
              <span className="vod-select font-small">
                {props.vodType === "highlight" ? "풀영상 보기" : "하이라이트 보기"}
              </span>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      <Drawer
        PaperProps={{ elevation: 0, style: { borderRadius: "16px" } }}
        anchor="bottom"
        open={openResolutionInfo}
        onClose={resolutionDrawerClose}
        className="live-more-body"
      >
        <Box
          sx={{ width: "94%", height: "350px", bgcolor: "while", borderRadius: "16px" }}
          className="live-more-body"
          onClick={resolutionDrawerClose}
        >
          해상도 선택
          <nav aria-label="Set Resolution">
            <List>
              <ListItem key={`auto`} disablePadding>
                <ListItemButton onClick={() => toggleAuto()}>
                  {isAutoMode() ? <ListItemText primary={"Auto *"} /> : <ListItemText primary={"Auto"} />}
                </ListItemButton>
              </ListItem>
              {resolutionList}
            </List>
          </nav>
          {props.broad_status !== "VOD" ? (
            <>
              <div> 지연시간 : {latency} sec</div>
              <div> 저지연 모드 : {isLiveLowLatency}</div>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default LiveHeader;
