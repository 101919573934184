import { useCallback, useEffect, useState } from "react";
import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";
import { deviceInfo } from "../../../interface/MainInterface";
import { HttpClientApi } from "../../../service/client-rest-api";
import "./ClientIVSPlayer.css";

interface propsType {
  endLoadInfo: boolean;
  broad_seq: string;
  broad_status: string;
  playbackUrl: string;
  streamState: string;
  videoStatusCallback: any;
  muted: boolean;
  webViewStatus: string;
  rehearsal: boolean;
}
export let player: any = null;

const clientApi = new HttpClientApi();

const _deviceInfo: deviceInfo = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

export const ClientIVSPlayer = (props: propsType) => {
  const [broadStatus, setBroadStatus] = useState("");

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadStatus("");
    };
  }, []);

  useEffect(() => {
    if (props.playbackUrl !== "") {
      mediaPlayerScriptLoaded(props.playbackUrl);
    }
  }, [props.playbackUrl]);

  useEffect(() => {
    if (!props.rehearsal) {
      setBroadStatus(props.broad_status);
      if (props.broad_status === "START") player.load(props.playbackUrl);
      if (props.broad_status === "READY") player.load("");
      if (props.streamState === "Stream Start" && props.broad_status === "START") player.load(props.playbackUrl);
    }
  }, [props.streamState, props.broad_status, props.playbackUrl, props.rehearsal]);

  const putErrorLog = async (errorMsg: string) => {
    try {
      const param = {
        command: "put_broaderror_data",
        broad_seq: props.broad_seq,
        device_info: _deviceInfo,
        error_msg: errorMsg,
      };

      await clientApi.put_broaderror_data(param);
    } catch (error) {
      console.error(error);
    }
  };

  const mediaPlayerScriptLoaded = (_PLAYBACK_URL: string) => {
    // IVS 스트림 체크하자... 스트림 시작전에 플레이어 렌더링하면 안된다고 함...
    const MediaPlayerPackage = window.IVSPlayer;
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.error("지원하지 않는 브라우저입니다. 다른 브라우저로 시도하시거나. 더현대닷컴 APP을 설치하시기 바랍니다.");
      props.videoStatusCallback("unSupported");
      putErrorLog("UnSupported Browser");
    }

    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create({
      serviceWorker: {
        url: "amazon-ivs-service-worker-loader.js",
      },
    });
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
    player.addEventListener(PlayerEventType.AUDIO_BLOCKED, onAudioBlocked);
    player.addEventListener(PlayerEventType.INITIALIZED, onInitialized);
    player.addEventListener(PlayerEventType.MUTED_CHANGED, onMutedChanged);
    player.addEventListener(PlayerEventType.NETWORK_UNAVAILABLE, onNetworkUnavailable);
    player.addEventListener(PlayerEventType.PLAYBACK_RATE_CHANGED, onPlaybackRateChanged);
    player.addEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);
    player.addEventListener(PlayerEventType.PLAYBACK_BLOCKED, onPlaybackBlocked);

    // Setup stream and play
    player.setAutoQualityMode(true);
    player.setLiveLowLatencyEnabled(true);
    player.setMuted(true);
    player.setVolume(1.0);
    player.setAutoplay(true);

    if (props.rehearsal) {
      player.load(_PLAYBACK_URL);
      player.play();
    } else {
      if (props.broad_status === "START") {
        player.load(_PLAYBACK_URL);
        player.play();
      }
    }

    //if client platform is iOS
    if (_deviceInfo.osName === "iOS") {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden" && player.isMuted()) {
          player.pause();
        }
        if (document.visibilityState === "visible" && player.getState() !== PlayerState.PLAYING) {
          player.play();
        }
      });
    }

    window.addEventListener("focus", fncAutoPlay);
    function fncAutoPlay() {
      if (props.webViewStatus === "ios" && props.playbackUrl !== "") {
        player.setAutoplay(true);
        player.load(props.playbackUrl);
        player.play();
      }
    }
  };

  const onIdle = useCallback(
    (cue: any) => {
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      props.videoStatusCallback("onIdle");
      window.addEventListener("visibilitychange", visibleCheck, false);
      function visibleCheck(e: any) {
        e.stopPropagation();
        if (document.visibilityState === "visible" && broadStatus === "START") {
          player.play();
        }
      }
      if (broadStatus === "START") {
        player.play();
      }
    },
    [props, broadStatus]
  );

  const onReady = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onReady");
  };

  const onStatePlaying = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onStatePlaying");
  };

  const onStateEnd = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onStateEnd");
  };

  const onError = async (err: any) => {
    console.warn("Player Event - ERROR:", err);
    props.videoStatusCallback("onError");
    const error: any = JSON.stringify(err);
    await putErrorLog(error);
    if (err.code === 101) {
      // {"type":"ErrorTimeout","code":101,"source":"Render","message":"Buffering timeout"}
      window.location.reload();
    }
    if (broadStatus === "START") {
      player.load(props.playbackUrl);
      player.play();
    }
  };

  const onTimeUpdate = (evt: any) => {};

  const onAudioBlocked = (evt: any) => {
    console.log("onAudioBlocked");
  };
  const onInitialized = (evt: any) => {
    console.log("onInitialized");
  };
  const onMutedChanged = (evt: any) => {
    console.log("onMutedChanged");
  };
  const onNetworkUnavailable = (evt: any) => {
    console.log("onNetworkUnavailable");
  };
  const onPlaybackRateChanged = (evt: any) => {
    console.log("onPlaybackRateChanged");
  };
  const onQualityChanged = (evt: any) => {
    console.log("onQualityChanged : ", evt);
  };
  const onPlaybackBlocked = (evt: any) => {
    console.log("onPlaybackBlocked");
  };

  return (
    <>
      <video id="videoPlayer" className="th-client-video" poster={"/images/ready_img.png"} playsInline muted autoPlay></video>
    </>
  );
};
