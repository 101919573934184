import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Interface
import { HttpClientApi } from "../../../service/client-rest-api";
import { CommonUtils } from "../../../service/common_utils";
// Component
import { useInterval } from "../../../utilities/UseInterval";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  newQnAMsg: any;
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
}

interface autoLinkProp {
  text: String;
}

const LiveNoticeQnAIOS = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class
  const cUtils = new CommonUtils();

  const [qnaText, setQnaText] = useState(""); // 롤링영역 QNA 최신 텍스트
  const [noticeText, setNoticeText] = useState(""); // 롤링영역 공지사항 최신 텍스트
  const [openNotice, setOpenNotice] = useState(false); // 공지사항, Q&A 상세보기
  const [broadQnaList, setBroadQnaList] = useState<any>([]); // 방송 QNA LIST
  const [openQna, setOpenQna] = useState(false); // 공지사항, Q&A 상세보기

  const notiCode = "notice";
  const qnaCode = "qna";

  useEffect(() => {
    getBroadQnaList(props.broad_info.broad_seq);
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNoticeText("");
      setOpenNotice(false);
      setBroadQnaList([]);
      setOpenQna(false);
    };
  }, []);

  // 부모 컴포넌트에서 공지사항 변경시 최신화
  useEffect(() => {
    let tmpNoticeText = props.broad_info.broad_notice;
    tmpNoticeText = tmpNoticeText.replaceAll("<br />", " ").replaceAll("\n", " ");
    setNoticeText(tmpNoticeText);
  }, [props.broad_info.broad_notice]);

  // 방송 Q&A List 조회
  const getBroadQnaList = async (broad_seq: string) => {
    const broadQnaResult = await clientApi.list_broad_qna(broad_seq, "DESCEND");
    setBroadQnaList(broadQnaResult.result_body);
  };

  useEffect(() => {
    if (props.newQnAMsg !== null) {
      setBroadQnaList([props.newQnAMsg, ...broadQnaList]);
    }
  }, [props.newQnAMsg]);

  // QNA List 변경시 최신 QNA TEXT 최신화
  useEffect(() => {
    if (broadQnaList.length > 0) {
      setQnaText(broadQnaList[0].qContent);
    } else {
      setQnaText("");
    }
  }, [broadQnaList]);

  // QNA TEXT, 공지사항 TEXT 변경시 Rolling 영역 텍스트 최신화
  useEffect(() => {
    let newRollingText: any = [];
    if (noticeText !== "") {
      newRollingText = [
        ...newRollingText,
        {
          text: noticeText.replaceAll("<br />", " ").replaceAll("\n", " "),
          type: notiCode,
        },
      ];
    }
    if (qnaText !== "") {
      newRollingText = [
        ...newRollingText,
        {
          text: qnaText,
          type: qnaCode,
        },
      ];
    }
    setRollingTexts(newRollingText);
  }, [qnaText, noticeText]);

  // 공지사항 슬라이더 관련 ------------------------------------
  // for useInterval
  const delay: number = 5000; // 4초마다 변경
  const [isRunning, setIsRunning] = useState(true); // SetInterval 실행
  const noticeSliderRef: any = useRef(); // slider ref
  // 슬라이딩할 텍스트
  const [rollingTexts, setRollingTexts] = useState<any>([]);

  // 슬라이딩 구현 (한 row의 height가 제일 중요)
  useInterval(
    () => {
      if (rollingTexts.length > 0) {
        if (noticeSliderRef.current.children.length > 0) {
          if (noticeSliderRef.current.children.length > 1) {
            // 리스트를 위로 올림. 에니메이션 추가
            noticeSliderRef.current.className = "thd-notice-silder-list-animation";
            setTimeout(() => {
              noticeSliderRef.current.className = "thd-notice-silder-list"; // 에니메이션 초기화
              // 올라간 리스트를 삭제한다.
              noticeSliderRef.current.removeChild(noticeSliderRef.current.children[0]);
              // 마지막 하나 남았을때 아래에 기존 리스트를 다시 붙인다.
              if (noticeSliderRef.current.children.length === 1) {
                for (const item of rollingTexts) {
                  const newElement = document.createElement("div");
                  newElement.className = "thd-notice-silder-item";
                  // newElement.innerText = item.text;
                  newElement.innerHTML = `
                      <span class="thd-live-${item.type}-typo">${item.type === notiCode ? "공지사항" : "Q&A"}</span>
                      <span>${item.text}</span>
                  `;
                  newElement.onclick = () => {
                    return item.type === notiCode ? handleNoticeDrawerOpen() : handleQnaDrawerOpen();
                  };
                  noticeSliderRef.current.appendChild(newElement);
                }
              }
            }, 2000); // 에니메이션 시간과 동일하게 세팅
          }
        }
      }
    },
    isRunning ? delay : null
  );

  // 상세보기 Drawer Control
  const handleNoticeDrawerClose = () => {
    setOpenNotice(false);
    setTimeout(() => {
      props.isFloat();
    }, 200);
  };
  const handleNoticeDrawerOpen = () => {
    props.isNotFloat();

    setTimeout(() => {
      setOpenNotice(true);
    }, 200);
  };

  const handleQnaDrawerClose = () => {
    setOpenQna(false);
    props.isFloat();
  };
  const handleQnaDrawerOpen = () => {
    getBroadQnaList(props.broad_info.broad_seq);
    props.isNotFloat();

    setTimeout(() => {
      setOpenQna(true);
    }, 200);
  };
  const AutoLink = (prop: autoLinkProp) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word, index) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "none", color: "#FF376D " }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };
  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    handleNoticeDrawerOpen,
  }));

  return (
    <>
      {noticeText.length > 0 || qnaText.length > 0 ? (
        <div className={`thd-live-notice-wrapper`}>
          <Box className="thd-live-notice-root">
            {/* 슬라이더 */}
            <div className="thd-notice-slider">
              <div ref={noticeSliderRef} className="thd-notice-silder-list">
                {rollingTexts.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="thd-notice-silder-item"
                      onClick={item.type === notiCode ? handleNoticeDrawerOpen : handleQnaDrawerOpen}
                    >
                      <span className={`thd-live-${item.type}-typo`}>{item.type === notiCode ? "공지사항" : "Q&A"}</span>
                      <span>{item.text}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Box>
          {/* <Box className={`thd-live-notice-root`} onClick={handleNoticeDrawerOpen}>
            <Box className="thd-live-notice-item"> */}
          {/* <Box component="img" className="thd-live-notice-img" src="/images/notice.png" /> */}
          {/* <span>&nbsp;</span> */}
          {/* <Typography
                className="thd-live-notice-text"
                fontFamily="Noto Sans KR"
                fontWeight="700"
                fontSize="13px"
                fontStyle="normal"
                color="#FF5C83"
              >
                {"공지사항"}
              </Typography>
              &nbsp;
              <Typography
                className="thd-live-notice-text"
                fontFamily="Noto Sans KR"
                fontWeight="400"
                fontSize="13px"
                fontStyle="normal"
                color="#FFFFFF"
              >
                {"공지사항"}
              </Typography>
            </Box>
          </Box> */}
          {/* 상세보기 */}
          <Drawer
            PaperProps={{ elevation: 0, style: { borderTopRightRadius: "16px", borderTopLeftRadius: "16px" } }}
            anchor="bottom"
            open={openNotice}
            onClose={handleNoticeDrawerClose}
            className="thd-live-more-body"
          >
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                bgcolor: "while",
                borderRadius: "16px",
                position: "relative",
                padding: "18px",
                overflow: "hidden",
              }}
            >
              {/* 공지사항 상세보기 상단 */}
              <Box className="thd-live-notice-drawer-top">
                <Box component="img" src="/images/modal-notice.png" className="thd-live-notice-drawer-top-img" />
                <div className="thd-live-notice-drawer-top-text">&nbsp;공지사항</div>
                <Box className="thd-live-notice-drawer-close">
                  <Box component="img" src="/images/notice-close.svg" onClick={handleNoticeDrawerClose} />
                </Box>
              </Box>
              <Box className="thd-live-notice-more" sx={{ display: "flex", width: "100%", height: "100%", whiteSpace: "pre-line" }}>
                <Typography
                  className="thd-live-notice-scroll"
                  component="div"
                  fontFamily="Noto Sans KR"
                  fontWeight="400"
                  fontSize="14px"
                  fontStyle="normal"
                  color="#777777"
                  // overflow="scroll"
                  width="100%"
                >
                  <AutoLink text={props.broad_info.broad_notice} />
                </Typography>
              </Box>
            </Box>
          </Drawer>
          <Drawer
            PaperProps={{ elevation: 0, style: { borderTopRightRadius: "16px", borderTopLeftRadius: "16px" } }}
            anchor="bottom"
            open={openQna}
            onClose={handleQnaDrawerClose}
            className="thd-live-more-body"
          >
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                bgcolor: "while",
                borderRadius: "16px",
                position: "relative",
                padding: "18px",
                overflow: "hidden",
              }}
            >
              <Box className="thd-live-qna-drawer-top">
                <Box component="img" src="/images/modal-qna.png" className="thd-live-qna-drawer-top-img" />
                <div className="thd-live-qna-drawer-top-text">Q&A</div>
                <Box className="thd-live-qna-drawer-close">
                  <Box component="img" src="/images/notice-close.svg" onClick={handleQnaDrawerClose} />
                </Box>
              </Box>
              {/* QNA 상세보기 */}
              <Box className="thd-live-qna-more" sx={{ display: "flex", width: "100%" }}>
                <List className="thd-live-qna-list" sx={{ paddingTop: "0px" }}>
                  {broadQnaList.map((obj: any, index: number) => {
                    if (obj.sk !== undefined) {
                      return (
                        <div key={`qna${obj.sk}`}>
                          <ListItem disablePadding sx={{ alignItems: "start", marginTop: "10px" }}>
                            {/* Question Icon */}
                            <Box component="img" className="thd-question-icon" src="/images/icon-question.png" />
                            <ListItemText
                              className="thd-question-item"
                              primary={
                                <Typography
                                  paddingRight="10px"
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="700"
                                  fontSize="16px"
                                  fontStyle="normal"
                                  color="#111111"
                                  sx={{ wordBreak: "break-all" }}
                                >
                                  {obj.qContent}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="400"
                                  fontSize="13px"
                                  fontStyle="normal"
                                  color="#999999"
                                >
                                  {cUtils.maskingChar(obj.qUserName) +
                                    " • " +
                                    dayjs(String(obj.qCreatedTimestamp)).format("YYYY.MM.DD, HH:mm")}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding className="thd-answer-list-item">
                            <ListItemText
                              primary={
                                <Typography
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="400"
                                  fontSize="14px"
                                  fontStyle="normal"
                                  color="#111111"
                                >
                                  <AutoLink text={obj.aContent} />
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                      );
                    }
                  })}
                </List>
              </Box>
            </Box>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNoticeQnAIOS);
