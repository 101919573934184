import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import "./MainDisplay.css";

interface propsType {}

const MainDisplay = (props: propsType) => {
  return (
    <>
      <Stack spacing={0} direction="column">
        <h2>놓치지마! Live</h2>
        <div style={{ position: "relative" }}>
          <CardMedia component="img" className="live-thumbnail" height="300" image="/images/2020_man_topimg1.jpeg" />
          <div className="main-display-label-middle">
            <b>스트리밍TF</b>
            <br />
            <b>화이팅</b>
          </div>
          {/* <div className="main-display-label-bottom">
            <b>😝대박 기원😝</b>
          </div> */}
        </div>
      </Stack>
    </>
  );
};

export default MainDisplay;
