import { useState, useRef, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// import user component
import { userState } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../service/common_utils";
import Toast from "../../../utilities/Toast";

interface propsType {
  userState: userState;
  broadInfo: any;
  ivsInfo: any;
}

const StreamInfo = (props: propsType) => {
  const cUtils = new CommonUtils();

  const toastRef: any = useRef();

  const [openStreamInfo, setOpenStreamInfo] = useState(false);

  // 스트림정보 더보기
  const moreViewStreamInfo = () => {
    setOpenStreamInfo(true);
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenStreamInfo(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box sx={{ width: "100%" }}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => moreViewStreamInfo()} endIcon={<SearchIcon />}>
                스트림
              </Button>
              <CopyToClipboard
                text={`rtmps://${props.ivsInfo.ingest_endpoint}:443/app/`}
                onCopy={() => {
                  toastRef.current?.toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
                }}
              >
                <Button endIcon={<ContentCopyIcon />}>서버</Button>
              </CopyToClipboard>
              <CopyToClipboard
                text={props.ivsInfo.stream_key}
                onCopy={() => {
                  toastRef.current?.toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
                }}
              >
                <Button endIcon={<ContentCopyIcon />}>KEY</Button>
              </CopyToClipboard>
            </ButtonGroup>
            <ButtonGroup variant="outlined" fullWidth sx={{ maxWidth: 240 }}>
              <Button
                onClick={() => {
                  if (props.broadInfo.extra_type === "THD") {
                    window.open(`/frame/${props.broadInfo.broad_seq}`);
                  } else {
                    window.open(`/live/${props.broadInfo.broad_seq}`);
                  }
                }}
                endIcon={<SearchIcon />}
              >
                라이브
              </Button>
              <Button onClick={() => window.open(`/frame/${props.broadInfo.broad_seq}?mode=rehearsal`)} endIcon={<SearchIcon />}>
                리허설
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      )}
      {/* 스트림 정보 */}
      <Dialog
        open={openStreamInfo}
        onClose={() => {
          setOpenStreamInfo(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "80%" } } }}
      >
        <DialogTitle>{"스트림 정보"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
            <TextField label="스트림 서버" value={`rtmps://${props.ivsInfo.ingest_endpoint}:443/app/`} size="small" fullWidth />
            <TextField label="스트림 키" value={props.ivsInfo.stream_key} size="small" fullWidth />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenStreamInfo(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 스트림 정보 */}
      <Toast ref={toastRef} />
    </>
  );
};

export default StreamInfo;
