import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LinearProgress from "@mui/material/LinearProgress";

import { DataGrid } from "@mui/x-data-grid";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, GetChannelInfoParam } from "../../service/thehyundai-rest-api";
import { prodInfo } from "../../interface/MainInterface";

const emptyChannelInfo = {
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_status: "",
  broad_cover_url: "",
  manager_id: "",
  manager_name: "",
  password: "",
  stream_server: "",
  stream_key: "",
  playback_url: "",
  total_likes: 0,
  total_views: 0,
  broad_prod_list: [],
};

const GetChannelInfo = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [broadSeq, setBroadSeq] = useState("");
  const [channelInfo, setChannelInfo] = useState(emptyChannelInfo);
  const prodColumns = [
    { field: "prod_title", headerName: "상품명", width: 300, editable: true },
    { field: "prod_callback", headerName: "랜딩URL", flex: 1, editable: true },
    { field: "prod_id", headerName: "id", width: 100, hide: true },
  ];
  const [prodRows, setProdRows] = useState<prodInfo[]>([]);
  const [expandProdList, setExpandProdList] = useState(false);
  const toggleExpandProdGrid = () => {
    setExpandProdList((expandProdList) => !expandProdList);
  };

  const getChannelInfo = async () => {
    if (broadSeq === "" || broadSeq === undefined) {
      window.alert("조회할 채널 고유번호를 입력하세요.");
      return;
    }

    const param: GetChannelInfoParam = {
      broad_seq: broadSeq,
    };
    setIsLoading(true);
    const res = await theHyundaiApi.get_broadcast_channel_info(param);
    if (res.code === "200") getBroadcastChannelSuccess(res.response);
    else getBroadcastChannelInfoFail(res);
  };

  const getBroadcastChannelSuccess = (res: any) => {
    setChannelInfo(res.channel_info);
    setProdRows(res.channel_info.broad_prod_list);
    setIsLoading(false);
  };
  const getBroadcastChannelInfoFail = (error: any) => {
    if (error.code === "201" || error.code === "202") alert(`${error.response.error_msg}`);
    else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };
  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            채널 정보 조회 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            생성한 채널의 정보를 조회한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "get_broadcast_channel_info"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_seq (string) : "string"
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { channel_info: "{*channel_info}" } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            *channel_info
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_seq (string) : 채널 고유번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_title (string) : 채널명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_desc (string) : 채널 설명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_start_tm (string) : 시작시간(YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_stop_tm (string) : 종료시간(YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_status (string) : 채널상태 (CREATE | READY | START | STOP | DELETE)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_cover_url (string) : 채널 대표 이미지 주소
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 채널 비밀번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : 채널 관리자ID
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name (string) : 채널 관리자 이름
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - stream_server (string) : 송출용 서버 URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - stream_key (string) : 송출용 스트림 키
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - playback_url (string) : 방송 시청용 주소
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_prod_list (list) : {"[{ 협의필요 }]"}
          </Typography>
          <TextField
            id="txtBroadSeq"
            label="Broad Seq"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={broadSeq}
            onChange={(e) => {
              setBroadSeq(e.target.value);
            }}
          />
          <Button variant="contained" onClick={getChannelInfo}>
            채널 정보 조회 요청
          </Button>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Result
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_seq : {channelInfo.broad_seq}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_title : {channelInfo.broad_title}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_desc : {channelInfo.broad_desc}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_start_tm : {channelInfo.broad_start_tm}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_stop_tm : {channelInfo.broad_stop_tm}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_status : {channelInfo.broad_status}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_cover_url : {channelInfo.broad_cover_url}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password : {channelInfo.password}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id : {channelInfo.manager_id}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name : {channelInfo.manager_name}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - stream_server : {channelInfo.stream_server}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - stream_key : {channelInfo.stream_key}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - playback_url : {channelInfo.playback_url}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - total_likes : {channelInfo.total_likes}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - total_views : {channelInfo.total_views}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_prod_list :
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={1} direction="column">
              <Stack spacing={2} direction="row" sx={{ width: "100%", justifyContent: "flex-end" }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    toggleExpandProdGrid();
                  }}
                >
                  {expandProdList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Stack>
              <Box sx={expandProdList ? { width: "100%", height: 800 } : { width: "100%", height: 200 }}>
                <DataGrid
                  rows={prodRows}
                  columns={prodColumns}
                  rowHeight={40}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  getRowId={(row) => row.prod_id}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  loading={isLoading}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default GetChannelInfo;
