import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, GetChannelListParam } from "../../service/thehyundai-rest-api";

interface IChannelCols {
  broad_seq: string;
  broad_title: string;
  broad_desc: string;
  broad_start_tm: string;
  broad_stop_tm: string;
  broad_status: string;
  manager_id: string;
  manager_name: string;
  password: string;
}

const GetChannelList = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [channelRows, setChannelRows] = useState<IChannelCols[]>([]);
  const channelCols = [
    { field: "broad_title", headerName: "채널명", width: 200 },
    { field: "broad_start_tm", headerName: "방송시작시간", width: 200 },
    { field: "broad_stop_tm", headerName: "방송종료시간", width: 200 },
    { field: "broad_status", headerName: "방송상태", width: 100 },
    { field: "manager_id", headerName: "관리자ID", width: 200 },
    { field: "manager_name", headerName: "관리자이름", width: 200 },
    { field: "broad_seq", headerName: "방송고유번호", width: 300 },
    { field: "password", headerName: "비밀번호", width: 150 },
  ];
  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().subtract(30, "day"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().add(7, "day"));
  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };
  const [managerId, setManagerId] = useState("");

  const getChannelList = async () => {
    if (managerId === "" || managerId === undefined) {
      window.alert("관리자ID를 입력하세요.");
      return;
    }
    setIsLoading(true);

    const statusKeys = Object.keys(statusState);
    let searchStatus = [];
    for (const key of statusKeys) {
      if (statusState[key]) {
        searchStatus.push(key);
      }
    }

    // 어드민, 일반 사용자 필터
    const sort = "ASCEND";
    const param: GetChannelListParam = {
      broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).format("YYYYMMDD")],
      broad_status: searchStatus,
      sort: sort,
      manager_id: managerId,
    };
    const res = await theHyundaiApi.get_broadcast_channel_list(param);
    if ("" + res.code === "200") {
      setChannelRows(res.response.channel_list);
    }

    setIsLoading(false);
  };

  const [statusState, setStatusState] = useState<any>({
    CREATE: true,
    READY: true,
    START: true,
    STOP: true,
    VOD: true,
    DELETE: false,
  });
  const { CREATE, READY, START, STOP, VOD, DELETE } = statusState;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusState({
      ...statusState,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            채널 리스트 조회 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            등록된 채널 리스트를 조회한다.
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "get_broadcast_channel_list"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_date (list) : [ 검색일자시작, 검색일자끝] (YYYYMMDD)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_status (list) : [CREATE, READY, START, STOP ...]
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { channel_list: ["*channel_info"] } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            *channel_info
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_seq (string) : 채널 고유번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_title (string) : 채널명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_desc (string) : 채널 설명
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_start_tm (string) : 시작시간(YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_stop_tm (string) : 종료시간(YYYYMMDDhh24miss)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_status (string) : 채널상태 (CREATE | READY | START | STOP | DELETE)
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - broad_cover_url (string) : 채널 대표 이미지 주소
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 채널 비밀번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : 채널 관리자ID
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name (string) : 채널 관리자 이름
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 채널 비밀번호
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"row"} spacing={2} sx={{ mb: "3px" }}>
              <DesktopDatePicker
                label="방송일From"
                inputFormat="MM/DD/YYYY"
                value={srchDateFrom}
                onChange={handleSrchDateFromChange}
                renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
              />
              <DesktopDatePicker
                label="방송일To"
                inputFormat="MM/DD/YYYY"
                value={srchDateTo}
                onChange={handleSrchDateToChange}
                renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
              />
            </Stack>
          </LocalizationProvider>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">방송상태</FormLabel>
            <FormGroup>
              <Stack direction={"row"} spacing={2}>
                <FormControlLabel
                  control={<Checkbox checked={CREATE} onChange={handleChange} name="CREATE" />}
                  label="CREATE"
                />
                <FormControlLabel
                  control={<Checkbox checked={READY} onChange={handleChange} name="READY" />}
                  label="READY"
                />
                <FormControlLabel
                  control={<Checkbox checked={START} onChange={handleChange} name="START" />}
                  label="START"
                />
                <FormControlLabel
                  control={<Checkbox checked={STOP} onChange={handleChange} name="STOP" />}
                  label="STOP"
                />
                <FormControlLabel control={<Checkbox checked={VOD} onChange={handleChange} name="VOD" />} label="VOD" />
                <FormControlLabel
                  control={<Checkbox checked={DELETE} onChange={handleChange} name="DELETE" />}
                  label="DELETE"
                />
              </Stack>
            </FormGroup>
          </FormControl>
          <TextField
            id="txtManagerId"
            label="Manager ID"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerId}
            onChange={(e) => {
              setManagerId(e.target.value);
            }}
          />
          <Button variant="contained" onClick={getChannelList}>
            채널 리스트 조회 요청
          </Button>
          {/** 사용자 리스트 그리드 */}
          <div style={{ width: "100%", height: "490px" }}>
            <DataGrid
              rows={channelRows}
              columns={channelCols}
              pageSize={7}
              rowsPerPageOptions={[7]}
              getRowId={(row) => row.broad_seq}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: GridToolbarFilterButton,
              }}
              loading={isLoading}
            />
          </div>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default GetChannelList;
