import { useEffect, useState } from "react";
import dayjs from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpClientApi } from "../../../service/client-rest-api";
import { HttpChannelApi, SetStreamParams, ChBaseParams, UpdateChParams } from "../../../service/channel-api";
import { CommonUtils } from "../../../service/common_utils";
import LoadingCircle from "../../../utilities/LoadingCircle";

interface propsType {
  userState: userState;
  broadInfo: any;
  ivsInfo: any;
  broadTypes: any;
  getChannelInfo: any;
  sendIoTMessageMeta: any;
  reload_parent: any;
  callback: any;
}

const clientApi = new HttpClientApi();
const channelApi = new HttpChannelApi();

const BroadInfo = (props: propsType) => {
  const [loading, setLoading] = useState(false);
  const cUtils = new CommonUtils();

  const [broadType, setBroadType] = useState("");

  // 방송타입별 텍스트
  useEffect(() => {
    if (!cUtils.isEmptyObj(props.broadInfo) && !cUtils.isEmptyObj(props.broadTypes)) {
      for (const typeInfo of props.broadTypes) {
        if (typeInfo.code === props.broadInfo.extra_type) {
          setBroadType(typeInfo.code_name);
        }
      }
    }
  }, [props.broadInfo, props.broadTypes, cUtils]);

  // 방송상태 변경
  const handleStream = async (status: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    let cmd = "";
    if (status === "START") cmd = "start_stream";
    else if (status === "READY") cmd = "ready_stream";
    else if (status === "STOP") cmd = "stop_stream";

    setLoading(true);
    const param: SetStreamParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      cmd: cmd,
    };

    // 방송 스트림 상태 조회
    let check_stream = true;
    if (param.cmd === "start_stream") {
      await clientApi.get_stream_with_status(props.ivsInfo.channel_arn, param.cmd).then((res) => {
        if (res.result_code !== "200") check_stream = false;
        if (cUtils.isEmptyObj(res.result_body)) check_stream = false;
      });
    }
    if (param.cmd === "start_stream" && !check_stream && !props.userState.isSuperAdmin) {
      alert("방송 시작전 영상을 먼저 송출하세요.");
      setLoading(false);
      return;
    }

    await fncUpdateStream(param, cmd);
    // 방송이 종료되면 VOD를 자동으로 생성한다.
    if (status === "STOP") {
      props.callback({ command: "pool_vod" });
      // await fncMakeVod();
    }

    props.getChannelInfo();
    await props.reload_parent();
    setLoading(false);
  };

  const fncUpdateStream = async (param: any, cmd: string) => {
    const res = await channelApi.handler_stream(param);
    console.log("UPDATE STREAM : ", res);
    if (res.result_code === "200") {
      props.sendIoTMessageMeta(cmd);
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
  };

  const fncMakeVod = async () => {
    const param: ChBaseParams = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
    };
    const res_record = await channelApi.get_record_list(param);
    if (res_record.result_code === "200") {
      if (res_record.result_body.length > 0) {
        const lastRecord = res_record.result_body[res_record.result_body.length - 1];
        const lastRecordStartAtKR = dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString();
        const date1 = dayjs(lastRecordStartAtKR, "YYYYMMDDHHmmss");
        const date2 = dayjs(props.broadInfo.broad_start_tm, "YYYYMMDDHHmmss");
        let diff = date2.diff(date1, "s");
        if (diff < 0) {
          diff = 0; //방송전 리허설이 없었으면 방송시작시간으로
        }

        const param: UpdateChParams = {
          pk: props.broadInfo.pk,
          broad_seq: props.broadInfo.broad_seq,
          broad_title: props.broadInfo.broad_title,
          broad_desc: props.broadInfo.broad_desc,
          broad_start_tm: props.broadInfo.broad_start_tm,
          broad_stop_tm: props.broadInfo.broad_stop_tm,
          broad_prod_list: props.broadInfo.broad_prod_list,
          broad_notice: props.broadInfo.broad_notice,
          broad_status: "VOD",
          vod_url: "/" + lastRecord.stream_info.bucket_root + "/media/hls/master.m3u8",
          vod_started_at: dayjs(new Date(lastRecord.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString(),
          vod_start_sec: diff,
          vod_end_sec: 0,
          high_start_sec: props.broadInfo.high_start_sec,
          password: props.broadInfo.password,
          extra_type: props.broadInfo.extra_type,
          progress_store: props.broadInfo.progress_store,
          setting: props.broadInfo.setting,
          host_id: props.broadInfo.host_id,
          channel_img: props.broadInfo.channel_img,
          channel_template: props.broadInfo.channel_template,
        };
        const makeVodRes = await channelApi.update_channel(param);
        console.log("MAKE VOD : ", makeVodRes);
      }
    } else {
      console.error("[ERROR] getRecordList : ", res_record.result_body);
    }
  };

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (cUtils.isEmptyObj(props.broadInfo)) return false;
    switch (newAlignment) {
      case "READY":
        if (window.confirm("방송을 준비중 상태로 변경하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "START":
        if (window.confirm("방송을 시작 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      case "STOP":
        if (window.confirm("방송을 중지 하시겠습니까?")) {
          handleStream(newAlignment);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadType("");
      setLoading(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box>
          <Stack spacing={2} direction="column">
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
              <TextField label="방송명" value={props.broadInfo.broad_title} size="small" fullWidth />
              {broadType !== "" && (
                <TextField label="타입" value={broadType} size="small" fullWidth sx={{ maxWidth: { md: 100, sx: 400 } }} />
              )}
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
              <TextField label="시작시간" value={dayjs(props.broadInfo.broad_start_tm).format("YYYY-MM-DD HH:mm")} size="small" fullWidth />
              <TextField label="종료시간" value={dayjs(props.broadInfo.broad_stop_tm).format("YYYY-MM-DD HH:mm")} size="small" fullWidth />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
              <TextField label="비밀번호" value={props.broadInfo.password} size="small" fullWidth sx={{ maxWidth: { md: 140, sx: 400 } }} />
              <TextField label="관리자" value={props.broadInfo.host_id} size="small" fullWidth />
            </Stack>
            <ToggleButtonGroup
              color="standard"
              value={props.broadInfo.broad_status}
              fullWidth
              size="small"
              exclusive
              onChange={handleStatusChange}
              aria-label="방송상태"
            >
              <ToggleButton value="CREATE" color="success">
                생성
              </ToggleButton>
              <ToggleButton value="READY" color="warning">
                준비중
              </ToggleButton>
              <ToggleButton value="START" color="error">
                방송중
              </ToggleButton>
              <ToggleButton value="STOP" color="standard">
                종료
              </ToggleButton>
              <ToggleButton value="VOD" color="info">
                VOD
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
      )}
      <LoadingCircle loading={loading} />
    </>
  );
};

export default BroadInfo;
