import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";

// import user component
import { HttpChannelApi, GetChannelListParams } from "../../../service/channel-api";
import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utilities/LoadingCircle";
import { calculateWindowSize } from "../../../utilities/utils";

interface propsType {
  userState: userState;
  windowSize: any;
}

const channelApi = new HttpChannelApi();

const ChannelList = (props: propsType) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().subtract(30, "day"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().add(7, "day"));

  const [channelList, setChannelList] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = useState(608);

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "관리",
      width: 100,
      renderCell: (params: GridCellParams) => {
        const goDetail = () => {
          if (calculateWindowSize(props.windowSize.width) === "xs") {
            // 모바일
            evtGridMobileClick();
          } else {
            evtGridPCClick();
          }
        };
        const evtGridMobileClick = () => {
          const url = `/mngChannel/${params.row.pk.split("#")[1]}/${params.row.broad_seq}/mobile`;
          navigate(url);
        };
        const evtGridPCClick = () => {
          const url = `/mngChannel/${params.row.pk.split("#")[1]}/${params.row.broad_seq}/pc`;
          navigate(url);
        };
        const goDetailStudio = () => {
          const url = `/mngChannel/${params.row.pk.split("#")[1]}/${params.row.broad_seq}/studio`;
          navigate(url);
        };
        return (
          <>
            <Stack direction={"row"}>
              <IconButton onClick={goDetail}>
                <SearchIcon />
              </IconButton>
              <IconButton onClick={goDetailStudio}>
                <LinkedCameraIcon />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
    {
      field: "broad_start_tm",
      headerName: "시작일시",
      width: 110,
      valueGetter: (value: any) => dayjs(value.row.broad_start_tm, "YYYYMMDDHHmmss").format("MM-DD HH:mm"),
    },
    { field: "broad_title", headerName: "방송명", flex: 1, minWidth: 300 },
    { field: "broad_status", headerName: "상태", width: 80 },
    { field: "host_id", headerName: "채널관리자", width: 200 },
    {
      field: "broad_stop_tm",
      headerName: "종료일시",
      width: 160,
      valueGetter: (value: any) => dayjs(value.row.broad_stop_tm, "YYYYMMDDHHmmss").format("MM-DD HH:mm"),
    },
    {
      field: "broad_desc",
      headerName: "비고",
      width: 200,
    },
  ];

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const getChannelList = async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;
    setLoading(true);

    const param: GetChannelListParams = {
      broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
      broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
      sort: "DESCEND",
    };
    const res = await channelApi.get_channel_list(param);
    if (res.result_code === "200") {
      setChannelList(res.result_body);
    } else {
      console.error(res.result_body);
    }

    setLoading(false);
  };

  const evtGridDblClick = useCallback(
    (param: GridCellParams) => {
      const url = `/mngChannel/${param.row.pk.split("#")[1]}/${param.row.broad_seq}/pc`;
      navigate(url);
    },
    [navigate]
  );

  useEffect(() => {
    getChannelList();
  }, []);

  useEffect(() => {
    setGridHeight(props.windowSize.height - 230);
  }, [props.windowSize]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setSrchDateFrom(dayjs().subtract(30, "day"));
      setSrchDateTo(dayjs().add(7, "day"));
      setChannelList([]);
      setGridHeight(608);
    };
  }, []);
  return (
    <>
      <Box>
        <Stack spacing={2} direction="column">
          {/* 검색조건 */}
          <Box>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="방송일From"
                  inputFormat="MM/DD/YYYY"
                  value={srchDateFrom}
                  onChange={handleSrchDateFromChange}
                  renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                />
                <DesktopDatePicker
                  label="방송일To"
                  inputFormat="MM/DD/YYYY"
                  value={srchDateTo}
                  onChange={handleSrchDateToChange}
                  renderInput={(params) => <TextField {...params} size="small" autoComplete="off" fullWidth />}
                />
              </LocalizationProvider>
              <Button variant="contained" size="small" onClick={getChannelList}>
                조회
              </Button>
            </Stack>
          </Box>
          {/* 검색조건 */}
          {/* 그리드 */}
          <Box>
            <Paper sx={{ width: "100%", height: gridHeight, p: 2 }} elevation={5}>
              <DataGrid
                rows={channelList}
                rowHeight={40}
                columns={columns}
                rowsPerPageOptions={[3, 10, 20, 50, 100]}
                pagination
                getRowId={(row) => row.broad_seq}
                onCellDoubleClick={evtGridDblClick}
                // onCellClick={evtGridMobileClick}
              />
            </Paper>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={loading} />
    </>
  );
};

export default ChannelList;
