import { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import "./AskLoginDialog.css";

interface propsType {
  command: boolean;
  callBack: any;
}

const AskLoginDialog = (props: propsType) => {
  const [openLoginNotice, setOpenLoginNotice] = useState(false);

  const handleLoginNoticeModalOpen = () => setOpenLoginNotice(true);
  const handleLoginNoticeModalClose = () => setOpenLoginNotice(false);

  useEffect(() => {
    if (props.command) {
      handleLoginNoticeModalOpen();
    } else {
      handleLoginNoticeModalClose();
    }
  }, [props.command]);

  const fncYes = () => {
    props.callBack("YES");
  };

  const fncNo = () => {
    handleLoginNoticeModalClose();
    props.callBack("NO");
  };

  useEffect(() => {
    return () => {
      setOpenLoginNotice(false);
    };
  }, []);

  return (
    <Dialog
      open={openLoginNotice}
      onClose={() => {
        fncNo();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"로그인이 필요합니다."}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">채팅에 참여하시려면 로그인이 필요합니다. 로그인 하시겠습니까?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            fncNo();
          }}
        >
          아니오
        </Button>
        <Button
          onClick={() => {
            fncYes();
          }}
          autoFocus
        >
          로그인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AskLoginDialog;
