import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { userState } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../service/common_utils";

import StatisticInfoMobile from "./StatisticInfoMobile";

interface propsType {
  userState: userState;
  broadInfo: any;
  broad_seq: string;
  broad_title: string;
  broad_status: string;
  video_status: string;
  host_picture: string;
  host_name: string;
  postMsg: any;
  tot_view_count: number;
  tot_like_count: number;
  tot_concurrent_view: number;
  hidden_menu: boolean;
  vodType: string;
  haveHighlight: boolean;
  toggleVodType: any;
  muted: boolean;
  toggleMute: any;
  braodWait: boolean;
  handleResolution: any;
}

const LiveInfoHeaderMobile = (props: propsType, ref: any) => {
  const titleShadow: string = "0px 0px 1px #494949";

  const [openAlert, setOpenAlert] = useState(false);
  const [startIvs, setStartIvs] = useState(false);
  const [totLikeCount, setTotLikeCount] = useState(0);
  const [totViewCount, setTotViewCount] = useState(0);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenAlert(false);
      setStartIvs(false);
      setTotLikeCount(0);
      setTotViewCount(0);
    };
  }, []);

  // 새로고침
  const handleRefresh = () => window.location.reload();

  // 볼륨 컨트롤
  const toggleMute = () => {
    setOpenAlert(false);
    props.toggleMute();
  };

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
      }, 30000);
    }
  }, [openAlert]);

  useEffect(() => {
    setTotViewCount(props.tot_view_count);
    setTotLikeCount(props.tot_like_count);
  }, [props.tot_view_count, props.tot_like_count]);

  // 음소거 알림
  const openAlertMuted = () => {
    setOpenAlert(true);
  };

  const initStartIvs = () => {
    setStartIvs(true);
  };

  const addLikeCount = () => {
    setTotLikeCount((totLikeCount: number) => totLikeCount + 1);
  };

  const addViewCount = () => {
    setTotViewCount((totViewCount: number) => totViewCount + 1);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    openAlertMuted,
    initStartIvs,
    addLikeCount,
    addViewCount,
  }));

  return (
    <>
      <Box className="live-header-wrapper color-snow" sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}>
        <Stack direction="column" spacing={0} sx={{ width: "100%", userSelect: "text" }}>
          <Stack direction="row" spacing={0} sx={{ width: "100%", mb: "10px" }}>
            {/* 로고영역 */}
            <Grid item xs={6} sx={{ display: "flex" }}>
              <Box component="img" className="thd-live-logo white-shadow" src="/images/logo.png" />
            </Grid>
            {/* 버튼영역 */}
            <Grid item xs={6} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                {/* 새로고침 */}
                <IconButton
                  sx={props.video_status !== "onStatePlaying" ? { display: "flex", padding: 0 } : { display: "none", padding: 0 }}
                  onClick={handleRefresh}
                >
                  <AutorenewIcon sx={{ fontSize: "20px" }} className="color-snow white-shadow" />
                </IconButton>
                {/* 볼륨 컨트롤 */}
                {openAlert ? (
                  <Box
                    className="thd-live-mute-text"
                    sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}
                    onClick={toggleMute}
                  >
                    <Box className="thd-live-mute-text-container">
                      <Box component="img" src="/images/sound-mute.png" className="thd-live-mute-text-img" />
                      <Typography
                        sx={{
                          position: "relative",
                          left: "3px",
                          fontSize: "13px",
                          color: "white",
                          overflow: "hidden",
                          textOverflow: "clip",
                        }}
                      >
                        음소거 해제
                      </Typography>
                    </Box>
                  </Box>
                ) : startIvs ? (
                  <IconButton sx={{ padding: 0 }} onClick={toggleMute}>
                    {props.muted ? (
                      <Box component="img" className="thd-live-mute thd-shake_bell white-shadow" src="/images/sound-mute.png" />
                    ) : (
                      <Box component="img" className="thd-live-mute white-shadow" src="/images/sound.png" />
                    )}
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Stack>
          <Box sx={{ width: "100%", mb: "10px" }}>
            <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
              <Box>
                <Avatar
                  sx={{
                    width: 44,
                    height: 44,
                    backgroundColor: "#07582e",
                    top: "1px",
                    borderRadius: "21.87px",
                  }}
                  src={props.host_picture}
                ></Avatar>
              </Box>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {props.broad_status === "START" ? <div className="thd-broad-title-live-mark">LIVE</div> : <></>}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textShadow: titleShadow,
                      fontFamily: "Noto Sans KR",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "23px",
                      color: "#FFFFFF",
                    }}
                  >
                    {props.broad_title}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textShadow: titleShadow,
                    fontFamily: "Noto Sans KR",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "23px",
                    color: "#FFFFFF",
                    marginTop: "0px",
                  }}
                >
                  {props.host_name}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <StatisticInfoMobile
            userState={props.userState}
            broadInfo={props.broadInfo}
            concurrentCnt={props.tot_concurrent_view}
            enterCnt={totViewCount}
            likeCnt={totLikeCount}
          />
          {/* {props.broad_status === "VOD" && props.haveHighlight ? (
            <Box sx={{ width: "100%", mt: 1 }} onClick={props.toggleVodType}>
              <span className="thd-vod-select font-small white-shadow">
                {props.vodType === "highlight" ? "풀영상 보기" : "하이라이트 보기"}
              </span>
            </Box>
          ) : (
            <></>
          )} */}
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(LiveInfoHeaderMobile);
