import { ApiBase, BaseParams, BroadParams } from "./api-base";

export interface CBroadParams extends BroadParams {}
export interface GetChannelinfoParams extends BaseParams {
  st_add: string;
}
export interface GetChannelListParams {
  broad_date: string[];
  broad_status: string[];
  sort: string;
}

export interface GetCodeInfoParams {
  large_code: string;
}

export interface PutMetadataParams extends BroadParams {
  chime_channel_arn: string;
  sender_user_arn: string;
  metadata: string;
  meta_type: string;
}

export interface ListChannelMessageParams {
  broad_seq: string;
  next_token: any;
  list_mode: string;
}

export interface SendChannelMessageParams {
  broad_seq: string;
  content: string;
  user_id: string;
}

export interface SendChannelMessageRawParams {
  channel_arn: string;
  content: string;
  type: string;
  persistence: string;
  metadata: string;
  user_arn: string;
  broad_seq: string;
}

export interface UpdateAdminAttrParams {
  user_id: string;
}

export interface SetProdClickParams {
  broad_seq: string;
  user_id: string;
  prod_id: string;
  prod_name: string;
  prod_price: string;
}

export interface GetProdClickParams {
  broad_seq: string;
}

export interface ApplyGameParam {
  game_info: any;
  user_id: string;
  cust_no: string;
  apply_info: any;
}

export interface GetGameApplyListParams {
  gameSeq: string;
}

export interface CheckGameParam {
  game_info: any;
  user_id: string;
}

export interface GetDrawResultParam {
  broad_seq: string;
  sk: string;
}

export interface GetGameInfoParam {
  broad_seq: string;
  sk: string;
}

export class HttpClientApi extends ApiBase {
  onApiName(): string {
    return "MobileApiName";
  }

  onUrlPath(): string {
    return "/broadcast";
  }

  public async get_prod_click(params: GetProdClickParams) {
    const requestBody = {
      command: "get_prod_click",
      broad_seq: params.broad_seq,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_prod_click(params: SetProdClickParams) {
    const requestBody = {
      command: "set_prod_click",
      prod_data: {
        broad_seq: params.broad_seq,
        user_id: params.user_id,
        prod_id: params.prod_id,
        prod_name: params.prod_name,
        prod_price: params.prod_price,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async create_chime_user() {
    const userid = await this.getUserId();
    if (userid === undefined) {
      console.error("current user is undefined");
      return;
    }
    const requestBody = {
      command: "create_chime_user",
      user_info: {
        user_id: userid,
        user_name: userid.split("@")[0],
      },
    };
    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_chime_user_arn() {
    const requestBody = {
      command: "get_chime_user_arn",
      user_info: {
        user_id: await this.getUserId(),
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_chime_user_arn_not_login(user_id: string) {
    const requestBody = {
      command: "get_chime_user_arn",
      user_info: {
        user_id: user_id,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async enter_channel(params: CBroadParams) {
    const userid = await this.getUserId();
    if (userid === undefined) {
      console.error("current user is undefined");
      return;
    }

    let userChatName = await this.getUserChatName();
    if (userChatName === undefined) {
      userChatName = userid.split("@")[0];
    }
    const requestBody = {
      command: "enter_channel",
      broad_info: {
        broad_seq: params.broad_seq,
      },
      user_info: {
        user_id: userid,
        user_name: userChatName,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response ENTER_CHANNEL", response);
    return response;
  }

  public async enter_chat_channel(params: CBroadParams, isAuth: boolean, user_id: string, nick_name: string) {
    let userid = undefined;
    let userChatName = undefined;
    if (isAuth) {
      userid = await this.getUserId();
      userChatName = await this.getUserChatName();
      if (userChatName === undefined && userid !== undefined) {
        userChatName = userid.split("@")[0];
      }
    } else {
      userid = user_id;
      userChatName = nick_name;
    }

    const requestBody = {
      command: "enter_chat_channel",
      broad_seq: params.broad_seq,
      user_id: userid,
      user_name: userChatName,
      auth_status: isAuth + "",
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async enter_chat_channel_frame(params: CBroadParams, isAuth: boolean, user_id: string, nick_name: string) {
    let userid = undefined;
    let userChatName = undefined;

    userid = user_id;
    userChatName = nick_name;

    const requestBody = {
      command: "enter_chat_channel",
      broad_seq: params.broad_seq,
      user_id: userid,
      user_name: userChatName,
      auth_status: isAuth + "",
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_info(params: CBroadParams) {
    const requestBody = {
      command: "get_channel_info",
      broad_seq: params.broad_seq,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  // TODO: broad_date 일자 받아야함
  public async get_channel_list(params: GetChannelListParams) {
    const user_id = await this.getUserId();
    const requestBody = {
      command: "get_channel_list",
      search_condition: {
        broad_date: params.broad_date,
        broad_status: params.broad_status,
        sort: params.sort,
      },
      request_user_id: user_id,
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async get_ivs_info(params: BaseParams) {
    const requestBody = {
      command: "get_ivs_info",
      broad_info: {
        pk: params.pk,
        broad_seq: params.broad_seq,
      },
      request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);

    return response;
  }

  public async get_code_info(params: GetCodeInfoParams) {
    const requestBody = {
      command: "get_code_info",
      search_condition: {
        large_code: params.large_code,
      },
    };

    const response = this.requestPost(requestBody);
    // console.info("Request response", response);
    return response;
  }

  public async put_metadata(params: PutMetadataParams) {
    const requestBody = {
      command: "put_metadata",
      broad_info: {
        broad_seq: params.broad_seq,
      },
      chime_info: {
        chime_channel_arn: params.chime_channel_arn,
        sender_user_arn: params.sender_user_arn,
      },
      metadata: params.metadata,
      meta_type: params.meta_type,
      // request_user_id: await this.getUserId(),
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_channel_meta(broadSeq: string) {
    const requestBody = {
      command: "get_channel_meta",
      broad_seq: broadSeq,
    };

    const response = this.requestPost(requestBody);
    // console.info("get_channel_meta response", response);
    return response;
  }

  public async list_channel_messages(params: ListChannelMessageParams) {
    const requestBody = {
      command: "list_channel_messages",
      broad_seq: params.broad_seq,
      next_token: params.next_token,
      list_mode: params.list_mode,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async send_channel_message(params: SendChannelMessageParams) {
    const requestBody = {
      command: "send_channel_message",
      message_info: {
        broad_seq: params.broad_seq,
        content: params.content,
        user_id: params.user_id,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async send_channel_message_raw(params: SendChannelMessageRawParams) {
    const requestBody = {
      command: "send_channel_message",
      chime_info: {
        channel_arn: params.channel_arn,
        content: params.content,
        type: params.type,
        persistence: params.persistence,
        metadata: params.metadata,
        user_arn: params.user_arn,
        broad_seq: params.broad_seq,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_channel_message(params: any) {
    const requestBody = {
      command: "update_channel_message",
      chime_info: {
        channel_arn: params.channel_arn,
        message_id: params.message_id,
        content: params.content,
        metadata: params.metadata,
        user_arn: params.user_arn,
        broad_seq: params.broad_seq,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async send_qna_message(broad_seq: string, qMessageId: string, aMessageId: string, origin_msg: string, sender_id: string) {
    const requestBody = {
      command: "send_qna_message",
      message_info: {
        broad_seq: broad_seq,
        q_message_id: qMessageId,
        a_message_id: aMessageId,
        origin_msg: origin_msg,
        user_id: sender_id,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async send_qna_message_iot(broad_seq: string, qMessageId: string, aMessageId: string, origin_msg: string, sender_id: string) {
    const requestBody = {
      command: "send_qna_message_iot",
      message_info: {
        broad_seq: broad_seq,
        q_message_id: qMessageId,
        a_message_id: aMessageId,
        origin_msg: origin_msg,
        user_id: sender_id,
      },
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async list_broad_qna(broad_seq: string, sort: string) {
    const requestBody = {
      command: "list_broad_qna",
      broad_seq: broad_seq,
      sort: sort,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async list_chat_history(broad_seq: string) {
    const requestBody = {
      command: "list_chat_history",
      broad_seq: broad_seq,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async list_live_announce_history(broad_seq: string) {
    const requestBody = {
      command: "list_chat_history",
      broad_seq: broad_seq,
      meta: "live_announcements",
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_stream_by_arn(channel_arn: string) {
    const requestBody = {
      command: "get_stream",
      channel_info: {
        channel_arn: channel_arn,
      },
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_stream(broad_seq: string) {
    const requestBody = {
      command: "get_stream",
      broad_seq: broad_seq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_stream_with_status(channel_arn: string, status: string) {
    const requestBody = {
      command: "get_stream_with_status",
      channel_info: {
        channel_arn: channel_arn,
      },
      status: status,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_admin_attr(params: UpdateAdminAttrParams) {
    const requestBody = {
      command: "update_admin_attr",
      user_id: params.user_id,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_access_token() {
    const response = this.getAccessToken();
    return response;
  }

  public async get_sts_credential(user_id: string = "") {
    const requestBody = {
      command: "get_sts_credential",
      user_info: {
        user_id: user_id,
      },
    };

    const response = this.requestPost(requestBody);
    console.info("Request [get_sts_credential] response", response);
    return response;
  }

  public async get_presigned_url(key: string) {
    const requestBody = {
      command: "get_presigned_url",
      key: key,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_concurrent_view(broad_seq: string) {
    const requestBody = {
      command: "get_concurrent_view",
      broad_seq: broad_seq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_thd_prod_list(thdBraodNo: string) {
    const requestBody: any = {};
    // const requestUrl: string = `https://www.thehyundai.com/front/bda/selectLiveBrodItemList.thd?bfmtNo=${thdBraodNo}`;
    const requestUrl: string = "https://hdmalldev.thehyundai.com/front/bda/selectLiveBrodItemList.thd?bfmtNo=202303310001";
    const response = this.requestExtPost(requestUrl, requestBody);
    return response;
  }

  public async get_broad_my_coupon_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_broad_my_coupon_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_broad_my_coupon_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_broad_my_coupon_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_broaderror_data(param: any) {
    const response = this.requestPost(param);
    return response;
  }
}
