import React, { useEffect, useRef, useState } from "react";

const Smile = (props: { removeSmile: any }) => {
  const { removeSmile } = props;

  const [done, setDone] = useState(false);

  const animationReqId = useRef<any>();
  const innerRef = useRef<any>(null);
  const outerRef = useRef<any>(null);

  useEffect(() => {
    let x = parseFloat("6");
    let y = parseFloat("12");

    let phase = Math.random() * 100;
    let radius = Math.random() * 1;
    let speed = 1 + Math.random() * 2;
    let scale = 0.8 + Math.random() * 1.8;
    let grow = 0.01;
    let alpha = 1;

    const draw = () => {
      if (outerRef.current !== null) {
        outerRef.current.style.transform = `translateX(${x}px) translateY(${y}px) translateZ(0) scale(${grow})`;
        outerRef.current.style.opacity = alpha;
      }
    };

    const update = () => {
      if (alpha > 0) {
        alpha -= 0.009;
      }

      if (alpha < 0) {
        alpha = 0;
      }

      x += Math.cos(phase / 30) * radius;
      y -= speed;

      grow += (scale - grow) / 10;
      phase += 1;

      const isDone = y < -500 || alpha <= 0;

      setDone(isDone);
    };

    const loop = () => {
      animationReqId.current = requestAnimationFrame(loop);

      update();
      draw();
    };

    loop();

    return () => {
      if (animationReqId.current) {
        cancelAnimationFrame(animationReqId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!done) return;

    if (animationReqId.current) {
      cancelAnimationFrame(animationReqId.current);
    }

    removeSmile();
  }, [done, removeSmile]);

  return (
    <div className="smile-outer" ref={outerRef}>
      <div className="smile-inner" ref={innerRef}></div>
    </div>
  );
};

export default Smile;
