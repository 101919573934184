import { useEffect, useState, useCallback } from "react";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpCodeApi, GetCodeDetailParams } from "../../../service/code-rest-api";
import { HttpChannelApi, GetChannelinfoParams } from "../../../service/channel-api";
import { appConfig } from "../../../config/Config";
import BroadInfo from "./BroadInfo";
import StreamInfo from "./StreamInfo";
import ChangeData from "./ChangeData";
import StatisticInfo from "./StatisticInfo";

import { useInterval } from "../../../utilities/UseInterval";

interface propsType {
  broadDate: string;
  broadSeq: string;
  userState: userState;
  sendIoTMessageMeta: any;
  sendIoTMessage: any;
  hidden_menu: boolean;
  getChannelInfo: any;
}

const codeApi = new HttpCodeApi();
const channelApi = new HttpChannelApi();

const DialogChangeData = (props: propsType) => {
  const [openBroadInfo, setOpenBroadInfo] = useState<any>(false);
  const [broadInfo, setBroadInfo] = useState<any>({});
  const [ivsInfo, setIvsInfo] = useState<any>({});
  const [broadTypes, setBroadTypes] = useState<any[]>([]);
  const [isPool, setIsPool] = useState(false);

  // 채널정보 조회
  const getChannelInfo = useCallback(async () => {
    const param: GetChannelinfoParams = {
      pk: `broad_date#${props.broadDate}`,
      broad_seq: props.broadSeq,
      st_add: "1",
    };

    const res = await channelApi.get_channel_info(param);
    if (res.result_code === "200") {
      setBroadInfo(res.result_body);
      setIvsInfo(res.ivs_info);
      if (res.result_body.broad_status === "VOD") {
        setIsPool(false);
        reload_parent();
      }
    } else {
      console.error("에러 발생 : " + res.result_body);
    }
  }, [props.broadDate, props.broadSeq]);

  const reload_parent = async () => {
    await props.getChannelInfo();
  };

  // 소분류 코드 조회
  const fncGetCodeDetail = async (largeCode: string) => {
    const sParam: GetCodeDetailParams = {
      large_code: largeCode,
    };

    const res = await codeApi.get_code_detail(sParam);
    if ("" + res.result_code === "200") {
      return res.result_body;
    } else {
      return [];
    }
  };

  // 방송타입 조회
  const initBroadTypes = useCallback(async () => {
    setBroadTypes(await fncGetCodeDetail(appConfig.broadTypeCode));
  }, []);

  // 방송 정보 편집 모달 띄우기
  const handleOpenBroadInfo = async () => {
    await getChannelInfo();
    setOpenBroadInfo(true);
  };

  useEffect(() => {
    initBroadTypes();
  }, [initBroadTypes]);

  useEffect(() => {
    getChannelInfo();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenBroadInfo(false);
      setBroadInfo({});
      setIvsInfo({});
      setBroadTypes([]);
      setIsPool(false);
    };
  }, []);

  const delay: number = 5000; // 5초마다 변경
  useInterval(
    () => {
      console.log("111");
      getChannelInfo();
    },
    isPool ? delay : null
  );

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "block" }} onClick={handleOpenBroadInfo}>
        <img className="thd-footer-right-icon" src="/images/btn-setting.png" />
      </Box>
      {/* 방송 정보 편집 */}
      <Dialog
        open={openBroadInfo}
        onClose={() => {
          setOpenBroadInfo(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "70%" } } }}
      >
        <DialogTitle>{"방송 정보 편집"}</DialogTitle>
        <DialogContent>
          {/* <Stack spacing={2} direction="column" sx={{ mt: 1 }}> */}
          <Stack spacing={2} direction="column" sx={{ pt: 1 }}>
            {/* 방송기본정보 */}
            <BroadInfo
              userState={props.userState}
              broadInfo={broadInfo}
              ivsInfo={ivsInfo}
              broadTypes={broadTypes}
              getChannelInfo={getChannelInfo}
              sendIoTMessageMeta={props.sendIoTMessageMeta}
              reload_parent={reload_parent}
              callback={(payload: any) => {
                console.log("payload : ", payload);
                if (payload.command === "pool_vod") {
                  setIsPool(true);
                }
              }}
            />
            {/* 방송기본정보 */}
            <Divider />
            {/* 스트림정보 */}
            <StreamInfo userState={props.userState} broadInfo={broadInfo} ivsInfo={ivsInfo} />
            {/* 스트림정보 */}
            {/* 기타정보수정 */}
            <ChangeData
              userState={props.userState}
              broadInfo={broadInfo}
              ivsInfo={ivsInfo}
              getChannelInfo={getChannelInfo}
              sendIoTMessageMeta={props.sendIoTMessageMeta}
            />
            {/* 기타정보수정 */}
            <Divider />
            {/* 통계정보 */}
            <StatisticInfo userState={props.userState} broadInfo={broadInfo} />
          </Stack>
          {/* </Stack> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenBroadInfo(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 방송 정보 편집 */}
    </>
  );
};

export default DialogChangeData;
