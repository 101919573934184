import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import user component
import { useWindowSize } from "../../../utilities/useWindowSize";
import { userState } from "../../../interface/MainInterface";
import { appConfig } from "../../../config/Config";
import IoTClient from "../../../service/iot/IoTClient";
import Header from "../../Header";
import ChannelList from "./ChannelList";
import ChannelInfoMobile from "./ChannelInfoMobile";
import ChannelInfoPC from "./ChannelInfoPC";
import ChannelInfoStudio from "./ChannelInfoStudio";

interface propsType {
  userState: userState;
}
const MngChannel = (props: propsType) => {
  const { _date, _broadseq, _mode } = useParams();

  const iotRef: any = useRef();
  const channelInfoMRef: any = useRef();

  const windowSize = useWindowSize();

  const [broadDate, setBroadDate] = useState("");
  const [broadSeq, setBroadSeq] = useState("");
  const [mode, setMode] = useState("");

  const iotConnectEnd = () => {
    console.info("iot Connection Success");
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  // IoT message recieve callback
  function receiveIoTMobileMessage(topic: any, payLoad: any, clientId: string) {
    if (_broadseq !== "none" && broadSeq !== "") channelInfoMRef.current.recieveIoTMessage(topic, payLoad, clientId);
  }

  const sendIoTMessageMeta = (metaName: string) => {
    if (_broadseq !== "none" && broadSeq !== "") {
      const topic = `live/${broadSeq}/metadata`;
      iotRef.current.sendIoTMessageMeta(topic, metaName);
    }
  };

  const sendIoTMessage = (payLoad: any) => {
    if (_broadseq !== "none" && broadSeq !== "") {
      const topic = `live/${broadSeq}/metadata`;
      iotRef.current.sendIoTMessage(topic, payLoad);
    }
  };

  useEffect(() => {
    if (_broadseq !== undefined) setBroadSeq(_broadseq);
    if (_date !== undefined) setBroadDate(_date);
    if (_mode !== undefined) setMode(_mode);
  }, [_date, _broadseq, _mode]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadDate("");
      setBroadSeq("");
      setMode("");
    };
  }, []);
  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ p: 3 }}>
        <Stack spacing={2} direction="column">
          {/* Title */}
          {_broadseq === "none" && (
            <>
              <Box component={"div"}>
                <Typography variant="h5" gutterBottom>
                  방송진행관리
                </Typography>
              </Box>
            </>
          )}
          {/* Title */}
          {/* 채널리스트 조회 */}
          {_broadseq === "none" && (
            <>
              <ChannelList userState={props.userState} windowSize={windowSize} />
            </>
          )}
          {/* 채널리스트 조회 */}
          {/* 상세 */}
          {_broadseq !== "none" && broadSeq !== "" ? (
            <>
              {mode === "mobile" && (
                <>
                  <ChannelInfoMobile
                    ref={channelInfoMRef}
                    userState={props.userState}
                    broadDate={broadDate}
                    broadSeq={broadSeq}
                    sendIoTMessageMeta={sendIoTMessageMeta}
                    sendIoTMessage={sendIoTMessage}
                  />
                  <IoTClient
                    ref={iotRef}
                    broadSeq={broadSeq}
                    iotRegion={appConfig.iotRegion}
                    iotIdentityPollId={appConfig.iotIdentityPollId}
                    iotEndPoint={appConfig.iotEndPoint}
                    recieveIoTMessage={receiveIoTMobileMessage}
                    iotConnectEnd={iotConnectEnd}
                    iotLost={iotLost}
                  />
                </>
              )}
              {mode === "pc" && (
                <>
                  <ChannelInfoPC
                    ref={channelInfoMRef}
                    userState={props.userState}
                    broadDate={broadDate}
                    broadSeq={broadSeq}
                    windowSize={windowSize}
                    sendIoTMessageMeta={sendIoTMessageMeta}
                    sendIoTMessage={sendIoTMessage}
                  />
                  <IoTClient
                    ref={iotRef}
                    broadSeq={broadSeq}
                    iotRegion={appConfig.iotRegion}
                    iotIdentityPollId={appConfig.iotIdentityPollId}
                    iotEndPoint={appConfig.iotEndPoint}
                    recieveIoTMessage={receiveIoTMobileMessage}
                    iotConnectEnd={iotConnectEnd}
                    iotLost={iotLost}
                  />
                </>
              )}
              {mode === "studio" && (
                <>
                  <ChannelInfoStudio
                    ref={channelInfoMRef}
                    userState={props.userState}
                    broadDate={broadDate}
                    broadSeq={broadSeq}
                    windowSize={windowSize}
                    sendIoTMessageMeta={sendIoTMessageMeta}
                    sendIoTMessage={sendIoTMessage}
                  />
                  <IoTClient
                    ref={iotRef}
                    broadSeq={broadSeq}
                    iotRegion={appConfig.iotRegion}
                    iotIdentityPollId={appConfig.iotIdentityPollId}
                    iotEndPoint={appConfig.iotEndPoint}
                    recieveIoTMessage={receiveIoTMobileMessage}
                    iotConnectEnd={iotConnectEnd}
                    iotLost={iotLost}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default MngChannel;
