import React from "react";

import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

type customLinkProps = {
  to: string;
  pageName: string;
  iconType: string;
};

const CustomLink: React.FC<customLinkProps> = ({ to, pageName, iconType }) => {
  return (
    <NavLink to={to} className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
      <ListItem button key={pageName}>
        <ListItemIcon>
          <Icon>{iconType}</Icon>
        </ListItemIcon>
        <ListItemText primary={pageName} />
      </ListItem>
    </NavLink>
  );
};

export default CustomLink;
