import { useRef, useEffect, forwardRef, useImperativeHandle } from "react";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import * as CryptoJS from "crypto-js";
import * as Paho from "paho-mqtt";
import * as AWS from "aws-sdk";

interface propsType {
  broadSeq: string;
  iotRegion: string;
  iotIdentityPollId: string;
  iotEndPoint: string;
  recieveIoTMessage: any;
  iotConnectEnd: any;
  iotLost: any;
}

const IoTClient = (props: propsType, ref: any) => {
  const iotRegion: string = props.iotRegion;
  const iotIdentityPollId: string = props.iotIdentityPollId;
  const iotEndPoint: string = props.iotEndPoint;

  let clientId = useRef<string>("");
  let mqtt_client: any = useRef();
  useEffect(() => {
    if (props.broadSeq !== undefined) {
      AWS.config.region = iotRegion;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: iotIdentityPollId,
      });
      AWS.config.getCredentials((err) => {
        if (err) console.error(err.stack);
        else {
          if (AWS.config.credentials !== undefined) {
            mqtt_client.current = initMqttClient();

            mqtt_client.current.onConnectionLost = function (err: any) {
              console.info("MQTT Connection Lost : ", err);
              props.iotLost();
            };

            mqtt_client.current.onMessageArrived = function (msg: any) {
              const topic = msg.destinationName;
              try {
                const pay_load = JSON.parse(msg.payloadString);
                props.recieveIoTMessage(topic, pay_load, clientId.current);
              } catch (e) {
                console.error("onMessageArrived error : ", e);
              }
            };

            var connectOptions: any = {
              onSuccess: function () {
                try {
                  props.iotConnectEnd();
                  mqtt_client.current?.subscribe(`live/${props.broadSeq}/payload`, {
                    onSuccess: function () {
                      console.info("subscribeSucess");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed retry");
                      // setTimeout(() => {
                      //   subscribeTopic();
                      // }, 1000);
                    },
                  });
                  mqtt_client.current.subscribe(`live/${props.broadSeq}/metadata`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = live");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                  mqtt_client.current.subscribe(`template/${props.broadSeq}/metadata`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = template");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                  mqtt_client.current.subscribe(`stream/${props.broadSeq}/viewerCount`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = stream/viewerCount");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                  mqtt_client.current.subscribe(`stream/${props.broadSeq}/stream_state`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = stream/stream_state");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                  mqtt_client.current.subscribe(`chat/${props.broadSeq}/block`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = chat/message");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                  mqtt_client.current.subscribe(`game/${props.broadSeq}`, {
                    onSuccess: function () {
                      // console.info("subscribeSucess : topic = game");
                    },
                    onFailure: function () {
                      console.error("subscribeFailed");
                    },
                  });
                } catch (e) {
                  console.error("subscribeFailed", e);
                }
              },
              useSSL: true,
              timeout: 3,
              mqttVersion: 4,
              onFailure: function (errorMessage: any) {
                console.error("connectionLost", errorMessage);
              },
            };
            mqtt_client.current.connect(connectOptions);
          }
        }
      });
    }
  }, [props.broadSeq]);

  function SigV4Utils() {}

  SigV4Utils.sign = function (key: any, msg: any) {
    var hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.sha256 = function (msg: any) {
    var hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.getSignatureKey = function (key: any, dateStamp: any, regionName: any, serviceName: any) {
    var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
    return kSigning;
  };

  const initMqttClient = () => {
    var time = moment.utc();
    var dateStamp = time.format("YYYYMMDD");
    var amzdate = dateStamp + "T" + time.format("HHmmss") + "Z";
    var service = "iotdevicegateway";
    var region = AWS.config.region;
    var accessKey = AWS.config.credentials?.accessKeyId;
    var secretKey = AWS.config.credentials?.secretAccessKey;
    var algorithm = "AWS4-HMAC-SHA256";
    var method = "GET";
    var canonicalUri = "/mqtt";
    var host = iotEndPoint;

    var credentialScope = dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
    var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
    canonicalQuerystring += "&X-Amz-Credential=" + encodeURIComponent(accessKey + "/" + credentialScope);
    canonicalQuerystring += "&X-Amz-Date=" + amzdate;
    canonicalQuerystring += "&X-Amz-Expires=86400";
    canonicalQuerystring += "&X-Amz-SignedHeaders=host";

    var canonicalHeaders = "host:" + host + "\n";
    var payloadHash = SigV4Utils.sha256("");
    var canonicalRequest = method + "\n" + canonicalUri + "\n" + canonicalQuerystring + "\n" + canonicalHeaders + "\nhost\n" + payloadHash;

    var stringToSign = algorithm + "\n" + amzdate + "\n" + credentialScope + "\n" + SigV4Utils.sha256(canonicalRequest);
    var signingKey = SigV4Utils.getSignatureKey(secretKey, dateStamp, region, service);
    var signature = SigV4Utils.sign(signingKey, stringToSign);
    canonicalQuerystring += "&X-Amz-Signature=" + signature;
    if (AWS.config.credentials?.sessionToken !== undefined) {
      canonicalQuerystring += "&X-Amz-Security-Token=" + encodeURIComponent(AWS.config.credentials.sessionToken);
    }

    var requestUrl = "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
    clientId.current = "live-mobile-client-" + uuidv4();
    var mqtt_client = new Paho.Client(requestUrl, clientId.current);

    return mqtt_client;
  };

  const sendIoTMessageMeta = (topic: string, meta: string) => {
    const iotPayload = { senderId: clientId.current, message: meta };
    sendIoTMessage(topic, iotPayload);
  };

  const sendIoTMessage = (topic: string, payload: any) => {
    mqtt_client.current.send(topic, JSON.stringify(payload), 1);
  };

  useImperativeHandle(ref, () => ({
    sendIoTMessageMeta,
    sendIoTMessage,
  }));

  return <></>;
};

export default forwardRef(IoTClient);
