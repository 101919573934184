import { useEffect, useState, useRef } from "react";
import { Storage } from "aws-amplify";

// mui component
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpClientApi } from "../../../service/client-rest-api";
import { HttpChannelApi, PutBroadNotice, PutChannelImg } from "../../../service/channel-api";
import { CommonUtils } from "../../../service/common_utils";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import ImageViewer from "../../../service/image/ImageViewer";

interface propsType {
  userState: userState;
  broadInfo: any;
  ivsInfo: any;
  getChannelInfo: any;
  sendIoTMessageMeta: any;
}

const clientApi = new HttpClientApi();
const channelApi = new HttpChannelApi();

const ChangeData = (props: propsType) => {
  const [loading, setLoading] = useState(false);
  const cUtils = new CommonUtils();

  const toastRef: any = useRef();
  const imageViewerRef: any = useRef();
  const channelImgInputRef = useRef<HTMLInputElement>();

  const [openBroadNotice, setOpenBroadNotice] = useState(false);
  const [broadNotice, setBroadNotice] = useState("");

  // 공지사항 보기
  const moreBroadNotice = () => {
    setOpenBroadNotice(true);
  };

  // 공지사항 수정하기
  const saveBroadNotice = async () => {
    if (!window.confirm("공지사항을 수정하시겠습니까?")) return;
    setLoading(true);

    const param: PutBroadNotice = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      broad_notice: broadNotice,
    };
    const res = await channelApi.put_broad_notice(param);
    if (res.result_code === "200") {
      setOpenBroadNotice(false);
      toastRef.current?.toast("공지사항을 수정했습니다.", "success", 3000);
      props.sendIoTMessageMeta("reloadChannelInfo");
      props.getChannelInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  // 대표이미지 미리보기
  const generateChannelImgUrl = async (path: string) => {
    const image_url = await clientApi.get_presigned_url(path);
    return image_url.result_body.url;
  };

  const previewChannelImg = async () => {
    if (props.broadInfo.channel_img !== "") imageViewerRef.current.open(await generateChannelImgUrl(props.broadInfo.channel_img));
  };

  // 채널이미지 등록
  const registChannelImg = () => {
    if (props.broadInfo.broad_seq !== "" && props.broadInfo.broad_seq !== undefined) {
      if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
        // 권한
        window.alert("다른 사람의 채널을 수정하실 수 없습니다.");
        return;
      }
      channelImgInputRef.current?.click(); // S3에 이미지 업로드
    } else {
      window.alert("채널을 먼저 생성 후 대표이미지를 등록해주세요.");
    }
  };

  const uploadS3 = async (dirName: string, filePath: string, file: any) => {
    let result = "fail";
    try {
      const data = await Storage.put(filePath, file, { customPrefix: { public: dirName } });
      result = data.key;
    } catch (e) {
      console.error("fail uploadding : ", e);
    }
    return result;
  };

  const handleChannelImgInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      const newFileName = "channel_img";
      const dirName = "channel-img/";
      const photoKey = props.broadInfo.broad_seq + "/" + newFileName;
      const uploadResult = await uploadS3(dirName, photoKey, file);
      if (uploadResult === "fail") return;
      // setChannelImg(dirName + photoKey);
      saveChannelImg(dirName + photoKey);
    }
  };

  const saveChannelImg = async (_imgPath: string) => {
    setLoading(true);
    const param: PutChannelImg = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      channel_img: _imgPath,
    };
    const res = await channelApi.put_channel_img(param);
    if (res.result_code === "200") {
      toastRef.current?.toast("대표이미지를 수정했습니다.", "success", 3000);
      props.sendIoTMessageMeta("reloadChannelInfo");
      props.getChannelInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  const deleteChannelImg = async () => {
    if (!window.confirm("대표이미지를 삭제하시겠습니까?")) return;

    setLoading(true);
    const param: PutChannelImg = {
      pk: props.broadInfo.pk,
      broad_seq: props.broadInfo.broad_seq,
      channel_img: "",
    };
    const res = await channelApi.put_channel_img(param);
    if (res.result_code === "200") {
      toastRef.current?.toast("대표이미지를 삭제했습니다.", "success", 3000);
      props.sendIoTMessageMeta("reloadChannelInfo");
      props.getChannelInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    setBroadNotice(props.broadInfo.broad_notice);
  }, [props.broadInfo.broad_notice]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenBroadNotice(false);
      setBroadNotice("");
      setLoading(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(props.broadInfo) && (
        <Box>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
            <Button
              variant="outlined"
              onClick={() => {
                moreBroadNotice();
              }}
              sx={{ minWidth: 12, maxWidth: 220 }}
              fullWidth
              endIcon={<SavedSearchIcon />}
            >
              공지사항
            </Button>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => previewChannelImg()} endIcon={props.broadInfo.channel_img !== "" ? <SearchIcon /> : <></>}>
                대표이미지
              </Button>
              {props.broadInfo.channel_img === "" ? (
                <Button onClick={() => registChannelImg()} endIcon={<AddCircleOutlineIcon />}>
                  등록
                </Button>
              ) : (
                <>
                  <Button onClick={() => registChannelImg()} endIcon={<PublishedWithChangesIcon />}>
                    변경
                  </Button>
                  <Button onClick={() => deleteChannelImg()} endIcon={<DeleteIcon />}>
                    삭제
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Stack>
        </Box>
      )}
      {/* 공지사항 */}
      <Dialog
        open={openBroadNotice}
        onClose={() => {
          setOpenBroadNotice(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "80%" } } }}
      >
        <DialogTitle>{"공지사항"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
            <TextField
              id="txtNotice"
              multiline
              rows={15}
              variant="filled"
              size="small"
              value={broadNotice}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              onChange={(e) => {
                setBroadNotice(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              saveBroadNotice();
            }}
          >
            수정
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenBroadNotice(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* 공지사항 */}
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
      <ImageViewer ref={imageViewerRef} />
      <Input
        key="inputLogo"
        color="primary"
        type="file"
        inputRef={channelImgInputRef}
        onChange={handleChannelImgInput}
        sx={{ display: "none" }}
      />
    </>
  );
};

export default ChangeData;
