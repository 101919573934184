import { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import LoadingCircle from "../../utilities/LoadingCircle";
import { HttpTheHyundaiApi, CreateManagerParam } from "../../service/thehyundai-rest-api";

const CreateManager = () => {
  const [theHyundaiApi] = useState(() => new HttpTheHyundaiApi());
  const [isLoading, setIsLoading] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerPassword, setManagerPassword] = useState("");
  const [managerMemo, setManagerMemo] = useState("");

  const createManager = async () => {
    if (managerId === "" || managerId === undefined) {
      window.alert("관리자 ID를 입력하세요.");
      return;
    }
    if (managerName === "" || managerName === undefined) {
      window.alert("관리자 이름을 입력하세요.");
      return;
    }
    if (managerPassword === "" || managerPassword === undefined) {
      window.alert("관리자가 사용할 비밀번호를 입력하세요.");
      return;
    }

    const param: CreateManagerParam = {
      managerId: managerId,
      managerName: managerName,
      password: managerPassword,
      memo: managerMemo,
    };

    setIsLoading(true);
    const res = await theHyundaiApi.create_broadcast_manager(param);
    if (res.code === "200") createBroadManagerSuccess();
    else createBroadManagerFail(res);
  };

  const createBroadManagerSuccess = () => {
    alert("관리자 등록에 성공하였습니다.");
    setIsLoading(false);
  };
  const createBroadManagerFail = (error: any) => {
    if (error.code === "201" || error.code === "202") alert(`${error.response.error_msg}`);
    else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${error.code}\n- message : ${error.response.error_msg}`);
    setIsLoading(false);
  };

  return (
    <>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Stack spacing={2} direction="column">
          <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: "bold", color: "orange" }}>
            관리자 생성 예제
          </Typography>
          <Divider />
          <Typography variant="body1" component="div" gutterBottom>
            관리자를 등록하는 이유는 채널을 생성하거나 수정 또는 삭제 그리고 방송 송출 권한을 부여하기 위함
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            API URL
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev/thehyundai
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Parameters
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - command (string) : "create_broadcast_manager"
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_id (string) : 방송을 관리하는 관리자가 사용할 아이디(email format). 향후 채널 생성/수정/삭제 및
            방송 송출시 해당 ID를 필수로 전송
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - manager_name (string) : 방송 관리자의 이름
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - password (string) : 방송 관리자의 비밀번호
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            - memo : 관리자 Remark
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Response
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`성공시 ${JSON.stringify({ code: "200", response: { msg: "string" } })}`}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {`실패시 ${JSON.stringify({ code: "500|201...", response: { error_msg: "string" } })}`}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: "bold" }}>
            Demo
          </Typography>
          <TextField
            id="txtManagerId"
            label="Manager ID"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerId}
            onChange={(e) => {
              setManagerId(e.target.value);
            }}
          />
          <TextField
            id="txtManagerName"
            label="Manager Name"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerName}
            onChange={(e) => {
              setManagerName(e.target.value);
            }}
          />
          <TextField
            id="txtManagerPassword"
            label="Pasword"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerPassword}
            onChange={(e) => {
              setManagerPassword(e.target.value);
            }}
          />
          <TextField
            id="txtManagerMemo"
            label="Memo"
            variant="standard"
            type="search"
            autoComplete="off"
            inputProps={{ enterKeyHint: "Enter" }}
            value={managerMemo}
            onChange={(e) => {
              setManagerMemo(e.target.value);
            }}
          />
          <Button variant="contained" onClick={createManager}>
            관리자 생성 요청
          </Button>
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default CreateManager;
