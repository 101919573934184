import React, { useEffect, useRef, useState } from "react";
import "../../thdstyle.css";
import "./TheHyundaiEmoji.css";

const TheHyundaiEmoji = (props: { emoji: any; setEmoji: any; emojiCategory: string }) => {
  const { emoji, setEmoji, emojiCategory } = props;
  const removeTheHyundai = () => {
    const activeSvg = [...emoji];
    activeSvg.shift();
    setEmoji(activeSvg);
  };

  const [done, setDone] = useState(false);

  const animationReqId = useRef<any>();
  const innerRef = useRef<any>(null);
  const outerRef = useRef<any>(null);

  useEffect(() => {
    let x = parseFloat("6");
    let y = parseFloat("12");

    let phase = Math.random() * 50;
    let radius = Math.random() * 1;
    let speed = 1 + Math.random() * 2;
    let scale = 0.8 + Math.random() * 1.1;
    let grow = 0.01;
    let alpha = 1;
    let deg = Math.random() * 6 * 10 - 30;

    // innerRef.current.style.background = `url(${thdPath}) no-repeat top left`

    const draw = () => {
      if (outerRef.current !== null) {
        outerRef.current.style.transform = `translateX(${x}px) translateY(${y}px) translateZ(0) scale(${grow}) rotate(${deg}deg)`;
        outerRef.current.style.opacity = alpha;
      }
    };

    const update = () => {
      if (alpha > 0) {
        if (y > -200) alpha -= 0.003;
        else alpha -= 0.03;
      }

      if (alpha < 0) {
        alpha = 0;
      }

      x += Math.cos(phase / 30) * radius;
      y -= speed;

      grow += (scale - grow) / 10;
      phase += 1;

      const isDone = y < -500 || alpha <= 0;

      setDone(isDone);
    };

    const loop = () => {
      animationReqId.current = requestAnimationFrame(loop);

      update();
      draw();
    };

    loop();

    return () => {
      if (animationReqId.current) {
        cancelAnimationFrame(animationReqId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!done) return;

    if (animationReqId.current) {
      cancelAnimationFrame(animationReqId.current);
    }

    removeTheHyundai();
  }, [done, removeTheHyundai]);

  return (
    <div className={`${emojiCategory}-outer`} ref={outerRef}>
      <div className={`${emojiCategory}-inner`} ref={innerRef}></div>
    </div>
  );
};

export default TheHyundaiEmoji;
